const { useSelector } = require('react-redux');
const { useLocation } = require('react-router');
const { groupSelectors } = require('redux/group');
const { postSelectors } = require('redux/post');

const groupMatch = /^#?\/groups\/(?<id>\d+)\/?$/;
const newsfeedMatch = /^#?\/messages\/(?<id>\d+)\/?$/;
const postMatch = /^#?\/post\/(?<id>\d+)\/?$/;

const useSmartPostCreateInitialValues = () => {

  const { pathname } = useLocation();

  const channelId = useSelector(state => {
    const newsfeedId = pathname.match(newsfeedMatch)?.groups?.id;
    if (newsfeedId) {
      return newsfeedId;
    }

    const groupId = pathname.match(groupMatch)?.groups?.id;
    if (groupId) {
      return groupSelectors.getGroup(state, groupId)?.channelId;
    }

    const postId = pathname.match(postMatch)?.groups?.id;
    if (postId) {
      return postSelectors.getPost(state, postId)?.channel;
    }

    return null;
  });

  return channelId
    ? { channelId: +channelId }
    : {};
};

export default useSmartPostCreateInitialValues;

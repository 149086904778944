export default {
  getOverview: 'birthday/GET_OVERVIEW',
  getOverviewSuccess: 'birthday/GET_OVERVIEW_SUCCESS',
  getOverviewFailure: 'birthday/GET_OVERVIEW_FAILURE',

  getBirthdays: 'birthday/GET_BIRTHDAYS',
  getBirthdaysSuccess: 'birthday/GET_BIRTHDAYS_SUCCESS',
  getBirthdaysFailure: 'birthday/GET_BIRTHDAYS_FAILURE',

  getBirthdayEvent: 'birthday/GET_BIRTHDAY_EVENT',
  getBirthdayEventSuccess: 'birthday/GET_BIRTHDAY_EVENT_SUCCESS',
  getBirthdayEventFailure: 'birthday/GET_BIRTHDAY_EVENT_FAILURE',

  addComment: 'birthday/ADD_COMMENT',
  addCommentSuccess: 'birthday/ADD_COMMENT_SUCCESS',
  addCommentFailure: 'birthday/ADD_COMMENT_FAILURE',

  getComments: 'birthday/GET_COMMENTS',
  getCommentsSuccess: 'birthday/GET_COMMENTS_SUCCESS',
  getCommentsFailure: 'birthday/GET_COMMENTS_FAILURE',
};

import React, { useContext } from 'react';
import MediaGallery from 'components/MediaGallery';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import AttachmentImage from 'components/AttachmentImage';
import VideoPlayer from 'components/VideoPlayer';
import { useTheme } from '@emotion/react';
import CommentContext from './CommentContext';

const Wrapper = styled('div')`
    width: 100%;

    /*
     * Border radius redefined here,
     * because of zoom we can't add "overflow: hidden" to container,
     * otherwise zoom wouldn't work
     */

    &, div, img {
      border-top-left-radius: ${({ isMe }) => isMe ? '8px' : '4px'};
      border-top-right-radius: ${({ isMe }) => isMe ? '4px' : '8px'};
    }
`;

const CommentMedia = ({ media, ...rest }) => {

  const { isMe } = useContext(CommentContext);

  const theme = useTheme();

  if (!media) {
    return null;
  }

  return (
    <Wrapper isMe={isMe}>
      <MediaGallery items={media} {...rest}>
        {item => (
          item.type === 'video'
            ? <VideoPlayer url={item.url} poster={item.poster} {...rest} />
            : (
              <AttachmentImage
                className="feed-image"
                src={item.src}
                srcSet={item.srcSet}
                sizes={theme.feedImageSrcSetSizes}
                zoomable={false}
                {...rest}
              />
            )
        )}
      </MediaGallery>
    </Wrapper>
  );
};

CommentMedia.propTypes = {
  media: PropTypes.array,
};

export default CommentMedia;

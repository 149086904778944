import React, { useEffect } from 'react';
import Dialog from 'components/Dialog/Dialog';
import DialogTitle from 'components/Dialog/DialogTitle';
import DialogActions from 'components/Dialog/DialogActions';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { useDispatch, useSelector } from 'react-redux';
import { challengeSelectors, challengeActions } from 'redux/challenge';
import { ChallengeBadge } from 'components/Challenge';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import history from 'providers/history';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { format } from 'services/date-fns';

const StyledTableRowPropsRemoved = ({ faded, ...rest }) => <TableRow {...rest} />; // eslint-disable-line
const StyledTableRow = styled(StyledTableRowPropsRemoved)`

  &:hover td {
    background: #eee;
  }

  ${({ faded }) => faded && css`
    opacity: .5;

    transition: opacity .3s;
    cursor: pointer;
  `}
`;

const FixedHeightTableWrapper = styled('div')`
  overflow-y: scroll;
  height: 400px;
  width: 100%;
  padding-bottom: 20%;
`;

const NameCell = styled(TableCell)`
  font-weight: 500;
`;

const AllChallengesDialog = ({ open, onClose, ...rest }) => {

  const { t } = useTranslation(['component']);

  const dispatch = useDispatch();

  const challenges = useSelector(challengeSelectors.getChallenges);

  useEffect(() => {
    if (open) {
      dispatch(challengeActions.getChallenges());
    }
  }, [dispatch, open]);

  const getLinkProps = challenge => ({
    onClick: () => history.push(`/challenges/${challenge.id}`),
  });

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} {...rest}>
      <DialogTitle>{t('allChallengesDialog.title')}</DialogTitle>
      <FixedHeightTableWrapper>
        <Table size="small">
          <TableBody>
            {/* Hide future challenges */}
            {challenges.map(challenge => (challenge.endedAt || challenge.active) && (
              <StyledTableRow faded={!challenge.active && challenge.endedAt} key={challenge.id}>
                <TableCell padding="none"><ChallengeBadge size="32" src={challenge.badge} title={challenge.title} {...getLinkProps(challenge)} style={{ marginLeft: 0, marginRight: 0 }} /></TableCell>
                <NameCell {...getLinkProps(challenge)}>{challenge.title}</NameCell>
                <TableCell align="right" padding="checkbox" style={{ fontSize: '0.9rem' }}>
                  {challenge.endedAt && <span title={format(new Date(challenge.endedAt), 'PPPPp')}>{t('allChallengesDialog.challengeFinishedAt', { date: format(new Date(challenge.endedAt), 'P') })} </span>}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </FixedHeightTableWrapper>
      <DialogActions>
        <Button onClick={onClose}>{t('allChallengesDialog.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

AllChallengesDialog.propTypes = {
  ...Dialog.propTypes,
};

export default AllChallengesDialog;

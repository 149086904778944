import { join, call, cancel, fork, takeEvery, take, race, delay, put } from 'redux-saga/effects';

export function takeLeadingPerKey(patternOrChannel, worker, keySelector, ...args) {
  return fork(function* takeLeadingPerKeyFork() {
    const tasks = {};

    yield takeEvery(patternOrChannel, function* takeLeadingPerKeyForkTakeEvery(action) {
      const key = yield call(keySelector, action);

      if (!(tasks[key] && tasks[key].isRunning())) {
        tasks[key] = yield fork(worker, ...args, action);

        yield join(tasks[key]);

        if (tasks[key] && !tasks[key].isRunning()) {
          delete tasks[key];
        }
      }
    });
  });
}

export function* takeOnce(pattern, saga, ...args) {
  const task = yield fork(function* takeOnceFork() {
    const action = yield take(pattern);
    yield call(saga, ...args.concat(action));
  });
  return task;
}

export function takeLatestPerKey(patternOrChannel, worker, keySelector, ...args) {
  return fork(function* takeLatestPerKeyFork() {
    const tasks = {};

    yield takeEvery(patternOrChannel, function* takeLatestPerKeyForkTakeEvery(action) {
      const key = yield call(keySelector, action);

      if (tasks[key]) {
        yield cancel(tasks[key]);
      }

      tasks[key] = yield fork(worker, ...args, action);

      yield join(tasks[key]);

      if (tasks[key] && !tasks[key].isRunning()) {
        delete tasks[key];
      }
    });
  });
}

export function takeEveryAndAggregate(pattern, actionToPayloadAggregator, callAction, debounce = 300) {
  return fork(function* takeEveryAndAggregateFork() {
    while (true) {
      const initAction = yield take(pattern);

      let actions = [initAction];

      while (true) {
        const { debounced, nextAction } = yield race({
          debounced: delay(debounce),
          nextAction: take(pattern),
        });

        if (nextAction) {
          actions = [...actions, nextAction];
        }

        if (debounced) {
          yield put(callAction(actionToPayloadAggregator(actions)));
          break;
        }
      }
    }
  });

}

import { all, takeLeading, put, debounce, select } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import types from './types';
import actions from './actions';

function* onGetMemberFeed({ payload }) {
  const { ok, error, response } = yield apiCall(api.member.getMemberFeed, payload);
  if (ok) {
    yield put(actions.getMemberFeedSuccess({ memberId: payload.memberId, ...response }));
  } else {
    yield put(actions.getMemberFeedFailure(error));
  }
}

function* onGetMembersForSearchField({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.workspace.member.index, { workspaceId, limit: 9999, ...payload });
  if (ok) {
    yield put(actions.getMembersForSearchFieldSuccess(response));
  } else {
    yield put(actions.getMembersForSearchFieldFailure(error));
  }
}

export default function* userSagas() {
  yield all([
    takeLeading(types.getMemberFeed, onGetMemberFeed),
    debounce(300, types.getMembersForSearchField, onGetMembersForSearchField),
  ]);
}

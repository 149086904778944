import update from 'immutability-helper';
import { calendarTypes } from 'redux/calendar';

const coursesReducer = (state = {}, { type, payload }) => {

  switch (type) {

    case calendarTypes.updateCourseSuccess:
      // No merging here. Just replace
      return update(state, {
        [payload.data]: { $set: payload.entities.courses[payload.data] },
      });

    default:
      return state;
  }
};

export default coursesReducer;

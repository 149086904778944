import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getComment: data => createAction(types.getComment, data),
  getCommentSuccess: data => createAction(types.getCommentSuccess, data),
  getCommentFailure: error => createAction(types.getCommentFailure, error),

  getLikes: ({ commentId }) => createAction(types.getLikes, { commentId }),
  getLikesSuccess: data => createAction(types.getLikesSuccess, data),
  getLikesFailure: error => createAction(types.getLikesFailure, error),

  removeLike: data => createAction(types.removeLike, data),
  removeLikeSuccess: ({ commentId }) => createAction(types.removeLikeSuccess, { commentId }),
  removeLikeFailure: error => createAction(types.removeLikeFailure, error),

  addLike: data => createAction(types.addLike, data),
  addLikeSuccess: ({ commentId }) => createAction(types.addLikeSuccess, { commentId }),
  addLikeFailure: error => createAction(types.addLikeFailure, error),

  // TODO: Define data
  delete: data => createAction(types.delete, data),
  deleteSuccess: data => createAction(types.deleteSuccess, data),
  deleteFailure: error => createAction(types.deleteFailure, error),

  // TODO: Define data
  update: data => createAction(types.update, data),
  updateSuccess: data => createAction(types.updateSuccess, data),
  updateFailure: error => createAction(types.updateFailure, error),

  getTranslation: data => createAction(types.getTranslation, data),
  getTranslationSuccess: data => createAction(types.getTranslationSuccess, data),
  getTranslationFailure: error => createAction(types.getTranslationFailure, error),
};

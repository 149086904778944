import { selectPagination } from 'redux/helpers/pagination';

const getNotification = ({ entities: { notifications } }, id) => notifications[id];

const getNotifications = ({ user: { notifications } }) => selectPagination(notifications, null, 'notificationIds');

const getNotificationSettings = ({ user: { notificationSettings } }) => notificationSettings;

const getSettings = ({ user: { settings } }) => settings;

export default {
  getNotifications,
  getNotification,
  getNotificationSettings,
  getSettings,
};

import { pollTypes } from 'redux/poll';
import update from 'immutability-helper';

const removeVote = id => ({ [id]: { votesCount: { $apply: votes => votes !== null ? votes - 1 : null } } });
const addVote = id => ({ [id]: { votesCount: { $apply: votes => votes !== null ? votes + 1 : null } } });
const removeOption = (pollId, optionId) => ({ [pollId]: { options: { $apply: opts => opts.filter((opt) => (opt !== optionId)) } } });

const pollsReducer = (state = {}, { type, payload }) => {

  switch (type) {
    case pollTypes.storeVote:
    case pollTypes.deleteVoteFailure:
      return update(state, addVote(payload.pollId));

    case pollTypes.deleteVote:
    case pollTypes.storeVoteFailure:
      return update(state, removeVote(payload.pollId));

    case pollTypes.removeOptionSuccess:
      return update(state, removeOption(payload.pollId, payload.optionId));
    default:
      return state;
  }
};

export default pollsReducer;

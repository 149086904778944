import React, { useContext } from 'react';
import Grid from 'components/Grid';
import DialogActions from 'components/Dialog/DialogActions';
import { isMobile } from 'helpers';
import PostFormContext from '../PostFormContext';

const style = isMobile ? { marginTop: 20 } : {};

const PostFormFooter = args => useContext(PostFormContext).asDialog
  ? <DialogActions gray {...args} />
  : <Grid item {...args} style={style} />;

export default PostFormFooter;

import React, { useContext, useEffect } from 'react';
import NavLink from 'components/NavLink';
import SideContext from 'containers/Side/SideContext';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { birthdayActions, birthdaySelectors } from 'redux/birthday';
import MemberList from './MemberList';
import { getCloseButtonLink } from '../memberEventsService';

const Birthdays = (args) => {

  const dispatch = useDispatch();
  const { t } = useTranslation(['component']);

  const { members, pagination } = useSelector(birthdaySelectors.getBirthdayEvents);

  const loadMore = () => dispatch(birthdayActions.getBirthdays({ page: pagination.nextPage }));

  const { setTitle } = useContext(SideContext);

  useEffect(() => {
    dispatch(birthdayActions.getBirthdays());
  }, [dispatch]);

  useEffect(() => {
    setTitle(({
      backButton: { component: NavLink, to: getCloseButtonLink },
      children: t('birthdayEvents.title'),
    }));
  }, [setTitle, t]);

  return (
    <MemberList
      type="Birthday"
      members={members}
      currentPage={pagination.currentPage}
      hasMore={pagination.hasMore}
      loadMore={loadMore}
      useWindow={false}

      texts={{
        loading: t('birthdayEvents.loadingBirthdays'),
        loadingMore: t('birthdayEvents.loadingMoreBirthdays'),
        noEvents: t('birthdayEvents.noBirthdays'),
        noMoreEvents: t('birthdayEvents.noMoreBirthdays'),
      }}

      {...args}
    />
  );
};

export default Birthdays;

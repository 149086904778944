import combineReducers from 'combine-reducers-global-state';
import { addToPagination, createPagination, resetPagination } from '../helpers/pagination';
import types from './types';

const birthdayEventsOverview = (state = { today: [], upcoming: [] }, {
  type,
  payload,
}) => {
  switch (type) {
    case types.getOverviewSuccess:
      return { ...state, today: payload.today, upcoming: payload.upcoming };
    case types.getOverviewFailure:
      return { ...state, today: [], upcoming: [] };
    default:
      return state;
  }
};

const birthdayEvents = (state = createPagination(null, 'members'), { type, payload }) => {
  switch (type) {
    case types.getBirthdaysSuccess:
      return addToPagination(state, null, payload, 'members');
    default:
      return state;
  }
};

const birthdayEventOpened = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getBirthdayEventSuccess:
      return { ...state, ...payload };
    case types.getBirthdayEventFailure:
    case types.getBirthdayEvent:
      return {};
    default:
      return state;
  }
};

const commentsByBirthdayEventId = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCommentsSuccess:
      return addToPagination(state, payload.eventId, payload, 'commentIds');
    case types.addCommentSuccess:
      return resetPagination(state, payload.eventId, 'commentIds');
    default:
      return state;
  }
};

const birthdayReducers = combineReducers({
  birthdayEvents,
  birthdayEventsOverview,
  birthdayEventOpened,
  commentsByBirthdayEventId,
});

export default { birthday: birthdayReducers };

import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  store: data => createAction(types.store, data),
  storeSuccess: data => createAction(types.storeSuccess, data),
  storeFailure: data => createAction(types.storeFailure, data),

  update: data => createAction(types.update, data),
  updateSuccess: data => createAction(types.updateSuccess, data),
  updateFailure: data => createAction(types.updateFailure, data),

  patch: data => createAction(types.patch, data),
  patchSuccess: data => createAction(types.patchSuccess, data),
  patchFailure: data => createAction(types.patchFailure, data),

  delete: data => createAction(types.delete, data),
  deleteSuccess: data => createAction(types.deleteSuccess, data),
  deleteFailure: data => createAction(types.deleteFailure, data),

  addComment: data => createAction(types.addComment, data),
  addCommentSuccess: data => createAction(types.addCommentSuccess, data),
  addCommentFailure: data => createAction(types.addCommentFailure, data),

  get: ({ postId }) => createAction(types.get, { postId }),
  getSuccess: data => createAction(types.getSuccess, data),
  getFailure: error => createAction(types.getFailure, error),

  getReachPercentage: ({ postId, ...data }) => createAction(types.getReachPercentage, { postId, ...data }),
  getReachPercentageSuccess: data => createAction(types.getReachPercentageSuccess, data),
  getReachPercentageFailure: error => createAction(types.getReachPercentageFailure, error),

  getReachPercentages: ({ postIds, ...data }) => createAction(types.getReachPercentages, { postIds, ...data }),
  getReachPercentagesSuccess: data => createAction(types.getReachPercentagesSuccess, data),
  getReachPercentagesFailure: error => createAction(types.getReachPercentagesFailure, error),

  getComments: ({ postId, ...data }) => createAction(types.getComments, { postId, ...data }),
  getCommentsSuccess: data => createAction(types.getCommentsSuccess, data),
  getCommentsFailure: error => createAction(types.getCommentsFailure, error),

  getLikes: ({ postId }) => createAction(types.getLikes, { postId }),
  getLikesSuccess: data => createAction(types.getLikesSuccess, data),
  getLikesFailure: error => createAction(types.getLikesFailure, error),

  removeLike: data => createAction(types.removeLike, data),
  removeLikeSuccess: ({ postId }) => createAction(types.removeLikeSuccess, { postId }),
  removeLikeFailure: error => createAction(types.removeLikeFailure, error),

  addLike: data => createAction(types.addLike, data),
  addLikeSuccess: data => createAction(types.addLikeSuccess, data),
  addLikeFailure: error => createAction(types.addLikeFailure, error),

  follow: data => createAction(types.follow, data),
  followSuccess: data => createAction(types.followSuccess, data),
  followFailure: error => createAction(types.followFailure, error),

  unfollow: data => createAction(types.unfollow, data),
  unfollowSuccess: data => createAction(types.unfollowSuccess, data),
  unfollowFailure: error => createAction(types.unfollowFailure, error),

  getSmsExpenseEstimation: data => createAction(types.getSmsExpenseEstimation, data),
  getSmsExpenseEstimationSuccess: data => createAction(types.getSmsExpenseEstimationSuccess, data),
  getSmsExpenseEstimationFailure: data => createAction(types.getSmsExpenseEstimationFailure, data),
  smsExpenseEstimationReset: data => createAction(types.smsExpenseEstimationReset, data),

  getTranslations: data => createAction(types.getTranslations, data),
  getTranslationsSuccess: data => createAction(types.getTranslationsSuccess, data),
  getTranslationsFailure: data => createAction(types.getTranslationsFailure, data),

  getFeed: data => createAction(types.getFeed, data),
  getFeedSuccess: data => createAction(types.getFeedSuccess, data),
  getFeedFailure: data => createAction(types.getFeedFailure, data),
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { challengeActions, challengeSelectors } from 'redux/challenge';
import Challenge from 'containers/Challenge/Challenge';
import AllChallengesDialog from 'containers/Challenge/AllChallengesDialog';
import HorizontalScroller from 'components/HorizontalScroller';
import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/SectionTitle';
import useFeature from 'hooks/useFeature';

const ActiveChallenges = args => {

  const dispatch = useDispatch();

  const { t } = useTranslation(['component']);

  const [seeMoreOpen, setSeeMoreOpen] = useState(false);

  const { enabled } = useFeature('challenges');

  const challenges = useSelector(challengeSelectors.getChallenges);

  useEffect(() => {
    if (enabled) {
      dispatch(challengeActions.getChallenges({ active: 1, limit: 5 }));
    }
  }, [dispatch, enabled]);

  if (!challenges || challenges.length === 0 || !enabled) {
    return null;
  }

  const filter = challenge => challenge.active || (new Date(challenge.startsAt) > new Date() && !challenge.endedAt);

  const activeChallenges = challenges.filter(filter);

  if (activeChallenges.length === 0) {
    return null;
  }

  const seeMore = {
    onClick: () => setSeeMoreOpen(true),
  };

  return (
    <div {...args}>
      <SectionTitle seeMore={seeMore}>{t('challenge.activeChallengesTitle')}</SectionTitle>
      <HorizontalScroller>
        {activeChallenges.map(challenge => <Challenge key={challenge.id} challenge={challenge} />)}
      </HorizontalScroller>
      <AllChallengesDialog open={seeMoreOpen} onClose={() => setSeeMoreOpen(false)} />
    </div>
  );
};

export default ActiveChallenges;

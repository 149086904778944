import update from 'immutability-helper';
import types from '../documentSection/types';

const documentSectionsReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case types.deleteSectionForAdminSuccess:
    {
      const nextState = { ...state };
      delete nextState[payload.sectionId];
      return nextState;
    }

    case types.reorderSectionsForAdmin:
      return update(state, {
        ...payload.sections.reduce((acc, { id, sortOrder }) => ({
          ...acc,
          [id]: {
            sortOrder: { $set: sortOrder },
          },
        }), {}),
      });

    default:
      return state;
  }
};

export default documentSectionsReducer;

import React, { useEffect } from 'react';
import Dialog from 'components/Dialog/Dialog';
import DialogContent from 'components/Dialog/DialogContent';
import DialogActions from 'components/Dialog/DialogActions';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { contractActions, contractSelectors } from 'redux/contract';

const DocumentDialogContent = styled(DialogContent)`

  line-height: 1.5;

  h3:first-child {
    text-align: center;
    margin-bottom: 3rem;
  }

  li {
    margin: 0.5rem 0.3rem 0;
  }
`;

const DocumentDialog = ({ type, open, onClose, ...rest }) => {

  const { t, i18n } = useTranslation(['component']);

  const doc = useSelector(state => contractSelectors.getContract(state, type));
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(contractActions.getContract({ type, language: i18n.language }));
    }
  }, [open, dispatch, i18n, type]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose} {...rest}>
      <DocumentDialogContent dangerouslySetInnerHTML={{ __html: doc }} />
      <DialogActions><Button onClick={onClose}>{t('component:documentDialog.closeButton')}</Button></DialogActions>
    </Dialog>
  );
};

DocumentDialog.propTypes = {
  type: PropTypes.oneOf(['user_terms', 'user_privacy']),

  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DocumentDialog;

import React, { useContext, useEffect } from 'react';

import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import SmsIcon from '@mui/icons-material/Sms';
import useCapabilities from 'containers/Post/PostForm/useCapabilities';
import PostFormContext from 'containers/Post/PostForm/PostFormContext';

const useSmsButtonProps = (args) => {
  const { t } = useTranslation(['component']);

  const { sms } = useCapabilities();

  const { setView, editing } = useContext(PostFormContext);

  const { change } = useForm();

  useEffect(() => {
    if (!sms && !editing) change('sms', undefined);
  }, [change, editing, sms]);

  return {
    startIcon: <SmsIcon />,
    children: t('postForm.addSmsButton'),
    onClick: () => setView('sms'),
    hidden: !sms,
    ...args,
  };
};

export default useSmsButtonProps;

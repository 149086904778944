import { all, takeLeading, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api, apiCall, commonApiSaga } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import { snackbarActions } from 'redux/snackbar';
import { takeLeadingPerKey, takeOnce } from 'redux/helpers/saga';
import types from './types';
import actions from './actions';

function* onGetFeedbacks({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, response, error } = yield apiCall(api.feedback.index, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getFeedbacksSuccess({ status: payload?.status, ...response }));
  } else {
    yield put(actions.getFeedbacksFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDownloadIndex({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.feedback.downloadIndex, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.downloadIndexSuccess({ status: payload?.status, ...response }));
  } else {
    yield put(actions.downloadIndexFailure(error));
  }
}

function* onGet({ payload }) {
  const { ok, response, error } = yield apiCall(api.feedback.show, payload);

  if (ok) {
    yield put(actions.getSuccess(response));
  } else {
    yield put(actions.getFailure(error));
  }
}

function* onStore({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.feedback.store, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.storeSuccess(response));
    yield put(snackbarActions.createSuccess('workspace.feedbackSent'));
  } else {
    yield put(actions.storeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onMassAction({ payload, config }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, error, response } = yield apiCall(api.feedback.massAction, { workspaceId, ...payload }, config);

  if (ok) {
    yield put(actions.massActionSuccess({ action: payload.action, data: payload.data, ...response }));
    yield put(snackbarActions.createSuccess());
  } else {
    yield put(actions.massActionFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onPublicStore({ payload: { workspaceId, ...payload } }) {
  const { ok, response, error } = yield apiCall(api.feedback.publicStore, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.publicStoreSuccess(response));
    yield put(snackbarActions.createSuccess('workspace.feedbackSent'));
  } else {
    yield put(actions.publicStoreFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onStoreType({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.feedbackType.store, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.storeTypeSuccess(response));
  } else {
    yield put(actions.storeTypeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateFeedbackType({ payload }) {
  const { ok, response, error } = yield apiCall(api.feedbackType.update, payload);

  if (ok) {
    yield put(actions.updateFeedbackTypeSuccess(response));
  } else {
    yield put(actions.updateFeedbackTypeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetFeedbackTypes({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.feedbackType.index, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getFeedbackTypesSuccess(response));
  } else {
    yield put(actions.getFeedbackTypesFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetFeedbackType({ payload }) {
  const { ok, response, error } = yield apiCall(api.feedbackType.show, payload);

  if (ok) {
    yield put(actions.getFeedbackTypeSuccess(response));
  } else {
    yield put(actions.getFeedbackTypeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetFeedbackStatuses({ payload }) {
  const { ok, response, error } = yield apiCall(api.feedbackStatus.index, payload);

  if (ok) {
    yield put(actions.getFeedbackStatusesSuccess(response));
  } else {
    yield put(actions.getFeedbackStatusesFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetFeedbackTypePublicUrl({ payload }) {
  const { ok, response, error } = yield apiCall(api.feedbackType.getPublicUrl, payload);

  if (ok) {
    yield put(actions.getFeedbackTypePublicUrlSuccess({ feedbackTypeId: payload.feedbackTypeId, ...response }));
  } else {
    yield put(actions.getFeedbackTypePublicUrlFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteFeedbackType({ payload }) {
  const { ok, response, error } = yield apiCall(api.feedbackType.delete, payload);

  if (ok) {
    yield put(actions.deleteFeedbackTypeSuccess({ ...response, feedbackTypeId: payload.feedbackTypeId }));
  } else {
    yield put(actions.deleteFeedbackTypeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onPatch({ payload }) {
  const { ok, response, error } = yield apiCall(api.feedback.patch, payload);

  if (ok) {
    yield put(actions.patchSuccess(response));
    yield put(snackbarActions.createSuccess('feedback.updated'));
  } else {
    yield put(actions.patchFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDelete({ payload }) {
  const { ok, response, error } = yield apiCall(api.feedback.delete, payload);

  if (ok) {
    yield put(actions.deleteSuccess({ feedbackId: payload.feedbackId, ...response }));
    yield put(snackbarActions.createSuccess('feedback.deleted'));
  } else {
    yield put(actions.deleteFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.feedbackSettings.get, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getSettingsSuccess(response));
  } else {
    yield put(actions.getSettingsFailure(error));
  }
}

function* onUpdateSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.feedbackSettings.update, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.updateSettingsSuccess(response));
  } else {
    yield put(actions.updateSettingsFailure(error));
  }
}

function* onAddComment({ payload }) {
  const { ok, error, response } = yield apiCall(api.feedbackComments.store, payload);

  const { feedbackId, channel } = payload;

  if (ok) {
    yield put(actions.addCommentSuccess({ feedbackId, channel, ...response }));
    yield put(actions.get({ feedbackId, token: payload?.token }));
  } else {
    yield put(actions.addCommentFailure({ feedbackId, channel, ...error }));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetComments({ payload }) {
  const { ok, response, error } = yield apiCall(api.feedbackComments.index, payload);

  const { feedbackId, channel } = payload;

  if (ok) {
    yield put(actions.getCommentsSuccess({ feedbackId, channel, ...response }));
  } else {
    yield put(actions.getCommentsFailure({ feedbackId, channel, ...error }));
  }
}

function* onGetComment({ payload }) {
  const { ok, error, response } = yield apiCall(api.feedbackComments.show, payload);

  if (ok) {
    yield put(actions.getCommentSuccess({ feedbackCommentId: payload.feedbackCommentId, ...response }));
  } else {
    yield put(actions.getCommentFailure(error));
  }
}

function* onCommentUpdate({ payload }) {
  const { ok, error, response } = yield apiCall(api.feedbackComments.update, payload);

  if (ok) {
    yield put(actions.updateCommentSuccess(response));
    yield put(snackbarActions.createSuccess('comment.updated'));
  } else {
    yield put(actions.updateCommentFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onCommentDelete({ payload }) {
  const { ok, error } = yield apiCall(api.feedbackComments.delete, payload);

  if (ok) {
    yield put(actions.deleteCommentSuccess(payload));
    yield put(snackbarActions.createSuccess('comment.deleted'));
  } else {
    yield put(actions.deleteCommentFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}
function* onGetCommentTranslation({ payload }) {
  const { ok, error, response } = yield apiCall(api.feedbackComments.translations, payload);

  if (ok) {
    response.feedbackCommentId = payload.feedbackCommentId;
    yield put(actions.getCommentTranslationSuccess(response));
  } else {
    yield put(actions.getCommentTranslationFailure(error));
  }
}

export default function* feedbackSagas() {
  yield all([
    takeLatest(types.getFeedbacks, onGetFeedbacks),
    takeEvery(types.downloadIndex, onDownloadIndex),

    takeEvery(types.getSettings, onGetSettings),
    takeEvery(types.updateSettings, onUpdateSettings),

    takeLeading(types.store, onStore),
    takeLeading(types.publicStore, onPublicStore),

    takeLeading(types.patch, onPatch),
    takeLeading(types.get, onGet),
    takeLeading(types.delete, onDelete),
    takeLeadingPerKey(types.massAction, onMassAction, ({ payload }) => payload.action),

    takeLeading(types.storeType, onStoreType),
    takeLeading(types.deleteFeedbackType, onDeleteFeedbackType),
    takeLeading(types.updateFeedbackType, onUpdateFeedbackType),

    takeLeading(types.patchFeedbackType, commonApiSaga, { apiFn: api.feedbackType.patch, successMessage: 'generic.success' }),
    takeLeading(types.download, commonApiSaga, { apiFn: api.feedback.download }),

    takeLeading(types.getFeedbackType, onGetFeedbackType),
    takeLeading(types.getFeedbackTypes, onGetFeedbackTypes),
    takeLeading(types.getFeedbackTypePublicUrl, onGetFeedbackTypePublicUrl),

    takeOnce(types.getFeedbackStatuses, onGetFeedbackStatuses),

    takeEvery(types.addComment, onAddComment),
    takeLeadingPerKey(types.getComments, onGetComments, ({ payload }) => payload.feedbackId),
    takeLeadingPerKey(types.getComment, onGetComment, ({ payload }) => payload.feedbackCommentId),
    takeEvery(types.updateComment, onCommentUpdate),
    takeEvery(types.deleteComment, onCommentDelete),
    takeLeadingPerKey(types.getCommentTranslation, onGetCommentTranslation, ({ payload }) => payload.feedbackCommentId),
  ]);
}

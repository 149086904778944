import React, { useRef, useCallback, useMemo, useEffect } from 'react';
import { isAndroid } from 'react-device-detect';
import { createRoot } from 'react-dom/client';

// Capture default true only on Android
// (Android & capture=true) Gives the option to choose from photos, files or camera
// (Apple & capture=true) Takes directly to the camera without the option to choose from existing photos
const captureDefault = isAndroid ? true : undefined;
const useFileInput = ({ onChange, capture = captureDefault, ...config } = {}) => {

  const inputRef = useRef();

  const [parent, parentRoot] = useMemo(() => {
    const element = document.createElement('div');
    element.style.display = 'none';

    return [element, createRoot(element)];
  }, []);

  useEffect(() => {
    document.body.appendChild(parent);
    return () => {
      document.body.removeChild(parent);
    };
  }, [parent]);

  const onFileChange = useCallback(
    ({ target: { files } }) => {
      const response = config.multiple ? Array.from(files) : files[0];
      inputRef.current.value = '';
      onChange(response);
    },
    [config, onChange],
  );

  const child = useMemo(() => <input type="file" capture={capture} ref={inputRef} {...config} onChange={onFileChange} />, [capture, config, onFileChange]);

  useEffect(() => {
    parentRoot.render(child);
  }, [child, parentRoot]);

  const open = useCallback(() => {
    inputRef.current.click();
  }, []);

  return { open };
};

export default useFileInput;

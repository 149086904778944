import update from 'immutability-helper';
import { driveTypes } from 'redux/drive';

const recordsReducer = (state = {}, { type, payload }) => {

  switch (type) {

    case driveTypes.updateRecordSuccess:
      // No merging here. Just replace
      return update(state, {
        [payload.data]: { $set: payload.entities.records[payload.data] },
      });

    default:
      return state;
  }
};

export default recordsReducer;

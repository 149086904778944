import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import CommentDotsIcon from 'components/Icons/CommentDots';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { postSelectors } from 'redux/post';
import NavLink from 'components/NavLink';
import { PostContext } from 'components/Post';
import ColorfulButton from 'components/ColorfulButton';
import styled from '@emotion/styled/macro';

const StyledButton = styled(ColorfulButton)`
  @media(max-width: 359px) {
    .MuiButton-label {
      white-space: initial;
    }
    line-height: 1;
  }
`;

const PostCommentButton = ({ postId, ...rest }) => {

  const { t } = useTranslation(['component']);

  const { isImportant, isCompact } = useContext(PostContext);

  const post = useSelector(state => postSelectors.getPost(state, postId));

  if (!post) {
    return null;
  }

  const suffix = post.commentsCount > 0 ? ` (${post.commentsCount})` : '';

  const commentProps = {
    startIcon: <CommentDotsIcon />,
    children: t('post.comment') + suffix,
    component: NavLink,
    to: `/post/${postId}`,
    onClick: (e) => {
      const el = document.querySelector(`#add-post-comment-${post.id}`);
      if (!el) {
        return;
      }
      el.scrollIntoView({ behavior: 'smooth' });
      if (el.focus({ preventScroll: true }) !== true) {
        // Element can't receive focus. Let's try clicking on it (Mobile div)
        setTimeout(() => el.click());
      }

      // Disable default behaviour if comment input is present
      e.preventDefault();
      return false;
    },
    darker: isImportant && isCompact,
  };

  return <StyledButton {...commentProps} {...rest} />;
};

PostCommentButton.propTypes = {
  postId: PropTypes.any.isRequired,
};

export default PostCommentButton;

import memoize from 'lodash/memoize';
import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';

const getMemberFeed = ({ member: { feedByMemberId } }, memberId) => selectPagination(feedByMemberId, memberId, 'postIds');

const getMemberIdsForSearchField = ({ member: { searchFieldMembers } }) => searchFieldMembers || [];

const getMemberEntities = ({ entities: { members } }) => members;

const getMemberSelector = createSelector(
  [getMemberEntities],
  members => memoize(
    memberId => members[memberId],
  ),
);

const getMembersSelector = createSelector(
  [getMemberEntities],
  members => memoize(
    memberIds => memberIds.map(memberId => members[memberId]),
  ),
);

const getMembersForSearchField = createSelector(
  [getMemberIdsForSearchField, getMemberEntities],
  memoize(
    (ids, entities) => ids.map(id => entities[id]),
    ids => ids.join('-'),
  ),
);

export default {
  getMemberFeed,
  getMemberSelector,
  getMembersSelector,
  getMemberEntities,
  getMemberIdsForSearchField,
  getMembersForSearchField,
};

import React, { useRef } from 'react';
import SmsViewContext from './SmsViewContext';

const SmsProvider = (args) => {

  const save = useRef();

  return (
    <SmsViewContext.Provider value={{ save }} {...args} />
  );
};

export default SmsProvider;

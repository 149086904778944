import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

const getChannelEntities = ({ entities: { channels } }) => channels;

const getChannelSelector = createSelector(
  [getChannelEntities],
  channels => memoize(channelId => channels[channelId]),
);

export default {
  getChannelSelector,
};

import React, { useEffect, useRef } from 'react';
import { authActions, authSelectors, authTypes } from 'redux/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useActionListener } from 'hooks/actionListener/useActionListener';
import config from 'config';
import useFeature from 'hooks/useFeature';
import { isMobile } from 'helpers';
import { useTheme } from '@emotion/react';

const updateWebWidget = ({ user, workspace, theme }) => {
  if (!window.zE) {
    return;
  }

  const userName = user?.name;
  const userEmail = user?.primaryEmail;
  const workspaceName = workspace?.name;

  window.zE('webWidget', 'prefill', {
    name: {
      value: userName ? `${userName} (${workspaceName})` : null,
      readOnly: !!userName, // optional
    },
    email: {
      value: userEmail,
      readOnly: !!userEmail, // optional
    },
  });

  window.zE('webWidget', 'setLocale', user?.locale);

  window.zE('webWidget', 'updateSettings', {
    color: { theme: workspace?.color },
    zIndex: theme?.zIndex?.helpFloat,
  });
};

const ZendeskProvider = args => {

  const dispatch = useDispatch();

  const user = useSelector(authSelectors.getMeUser);
  const workspace = useSelector(authSelectors.getWorkspace);
  const theme = useTheme();

  const zendeskFeature = useFeature('zendesk');

  const callbackFn = useRef(null);
  useActionListener([authTypes.getZendeskTokenSuccess, authTypes.getZendeskTokenFailure], (d, { payload }) => (
    callbackFn.current(payload?.token)
  ));

  useEffect(() => {
    if (!config.zendesk.enabled || !zendeskFeature.enabled || isMobile) {
      return;
    }

    window.zESettings = {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: (callback) => {
              callbackFn.current = callback;
              dispatch(authActions.getZendeskToken());
            },
          },
        },
      },
    };

    if (!document.getElementById('ze-snippet')) {
      const zendesk = document.createElement('script');
      zendesk.setAttribute('id', 'ze-snippet');
      zendesk.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=${config.zendesk.key}`);
      zendesk.onload = () => updateWebWidget({ user, workspace, theme });
      document.body.appendChild(zendesk);
    }
  }, [dispatch, theme, user, workspace, zendeskFeature.enabled]);

  useEffect(() => {
    if (!window.zE) {
      return;
    }
    window.zE('webWidget', 'logout');
  }, [user.id]);

  useEffect(() => {
    updateWebWidget({ user, workspace, theme });
  }, [theme, user, workspace, zendeskFeature]);

  return <React.Fragment {...args} />;
};

export default ZendeskProvider;

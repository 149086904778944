import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Grid from 'components/Grid';
import FormField from 'components/FinalForm/Fields/FormField';
import MediaExhibit from 'containers/MediaExhibit/MediaExhibit';
import AttachmentsExhibit from 'components/AttachmentsExhibit';

import useOpengraph from 'containers/Opengraph/useOpengraph';
import debounce from 'lodash/debounce';
import ToggleableRichEditorField from 'components/FinalForm/Fields/ToggleableRichEditorField';
import PostFormContent from '../Partials/PostFormContent';
import useCapabilities from '../useCapabilities';

import ScheduleField from './Fields/ScheduleField';
import PostTargetField from './Fields/PostTargetField';
import ChannelSelectField from './Fields/ChannelSelectField';
import ActivityCategorySelectField from './Fields/ActivityCategorySelectField';
import ActivityCategorySpecificFields from './Fields/ActivityCategorySpecificFields';
import PollExhibit from './Partials/PollExhibit';
import QuizExhibit from './Partials/QuizExhibit';
import SmsExhibit from './Partials/SmsExhibit';

const BaseViewContent = () => {

  const form = useForm();
  const { values, dirtyFields } = useFormState({
    subscription: {
      values: true,
      dirtyFields: true,
    },
  });

  const capabilities = useCapabilities();

  const { t } = useTranslation(['common', 'component']);

  const label = t(capabilities.activity ? 'field.postActivityBodyLabel' : 'field.postBodyLabel');
  const placeholder = t(capabilities.activity ? 'field.postActivityBodyPlaceholder' : 'field.postBodyPlaceholder');

  useEffect(() => {
    if (!capabilities.rich && dirtyFields.body) {
      form.change('bodyHtml', '');
    }
  }, [capabilities.rich, dirtyFields.body, form]);

  const { parseHtml, parseText, linkData, preview } = useOpengraph({
    variant: 'large',
    disabled: values.media?.filter(({ delete: del }) => !del)?.length > 0,
    initialValue: values.link,
  });

  useEffect(() => {
    form.change('link', linkData ?? []);
  }, [form, linkData]);

  const onInput = (e) => {
    return e?.target?.value !== undefined
      ? parseText(e?.target?.value)
      : parseHtml(e?.target?.outerHTML);
  };

  // Debounce limits updates but also helps with getting the final content through getContent
  // For example didn't find end result anywhere inside the cut event, and when the event is
  // trigger then getContent also returned the previous result. Debounce helps.
  const onContentEvents = debounce((e, editor) => {
    if (!editor?.getContent) {
      return;
    }
    switch (e.type) {
      case 'cut':
      case 'redo':
      case 'undo':
        return parseHtml(editor.getContent());
      default:
    }
  }, 300);

  // Tracking all possible content changes with TinyMCE is difficult
  // For example editing links with dialog doesn't trigger any trackable updates
  // Extra validation on blur event to remove all no longer present urls
  const onRichBlur = (e) => {
    if (e?.target?.getContent) {
      return parseHtml(e?.target?.getContent());
    }
  };

  return (
    <PostFormContent>
      <Grid container direction="column" wrap="nowrap" spacing={2}>

        {capabilities.activity && (
          <Grid item>
            <ActivityCategorySelectField name="activity.categoryId" margin="dense" />
          </Grid>
        )}

        <Grid item>
          {capabilities.rich
            ? <ToggleableRichEditorField multiline minRows={3} maxRows={12} name="body" id="post-form-rich-body" label={label} placeholder={placeholder} margin="dense" variant="outlined" onInput={onInput} onContentEvents={onContentEvents} onBlur={onRichBlur} parse={v => v} richEditorProps={{ openai: true }} />
            : <FormField multiline minRows={3} maxRows={12} name="body" id="post-form-body" label={label} placeholder={placeholder} margin="dense" variant="outlined" onInput={onInput} parse={v => v} />}
        </Grid>

        {capabilities.activity && values.activity && values.activity.categoryId && (
          <Grid item>
            <ActivityCategorySpecificFields name="activity.values" categoryId={values.activity.categoryId} margin="dense" />
          </Grid>
        )}

        {values.poll && (
          <Grid item>
            <PollExhibit />
          </Grid>
        )}

        {values.quiz && (
          <Grid item>
            <QuizExhibit />
          </Grid>
        )}

        {values.sms && (
          <Grid item>
            <SmsExhibit />
          </Grid>
        )}

        {values.media?.length > 0 && (
          <Grid item xs={12}>
            <MediaExhibit />
          </Grid>
        )}

        {preview && !values.media?.length && (
          <Grid item>
            {preview}
          </Grid>
        )}

        {values.attachments?.length > 0 && (
          <Grid item xs={12}>
            <AttachmentsExhibit />
          </Grid>
        )}

        {(capabilities.channel || capabilities.target) && (
          <Grid item container spacing={2} direction="row" wrap="nowrap" alignItems="flex-end">
            {capabilities.channel && <Grid item xs><ChannelSelectField margin="none" /></Grid>}
            {capabilities.target && <Grid item xs zeroMinWidth><PostTargetField margin="none" /></Grid>}
          </Grid>
        )}

        {values.$schedule && (
          <Grid item xs={12}>
            <ScheduleField />
          </Grid>
        )}

      </Grid>
    </PostFormContent>
  );
};

export default BaseViewContent;

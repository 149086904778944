import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getBoosts: config => createAction(types.getBoosts, null, config),
  getBoostsSuccess: ({ data, entities }) => createAction(types.getBoostsSuccess, { data, entities }),
  getBoostsFailure: error => createAction(types.getBoostsFailure, error),

  update: ({ boostId, completed }) => createAction(types.update, { boostId, completed }),
  updateSuccess: ({ boostId, completed }) => createAction(types.updateSuccess, { boostId, completed }),
  updateFailure: error => createAction(types.updateFailure, error),
};

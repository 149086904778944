import { all, takeLeading, put, select } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import types from './types';
import actions from './actions';
import { snackbarActions } from '../snackbar';
import { takeLeadingPerKey } from '../helpers/saga';

function* onGetOverview({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  if (!workspaceId) {
    return;
  }

  const { ok, error, response } = yield apiCall(api.birthday.getOverview, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getOverviewSuccess(response));
  } else {
    yield put(actions.getOverviewFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetEvents({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  if (!workspaceId) {
    return;
  }

  const { ok, error, response } = yield apiCall(api.birthday.getBirthdays, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getBirthdaysSuccess(response));
  } else {
    yield put(actions.getBirthdaysFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetBirthdayEvent({ payload }) {
  const { ok, error, response } = yield apiCall(api.birthday.getBirthdayEvent, payload);

  if (ok) {
    yield put(actions.getBirthdayEventSuccess(response));
  } else {
    yield put(actions.getBirthdayEventFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onAddComment({ payload, config }) {
  const data = yield apiCall(api.birthday.addComment, payload, config);

  if (data.ok) {
    data.response.eventId = payload.eventId;
    yield put(actions.addCommentSuccess(data.response));
  } else {
    yield put(actions.addCommentFailure(data.error));
    yield put(snackbarActions.createFailure(data.error.message));
  }
}

function* onGetComments({ payload }) {
  const { ok, response, error } = yield apiCall(api.birthday.getComments, payload);

  if (ok) {
    response.eventId = payload.eventId;
    yield put(actions.getCommentsSuccess(response));
  } else {
    yield put(actions.getCommentsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

export default function* birthdaySagas() {
  yield all([
    takeLeading(types.getOverview, onGetOverview),
    takeLeading(types.getBirthdays, onGetEvents),
    takeLeading(types.getBirthdayEvent, onGetBirthdayEvent),
    takeLeading(types.addComment, onAddComment),
    takeLeadingPerKey(types.getComments, onGetComments, ({ payload }) => payload.eventId),
  ]);
}

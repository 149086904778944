import { all, takeLeading, put, takeEvery } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { takeLeadingPerKey } from 'redux/helpers/saga';
import types from './types';
import actions from './actions';
import { snackbarActions } from '../snackbar';

function* onGetLessonsByCourseVariantForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getLessons, payload);

  if (ok) {
    yield put(actions.getLessonsByCourseVariantForMemberSuccess({ courseVariantId: payload.courseVariantId, ...response }));
  } else {
    yield put(actions.getLessonsByCourseVariantForMemberFailure(error));
  }
}

function* onGetLessonForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getLesson, payload);

  if (ok) {
    yield put(actions.getLessonForMemberSuccess(response));
  } else {
    yield put(actions.getLessonForMemberFailure(error));
  }
}

function* onGetLessonsByCourseVariantForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getLessonsForAdmin, payload);

  if (ok) {
    yield put(actions.getLessonsByCourseVariantForAdminSuccess({ courseVariantId: payload.courseVariantId, ...response }));
  } else {
    yield put(actions.getLessonsByCourseVariantForAdminFailure(error));
  }
}

function* onGetLessonForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getLessonForAdmin, payload);

  if (ok) {
    yield put(actions.getLessonForAdminSuccess(response));
  } else {
    yield put(actions.getLessonForAdminFailure(error));
  }
}

function* onPostLessonImageForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.postLessonImageForAdmin, payload);

  if (ok) {
    yield put(actions.postLessonImageForAdminSuccess({ uuid: payload.uuid, data: response }));
  } else {
    yield put(actions.postLessonImageForAdminFailure({ uuid: payload.uuid, data: response }));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onPostLessonForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.postLessonForAdmin, payload);

  if (ok) {
    yield put(actions.postLessonForAdminSuccess(response));
    yield put(snackbarActions.createSuccess('lessons.successfulPostLesson', { translate: true }));
  } else {
    yield put(actions.postLessonForAdminFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onPutLessonForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.putLessonForAdmin, payload);

  if (ok) {
    yield put(actions.putLessonForAdminSuccess(response));
    yield put(snackbarActions.createSuccess('lessons.successfulPutLesson', { translate: true }));
  } else {
    yield put(actions.putLessonForAdminFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteLessonForAdmin({ payload }) {
  const { ok, error } = yield apiCall(api.learning.deleteLessonForAdmin, payload);

  if (ok) {
    yield put(actions.deleteLessonForAdminSuccess({ courseVariantId: payload.courseVariantId, lessonId: payload.lessonId }));
    yield put(snackbarActions.createSuccess('lessons.successfulDeleteLesson', { translate: true }));
  } else {
    yield put(actions.deleteLessonForAdminFailure(error));
    yield put(snackbarActions.createFailure('lessons.unsuccessfulDeleteLesson', { translate: true }));
  }
}

function* onReorderLessonForAdmin({ payload }) {
  const { ok, error } = yield apiCall(api.learning.reorderLessonForAdmin, payload);

  if (ok) {
    yield put(actions.reorderLessonsForAdminSuccess({ courseVariantId: payload.courseVariantId, lessonId: payload.lessonId }));
    yield put(snackbarActions.createSuccess('lessons.lessonsReorderSuccess', { translate: true }));
  } else {
    yield put(actions.reorderLessonsForAdminFailure(error));
    yield put(snackbarActions.createFailure('lessons.lessonsReorderFailure', { translate: true }));
  }
}

export default function* lessonSagas() {
  yield all([
    takeLeadingPerKey(types.getLessonsByCourseVariantForMember, onGetLessonsByCourseVariantForMember, ({ payload }) => payload.courseVariantId),
    takeLeading(types.getLessonForMember, onGetLessonForMember),
    takeLeadingPerKey(types.getLessonsByCourseVariantForAdmin, onGetLessonsByCourseVariantForAdmin, ({ payload }) => payload.courseVariantId),
    takeLeadingPerKey(types.getLessonForAdmin, onGetLessonForAdmin, ({ payload }) => payload.lessonId),
    takeEvery(types.postLessonImageForAdmin, onPostLessonImageForAdmin),
    takeLeading(types.postLessonForAdmin, onPostLessonForAdmin),
    takeLeadingPerKey(types.putLessonForAdmin, onPutLessonForAdmin, ({ payload }) => payload.lessonId),
    takeLeadingPerKey(types.deleteLessonForAdmin, onDeleteLessonForAdmin, ({ payload }) => payload.lessonId),
    takeLeadingPerKey(types.reorderLessonsForAdmin, onReorderLessonForAdmin, ({ payload }) => payload.courseVariantId),
  ]);
}

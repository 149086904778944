import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import ManageContactsButton from 'containers/ContactDirectory/ManageContactsButton';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';
import useContactDirectoryQueryParams from 'containers/Side/ContactDirectory/useContactDirectoryQueryParams';
import { workspaceSelectors } from 'redux/workspace';
import PropTypes from 'propTypes';
import SideContext from '../SideContext';
import DrawerBody from '../Drawer/DrawerBody';

const ContactDirectorySingle = ({ list, item }) => {

  const { t } = useTranslation(['component']);

  const { setTitle } = useContext(SideContext);

  const hasPermission = useSelector(authSelectors.hasPermissionSelector);

  const { itemType, itemId, isViewMode, isContactManageMode, isContactCreateMode, onSideCloseClick, onSideOpenClick, onItemCloseClick, onManageContactsToggleClick } = useContactDirectoryQueryParams();

  const getItemSelector = useSelector(workspaceSelectors.getContactDirectoryItemSelector);

  useEffect(() => {
    if (isViewMode) {
      setTitle({
        children: getItemSelector(itemType, itemId)?.name,
        backButton: { onClick: onSideOpenClick },
        closeButton: { onClick: onSideCloseClick },
      });
    } else {
      setTitle({
        children: (
          <Grid container direction="row" wrap="nowrap" spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>{t('component:contactDirectory.title')}</Grid>
            {hasPermission('MANAGE_PEOPLE_DIRECTORY') && (
              <Grid item>
                <ManageContactsButton isManageMode={isContactManageMode} onClick={onManageContactsToggleClick} />
              </Grid>
            )}
          </Grid>
        ),
        backButton: { onClick: isContactCreateMode ? onItemCloseClick : onSideCloseClick },
      });
    }
  }, [getItemSelector, hasPermission, isContactCreateMode, isContactManageMode, isViewMode, itemId, itemType, onItemCloseClick, onManageContactsToggleClick, onSideCloseClick, onSideOpenClick, setTitle, t]);

  return (
    <DrawerBody>
      {isViewMode || isContactCreateMode ? item : list}
    </DrawerBody>
  );
};

ContactDirectorySingle.propTypes = {
  list: PropTypes.any,
  item: PropTypes.any,
};

export default ContactDirectorySingle;

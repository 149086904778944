import styled from '@emotion/styled/macro';
import TextField from '@mui/material/TextField';
import CopyToClipboardAdornmenet from 'components/CopyToClipboardAdornment';
import { isMobile } from 'helpers';
import PropTypes from 'propTypes';
import React from 'react';


const StyledTextfield = isMobile ? TextField : styled(TextField)`
  .MuiInputAdornment-positionEnd {
    transition: opacity 300ms;
    opacity: 0;
  }

  &:hover .MuiInputAdornment-positionEnd {
    opacity: .5;
  }

  &:hover .MuiInputAdornment-positionEnd:hover {
    opacity: 1;
  }
`;

const CopyableTextField = ({ label, value, ...rest }) => (
  <StyledTextfield
    label={label}
    value={value}
    fullWidth
    margin="none"
    {...rest}
    InputProps={{
      readOnly: true,
      disableUnderline: true,
      endAdornment: <CopyToClipboardAdornmenet value={value} popperPlacement="left" />,
    }}
  />
);

CopyableTextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CopyableTextField;

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import styled from '@emotion/styled/macro';
import { ReactComponent as EmojiSmileColoredSvg } from './assets/emoji-smile-colored.svg';

const StyledSvgIcon = styled(SvgIcon)`
  color: #A0CC7C;
`;

const EmojiSmileColoredIcon = args => <StyledSvgIcon component={EmojiSmileColoredSvg} {...args} />;

export default EmojiSmileColoredIcon;

import React, { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import { PostHeaderImportant } from 'components/Post';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import Grid from 'components/Grid';
import Avatar from 'components/Avatar';
import PinIcon from 'components/Icons/Pin';
import PostFormHeader from '../Partials/PostFormHeader';
import PostFormContext from '../PostFormContext';

const StyledPostFormHeader = styled(PostFormHeader)`
  font-size: 1rem;
`;

const Name = styled('div')`
  font-size: 1.1em;
  font-weight: 700;
  text-decoration: none;
`;

const PinnedNotice = styled('div')`
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #666;
  font-weight: 400;
  font-size: 0.9rem;

  svg {
    font-size: 1rem;
    margin-right: 0.25rem;
  }
`;

const BaseViewHeader = () => {

  const { t } = useTranslation(['component']);

  const { onClose } = useContext(PostFormContext);

  const { values: { important, pinned } } = useFormState({
    subscription: {
      values: true,
    },
  });

  // authSelectors.meUser is correct as long as nobody else can edit somebody else's posts
  const { firstName, lastName, avatar } = useSelector(authSelectors.getMeUser);

  return (
    <StyledPostFormHeader>
      <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} wrap="nowrap" justifyContent="flex-start" alignItems="center">
            <Grid item><Avatar size={48} firstName={firstName} lastName={lastName} src={avatar?.src} srcSet={avatar?.srcSet} /></Grid>
            <Grid item><Name translate="no">{`${firstName} ${lastName}`}</Name></Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} wrap="nowrap" justifyContent="flex-end" alignItems="center">
            {(!!important || !!pinned) && (
              <Grid item>
                <Grid container spacing={0} direction="column" wrap="nowrap" alignItems="flex-end">
                  {!!important && <Grid item><PostHeaderImportant /></Grid>}
                  {!!pinned && <Grid item><PinnedNotice><PinIcon />&nbsp;{t('post.pinned')}</PinnedNotice></Grid>}
                </Grid>
              </Grid>
            )}
            {onClose && (
              <Grid item>
                <IconButton onClick={onClose} edge="end" color="inherit">
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </StyledPostFormHeader>
  );
};

export default BaseViewHeader;

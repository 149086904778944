import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  get: ({ id }) => createAction(types.get, { id }),
  getSuccess: data => createAction(types.getSuccess, data),
  getFailure: error => createAction(types.getFailure, error),

  store: data => createAction(types.store, data),
  storeSuccess: data => createAction(types.storeSuccess, data),
  storeFailure: error => createAction(types.storeFailure, error),

  update: data => createAction(types.update, data),
  updateSuccess: data => createAction(types.updateSuccess, data),
  updateFailure: error => createAction(types.updateFailure, error),

  delete: data => createAction(types.delete, data),
  deleteSuccess: data => createAction(types.deleteSuccess, data),
  deleteFailure: error => createAction(types.deleteFailure, error),

  subscribe: data => createAction(types.subscribe, data),
  subscribeSuccess: data => createAction(types.subscribeSuccess, data),
  subscribeFailure: error => createAction(types.subscribeFailure, error),

  getMembers: data => createAction(types.getMembers, data),
  getMembersSuccess: data => createAction(types.getMembersSuccess, data),
  getMembersFailure: error => createAction(types.getMembersFailure, error),
};

import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';
import Grid from 'components/Grid';

const DialogTitle = ({ children, withClose, ...args }) => (
  <MuiDialogTitle {...args}>
    {withClose ? (
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>{children}</Grid>
        <Grid item>
          <IconButton edge="end" color="inherit" {...withClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    ) : children}
  </MuiDialogTitle>
);

DialogTitle.propTypes = {
  withClose: PropTypes.object,
};

export default DialogTitle;

import combineReducers from 'combine-reducers-global-state';
import { addToPagination, createPagination } from 'redux/helpers/pagination';
import types from './types';

const foldersForMember = (state = createPagination(null, 'folderIds'), { type, payload }) => {
  switch (type) {
    case types.getFoldersForMemberSuccess:
      return addToPagination(state, null, payload, 'folderIds');
    default:
      return state;
  }
};

const foldersForAdmin = (state = createPagination(null, 'folderIds'), { type, payload }) => {
  switch (type) {
    case types.getFoldersForAdminSuccess:
      return addToPagination(state, null, payload, 'folderIds');
    default:
      return state;
  }
};

const folderReducers = combineReducers({
  foldersForMember,
  foldersForAdmin,
});

export default { documentFolder: folderReducers };

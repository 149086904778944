export default {
  getCoursesAnalytics: 'learningAnalytics/GET_COURSES_ANALYTICS',
  getCoursesAnalyticsSuccess: 'learningAnalytics/GET_COURSES_ANALYTICS_SUCCESS',
  getCoursesAnalyticsFailure: 'learningAnalytics/GET_COURSES_ANALYTICS_FAILURE',

  getCourseMembersAnalytics: 'learningAnalytics/GET_COURSE_MEMBERS_ANALYTICS',
  getCourseMembersAnalyticsSuccess: 'learningAnalytics/GET_COURSE_MEMBERS_ANALYTICS_SUCCESS',
  getCourseMembersAnalyticsFailure: 'learningAnalytics/GET_COURSE_MEMBERS_ANALYTICS_FAILURE',

  getCourseMembersAnalyticsDownload: 'learningAnalytics/GET_COURSE_MEMBERS_ANALYTICS_DOWNLOAD',
  getCourseMembersAnalyticsDownloadSuccess: 'learningAnalytics/GET_COURSE_MEMBERS_ANALYTICS_DOWNLOAD_SUCCESS',
  getCourseMembersAnalyticsDownloadFailure: 'learningAnalytics/GET_COURSE_MEMBERS_ANALYTICS_DOWNLOAD_FAILURE',

  getCourseLessonsAnalytics: 'learningAnalytics/GET_COURSE_LESSONS_ANALYTICS',
  getCourseLessonsAnalyticsSuccess: 'learningAnalytics/GET_COURSE_LESSONS_ANALYTICS_SUCCESS',
  getCourseLessonsAnalyticsFailure: 'learningAnalytics/GET_COURSE_LESSONS_ANALYTICS_FAILURE',

  getCourseLessonsAnalyticsDownload: 'learningAnalytics/GET_COURSE_LESSONS_ANALYTICS_DOWNLOAD',
  getCourseLessonsAnalyticsDownloadSuccess: 'learningAnalytics/GET_COURSE_LESSONS_ANALYTICS_DOWNLOAD_SUCCESS',
  getCourseLessonsAnalyticsDownloadFailure: 'learningAnalytics/GET_COURSE_LESSONS_ANALYTICS_DOWNLOAD_FAILURE',

  getCourseVariantQuestionsAnalytics: 'learningAnalytics/GET_COURSE_VARIANT_QUESTIONS_ANALYTICS',
  getCourseVariantQuestionsAnalyticsSuccess: 'learningAnalytics/GET_COURSE_VARIANT_QUESTIONS_ANALYTICS_SUCCESS',
  getCourseVariantQuestionsAnalyticsFailure: 'learningAnalytics/GET_COURSE_VARIANT_QUESTIONS_ANALYTICS_FAILURE',

  getLessonQuestionsAnalytics: 'learningAnalytics/GET_LESSON_QUESTIONS_ANALYTICS',
  getLessonQuestionsAnalyticsSuccess: 'learningAnalytics/GET_LESSON_QUESTIONS_ANALYTICS_SUCCESS',
  getLessonQuestionsAnalyticsFailure: 'learningAnalytics/GET_LESSON_QUESTIONS_ANALYTICS_FAILURE',

  getMembersAnalytics: 'learningAnalytics/GET_MEMBERS_ANALYTICS',
  getMembersAnalyticsSuccess: 'learningAnalytics/GET_MEMBERS_ANALYTICS_SUCCESS',
  getMembersAnalyticsFailure: 'learningAnalytics/GET_MEMBERS_ANALYTICS_FAILURE',

  getMemberCoursesAnalytics: 'learningAnalytics/GET_MEMBER_COURSES_ANALYTICS',
  getMemberCoursesAnalyticsSuccess: 'learningAnalytics/GET_MEMBER_COURSES_ANALYTICS_SUCCESS',
  getMemberCoursesAnalyticsFailure: 'learningAnalytics/GET_MEMBER_COURSES_ANALYTICS_FAILURE',

  getMemberCoursesAnalyticsDownload: 'learningAnalytics/GET_MEMBER_COURSES_DOWNLOAD_ANALYTICS',
  getMemberCoursesAnalyticsDownloadSuccess: 'learningAnalytics/GET_MEMBER_COURSES_ANALYTICS_DOWNLOAD_SUCCESS',
  getMemberCoursesAnalyticsDownloadFailure: 'learningAnalytics/GET_MEMBER_COURSES_ANALYTICS_DOWNLOAD_FAILURE',

  getMemberCourseAnalytics: 'learningAnalytics/GET_MEMBER_COURSE_ANALYTICS',
  getMemberCourseAnalyticsSuccess: 'learningAnalytics/GET_MEMBER_COURSE_ANALYTICS_SUCCESS',
  getMemberCourseAnalyticsFailure: 'learningAnalytics/GET_MEMBER_COURSE_ANALYTICS_FAILURE',

  getMemberCourseAnalyticsDownloadCsv: 'learningAnalytics/GET_MEMBER_COURSE_ANALYTICS_DOWNLOAD_CSV',
  getMemberCourseAnalyticsDownloadCsvSuccess: 'learningAnalytics/GET_MEMBER_COURSE_ANALYTICS_DOWNLOAD_CSV_SUCCESS',
  getMemberCourseAnalyticsDownloadCsvFailure: 'learningAnalytics/GET_MEMBER_COURSE_ANALYTICS_DOWNLOAD_CSV_FAILURE',

  getMemberCourseAnalyticsDownloadPdf: 'learningAnalytics/GET_MEMBER_COURSE_ANALYTICS_DOWNLOAD_PDF',
  getMemberCourseAnalyticsDownloadPdfSuccess: 'learningAnalytics/GET_MEMBER_COURSE_ANALYTICS_DOWNLOAD_PDF_SUCCESS',
  getMemberCourseAnalyticsDownloadPdfFailure: 'learningAnalytics/GET_MEMBER_COURSE_ANALYTICS_DOWNLOAD_PDF_FAILURE',

  getLabelsAnalytics: 'learningAnalytics/GET_LABELS_ANALYTICS',
  getLabelsAnalyticsSuccess: 'learningAnalytics/GET_LABELS_ANALYTICS_SUCCESS',
  getLabelsAnalyticsFailure: 'learningAnalytics/GET_LABELS_ANALYTICS_FAILURE',

  getLabelCoursesAnalytics: 'learningAnalytics/GET_LABEL_COURSES_ANALYTICS',
  getLabelCoursesAnalyticsSuccess: 'learningAnalytics/GET_LABEL_COURSES_ANALYTICS_SUCCESS',
  getLabelCoursesAnalyticsFailure: 'learningAnalytics/GET_LABEL_COURSES_ANALYTICS_FAILURE',

  getMemberCourseVariantAnalytics: 'learningAnalytics/GET_MEMBER_COURSE_VARIANT_ANALYTICS',
  getMemberCourseVariantAnalyticsSuccess: 'learningAnalytics/GET_MEMBER_COURSE_VARIANT_ANALYTICS_SUCCESS',
  getMemberCourseVariantAnalyticsFailure: 'learningAnalytics/GET_MEMBER_COURSE_VARIANT_ANALYTICS_FAILURE',

  getMemberCourseVariantsAnalytics: 'learningAnalytics/GET_MEMBER_COURSE_VARIANTS_ANALYTICS',
  getMemberCourseVariantsAnalyticsSuccess: 'learningAnalytics/GET_MEMBER_COURSE_VARIANTS_ANALYTICS_SUCCESS',
  getMemberCourseVariantsAnalyticsFailure: 'learningAnalytics/GET_MEMBER_COURSE_VARIANTS_ANALYTICS_FAILURE',
};

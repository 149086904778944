import React, { createRef, useRef, useMemo, useState, useCallback } from 'react';
import Grid from 'components/Grid';
import Button from 'components/Button';
import FormField from 'components/FinalForm/Fields/FormField';
import { useFieldArray } from 'react-final-form-arrays';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TrashIcon from 'components/Icons/Trash';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import RichEditorField from 'components/FinalForm/Fields/RichEditorField';
import styled from '@emotion/styled/macro';
import SubmitBlocker from 'components/FinalForm/Fields/SubmitBlocker';
import PostFormContent from '../Partials/PostFormContent';

const FakeTable = styled('div')`
  display: table;
`;

const FakeRow = styled('div')`
  display: table-row;
`;

const RadioLabel = styled(InputLabel)`
  transform-origin: center top;
`;

const StyledRadio = styled(Radio)`
  padding: 0;
`;

const FakeCell = styled('div')`
  display: table-cell;
  padding: 0.5rem 0;

  &:first-child {
    text-align: center;
    width: 1px;
    padding-right: 0.5rem
  }

  ${FakeRow}:not(:first-child) ${RadioLabel} {
    opacity: 0;
  }
`;

const RemoveOptionAdornment = args => (
  <InputAdornment>
    <IconButton color="inherit" edge="end" {...args}>
      <TrashIcon fontSize="small" />
    </IconButton>
  </InputAdornment>
);

export function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

const optionValidation = (current, values, idx, t) => {

  if (values.filter(({ value }) => !!value).length === 0 && idx === 0) {
    return t('quizForm.noValidOptions');
  }

  const trimmed = values.map(({ value }) => value ? value.trim() : value).filter(v => !!v);

  if (!hasDuplicates(trimmed)) {
    return undefined;
  }

  if (hasDuplicates(trimmed) && trimmed.filter(value => value === current).length > 1 && idx !== 0) {
    return t('quizForm.duplicatesError');
  }

  return undefined;
};

const QuizViewContent = () => {

  const { t } = useTranslation('component');

  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });

  const form = useForm();

  const initialValue = useMemo(() => (!form.getState().initialValues?.options || form.getState().initialValues?.options.length === 0)
    ? [{ value: '', isCorrect: false }, { value: '', isCorrect: false }]
    : form.getState().initialValues?.options, []); // eslint-disable-line

  const { fields } = useFieldArray('options', { initialValue });

  const inputRefs = useRef([]);

  const getOrCreateInputRef = useCallback(idx => {
    if (!inputRefs.current[idx]) {
      inputRefs.current[idx] = createRef();
    }

    return inputRefs.current[idx];
  }, []);

  const focusInput = idx => {
    const ref = inputRefs.current[idx];
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  const isFieldEmpty = idx => !fields.value[idx].value;

  const onEnterKeyPress = (e, idx) => {
    if (isFieldEmpty(idx)) {
      return;
    }

    const lastIndex = fields.length - 1;

    if (lastIndex >= idx + 1 && isFieldEmpty(idx + 1)) {
      // If next field is empty, then don't add new
    } else {
      fields.insertAt(idx + 1, { value: '', isCorrect: false });
    }

    setTimeout(() => focusInput(idx + 1));
  };

  const onDeleteKeyPress = (e, idx) => {
    if (isFieldEmpty(idx) && fields.length > 1) {
      fields.remove(idx);
      setTimeout(() => focusInput(idx - 1), 0);
    }
  };

  const onKeyPress = (e, idx) => {
    if (e.key === 'Enter') {
      onEnterKeyPress(e, idx);
    }
  };

  const onKeyDown = (e, idx) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      onDeleteKeyPress(e, idx);
    }
  };

  const [correct, setCorrect] = useState(null);

  const onChange = selected => {
    fields.forEach((name, idx) => {
      fields.update(idx, { ...fields.value[idx], isCorrect: +selected === idx });
    });
    setCorrect(selected);
  };

  return (
    <PostFormContent>
      <Grid container direction="column" wrap="nowrap" spacing={2}>

        <Grid item>
          <FormField name="question" label={t('quizForm.questionLabel')} placeholder={t('quizForm.questionPlaceholder')} margin="dense" inputProps={values.question ? { style: { fontWeight: 600 } } : {}} />
        </Grid>

        <RadioGroup value={correct} onChange={e => onChange(e.target.value)}>
          <FakeTable>
            {fields.map((fieldName, idx) => (
              <FakeRow key={fieldName}>
                <FakeCell>
                  <RadioLabel shrink margin="dense">{t('quizForm.correctLabel')}</RadioLabel>
                  <StyledRadio value={`${idx}`} />
                </FakeCell>
                <FakeCell>
                  <FormField
                    name={`${fieldName}.value`}
                    label={t('quizForm.nthOptionLabel', { count: idx + 1 })}
                    placeholder={t('quizForm.nthOptionPlaceholder', { count: idx + 1 })}
                    margin="none"
                    inputRef={getOrCreateInputRef(idx)}
                    InputProps={{
                      // Tabindex -1, since this can also be done with backspace & delete.
                      // Feels better, if tab takes to next field
                      endAdornment: <RemoveOptionAdornment onClick={() => fields.remove(idx)} tabIndex={-1} />,
                    }}
                    onKeyPress={e => onKeyPress(e, idx)}
                    onKeyDown={e => onKeyDown(e, idx)}
                    validate={(value, allValues) => optionValidation(value, allValues.options, idx, t)}
                  />
                </FakeCell>
              </FakeRow>
            ))}
          </FakeTable>
          {!correct && <SubmitBlocker name="$isCorrect">A correct answer must be selected</SubmitBlocker>}
        </RadioGroup>

        <Grid item style={{ textAlign: 'center' }}>
          <Button color="default" size="small" onClick={() => fields.push({ option: '' })}>{t('quizForm.addOption')}</Button>
        </Grid>

        <Grid item xs={12}>
          <RichEditorField
            name="explanation"
            label="Explanation"
            placeholder="Text to show after correct response (Optional)"
            margin="dense"
          />
        </Grid>

        {form.getState().error && (
          <Grid item>
            <FormHelperText error>
              {form.getState().error}
            </FormHelperText>
          </Grid>
        )}

      </Grid>
    </PostFormContent>
  );
};

export default QuizViewContent;

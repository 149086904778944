import combineReducers from 'combine-reducers-global-state';
import { addToPagination, createPagination, resetPagination, setPaginationLoading } from 'redux/helpers/pagination';
import types from './types';

const member = (state = {}, { type }) => {
  switch (type) {
    default:
      return state;
  }
};

const admin = (state = createPagination(null, 'feedbackIds'), { type, payload }) => {
  switch (type) {
    case types.getFeedbacks:
      return setPaginationLoading(state, null, true);
    case types.getFeedbacksFailure:
      return setPaginationLoading(state, null, false);
    case types.getFeedbacksSuccess:
      return addToPagination(state, null, payload, 'feedbackIds');
    default:
      return state;
  }
};

const feedbackSettings = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getSettingsSuccess:
    case types.updateSettingsSuccess:
      return payload;
    default:
      return state;
  }
};

const feedbackStatuses = (state = [], { type, payload }) => {
  switch (type) {
    case types.getFeedbackStatusesSuccess:
      return payload;
    default:
      return state;
  }
};

const feedbackTypes = (state = [], { type, payload }) => {
  switch (type) {
    case types.deleteFeedbackTypeSuccess:
      return state.filter(id => id !== payload.feedbackTypeId);
    case types.getFeedbackTypesSuccess:
      return payload.data;
    case types.storeTypeSuccess:
      return [...state, payload.data];
    default:
      return state;
  }
};

const feedbackTypesLoading = (state = false, { type }) => {
  switch (type) {
    case types.getFeedbackTypesSuccess:
    case types.getFeedbackTypesFailure:
      return false;
    case types.getFeedbackTypes:
      return true;
    default:
      return state;
  }
};

const isCommentChannelMatch = (a, b) => {
  return a?.toLowerCase() === b?.toLowerCase();
};

const getCommentsReducer = (requiredChannel) => (state = {}, { type: reduxType, payload }) => {
  switch (reduxType) {
    case types.getCommentsSuccess:
      return isCommentChannelMatch(payload.channel, requiredChannel)
        ? addToPagination(state, payload.feedbackId, payload, 'feedbackCommentIds')
        : state;

    case types.deleteCommentSuccess:
      return resetPagination(state, payload.feedbackId, 'feedbackCommentIds');

    case types.addCommentSuccess:
      return isCommentChannelMatch(payload.channel, requiredChannel)
        ? resetPagination(state, payload.feedbackId, 'feedbackCommentIds')
        : state;

    default:
      return state;
  }
};

const publicReducer = getCommentsReducer('public');
const internalReducer = getCommentsReducer('internal');

const feedbackComments = combineReducers({
  public: publicReducer,
  internal: internalReducer,
});

const feedback = combineReducers({
  member,
  admin,

  feedbackSettings,

  feedbackTypes,
  feedbackTypesLoading,

  feedbackStatuses,

  feedbackComments,
});

export default { feedback };

import { all, takeLeading, takeEvery, put, select } from 'redux-saga/effects';
import { api, apiCall, commonApiSaga } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import { snackbarActions } from 'redux/snackbar';
import types from './types';
import actions from './actions';

function* onGetEvent({ payload }) {
  const { ok, response, error } = yield apiCall(api.calendar.getEvent, payload);

  if (ok) {
    yield put(actions.getEventSuccess(response));
  } else {
    yield put(actions.getEventFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}


function* onGetEvents({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, response, error } = yield apiCall(api.calendar.getEvents, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getEventsSuccess({ status: payload.status, ...response }));
  } else {
    yield put(actions.getEventsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onCreateEvent({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, response, error } = yield apiCall(api.calendar.createEvent, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.createEventSuccess(response));
  } else {
    yield put(actions.createEventFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateEvent({ payload }) {
  const { ok, response, error } = yield apiCall(api.calendar.updateEvent, payload);

  if (ok) {
    yield put(actions.updateEventSuccess(response));
  } else {
    yield put(actions.updateEventFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteEvent({ payload }) {
  const { ok, response, error } = yield apiCall(api.calendar.deleteEvent, payload);

  if (ok) {
    yield put(actions.deleteEventSuccess(response));
  } else {
    yield put(actions.deleteEventFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

export default function* calendarSagas() {
  yield all([
    takeEvery(types.getEvent, onGetEvent),
    takeLeading(types.getEvents, onGetEvents),
    takeEvery(types.createEvent, onCreateEvent),
    takeEvery(types.updateEvent, onUpdateEvent),
    takeEvery(types.deleteEvent, onDeleteEvent),

    takeLeading(types.downloadIcs, commonApiSaga, { apiFn: api.calendar.downloadIcs }),
  ]);
}

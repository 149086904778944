import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  upload: data => createAction(types.upload, data),
  uploadSuccess: data => createAction(types.uploadSuccess, data),
  uploadFailure: data => createAction(types.uploadFailure, data),

  getFiles: data => createAction(types.getFiles, data),
  getFilesSuccess: data => createAction(types.getFilesSuccess, data),
  getFilesFailure: data => createAction(types.getFilesFailure, data),

  saveFiles: data => createAction(types.saveFiles, data),
  saveFilesSuccess: data => createAction(types.saveFilesSuccess, data),
  saveFilesFailure: data => createAction(types.saveFilesFailure, data),

  uploadEvent: ({ event, resumable, args }) => createAction(types.uploadEvent, { event, resumable, args }),
};

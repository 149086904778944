import { pageTypes } from 'redux/page';
import merge from 'lodash/merge';

const pollsReducer = (state = {}, { type, payload }) => {

  switch (type) {
    case pageTypes.deleteSuccess:
      return merge({}, state, { [payload.request.pageId]: null });

    default:
      return state;
  }
};

export default pollsReducer;

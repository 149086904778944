import React, { useRef } from 'react';
import QuizViewContext from './QuizViewContext';

const QuizProvider = args => {

  const save = useRef();

  return (
    <QuizViewContext.Provider value={{ save }} {...args} />
  );
};

export default QuizProvider;

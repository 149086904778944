import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { challengeSelectors, challengeActions } from 'redux/challenge';
import SelectField from 'components/FinalForm/Fields/SelectField';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import { isMobile } from 'helpers';

const Placeholder = styled('span')`
  color: #aaa;
`;

const ActivityCategorySelectField = args => {

  const dispatch = useDispatch();

  const categories = useSelector(challengeSelectors.getActivityCategories);

  useEffect(() => {
    dispatch(challengeActions.getActivityCategories());
  }, [dispatch]);

  const { t } = useTranslation(['common', 'component']);

  const challengeId = (/^#?\/challenges\/(\d+)\/?$/.exec(isMobile ? window.location.hash : window.location.pathname) || [])[1];

  const challengeCategories = useSelector(state => challengeSelectors.getCategoriesForChallenge(state, challengeId));
  const selectableCategories = (challengeId && challengeCategories && challengeCategories.length > 0) ? challengeCategories : categories;

  if (selectableCategories.length === 0) {
    return null;
  }

  if (isMobile) {
    return (
      <SelectField native label={t('component:activityForm.categoryLabel')} displayEmpty {...args}>
        <option value="">{t('component:activityForm.categoryPlaceholder')}</option>;
        {selectableCategories.map(category => (
          <option key={category.id} value={category.id}>
            {capitalize(t(`common:activityCategory.${category.name.toLowerCase()}`, category.name))}
          </option>
        ))}
      </SelectField>
    );
  }

  return (
    <SelectField label={t('component:activityForm.categoryLabel')} displayEmpty {...args}>
      <MenuItem value=""><Placeholder>{t('component:activityForm.categoryPlaceholder')}</Placeholder></MenuItem>;
      {selectableCategories.map(category => (
        <MenuItem key={category.id} value={category.id}>
          {capitalize(t(`common:activityCategory.${category.name.toLowerCase()}`, category.name))}
        </MenuItem>
      ))}
    </SelectField>
  );
};

export default ActivityCategorySelectField;

export default {
  updateAvatar: 'user/UPDATE_AVATAR',
  updateAvatarSuccess: 'user/UPDATE_AVATAR_SUCCESS',
  updateAvatarFailure: 'user/UPDATE_AVATAR_FAILURE',

  deleteAvatar: 'user/DELETE_AVATAR',
  deleteAvatarSuccess: 'user/DELETE_AVATAR_SUCCESS',
  deleteAvatarFailure: 'user/DELETE_AVATAR_FAILURE',

  getNotifications: 'user/GET_NOTIFICATIONS',
  getNotificationsSuccess: 'user/GET_NOTIFICATIONS_SUCCESS',
  getNotificationsFailure: 'user/GET_NOTIFICATIONS_FAILURE',

  getSettings: 'user/GET_SETTINGS',
  getSettingsSuccess: 'user/GET_SETTINGS_SUCCESS',
  getSettingsFailure: 'user/GET_SETTINGS_FAILURE',

  getNotificationSettings: 'user/GET_NOTIFICATION_SETTINGS',
  getNotificationSettingsSuccess: 'user/GET_NOTIFICATION_SETTINGS_SUCCESS',
  getNotificationSettingsFailure: 'user/GET_NOTIFICATION_SETTINGS_FAILURE',

  readNotifications: 'user/READ_NOTIFICATIONS',
  readNotificationsSuccess: 'user/READ_NOTIFICATIONS_SUCCESS',
  readNotificationsFailure: 'user/READ_NOTIFICATIONS_FAILURE',

  updateProfile: 'user/UPDATE_PROFILE',
  updateProfileSuccess: 'user/UPDATE_PROFILE_SUCCESS',
  updateProfileFailure: 'user/UPDATE_PROFILE_FAILURE',

  updateSettings: 'user/UPDATE_SETTINGS',
  updateSettingsSuccess: 'user/UPDATE_SETTINGS_SUCCESS',
  updateSettingsFailure: 'user/UPDATE_SETTINGS_FAILURE',

  updateNotificationSettings: 'user/UPDATE_NOTIFICATION_SETTINGS',
  updateNotificationSettingsSuccess: 'user/UPDATE_NOTIFICATION_SETTINGS_SUCCESS',
  updateNotificationSettingsFailure: 'user/UPDATE_NOTIFICATION_SETTINGS_FAILURE',

  sendEmailChangeVerification: 'user/SEND_EMAIL_CHANGE_VERIFICATION',
  sendEmailChangeVerificationSuccess: 'user/SEND_EMAIL_CHANGE_VERIFICATION_SUCCESS',
  sendEmailChangeVerificationFailure: 'user/SEND_EMAIL_CHANGE_VERIFICATION_FAILURE',

  changeLanguageSuccess: 'user/CHANGE_LANGUAGE_SUCCESS',
  subscribeToNotifications: 'user/SUBSCRIBE_TO_NOTIFICATIONS',
  newNotification: 'user/NEW_NOTIFICATION',
};

import styled from '@emotion/styled/macro';
import React, { useContext } from 'react';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';
import Grid from 'components/Grid';
import IconButton from '@mui/material/IconButton';
import TitleContext from '../TitleContext';

const Wrapper = styled('div')`
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid #f5f5f5;

  min-height: 52px; /* Same as bottom navbar */

  display: flex;
  align-items: center;
`;

const Title = styled('span')`
  font-weight: 600;
  font-size: 1.2rem;

  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DrawerTitle = () => {

  const { title: { children, backButton, closeButton, ...rest } = {} } = useContext(TitleContext);

  return (
    <Wrapper {...rest}>
      <Grid container alignItems="center" spacing={1}>

        {backButton && (
          <Grid item>
            <IconButton color="inherit" edge="start" {...backButton}><ArrowBackIosRoundedIcon fontSize="small" /></IconButton>
          </Grid>
        )}

        <Grid item xs zeroMinWidth>
          <Title>{children}</Title>
        </Grid>

        {closeButton && (
          <Grid item>
            <IconButton color="inherit" edge="end" {...closeButton}><CloseRoundedIcon /></IconButton>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};

DrawerTitle.propTypes = {
  backButton: PropTypes.object,
  closeButton: PropTypes.object,
};

export default DrawerTitle;

import history from 'providers/history';
import AuthService from 'services/authService';
import NotificationService from 'services/notificationService';

function onDeviceReady() {
  // Enable universal links
  if (window.universalLinks) {
    window.universalLinks.subscribe(null, eventData => {

      if (eventData.params?.side === 'directory') {
        history.push('/directory');
        return;
      }

      if (eventData.params?.side === 'contacts') {
        history.push('/contacts');
        return;
      }

      if (eventData?.path || Object.keys(eventData.params).length > 0) {
        history.push({
          pathname: eventData.path,
          search: new URLSearchParams(eventData.params).toString(),
        });
      }
    });
  }

  // Set up device_key fetching
  // Set up notification actions
  if (window.FirebasePlugin) {
    window.FirebasePlugin.getToken(AuthService.setDeviceKey);
    window.FirebasePlugin.onTokenRefresh(AuthService.setDeviceKey);
    window.FirebasePlugin.onMessageReceived(({ tap, ...data }) => {
      if (tap && tap === 'background') {
        NotificationService.onNotificationClick({ data });
        // TODO: No backend support to mark only one comment read
        // readNotifications([data.notificationId]);
      } else {
        // App in foreground. Received a notification...
      }
    });
  }
}

const init = () => {
  document.addEventListener('deviceready', onDeviceReady, { passive: true });
};

export default init;

/* eslint-disable import/first */
/* eslint-disable no-unused-expressions */
// Polyfills:
import './scripts/polyfills.js';

// Rest:
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'app/App';
import config from 'config';
import BugsnagErrorBoundary from 'providers/BugsnagErrorBoundary';
import mobileScripts from 'scripts/mobile';
import webScripts from 'scripts/web';
import commonScripts from 'scripts/common';
import { isMobile } from 'helpers';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BugsnagErrorBoundary>
    <App />
  </BugsnagErrorBoundary>,
);

window.UPLOADCARE_PUBLIC_KEY = config.uploadcare.key;

commonScripts();

if (isMobile) {
  // Maybe mobile scripts don't need polyfills? Possibility to optimise?
  mobileScripts();
} else {
  webScripts();
}

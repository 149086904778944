import React, { useCallback, useRef } from 'react';
import { FormSpy } from 'react-final-form';
import useConfirm from './useConfirm';

const useFormDialogCloseConfirm = (onClose, confirmOpts) => {

  const confirm = useConfirm();

  const formState = useRef({ dirty: false, submitting: false });

  const onConfirmClose = (...args) => {
    if (formState.current.submitting) {
      return; // Or maybe popup: submitting...
    }

    if (!formState.current.dirty) {
      return onClose(...args);
    }

    confirm(confirmOpts)
      .then(() => onClose(...args))
      .catch(() => { });
  };

  const Spy = useCallback(() => {
    const onChange = values => {
      formState.current = values;
    };

    return <FormSpy subscription={{ dirty: true, submitting: true }} onChange={onChange} />;
  }, []);

  return {
    FormSpy: Spy,
    onConfirmClose,
  };
};

export default useFormDialogCloseConfirm;

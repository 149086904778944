import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getActivityCategories: () => createAction(types.getActivityCategories),
  getActivityCategoriesSuccess: data => createAction(types.getActivityCategoriesSuccess, data),
  getActivityCategoriesFailure: error => createAction(types.getActivityCategoriesFailure, error),

  storeActivity: data => createAction(types.storeActivity, data),
  storeActivitySuccess: data => createAction(types.storeActivitySuccess, data),
  storeActivityFailure: error => createAction(types.storeActivityFailure, error),

  updateActivity: data => createAction(types.updateActivity, data),
  updateActivitySuccess: data => createAction(types.updateActivitySuccess, data),
  updateActivityFailure: error => createAction(types.updateActivityFailure, error),

  getChallenge: ({ challengeId }) => createAction(types.getChallenge, { challengeId }),
  getChallengeSuccess: data => createAction(types.getChallengeSuccess, data),
  getChallengeFailure: error => createAction(types.getChallengeFailure, error),

  getChallenges: data => createAction(types.getChallenges, data),
  getChallengesSuccess: data => createAction(types.getChallengesSuccess, data),
  getChallengesFailure: error => createAction(types.getChallengesFailure, error),
};

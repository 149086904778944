import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import Form from 'components/FinalForm/Form';
import Button from 'components/Button';
import { PostContext } from 'components/Post';
import Quiz from 'components/Quiz';
import { useDispatch, useSelector } from 'react-redux';
import { quizActions, quizSelectors } from 'redux/quiz';
import { translationSelectors } from 'redux/translation';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
  margin: 1rem;

  ${({ white }) => white && css`
    padding: 2rem 1.5rem;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
                0px 2px 6px rgba(0, 0, 0, 0.05);
  `}
`;

const Footer = styled('div')`
  margin-top: 0.5rem;
  text-align: right;
`;

const PostQuiz = ({ quizId }) => {

  const { t } = useTranslation('component');

  const [value, setValue] = useState(null);

  const dispatch = useDispatch();

  const { isImportant, isCompact, translated, setTranslated } = useContext(PostContext);

  const quiz = useSelector(state => quizSelectors.getQuiz(state, quizId));

  const onSubmit = () => {
    dispatch(quizActions.storeAnswer({ quizId, optionId: value }));
    setTranslated(current => current); // Refetch translations if necessary
  };

  const getTranslation = useSelector(translationSelectors.getTranslationSelector);

  const question = useMemo(() => translated
    ? getTranslation('quiz', quizId, 'question', quiz.question)
    : quiz.question,
  [getTranslation, quiz.question, quizId, translated]);

  let options = useSelector(quizSelectors.getOptionsForQuiz)(quizId);
  options = useMemo(() => {
    return translated
      ? options.map(option => ({
        ...option,
        value: getTranslation('quiz_option', option.id, 'value', option.value),
      }))
      : options;
  }, [getTranslation, options, translated]);

  const isRevealed = !!options.find(option => option.isCorrect !== undefined);

  const explanation = useMemo(() => translated
    ? getTranslation('quiz', quizId, 'explanation', quiz.explanation)
    : quiz.explanation,
  [getTranslation, quiz.explanation, quizId, translated]);

  if (!quiz) {
    return null;
  }

  return (
    <Wrapper white={isImportant && isCompact}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>

            <Quiz
              question={question}
              subtitle={quiz.answered ? t('component:quiz.alreadyAnswered') : null}
              options={options}
              value={+value}
              setValue={setValue}
              revealed={isRevealed}
              explanation={explanation}
            />

            <Footer hidden={isRevealed}>
              <Button type="submit">{t('component:quiz.checkYourAnswer')}</Button>
            </Footer>

          </form>
        )}
      </Form>
    </Wrapper>
  );
};

PostQuiz.propTypes = {
  quizId: PropTypes.any.isRequired,
};

export default PostQuiz;

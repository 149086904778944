import styled from '@emotion/styled/macro';

const CountBadge = styled('div')`
  padding: 0.3rem 0.5rem;
  line-height: 1;
  font-size: 1rem;
  min-width: 1.6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 40px;
`;

export default CountBadge;

import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import PropTypes from 'propTypes';

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  & .MuiDrawer-paper::before {
    content: '';
    display: block;
    position: absolute;

    top: 0.5rem;
    left: 0;
    right: 0;
    margin: 0 auto;

    width: 2rem;
    height: 4px;
    border-radius: 4px;
    background-color: #ddd;
  }
`;

const MobileBottomDrawer = ({ ModalProps, PaperProps, ...rest }) => {

  const theme = useTheme();

  return (
    <StyledSwipeableDrawer
      disableSwipeToOpen
      disableDiscovery
      anchor="bottom"
      ModalProps={{
        style: {
          zIndex: theme.zIndex.tabbarBackdrop,
        },
        ...ModalProps,
      }}
      PaperProps={{
        className: 'u-tabbar-bottom-padding',
        style: {
          zIndex: theme.zIndex.tabbarDrawer,
          paddingTop: '1.5rem',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        },
        ...PaperProps,
      }}
      {...rest}
    />
  );
};

MobileBottomDrawer.propTypes = {
  ModalProps: PropTypes.object,
  PaperProps: PropTypes.object,
};

export default MobileBottomDrawer;

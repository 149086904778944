import { createAction } from 'redux/helpers/actions';
import types from './types';

const actions = {
  trackEvent: ({ type, ...extra }) => createAction(types.trackEvent, { type, extra }),

  sendAnalyticsData: () => createAction(types.sendAnalyticsData),
  sendAnalyticsDataFailure: () => createAction(types.sendAnalyticsDataFailure),
  sendAnalyticsDataSuccess: () => createAction(types.sendAnalyticsDataSuccess),
};

export default actions;

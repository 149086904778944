import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import DateTimePickerField from 'components/FinalForm/Fields/DateTimePickerField';
import { fieldValidator, isRequired } from 'validators';
import IconButton from '@mui/material/IconButton';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import InputAdornment from '@mui/material/InputAdornment';

const scheduleValidator = fieldValidator('schedule', isRequired);

const ScheduleField = ({ ...rest }) => {

  const { t } = useTranslation(['component']);

  const form = useForm();

  const beforeSubmit = () => {
    const { values } = form.getState();
    if (!values.$schedule) {
      form.change('scheduledAt', null);
    }
  };

  const onDeleteClick = () => {
    form.change('scheduledAt', null);
    form.change('$schedule', undefined);
  };

  return (
    <DateTimePickerField
      name="scheduledAt"
      validate={scheduleValidator}
      beforeSubmit={beforeSubmit}
      label={t('component:postScheduleField.scheduleDateLabel')}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton color="default" edge="end" onClick={onDeleteClick}>
            <DeleteRoundedIcon />
          </IconButton>
        </InputAdornment>
      )}
      showTodayButton
      autoOk
      {...rest}
    />
  );
};

export default ScheduleField;

export default {
  store: 'post/STORE',
  storeSuccess: 'post/STORE_SUCCESS',
  storeFailure: 'post/STORE_FAILURE',

  update: 'post/UPDATE',
  updateSuccess: 'post/UPDATE_SUCCESS',
  updateFailure: 'post/UPDATE_FAILURE',

  patch: 'post/PATCH',
  patchSuccess: 'post/PATCH_SUCCESS',
  patchFailure: 'post/PATCH_FAILURE',

  delete: 'post/DELETE',
  deleteSuccess: 'post/DELETE_SUCCESS',
  deleteFailure: 'post/DELETE_FAILURE',

  addComment: 'post/ADD_COMMENT',
  addCommentSuccess: 'post/ADD_COMMENT_SUCCESS',
  addCommentFailure: 'post/ADD_COMMENT_FAILURE',

  get: 'post/GET',
  getSuccess: 'post/GET_SUCCESS',
  getFailure: 'post/GET_FAILURE',

  getReachPercentage: 'post/GET_REACH_PERCENTAGE',
  getReachPercentageSuccess: 'post/GET_REACH_PERCENTAGE_SUCCESS',
  getReachPercentageFailure: 'post/GET_REACH_PERCENTAGE_FAILURE',

  getReachPercentages: 'post/GET_REACH_PERCENTAGES',
  getReachPercentagesSuccess: 'post/GET_REACH_PERCENTAGES_SUCCESS',
  getReachPercentagesFailure: 'post/GET_REACH_PERCENTAGES_FAILURE',

  follow: 'post/FOLLOW',
  followSuccess: 'post/FOLLOW_SUCCESS',
  followFailure: 'post/FOLLOW_FAILURE',

  unfollow: 'post/UNFOLLOW',
  unfollowSuccess: 'post/UNFOLLOW_SUCCESS',
  unfollowFailure: 'post/UNFOLLOW_FAILURE',

  getComments: 'post/GET_COMMENTS',
  getCommentsSuccess: 'post/GET_COMMENTS_SUCCESS',
  getCommentsFailure: 'post/GET_COMMENTS_FAILURE',

  getLikes: 'post/GET_LIKES',
  getLikesSuccess: 'post/GET_LIKES_SUCCESS',
  getLikesFailure: 'post/GET_LIKES_FAILURE',

  removeLike: 'post/REMOVE_LIKE',
  removeLikeSuccess: 'post/REMOVE_LIKE_SUCCESS',
  removeLikeFailure: 'post/REMOVE_LIKE_FAILURE',

  addLike: 'post/ADD_LIKE',
  addLikeSuccess: 'post/ADD_LIKE_SUCCESS',
  addLikeFailure: 'post/ADD_LIKE_FAILURE',

  getSmsExpenseEstimation: 'post/GET_SMS_EXPENSE_ESTIMATION',
  getSmsExpenseEstimationSuccess: 'post/GET_SMS_EXPENSE_ESTIMATION_SUCCESS',
  getSmsExpenseEstimationFailure: 'post/GET_SMS_EXPENSE_ESTIMATION_FAILURE',
  smsExpenseEstimationReset: 'post/SMS_EXPENSE_ESTIMATION_RESET',

  getTranslations: 'post/GET_TRANSLATIONS',
  getTranslationsSuccess: 'post/GET_TRANSLATIONS_SUCCESS',
  getTranslationsFailure: 'post/GET_TRANSLATIONS_FAILURE',

  getFeed: 'post/GET_FEED',
  getFeedSuccess: 'post/GET_FEED_SUCCESS',
  getFeedFailure: 'post/GET_FEED_FAILURE',
};

import React from 'react';
import EditPostForm from 'containers/Post/PostForm/EditPostForm';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import useFormDialogCloseConfirm from 'components/Confirmation/useFormDialogCloseConfirm';

const EditPostDialog = ({ postId, open, onClose, ...rest }) => {

  const { t } = useTranslation(['component']);

  const { FormSpy, onConfirmClose } = useFormDialogCloseConfirm(onClose, {
    title: t('editPostDialog.dirtyFormCloseConfirmation.title'),
    description: t('editPostDialog.dirtyFormCloseConfirmation.description'),
    submit: t('editPostDialog.dirtyFormCloseConfirmation.submit'),
    cancel: t('editPostDialog.dirtyFormCloseConfirmation.cancel'),
  });

  return (
    <Dialog maxWidth="md" open={open} onClose={onConfirmClose}>
      <EditPostForm
        postId={postId}
        onSuccess={onClose}
        formHeader={FormSpy}

        asDialog
        onClose={onClose}
        {...rest}
      />
    </Dialog>
  );
};

EditPostDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  postId: PropTypes.number.isRequired,
};

export default EditPostDialog;

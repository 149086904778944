import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import useCapabilities from '../../useCapabilities';

const useImportantButtonProps = (args) => {
  const { t } = useTranslation(['component']);

  const { important, editing } = useCapabilities();

  const { input: { onChange, value } } = useField('important', {
    parse: val => val ? 1 : 0,
    beforeSubmit: val => val ? 1 : 0,
  });

  useEffect(() => {
    if (!important && !editing && value) onChange(0);
  }, [editing, important, onChange, value]);

  return {
    hidden: !important,
    ...(!!value && { color: 'primary', selected: true }),
    onClick: () => onChange(!value),
    children: t('postForm.importantToggleButton'),
    ...args,
  };
};

export default useImportantButtonProps;

import combineReducers from 'combine-reducers-global-state';
import types from './types';

const personaSettings = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getPersonaSettingsSuccess:
    case types.updatePersonaSettingsSuccess:
      return payload;
    default:
      return state;
  }
};

const netvisorSettings = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getNetvisorSettingsSuccess:
    case types.updateNetvisorSettingsSuccess:
      return payload;
    default:
      return state;
  }
};

const coopSettings = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCoopSettingsSuccess:
    case types.updateCoopSettingsSuccess:
    case types.syncCoopSuccess:
      return payload?.data || payload; // TODO: Wtf?
    default:
      return state;
  }
};

const integrations = (state = [], { type, payload }) => {
  switch (type) {
    case types.getIntegrationsSuccess:
      return payload.data;
    default:
      return state;
  }
};

const rulesByIntegration = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getIntegrationRulesSuccess:
      return {
        ...state,
        [payload.integrationId]: payload.data,
      };
    case types.createIntegrationRuleSuccess: {
      const integrationRule = payload.entities.integrationRules[payload.data];

      return {
        ...state,
        [integrationRule.integrationId]: [
          payload.data,
          ...state[integrationRule.integrationId],
        ],
      };
    }

    default:
      return state;
  }
};

const integrationReducers = combineReducers({
  integrations,
  rulesByIntegration,
  persona: combineReducers({
    settings: personaSettings,
  }),
  netvisor: combineReducers({
    settings: netvisorSettings,
  }),
  coop: combineReducers({
    settings: coopSettings,
  }),
});

export default { integration: integrationReducers };

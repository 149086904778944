import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import guava from './guava-colored.svg';

const Wrapper = styled('img')`
  max-width: 100%;
  max-height: 100%;
  display: block;
`;

const Logo = ({ src, ...rest }) => {

  const { t } = useTranslation('common');

  return (
    <Wrapper
      src={src || guava}
      alt={src ? undefined : t('common:guavahr')}
      {...rest}
    />
  );
};

Logo.propTypes = {
  src: PropTypes.string,
};

export default Logo;

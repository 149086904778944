import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';

const getGroupEntities = state => state.entities.groups;

const getGroupIds = ({ workspace: { groups } }) => groups;
const getGroups = createSelector(
  [getGroupIds, getGroupEntities],
  (ids, entities) => ids.map(id => entities[id]),
);

const getGroupSelector = createSelector([getGroupEntities], groups => memoize((groupId) => groups?.[groupId]));

const getSortedGroups = createSelector(
  [getGroupIds, getGroupSelector],
  (groupIds, groupSelector) => groupIds.map(groupSelector).sort((a, b) => a.name.localeCompare(b.name)),
);

const getSubscribedGroups = createSelector(
  [getGroups],
  memoize(groups => groups?.filter(({ subscribed }) => !!subscribed) ?? []),
);

const getGroup = (state, id) => getGroupEntities(state)[id];

const fallbackSubscribers = [];
const getGroupSubscribers = (state, groupId) => {
  const group = getGroup(state, groupId);

  return group ? group.subscribers.map(id => state.entities.users[id]) : fallbackSubscribers;
};

const getMemberIdsByGroup = (state, groupId) => selectPagination(state.group.membersByGroup, groupId, 'memberIds').memberIds;

const getMemberEntities = ({ entities: { members } }) => members;


export default {
  getGroupIds,
  getGroups,
  getSortedGroups,
  getSubscribedGroups,
  getGroup,

  getGroupSelector,

  getGroupSubscribers,

  getMemberIdsByGroup,
  getMemberEntities,
};

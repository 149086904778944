import { combineReducers } from 'redux';
import { addToPagination, createPagination, resetPagination } from 'redux/helpers/pagination';
import types from './types';

const recordTypes = (state = [], { type, payload }) => {
  switch (type) {
    case types.getRecordTypesSuccess:
      return payload;
    default:
      return state;
  }
};

const recordsInitialState = {
  all: createPagination(null, 'recordIds'),
};
const records = (state = recordsInitialState, { type, payload }) => {
  switch (type) {
    case types.createRecordSuccess:
    case types.updateRecordSuccess:
      return resetPagination(state, '*', 'recordIds');
    case types.getRecordsSuccess:
      return addToPagination(state, payload.type || 'all', payload, 'recordIds');
    default:
      return state;
  }
};

const driveReducers = combineReducers({
  recordTypes,
  records,
});

export default { drive: driveReducers };

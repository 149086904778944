const getCommentEntities = state => state.entities.comments;

const getComment = (state, commentId) => getCommentEntities(state)[commentId];

const getLikesByCommentId = (state, commentId) => {
  const getLikeEntities = state.entities.likes;
  const getLikeIdsByCommentId = state.comment.likesByCommentId[commentId];

  if (getLikeEntities && getLikeIdsByCommentId) {
    return getLikeIdsByCommentId.map(id => getLikeEntities[id]);
  }

  return null;
};

export default {
  getComment,
  getLikesByCommentId,
};

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import styled from '@emotion/styled/macro';
import { ReactComponent as EmojiHappyColoredSvg } from './assets/emoji-happy-colored.svg';

const StyledSvgIcon = styled(SvgIcon)`
  color: #4DAF4F;
`;

const EmojiHappyColoredIcon = args => <StyledSvgIcon component={EmojiHappyColoredSvg} {...args} />;

export default EmojiHappyColoredIcon;

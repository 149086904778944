import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import styled from '@emotion/styled/macro';
import InputAdornment from '@mui/material/InputAdornment';
import FormField from 'components/FinalForm/Fields/FormField';
import { postTextToHtml, postHtmlToText } from 'services/parsingService';
import Button from 'components/Button';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useConfirm from 'components/Confirmation/useConfirm';
import RichEditorField from './RichEditorField';

const RichEditorButton = styled(Button)`
  padding: 0.1rem;
  min-width: auto;
`;

const StyledInputAdornment = styled(InputAdornment)`
  align-self: flex-end;
  height: auto;

  :not(.MuiOutlinedInput-root) & {
    margin-top: -10px; /* So that it doesn't increase the visual height of the input */
  }
`;

const EndAdornment = args => (
  <StyledInputAdornment position="end">
    <RichEditorButton variant="outlined" size="small" type="button" {...args}>
      <TextFormatIcon />
    </RichEditorButton>
  </StyledInputAdornment>
);

const ToggleableRichEditorField = ({ name, richEditorProps, onContentEvents, ...args }) => {
  const form = useForm();

  const { t } = useTranslation(['component']);

  const confirm = useConfirm();

  const getRichValue = () => form.getState().values[`${name}Html`];
  const setRichValue = value => form.change(`${name}Html`, value);
  const getPlainValue = () => form.getState().values[name];
  const setPlainValue = value => form.change(name, value);

  const [isRich, setIsRich] = useState(!!getRichValue());

  const onEditorChange = content => setPlainValue(postHtmlToText(content));

  const clearRichValue = () => setRichValue('');

  const syncPlain = () => setPlainValue(postHtmlToText(getRichValue()));
  const syncRich = () => setRichValue(postTextToHtml(getPlainValue()));

  const toggleRich = e => {
    setIsRich(current => {
      if (current) {
        syncPlain(); // Rich -> Plain. Update plain
      } else {
        syncRich(); // Plain -> Rich. Update Rich
      }

      return !current;
    });

    if (e) {
      e.preventDefault();
    }
  };

  return isRich ? (
    <RichEditorField
      name={`${name}Html`}
      toggleRich={toggleRich}
      onEditorChange={onEditorChange}
      beforeSubmit={syncPlain}
      init={{
        setup: (editor) => {
          editor.ui.registry.addToggleButton('disableRich', {
            text: 'Aa',
            onSetup: api => {
              api.setActive(true);
            },
            onAction: () => {
              confirm({
                title: t('richEditorField.switchToBasicConfirmationTitle'),
                description: t('richEditorField.switchToBasicConfirmationDescription'),
                cancel: t('richEditorField.switchToBasicConfirmationCancel'),
                submit: t('richEditorField.switchToBasicConfirmationSubmit'),
              })
                .then(() => toggleRich())
                .catch(() => { });
            },
          });
        },
      }}
      onContentEvents={onContentEvents}
      {...(richEditorProps ?? {})}
      {...args}
    />
  ) : (
    <FormField
      name={name}
      multiline
      variant="outlined"
      FormHelperTextProps={{
        variant: 'standard',
      }}
      InputProps={{
        endAdornment: <EndAdornment onClick={toggleRich} />,
      }}
      beforeSubmit={clearRichValue}
      {...args}
    />
  );
};

ToggleableRichEditorField.propTypes = {
  name: PropTypes.string.isRequired,
  richEditorProps: PropTypes.object,
  onContentEvents: PropTypes.func,
};

export default ToggleableRichEditorField;

import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

const options = [
  [10, 10], // Start with big numbers as maybe there's only one widget
  [10, 9], // and maybe something like [10, 0] or [0, 10] will fit
  [10, 8],
  [10, 7],
  [10, 6],
  [9, 6],
  [8, 6],
  [7, 6],
  [6, 6],
  [6, 5], // In case we don't have either today & upcoming,
  [6, 4], // then some of these would match
  [6, 3],
  [5, 3],
  [4, 3], // But if nothing fits then we likely have both today & upcoming
  [3, 3],
  [3, 2],
  [2, 2],
  [2, 1],
  [7, 0], // Lose the upcoming which should give us ton of extra space,
  [6, 0], // so try again starting with some bigger numbers
  [5, 0],
  [4, 0],
  [3, 0],
  [2, 0],
];

const useWidgetCapDetector = () => {

  const ref = useRef();
  const option = useRef(0);
  const [cap, setCap] = useState(options[option.current]);

  const hasVerticalOverflow = useCallback(() => {
    return ref.current?.scrollWidth > ref.current?.clientWidth;
  }, []);

  const detectOverflow = useCallback(() => {
    const hasOverflow = hasVerticalOverflow();

    if (hasOverflow && option.current < options.length - 1) {
      option.current++;
      setCap(options[option.current]);
    }
  }, [hasVerticalOverflow]);

  const onResize = useCallback(() => {
    // On resize, we must try all options again, as
    // blocks might shift and go bigger as well
    option.current = 0;
    setCap(options[0]);

    detectOverflow();
  }, [detectOverflow]);

  useLayoutEffect(() => {
    const debouncedOnResize = debounce(onResize, 50);

    window.addEventListener('resize', debouncedOnResize, { passive: true });
    return () => window.removeEventListener('resize', debouncedOnResize, { passive: true });
  }, [onResize]);

  useLayoutEffect(() => {
    detectOverflow();
  }, [detectOverflow, cap]);

  return [ref, cap];
};

export default useWidgetCapDetector;


const types = {
  getActivityCategories: 'challenge/GET_ACTIVITY_CATEGORIES',
  getActivityCategoriesSuccess: 'challenge/GET_ACTIVITY_CATEGORIES_SUCCESS',
  getActivityCategoriesFailure: 'challenge/GET_ACTIVITY_CATEGORIES_FAILURE',

  storeActivity: 'challenge/STORE_ACTIVITY',
  storeActivitySuccess: 'challenge/STORE_ACTIVITY_SUCCESS',
  storeActivityFailure: 'challenge/STORE_ACTIVITY_FAILURE',

  updateActivity: 'challenge/UPDATE_ACTIVITY',
  updateActivitySuccess: 'challenge/UPDATE_ACTIVITY_SUCCESS',
  updateActivityFailure: 'challenge/UPDATE_ACTIVITY_FAILURE',

  getChallenge: 'challenge/GET_CHALLENGE',
  getChallengeSuccess: 'challenge/GET_CHALLENGE_SUCCESS',
  getChallengeFailure: 'challenge/GET_CHALLENGE_FAILURE',

  getChallenges: 'challenge/GET_CHALLENGES',
  getChallengesSuccess: 'challenge/GET_CHALLENGES_SUCCESS',
  getChallengesFailure: 'challenge/GET_CHALLENGES_FAILURE',
};

export default types;

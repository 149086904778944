import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import useMediaQuery from '@mui/material/useMediaQuery';
import DateFormat from 'components/DateFormat';

const StyledDateFormat = styled(DateFormat)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
  padding: 0.25rem 0;
`;

const CommentDate = ({ date }) => {

  const smallerDates = useMediaQuery('(max-width: 499px)');

  return date
    ? <StyledDateFormat date={date} fromNowDuring={5 * 365 * 24 * 60 * 60} format="PPP" addSuffix={!smallerDates} />
    : null;
};

CommentDate.propTypes = {
  date: PropTypes.any.isRequired,
};

export default CommentDate;

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'propTypes';
import EmptyState from 'components/EmptyState';
import { useTranslation } from 'react-i18next';
import TextLoader from 'components/TextLoader';
import Box from '@mui/material/Box';
import ViewContext from '../Contexts/ViewContext';
import ContactDirectoryTree from './ContactDirectoryTree';
import ContactDirectoryByName from './ContactDirectoryByName';
import ContactDirectoryTopBar from './ContactDirectoryTopBar';

const TopWrapper = styled(Box)`
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

const ContactDirectoryList = ({ useWindow, topWrapperProps }) => {

  const { t } = useTranslation(['component']);

  const { tree, items, pagination, loadMore } = useContext(ViewContext);

  const loader = <TextLoader key="0" text={pagination?.currentPage <= 1 ? t('contactDirectory.loadingPeople') : t('contactDirectory.loadingMorePeople')} />;

  const isEmptyArray = items && Array.isArray(items) && !items.length;
  const isEmptyObject = items && !Array.isArray(items) && Object.keys(items).length === 0;
  const isEmpty = isEmptyArray || isEmptyObject;

  const ListComponent = tree ? ContactDirectoryTree : ContactDirectoryByName;

  return (
    <>
      <TopWrapper {...topWrapperProps}>
        <ContactDirectoryTopBar />
      </TopWrapper>
      {!pagination?.hasMore && isEmpty
        ? <EmptyState title={t('contactDirectory.noPeople')} />
        : <ListComponent useWindow={useWindow} items={items} loader={loader} loadMore={loadMore} hasMore={pagination?.hasMore} />}
    </>
  );
};

ContactDirectoryList.propTypes = {
  useWindow: PropTypes.bool,

  topWrapperProps: PropTypes.object,
};

export default ContactDirectoryList;

import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { birthdayActions, birthdaySelectors } from 'redux/birthday';
import DrawerFooter from 'containers/Side/Drawer/DrawerFooter';
import DrawerBody from 'containers/Side/Drawer/DrawerBody';
import NavLink from 'components/NavLink';
import PropTypes from 'prop-types';
import { NumberParam, useQueryParam } from 'use-query-params';
import AddComment from '../Commons/AddComment';
import EventHeader from '../Commons/EventHeader';
import SideContext from '../../SideContext';
import BirthdayComments from './BirthdayComments';
import { getBirthdaysLink, getCloseButtonLink } from '../memberEventsService';

const Birthday = ({ memberId }) => {
  const dispatch = useDispatch();

  const { id, member, cover, permissions } = useSelector(birthdaySelectors.getBirthdayEvent);

  const { setTitle } = useContext(SideContext);

  const [year] = useQueryParam('year', NumberParam);

  useEffect(() => {
    if (!memberId) return;
    dispatch(birthdayActions.getBirthdayEvent({ memberId, year }));
  }, [dispatch, memberId, year]);

  useEffect(() => {
    setTitle({
      children: [member?.firstName, member?.lastName].filter(v => !!v).join(' '),
      closeButton: { component: NavLink, to: getCloseButtonLink },
      backButton: { component: NavLink, to: getBirthdaysLink },
    });
  }, [member, setTitle]);

  return (
    <>
      <DrawerBody>
        <EventHeader cover={cover} member={member} />
        {id && <BirthdayComments eventId={id} />}
      </DrawerBody>
      <DrawerFooter>
        <AddComment type="Birthday" disabled={!permissions?.comment} eventId={id} />
      </DrawerFooter>
    </>
  );
};

Birthday.propTypes = {
  memberId: PropTypes.any,
};

export default Birthday;

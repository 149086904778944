import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const Wrapper = styled('div')`
  @media(min-width: 321px) {
    background: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08),
      0px 16px 32px rgba(0, 0, 0, 0.08);
    padding: 1rem 0.25rem;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  height: 100%;

  color: inherit;
  text-decoration: none;
`;

const IconWrapper = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;

  background: #f5f5f5;
  color: ${({ theme }) => theme.color};
`;

const Name = styled('span')`
  font-weight: 500;
  width: 100%;
  text-align: center;

  font-size: 12px;
  line-height: 1.3;
  max-height: 3.9em; // Limit to 3 lines (Org. name is unknown size)
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

const HubButton = ({ icon, children, ...args }) => {
  return (
    <Wrapper {...args}>
      <IconWrapper>
        {icon}
      </IconWrapper>
      <Name>{children}</Name>
    </Wrapper>
  );
};

HubButton.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.any.isRequired,
};

export default HubButton;

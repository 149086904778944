/* eslint-disable prefer-destructuring */
import { useRef, useCallback } from 'react';
import { useSpring } from '@react-spring/web';
import clamp from 'lodash/clamp';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useGesture } from 'react-use-gesture';
import PropTypes from 'prop-types';

const getLength = ([touch1, touch2]) => {
  const dx = Math.abs(touch1.clientX - touch2.clientX) ** 2;
  const dy = Math.abs(touch1.clientY - touch2.clientY) ** 2;

  return Math.sqrt(dx + dy);
};

const subtract = (touch1, touch2) => ({
  clientX: touch1.clientX - touch2.clientX,
  clientY: touch1.clientY - touch2.clientY,
});

const add = (touch1, touch2) => ({
  clientX: touch1.clientX + touch2.clientX,
  clientY: touch1.clientY + touch2.clientY,
});

const getMiddle = ([a, b]) => {
  const sum = add(a, b);

  const clientX = sum.clientX / 2;
  const clientY = sum.clientY / 2;

  return { clientX, clientY };
};

const toRelative = ({ clientX, clientY }, boundingRect) => ({
  clientX: clientX - boundingRect.left,
  clientY: clientY - boundingRect.top,
});

const useZoom = ({ minScale = 1, maxScale = 5, zIndex = 10000, onChange = (() => {}), domTarget, enabled = true, ...config } = {}, deps) => {

  // Currently most relevant touch coordinates to track.
  // For zooming it's the center and point where all other distances are calculated from
  // After zooming (dragging) this references the previous finger position
  const touchBase = useRef(null);

  const initialDistance = useRef(null);

  const elementBounds = useRef(null);

  const initialized = useRef(false);
  const stopping = useRef(false);
  const scalingAllowed = useRef(true);

  const spring = useSpring(() => ({
    scale: 1,
    x: 0,
    y: 0,
    transformOriginX: 0,
    transformOriginY: 0,
    immediate: true,
  }));

  const [style, animate] = spring;

  const onTouchStart = useCallback(({ event }) => {

    if (event.targetTouches.length < 2 || !enabled) {
      return;
    }

    event.preventDefault();

    if (initialized.current || stopping.current) {
      return;
    }

    initialized.current = true;
    onChange(true);
    disableBodyScroll(domTarget.current);
    elementBounds.current = domTarget.current.getBoundingClientRect();

    touchBase.current = toRelative(getMiddle(event.targetTouches), elementBounds.current);
    initialDistance.current = getLength(event.targetTouches);

    animate({
      zIndex,
      transformOriginX: touchBase.current.clientX,
      transformOriginY: touchBase.current.clientY,
      immediate: true,
    });

  }, [animate, enabled, domTarget, onChange, zIndex]);

  const onTouchMove = useCallback(({ event }) => {
    if (!initialized.current || stopping.current) {
      return;
    }

    if (scalingAllowed.current && event.targetTouches.length >= 2) {
      const distance = getLength(event.targetTouches);
      const middle = toRelative(getMiddle(event.targetTouches), elementBounds.current);
      const nextScale = clamp(distance / initialDistance.current, minScale, maxScale);
      const { clientX, clientY } = subtract(middle, touchBase.current);

      animate({
        x: clientX,
        y: clientY,
        scale: nextScale,
        immediate: true,
      });

      return;
    }

    const touch = toRelative(event.targetTouches[0], elementBounds.current);
    const { clientX, clientY } = subtract(touch, touchBase.current); // Movement delta
    touchBase.current = touch;

    animate({
      x: style.x.goal + clientX,
      y: style.y.goal + clientY,
      immediate: true,
    });
  }, [animate, maxScale, minScale, style.x.goal, style.y.goal]);

  const reset = useCallback(() => {
    stopping.current = true;
    animate({ cancel: true });
    animate({
      to: async next => {
        await next({ scale: 1, x: 0, y: 0 });
        await next({ zIndex: '', immediate: true });
        await onChange(false);
        await enableBodyScroll(domTarget.current);

        stopping.current = false;
        initialized.current = false;
        scalingAllowed.current = true;
        touchBase.current = null;
        initialDistance.current = null;
      },
    });

  }, [animate, domTarget, onChange]);

  const onTouchEnd = useCallback(({ event }) => {
    if (!initialized.current || stopping.current) {
      return;
    }

    if (event.targetTouches.length === 0) {
      reset();
    } else {
      scalingAllowed.current = false;
      touchBase.current = toRelative(event.targetTouches[0], elementBounds.current);
    }
  }, [reset]);

  useGesture({ onTouchMove, onTouchEnd }, {
    domTarget,
    ...config,
  }, deps);

  useGesture({ onTouchStart }, {
    domTarget,
    eventOptions: { passive: false },
    ...config,
  }, deps);

  return { spring };
};

useZoom.propTypes = {
  domTarget: PropTypes.elementType.isRequired,
};

export default useZoom;

import React, { useContext } from 'react';
import DialogTitle from 'components/Dialog/DialogTitle';
import Grid from 'components/Grid';
import PostFormContext from '../PostFormContext';

const PostFormHeader = args => useContext(PostFormContext).asDialog
  ? <DialogTitle {...args} />
  : <Grid item {...args} />;

export default PostFormHeader;

import update from 'immutability-helper';
import types from '../lesson/types';

const learningLessonsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case types.putLessonForAdminSuccess:
    {
      return { ...state, [payload.id]: payload };
    }

    case types.deleteLessonForAdminSuccess:
    {
      const nextState = { ...state };
      delete nextState[payload.lessonId];
      return nextState;
    }

    case types.reorderLessonsForAdmin:
      return update(state, {
        ...payload.lessons.reduce((acc, { id, sortOrder }) => ({
          ...acc,
          [id]: {
            sortOrder: { $set: sortOrder },
          },
        }), {}),
      });


    default:
      return state;
  }
};

export default learningLessonsReducer;

import React, { useEffect, useMemo, useLayoutEffect } from 'react';
import find from 'lodash/find';
import { useDispatch, useSelector } from 'react-redux';
import { challengeSelectors, challengeActions } from 'redux/challenge';
import PropTypes from 'prop-types';
import FormField from 'components/FinalForm/Fields/FormField';
import { useFieldArray } from 'react-final-form-arrays';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { getFieldLabelTranslation, getFieldPlaceholderTranslation, getUnitTranslation } from 'services/challengeService';
import { fieldValidator, isGTE, isRequired } from 'validators';
import { useField, useForm } from 'react-final-form';

const getValidator = (name, rules) => {
  if (!rules) {
    return undefined;
  }

  const min = rules.match(/(\||^)min:(\d+)(\||$)/);
  const required = rules.match(/(\||^)(required)(\||$)/);

  return fieldValidator(name, [
    ...(min ? [isGTE(min[2])] : []),
    ...(required ? [isRequired] : []),
  ]);
};

const fallbackCategoryField = {};

const CategoryField = ({ name, typeId, categoryName }) => {
  const { t } = useTranslation(['common', 'component']);

  const type = useSelector(challengeSelectors.getActivityCategoryFieldTypes)[typeId] || fallbackCategoryField;

  const { input: { value } } = useField(`${name}.value`);

  const props = {
    name: `${name}.value`,
    label: getFieldLabelTranslation(t, type.name, categoryName),
    placeholder: getFieldPlaceholderTranslation(t, type.name, categoryName),
    InputProps: {
      endAdornment: type.unit ? <InputAdornment>{getUnitTranslation(t, +value, type.unit, categoryName)}</InputAdornment> : undefined,
    },
    inputProps: type.input || {},
    validate: getValidator(type.name, type.rules),
  };

  return <FormField margin="dense" {...props} />;
};

CategoryField.propTypes = {
  name: PropTypes.string.isRequired,
  categoryName: PropTypes.string,
  typeId: PropTypes.number,
};

const ActivityCategorySpecificFields = ({ name, categoryId }) => {

  const dispatch = useDispatch();
  const form = useForm();

  const { fields } = useFieldArray(name);

  const categories = useSelector(challengeSelectors.getActivityCategories);

  const category = useMemo(() => categories.find(({ id }) => +id === +categoryId), [categories, categoryId]);

  useEffect(() => {
    dispatch(challengeActions.getActivityCategories());
  }, [dispatch]);

  useLayoutEffect(() => {
    return () => form.change(fields.name, null);
  }, [form, fields.name]);

  useLayoutEffect(() => {
    if (!category) {
      return;
    }

    const newFields = (category.types || []).map(typeId => ({
      typeId,
      value: (find(fields.value, { typeId }) || {}).value,
    }));

    form.change(fields.name, newFields);
  }, [category]); // eslint-disable-line

  if (!fields.length) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      {fields.map((fieldName, idx) => (
        <Grid key={fieldName} item xs={6}>
          <CategoryField name={fieldName} typeId={fields.value[idx].typeId} categoryName={category?.name} />
        </Grid>
      ))}
    </Grid>
  );
};

ActivityCategorySpecificFields.propTypes = {
  name: PropTypes.string,
  categoryId: PropTypes.any,
};

export default ActivityCategorySpecificFields;

import React, { useLayoutEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TrashIcon from 'components/Icons/Trash';
import styled from '@emotion/styled/macro';
import PropTypes from 'propTypes';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import Stack from '@mui/material/Stack';
import Grid from 'components/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { isMobile } from 'helpers';
import { css } from '@emotion/react';

const StyledIconButton = styled(IconButton)`
  color: #fff;
  background-color: #00000070;

  &:hover {
    background-color: #00000090;
  }
`;

const ContentContainer = styled('div')``;

const ButtonContainer = styled('div')`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  right: 0.75rem;
`;

const Wrapper = styled('div')`
  position: relative;

  ${isMobile && css`
    ${StyledIconButton} {
      opacity: 1;
      background-color: #000;
    }
  `}

  ${!isMobile && css`
    ${StyledIconButton} {
      opacity: 0.4;
      transition: opacity 100ms ease-in;
      transition: background-color 100ms ease-in;
    }

    &:hover ${StyledIconButton} {
      opacity: 1;
      background-color: #000;
    }
  `}
`;

const OpengraphActionsWrapper = ({ onDelete, choices, onChoiceClick, renderChoice, children, ...rest }) => {

  const [choicesDialogOpen, setChoicesDialogOpen] = useState(false);

  useLayoutEffect(() => {
    if (choices?.length <= 1) {
      setChoicesDialogOpen(false);
    }
  }, [choices?.length]);

  const $onChoiceClick = (choice) => {
    setChoicesDialogOpen(false);
    onChoiceClick(choice);
  };

  return (
    <Wrapper {...rest}>

      <Dialog maxWidth="xs" fullWidth onClose={() => setChoicesDialogOpen(false)} open={choicesDialogOpen}>
        <DialogContent>
          <Grid container direction="column" wrap="nowrap" spacing={1}>
            {choices?.map((choice) => (
              <Grid item key={choice?.url} sx={{ cursor: 'pointer' }} onClick={() => $onChoiceClick(choice)}>
                {renderChoice(choice)}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>

      <ContentContainer>
        {children}
      </ContentContainer>

      <ButtonContainer>
        <Stack direction="row" spacing={1} wrap="nowrap" justifyContent="flex-end">
          {choices?.length > 1 && (
            <StyledIconButton size="small" onClick={() => setChoicesDialogOpen(true)}>
              <SwapHorizRoundedIcon fontSize="small" />
            </StyledIconButton>
          )}

          {onDelete && (
            <StyledIconButton size="small" onClick={onDelete}>
              <TrashIcon fontSize="small" />
            </StyledIconButton>
          )}
        </Stack>
      </ButtonContainer>
    </Wrapper>
  );
};

OpengraphActionsWrapper.propTypes = {
  onDelete: PropTypes.func,

  choices: PropTypes.array,
  onChoiceClick: PropTypes.func,
  renderChoice: PropTypes.func,
};

export default OpengraphActionsWrapper;

import { schema } from 'normalizr';
import comment from './comment';
import channel from './channel';
import poll from './poll';
import quiz from './quiz';

const postSchema = new schema.Entity('posts', {
  comments: [comment],
  channel,
  poll,
  quiz,
}, {
  processStrategy: ({ feedPinnable, ...value }) => value, // Remove feedPinnable if present, as it needs some processing, otherwise messes up the values
});

export const getFeedPostSchema = (feed, feedId) => new schema.Entity('posts', {
  comments: [comment],
  channel,
  poll,
  quiz,
}, {
  processStrategy: value => ({
    ...value,
    feedPinnable: {
      [feedId ? `${feed}-${feedId}` : feed]: !!value.feedPinnable,
    },
  }),
});

export default postSchema;

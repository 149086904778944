export default {
  getFoldersForMember: 'documentFolder/GET_FOLDERS_FOR_MEMBER',
  getFoldersForMemberSuccess: 'documentFolder/GET_FOLDERS_FOR_MEMBER_SUCCESS',
  getFoldersForMemberFailure: 'documentFolder/GET_FOLDERS_FOR_MEMBER_FAILURE',

  getFolderForMember: 'documentFolder/GET_FOLDER_FOR_MEMBER',
  getFolderForMemberSuccess: 'documentFolder/GET_FOLDER_FOR_MEMBER_SUCCESS',
  getFolderForMemberFailure: 'documentFolder/GET_FOLDER_FOR_MEMBER_FAILURE',

  getFoldersForAdmin: 'documentFolder/GET_FOLDERS_FOR_ADMIN',
  getFoldersForAdminSuccess: 'documentFolder/GET_FOLDERS_FOR_ADMIN_SUCCESS',
  getFoldersForAdminFailure: 'documentFolder/GET_FOLDERS_FOR_ADMIN_FAILURE',

  getFolderForAdmin: 'documentFolder/GET_FOLDER_FOR_ADMIN',
  getFolderForAdminSuccess: 'documentFolder/GET_FOLDER_FOR_ADMIN_SUCCESS',
  getFolderForAdminFailure: 'documentFolder/GET_FOLDER_FOR_ADMIN_FAILURE',

  postFolderForAdmin: 'documentFolder/POST_FOLDER_FOR_ADMIN',
  postFolderForAdminSuccess: 'documentFolder/POST_FOLDER_FOR_ADMIN_SUCCESS',
  postFolderForAdminFailure: 'documentFolder/POST_FOLDER_FOR_ADMIN_FAILURE',

  putFolderForAdmin: 'documentFolder/PUT_FOLDER_FOR_ADMIN',
  putFolderForAdminSuccess: 'documentFolder/PUT_FOLDER_FOR_ADMIN_SUCCESS',
  putFolderForAdminFailure: 'documentFolder/PUT_FOLDER_FOR_ADMIN_FAILURE',

  deleteFolderVariantForAdmin: 'documentFolder/DELETE_FOLDER_VARIANT_FOR_ADMIN',
  deleteFolderVariantForAdminSuccess: 'documentFolder/DELETE_FOLDER_VARIANT_FOR_ADMIN_SUCCESS',
  deleteFolderVariantForAdminFailure: 'documentFolder/DELETE_FOLDER_VARIANT_FOR_ADMIN_FAILURE',
};

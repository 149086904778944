import Button from 'components/Button';
import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useTranslation } from 'react-i18next';

const AddNewContactButton = (args) => {

  const { t } = useTranslation(['component']);

  return (
    <Button
      variant="text"
      startIcon={<AddRoundedIcon />}
      {...args}
    >
      {t('contactDirectory.addNewContact')}
    </Button>
  );
};

export default AddNewContactButton;

import { createSelector } from 'reselect';

const getFileEntities = ({ entities: { fileGroups } }) => fileGroups;
const getFileIds = ({ files: { all } }) => all;
const getFiles = createSelector([getFileIds, getFileEntities], (ids, fileGroups) => ids.map(id => fileGroups[id]));

const getFile = ({ entities: { fileGroups } }, id) => fileGroups[id];

const getFilesLoading = ({ files: { getFilesLoading: isLoading } }) => isLoading;

const getSaveInfo = ({ files: { save } }) => save;

export default {
  getFiles,
  getFile,
  getFilesLoading,
  getSaveInfo,
};

import { boostTypes } from 'redux/boost';
import update from 'immutability-helper';

const boostsReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case boostTypes.updateSuccess:
      return update(state, {
        [payload.boostId]: {
          completed: { $set: payload.completed },
        },
      });

    default:
      return state;
  }
};

export default boostsReducer;

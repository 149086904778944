import { useEffect, useState } from 'react';

const useObjectUrl = file => {

  const [objectUrl, setObjectUrl] = useState(null);

  useEffect(() => {
    const next = file instanceof File ? URL.createObjectURL(file) : null;

    setObjectUrl(next);

    return () => URL.revokeObjectURL(next);
  }, [file]);

  return objectUrl;
};

export default useObjectUrl;

import combineReducers from 'combine-reducers-global-state';
import analyticTypes from './types';

const createEvent = data => ({
  data: { time: Date.now(), ...data },
  pending: false,
});

const lastSend = (state = Date.now(), { type }) => {
  switch (type) {
    case analyticTypes.sendAnalyticsData:
      return Date.now();
    default:
      return state;
  }
};

const events = (state = [], { type, payload }) => {
  switch (type) {
    case analyticTypes.trackEvent:
      return [...state, createEvent(payload)];

    case analyticTypes.sendAnalyticsData:
      return state.map(({ data }) => ({ data, pending: true }));

    case analyticTypes.sendAnalyticsDataSuccess:
      return state.filter(({ pending }) => !pending);

    case analyticTypes.sendAnalyticsDataFailure:
      return state.map(({ data }) => ({ data, pending: false }));

    default:
      return state;
  }
};

const analyticsReducer = combineReducers({
  lastSend,
  events,
});

export default { analytic: analyticsReducer };

import React, { useState, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import NotificationsIconButton from 'containers/NotificationsIconButton';
import NotificationsList from './NotificationsList';

const Notifications = styled(NotificationsList)`
  height: 376px;
  width: 350px;
  max-width: 100vw;
`;

const NotificationMenu = args => {

  const { t } = useTranslation(['component']);

  const [open, setOpen] = useState(false);

  const anchorEl = useRef();

  return (
    <div {...args}>
      <NotificationsIconButton
        fontSize="small"
        size="small"
        ref={anchorEl}
        aria-controls={open ? 'authenticated-user-notifications' : undefined}
        aria-haspopup="true"
        onClick={() => setOpen(true)}
        aria-label={t('notificationMenu.button')}
      />
      <Popper open={open} anchorEl={anchorEl.current} transition disablePortal placement="top-end">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="authenticated-user-notifications">
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Notifications useWindow={false} onClose={() => setOpen(false)} />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default NotificationMenu;

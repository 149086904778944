import React from 'react';
import styled from '@emotion/styled/macro';
import Button from 'components/Button';

const Tab = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-around;

  max-width: 10rem;
  min-width: 1rem; /* Likely not needed, but helps with even spacing on very small screens */

  font-size: 1.5rem;
  color: #ccc;
  height: 100%;
  width: 100%;
  text-decoration: none;
  cursor: pointer;

  &.active {

    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      background: #f5f5f5;
      border-radius: 50%;
      z-index: -1;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export default React.forwardRef((props, ref) => <Tab variant="text" color="inherit" ref={ref} {...props} />);

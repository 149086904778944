import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getWorkspaceSettings: () => createAction(types.getWorkspaceSettings),
  getWorkspaceSettingsSuccess: data => createAction(types.getWorkspaceSettingsSuccess, data),
  getWorkspaceSettingsFailure: data => createAction(types.getWorkspaceSettingsFailure, data),

  updateWorkspaceSettings: data => createAction(types.updateWorkspaceSettings, data),
  updateWorkspaceSettingsSuccess: data => createAction(types.updateWorkspaceSettingsSuccess, data),
  updateWorkspaceSettingsFailure: data => createAction(types.updateWorkspaceSettingsFailure, data),

  getWorkspaceProfile: () => createAction(types.getWorkspaceProfile),
  getWorkspaceProfileSuccess: data => createAction(types.getWorkspaceProfileSuccess, data),
  getWorkspaceProfileFailure: data => createAction(types.getWorkspaceProfileFailure, data),

  updateWorkspaceProfile: () => createAction(types.updateWorkspaceProfile),
  updateWorkspaceProfileSuccess: data => createAction(types.updateWorkspaceProfileSuccess, data),
  updateWorkspaceProfileFailure: data => createAction(types.updateWorkspaceProfileFailure, data),

  getWorkspaceChannels: () => createAction(types.getWorkspaceChannels),
  getWorkspaceChannelsSuccess: data => createAction(types.getWorkspaceChannelsSuccess, data),
  getWorkspaceChannelsFailure: error => createAction(types.getWorkspaceChannelsFailure, error),

  getGroups: data => createAction(types.getGroups, data),
  getGroupsSuccess: data => createAction(types.getGroupsSuccess, data),
  getGroupsFailure: data => createAction(types.getGroupsFailure, data),

  getMembers: (data, config) => createAction(types.getMembers, data, config),
  getMembersSuccess: data => createAction(types.getMembersSuccess, data),
  getMembersFailure: data => createAction(types.getMembersFailure, data),

  exportMembers: (data, config) => createAction(types.exportMembers, data, config),
  exportMembersSuccess: data => createAction(types.exportMembersSuccess, data),
  exportMembersFailure: data => createAction(types.exportMembersFailure, data),

  getMember: (data, config) => createAction(types.getMember, data, config),
  getMemberSuccess: data => createAction(types.getMemberSuccess, data),
  getMemberFailure: data => createAction(types.getMemberFailure, data),

  getLabel: data => createAction(types.getLabel, data),
  getLabelSuccess: data => createAction(types.getLabelSuccess, data),
  getLabelFailure: data => createAction(types.getLabelFailure, data),

  getLabels: data => createAction(types.getLabels, data),
  getLabelsSuccess: data => createAction(types.getLabelsSuccess, data),
  getLabelsFailure: data => createAction(types.getLabelsFailure, data),

  getLabelMembers: data => createAction(types.getLabelMembers, data),
  getLabelMembersSuccess: data => createAction(types.getLabelMembersSuccess, data),
  getLabelMembersFailure: data => createAction(types.getLabelMembersFailure, data),

  addMember: (data, config) => createAction(types.addMember, data, config),
  addMemberSuccess: data => createAction(types.addMemberSuccess, data),
  addMemberFailure: data => createAction(types.addMemberFailure, data),

  updateMember: data => createAction(types.updateMember, data),
  updateMemberSuccess: data => createAction(types.updateMemberSuccess, data),
  updateMemberFailure: data => createAction(types.updateMemberFailure, data),

  deleteMember: data => createAction(types.deleteMember, data),
  deleteMemberSuccess: data => createAction(types.deleteMemberSuccess, data),
  deleteMemberFailure: data => createAction(types.deleteMemberFailure, data),

  reinviteMember: data => createAction(types.reinviteMember, data),
  reinviteMemberSuccess: data => createAction(types.reinviteMemberSuccess, data),
  reinviteMemberFailure: data => createAction(types.reinviteMemberFailure, data),

  memberMassAction: (data, config) => createAction(types.memberMassAction, data, config),
  memberMassActionSuccess: data => createAction(types.memberMassActionSuccess, data),
  memberMassActionFailure: data => createAction(types.memberMassActionFailure, data),

  getUser: (data, config) => createAction(types.getUser, data, config),
  getUserSuccess: data => createAction(types.getUserSuccess, data),
  getUserFailure: data => createAction(types.getUserFailure, data),

  updateUser: (data, config) => createAction(types.updateUser, data, config),
  updateUserSuccess: data => createAction(types.updateUserSuccess, data),
  updateUserFailure: data => createAction(types.updateUserFailure, data),

  getInvitation: data => createAction(types.getInvitation, data),
  getInvitationSuccess: data => createAction(types.getInvitationSuccess, data),
  getInvitationFailure: data => createAction(types.getInvitationFailure, data),

  getContactDirectory: data => createAction(types.getContactDirectory, data),
  getContactDirectorySuccess: data => createAction(types.getContactDirectorySuccess, data),
  getContactDirectoryFailure: data => createAction(types.getContactDirectoryFailure, data),

  regenerateInvitation: data => createAction(types.regenerateInvitation, data),
  regenerateInvitationSuccess: data => createAction(types.regenerateInvitationSuccess, data),
  regenerateInvitationFailure: data => createAction(types.regenerateInvitationFailure, data),

  getChallengeTemplates: data => createAction(types.getChallengeTemplates, data),
  getChallengeTemplatesSuccess: data => createAction(types.getChallengeTemplatesSuccess, data),
  getChallengeTemplatesFailure: data => createAction(types.getChallengeTemplatesFailure, data),

  getChallenges: data => createAction(types.getChallenges, data),
  getChallengesSuccess: data => createAction(types.getChallengesSuccess, data),
  getChallengesFailure: data => createAction(types.getChallengesFailure, data),

  storeChallenge: (data, config) => createAction(types.storeChallenge, data, config),
  storeChallengeSuccess: data => createAction(types.storeChallengeSuccess, data),
  storeChallengeFailure: data => createAction(types.storeChallengeFailure, data),

  updateChallenge: data => createAction(types.updateChallenge, data),
  updateChallengeSuccess: data => createAction(types.updateChallengeSuccess, data),
  updateChallengeFailure: data => createAction(types.updateChallengeFailure, data),

  exportChallenge: data => createAction(types.exportChallenge, data),
  exportChallengeSuccess: data => createAction(types.exportChallengeSuccess, data),
  exportChallengeFailure: data => createAction(types.exportChallengeFailure, data),

  endChallenge: data => createAction(types.endChallenge, data),
  endChallengeSuccess: data => createAction(types.endChallengeSuccess, data),
  endChallengeFailure: data => createAction(types.endChallengeFailure, data),

  getAnalyticsOverview: data => createAction(types.getAnalyticsOverview, data),
  getAnalyticsOverviewSuccess: data => createAction(types.getAnalyticsOverviewSuccess, data),
  getAnalyticsOverviewFailure: data => createAction(types.getAnalyticsOverviewFailure, data),

  getAnalyticsPosts: data => createAction(types.getAnalyticsPosts, data),
  getAnalyticsPostsSuccess: data => createAction(types.getAnalyticsPostsSuccess, data),
  getAnalyticsPostsFailure: data => createAction(types.getAnalyticsPostsFailure, data),

  getAnalyticsComments: data => createAction(types.getAnalyticsComments, data),
  getAnalyticsCommentsSuccess: data => createAction(types.getAnalyticsCommentsSuccess, data),
  getAnalyticsCommentsFailure: data => createAction(types.getAnalyticsCommentsFailure, data),

  getAnalyticsLikes: data => createAction(types.getAnalyticsLikes, data),
  getAnalyticsLikesSuccess: data => createAction(types.getAnalyticsLikesSuccess, data),
  getAnalyticsLikesFailure: data => createAction(types.getAnalyticsLikesFailure, data),

  getAnalyticsMembers: data => createAction(types.getAnalyticsMembers, data),
  getAnalyticsMembersSuccess: data => createAction(types.getAnalyticsMembersSuccess, data),
  getAnalyticsMembersFailure: data => createAction(types.getAnalyticsMembersFailure, data),

  getAnalyticsUsers: data => createAction(types.getAnalyticsUsers, data),
  getAnalyticsUsersSuccess: data => createAction(types.getAnalyticsUsersSuccess, data),
  getAnalyticsUsersFailure: data => createAction(types.getAnalyticsUsersFailure, data),

  getAnalyticsActiveness: data => createAction(types.getAnalyticsActiveness, data),
  getAnalyticsActivenessSuccess: data => createAction(types.getAnalyticsActivenessSuccess, data),
  getAnalyticsActivenessFailure: data => createAction(types.getAnalyticsActivenessFailure, data),

  getAnalyticsAdoption: data => createAction(types.getAnalyticsAdoption, data),
  getAnalyticsAdoptionSuccess: data => createAction(types.getAnalyticsAdoptionSuccess, data),
  getAnalyticsAdoptionFailure: data => createAction(types.getAnalyticsAdoptionFailure, data),

  getAnalyticsEngagement: data => createAction(types.getAnalyticsEngagement, data),
  getAnalyticsEngagementSuccess: data => createAction(types.getAnalyticsEngagementSuccess, data),
  getAnalyticsEngagementFailure: data => createAction(types.getAnalyticsEngagementFailure, data),

  getAnalyticsPulse: data => createAction(types.getAnalyticsPulse, data),
  getAnalyticsPulseSuccess: data => createAction(types.getAnalyticsPulseSuccess, data),
  getAnalyticsPulseFailure: data => createAction(types.getAnalyticsPulseFailure, data),

  getAnalyticsFunnel: data => createAction(types.getAnalyticsFunnel, data),
  getAnalyticsFunnelSuccess: data => createAction(types.getAnalyticsFunnelSuccess, data),
  getAnalyticsFunnelFailure: data => createAction(types.getAnalyticsFunnelFailure, data),

  getAnalyticsPulseExport: data => createAction(types.getAnalyticsPulseExport, data),
  getAnalyticsPulseExportSuccess: data => createAction(types.getAnalyticsPulseExportSuccess, data),
  getAnalyticsPulseExportFailure: data => createAction(types.getAnalyticsPulseExportFailure, data),

  getWorkspacePulseSettings: () => createAction(types.getWorkspacePulseSettings),
  getWorkspacePulseSettingsSuccess: data => createAction(types.getWorkspacePulseSettingsSuccess, data),
  getWorkspacePulseSettingsFailure: data => createAction(types.getWorkspacePulseSettingsFailure, data),

  updateWorkspacePulseSettings: data => createAction(types.updateWorkspacePulseSettings, data),
  updateWorkspacePulseSettingsSuccess: data => createAction(types.updateWorkspacePulseSettingsSuccess, data),
  updateWorkspacePulseSettingsFailure: data => createAction(types.updateWorkspacePulseSettingsFailure, data),

  sendPulseFeedback: (data, config) => createAction(types.sendPulseFeedback, data, config),
  sendPulseFeedbackSuccess: data => createAction(types.sendPulseFeedbackSuccess, data),
  sendPulseFeedbackFailure: data => createAction(types.sendPulseFeedbackFailure, data),

  workspaceSearch: data => createAction(types.workspaceSearch, data),
  workspaceSearchSuccess: data => createAction(types.workspaceSearchSuccess, data),
  workspaceSearchFailure: data => createAction(types.workspaceSearchFailure, data),

  workspaceSearchAutocomplete: data => createAction(types.workspaceSearchAutocomplete, data),
  workspaceSearchAutocompleteSuccess: data => createAction(types.workspaceSearchAutocompleteSuccess, data),
  workspaceSearchAutocompleteFailure: data => createAction(types.workspaceSearchAutocompleteFailure, data),

  getMemberRoles: () => createAction(types.getMemberRoles),
  getMemberRolesSuccess: data => createAction(types.getMemberRolesSuccess, data),
  getMemberRolesFailure: () => createAction(types.getMemberRolesFailure),
};

import combineReducers from 'combine-reducers-global-state';
import types from './types';

const isLoading = (state = false, { type }) => {
  switch (type) {
    case types.getBoosts:
      return true;
    case types.getBoostsSuccess:
    case types.getBoostsFailure:
      return false;
    default:
      return state;
  }
};

const activeIds = (state = [], { type, payload }) => {
  switch (type) {
    case types.getBoostsSuccess:
      return payload.data;
    default:
      return state;
  }
};

const boostReducer = combineReducers({
  isLoading,
  activeIds,
});

export default { boost: boostReducer };

import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import File from 'components/File';
import Grid from 'components/Grid';
import PostContext from './PostContext';

const Wrapper = styled('div')`
  margin: 1rem;
`;

const PostAttachments = ({ attachments, ...rest }) => {

  const { isCompact, isImportant } = useContext(PostContext);

  if (!attachments?.length) {
    return null;
  }

  return (
    <Wrapper>
      <Grid container spacing={1} {...rest}>
        {attachments.map(({ id, name, url }) => (
          <Grid item xs={12} key={id}>
            <File url={url} name={name} color="secondary" darker={isCompact && isImportant} />
          </Grid>
        ))}
      </Grid>
    </Wrapper>
  );
};

PostAttachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    url: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
  })),
};

export default PostAttachments;

import { isSameDay, isThisYear, isSameYear, getDateString } from 'services/date-fns';

export const getFormattedPeriod = (startAt, endAt, allDay = false) => {
  const start = new Date(startAt);
  const startObj = {
    year: isThisYear(start) ? undefined : 'numeric',
    month: 'long',
    day: 'numeric',
    hour: allDay ? undefined : 'numeric',
    minute: allDay ? undefined : 'numeric',
    timeZone: allDay ? 'UTC' : undefined, // For allDay events there's no timestamp portion of the date
  };

  if (!endAt) {
    return [getDateString(start, startObj)];
  }

  const end = new Date(endAt);

  if (allDay && isSameDay(start, end)) {
    return [getDateString(start, startObj)];
  }

  const endFallback = isSameDay(start, end) ? 'p' : 'PPPp';
  const endObj = {
    year: isSameYear(start, end) ? undefined : 'numeric',
    month: isSameDay(start, end) ? undefined : 'long',
    day: isSameDay(start, end) ? undefined : 'numeric',
    hour: allDay ? undefined : 'numeric',
    minute: allDay ? undefined : 'numeric',
    timeZone: allDay ? 'UTC' : undefined, // For allDay events there's no timestamp portion of the date
  };

  return [getDateString(start, startObj), getDateString(end, endObj, endFallback)];
};

export const getPeriodDisplay = (startAt, endAt, allDay = false) => {

  const [start, end] = getFormattedPeriod(startAt, endAt, allDay);

  return end ? `${start} - ${end}` : start;
};

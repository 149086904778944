import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import Twemoji from 'components/Twemoji';
import { isMobile } from 'helpers';

const Container = styled('div')`
  display: inline-flex;
  width: 100%;
  flex-direction: column;

  line-height: 1.3;
`;

const Title = styled('div')`
  font-size: 1.6rem;
  font-weight: 700;
  flex: 1 1 auto;
  color: #000;
`;

const Subtitle = styled('span')`
  font-size: ${isMobile ? '0.9rem' : '0.95rem'};
  margin-top: ${isMobile ? '0' : '0.3rem'};
  display: inline-block;
  font-weight: 600;
  color: #666;
`;

const Emoji = () => <span role="img" aria-label="emoji">&#x1F44B;</span>;

const Greeting = ({ greeting, subtitle, ...rest }) => {

  return (
    <Container {...rest}>
      <Title>
        {greeting}
      &nbsp;
        <Emoji />
      </Title>
      <Twemoji>
        <Subtitle>{subtitle}</Subtitle>
      </Twemoji>
    </Container>
  );
};

Greeting.propTypes = {
  greeting: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Greeting;

import { pollTypes } from 'redux/poll';
import update from 'immutability-helper';

const addVote = id => ({ [id]: { votesCount: { $apply: votes => votes !== null ? votes + 1 : null }, voted: { $set: true } } });
const removeVote = id => ({ [id]: { votesCount: { $apply: votes => votes !== null ? votes - 1 : null }, voted: { $set: false } } });

const optionsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case pollTypes.deleteVoteFailure:
    case pollTypes.storeVote:
      return update(state, addVote(payload.optionId));

    case pollTypes.storeVoteFailure:
    case pollTypes.deleteVote:
      return update(state, removeVote(payload.optionId));

    default:
      return state;
  }
};

export default optionsReducer;

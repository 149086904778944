import Paper from 'components/Paper/Paper';
import PaperTitle from 'components/Paper/PaperTitle';
import React, { useState } from 'react';
import List from '@mui/material/List';
import NavLink from 'components/NavLink';
import ListItemButton from '@mui/material/ListItemButton';
import { useSelector } from 'react-redux';
import { postSelectors } from 'redux/post';
import styled from '@emotion/styled/macro';
import PropTypes from 'propTypes';
import { useTranslation } from 'react-i18next';
import MiniPostPreview from '../MiniPostPreview';

const SeeMoreListItemButton = styled(ListItemButton)`
  justify-content: center;
  font-size: 0.9rem;
`;

const StyledPaperTitle = styled(PaperTitle)`
  h3 {
    font-size: 1rem;
  }
  min-height: initial;
  padding-left: 16px;
  padding-right: 16px;
`;

const TotalCounter = styled('div')`
  color: #888;
  font-size: 0.9rem;
`;


const ScheduledPosts = ({ postIds = [], loadMore, hasMore, total, cap = 2, ...rest }) => {

  const { t } = useTranslation('component');

  const getPostSelector = useSelector(postSelectors.getPostSelector);

  const [capped, setCapped] = useState(true);

  const visiblePostIds = capped
    ? postIds.slice(0, cap)
    : postIds;

  const combinedHasMore = (capped && postIds.length > cap) || hasMore;

  const onHasMoreClick = () => {
    if (capped) {
      setCapped(false);
    } else {
      loadMore();
    }
  };

  return (
    <Paper {...rest}>
      <StyledPaperTitle title={t('scheduledPosts.title')}>
        <TotalCounter>{!!total && <>{t('scheduledPosts.total')}: {total}</>}</TotalCounter>
      </StyledPaperTitle>

      <List disablePadding>
        {visiblePostIds
          .map(getPostSelector)
          .map((post, idx, arr) => (
            <ListItemButton divider={arr.length - 1 !== idx || combinedHasMore} key={post.id} component={NavLink} to={`/post/${post.id}`}>
              <MiniPostPreview postId={post.id} />
            </ListItemButton>
          ))}

        {combinedHasMore && <SeeMoreListItemButton onClick={onHasMoreClick}>{t('scheduledPosts.seeMore')}</SeeMoreListItemButton>}
      </List>
    </Paper>
  );
};

ScheduledPosts.propTypes = {
  postIds: PropTypes.array,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  total: PropTypes.number,
  cap: PropTypes.number,
};

export default ScheduledPosts;

import { integrationTypes } from 'redux/integration';
import update from 'immutability-helper';

const integrationRulesReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case integrationTypes.integrationRuleMassActionSuccess: {
      if (payload.request.action === 'delete') {
        return update(state, { $unset: payload.request.integrationRuleIds });
      }

      return state;
    }

    default:
      return state;
  }
};

export default integrationRulesReducer;

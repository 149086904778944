import folderTypes from '../documentFolder/types';

const documentFoldersReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case folderTypes.putFolderForAdminSuccess:
      return { ...state, [payload.id]: payload };

    case folderTypes.deleteFolderVariantForAdminSuccess:
      // eslint-disable-next-line eqeqeq
      return { ...state, [payload.folderId]: { ...state[payload.folderId], variants: state[payload.folderId].variants.filter((value) => value != payload.folderVariantId) } };

    default:
      return state;
  }
};

export default documentFoldersReducer;

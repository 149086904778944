import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getCoursesForMember: data => createAction(types.getCoursesForMember, data),
  getCoursesForMemberSuccess: data => createAction(types.getCoursesForMemberSuccess, data),
  getCoursesForMemberFailure: error => createAction(types.getCoursesForMemberFailure, error),

  getCourseForMember: data => createAction(types.getCourseForMember, data),
  getCourseForMemberSuccess: data => createAction(types.getCourseForMemberSuccess, data),
  getCourseForMemberFailure: error => createAction(types.getCourseForMemberFailure, error),

  getCoursesForAdmin: data => createAction(types.getCoursesForAdmin, data),
  getCoursesForAdminSuccess: data => createAction(types.getCoursesForAdminSuccess, data),
  getCoursesForAdminFailure: error => createAction(types.getCoursesForAdminFailure, error),

  getCourseForAdmin: data => createAction(types.getCourseForAdmin, data),
  getCourseForAdminSuccess: data => createAction(types.getCourseForAdminSuccess, data),
  getCourseForAdminFailure: error => createAction(types.getCourseForAdminFailure, error),

  postCourseForAdmin: data => createAction(types.postCourseForAdmin, data),
  postCourseForAdminSuccess: data => createAction(types.postCourseForAdminSuccess, data),
  postCourseForAdminFailure: error => createAction(types.postCourseForAdminFailure, error),

  putCourseForAdmin: data => createAction(types.putCourseForAdmin, data),
  putCourseForAdminSuccess: data => createAction(types.putCourseForAdminSuccess, data),
  putCourseForAdminFailure: error => createAction(types.putCourseForAdminFailure, error),

  deleteCourseVariantForAdmin: data => createAction(types.deleteCourseVariantForAdmin, data),
  deleteCourseVariantForAdminSuccess: data => createAction(types.deleteCourseVariantForAdminSuccess, data),
  deleteCourseVariantForAdminFailure: error => createAction(types.deleteCourseVariantForAdminFailure, error),

  duplicateCourseVariantForAdmin: data => createAction(types.duplicateCourseVariantForAdmin, data),
  duplicateCourseVariantForAdminSuccess: data => createAction(types.duplicateCourseVariantForAdminSuccess, data),
  duplicateCourseVariantForAdminFailure: error => createAction(types.duplicateCourseVariantForAdminFailure, error),

  archiveCourseVariantForAdmin: data => createAction(types.archiveCourseVariantForAdmin, data),
  archiveCourseVariantForAdminSuccess: data => createAction(types.archiveCourseVariantForAdminSuccess, data),
  archiveCourseVariantForAdminFailure: error => createAction(types.archiveCourseVariantForAdminFailure, error),

  courseMembersMassAction: data => createAction(types.courseMembersMassAction, data),
  courseMembersMassActionSuccess: data => createAction(types.courseMembersMassActionSuccess, data),
  courseMembersMassActionFailure: data => createAction(types.courseMembersMassActionFailure, data),

  getTrainingPlanByCourses: data => createAction(types.getTrainingPlanByCourses, data),
  getTrainingPlanByCoursesSuccess: data => createAction(types.getTrainingPlanByCoursesSuccess, data),
  getTrainingPlanByCoursesFailure: data => createAction(types.getTrainingPlanByCoursesFailure, data),

  getTrainingPlanByLabels: data => createAction(types.getTrainingPlanByLabels, data),
  getTrainingPlanByLabelsSuccess: data => createAction(types.getTrainingPlanByLabelsSuccess, data),
  getTrainingPlanByLabelsFailure: data => createAction(types.getTrainingPlanByLabelsFailure, data),
};

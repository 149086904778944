import { createSelector } from 'reselect';

const getActivityCategoryEntities = ({ entities: { activityCategories } }) => activityCategories;
const getActivityCategoryIds = ({ challenge: { activityCategoryIds } }) => activityCategoryIds;
const getActivityCategories = createSelector(
  [getActivityCategoryEntities, getActivityCategoryIds],
  (entities, ids) => ids.map(id => entities[id]),
);

const getActivityCategoryFieldTypes = ({ entities: { activityCategoryTypes } }) => activityCategoryTypes;

const getChallengeIds = ({ challenge: { challengeIds } }) => challengeIds;
const getChallengeEntities = ({ entities: { challenges } }) => challenges;
const getChallenges = createSelector(
  [getChallengeEntities, getChallengeIds],
  (entities, ids) => ids.map(id => entities[id]),
);

const getChallenge = ({ entities: { challenges } }, challengeId) => challenges[challengeId];

const fallbackCategories = [];

const getCategoriesForChallenge = (state, challengeId) => {
  const challenge = getChallenge(state, challengeId);

  const categoryIds = challenge && challenge.categories ? challenge.categories : fallbackCategories;
  const categories = getActivityCategoryEntities(state);

  return categoryIds.map(id => categories[id]);
};

export default {
  getActivityCategories,
  getActivityCategoryFieldTypes,
  getChallenge,
  getChallenges,
  getChallengeEntities,

  getCategoriesForChallenge,
};

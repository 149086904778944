import i18next from 'i18next';
import { authTypes } from 'redux/auth';
import { userTypes, userActions } from 'redux/user';

const changeApplicationLanguage = (locale, dispatch) => {
  if (i18next.language === locale) {
    return;
  }
  i18next.changeLanguage(locale);
  dispatch(userActions.changeLanguageSuccess(locale));
};

const i18nMiddleware = store => next => (action) => {
  const { type, payload } = action;

  switch (type) {
    case authTypes.loginSuccess:
      changeApplicationLanguage(payload.user.locale, store.dispatch);
      break;

    case userTypes.updateSettingsSuccess:
      changeApplicationLanguage(payload.locale, store.dispatch);
      break;

    default:
      break;
  }

  next(action);
};

export default i18nMiddleware;

import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from 'components/Icons/Notifications';
import PropTypes from 'prop-types';

const NotificationsIconButton = React.forwardRef(({ fontSize, ...rest }, ref) => {

  const notificationsCount = useSelector(authSelectors.getNotificationCount);
  const hasNotifications = notificationsCount > 0;

  return (
    <IconButton ref={ref} color={hasNotifications ? 'primary' : 'inherit'} {...rest}>
      <Badge badgeContent={notificationsCount} color="primary">
        <NotificationsIcon fontSize={fontSize} />
      </Badge>
    </IconButton>
  );
});

NotificationsIconButton.propTypes = {
  fontSize: PropTypes.oneOf(['default', 'inherit', 'large', 'small']),
};

export default NotificationsIconButton;

import { useCallback, useEffect, useMemo } from 'react';
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import useSideQueryParam from '../useSideQueryParam';

const LegacyMemberParam = withDefault(NumberParam);
const LegacyMemberParamOptions = { updateType: 'replaceIn', enableBatching: true, removeDefaultsFromUrl: true };

const ItemTypeParam = withDefault(StringParam);
const itemTypeParamOptions = { updateType: 'replaceIn', enableBatching: true, removeDefaultsFromUrl: true };

const ItemIdParam = withDefault(NumberParam);
const itemIdParamOptions = { updateType: 'replaceIn', enableBatching: true, removeDefaultsFromUrl: true };

const useContactDirectoryQueryParams = () => {

  const [side, setSide] = useSideQueryParam();

  const [itemType, setItemType] = useQueryParam('cd_type', ItemTypeParam, itemTypeParamOptions);
  const [itemId, setItemId] = useQueryParam('cd_id', ItemIdParam, itemIdParamOptions);

  const setQuery = useCallback(($side, $type, $id) => {
    setSide($side);
    setItemType($type);
    setItemId($id);
  }, [setItemId, setItemType, setSide]);

  // Legacy url support
  const [memberId, setMemberId] = useQueryParam('memberId', LegacyMemberParam, LegacyMemberParamOptions);
  useEffect(() => {
    if ((side === 'directory' || side === 'contacts') && memberId) {
      setQuery(side, 'member', memberId);
      setMemberId(undefined);
    }
  }, [memberId, setMemberId, setQuery, side]);

  const onSideOpenClick = useCallback(() => {
    setQuery('directory');
  }, [setQuery]);

  const onItemCloseClick = useCallback(() => {
    setQuery(side, undefined, undefined);
  }, [setQuery, side]);

  const onItemOpenClick = useCallback((nextType, nextId) => {
    setQuery(side, nextType, nextId);
  }, [setQuery, side]);

  const onSideCloseClick = useCallback(() => {
    setQuery();
  }, [setQuery]);

  const onSideToggleClick = useCallback(() => {
    if (side) {
      onSideCloseClick();
    } else {
      onSideOpenClick();
    }
  }, [onSideCloseClick, onSideOpenClick, side]);

  const onManageContactsToggleClick = useCallback(() => {
    if (side === 'contacts') {
      const nextItemType = ['contact', 'member'].includes(itemType) ? itemType : null;
      const nextItemId = nextItemType ? itemId : null;
      setQuery('directory', nextItemType, nextItemId);
    } else {
      const nextItemType = ['contact', 'contact-new'].includes(itemType) ? itemType : null;
      const nextItemId = nextItemType ? itemId : null;
      setQuery('contacts', nextItemType, nextItemId);
    }
  }, [itemId, itemType, setQuery, side]);

  const onItemToggleClick = useCallback((nextType, nextId) => {
    const isAlreadyOpen = itemType === nextType && itemId === nextId;

    setItemType(isAlreadyOpen ? undefined : nextType);
    setItemId(isAlreadyOpen ? undefined : nextId);
  }, [itemId, itemType, setItemId, setItemType]);

  const onAddContactClick = useCallback(() => {
    setQuery('contacts', 'contact-new', undefined);
  }, [setQuery]);

  const isViewMode = useMemo(() => ['contact', 'member'].includes(itemType) && !!itemId, [itemId, itemType]);
  const isContactCreateMode = useMemo(() => ['contact-new'].includes(itemType), [itemType]);

  const isContactManageMode = useMemo(() => side === 'contacts', [side]);

  return useMemo(() => ({
    itemType,
    itemId,

    onItemToggleClick,

    onItemOpenClick,
    onItemCloseClick,
    onSideOpenClick,
    onSideCloseClick,
    onSideToggleClick,

    onManageContactsToggleClick,
    onAddContactClick,

    isViewMode,
    isContactCreateMode,
    isContactManageMode,
  }), [itemType, itemId, onItemToggleClick, onItemOpenClick, onItemCloseClick, onSideOpenClick, onSideCloseClick, onSideToggleClick, onManageContactsToggleClick, onAddContactClick, isViewMode, isContactCreateMode, isContactManageMode]);
};



export default useContactDirectoryQueryParams;

import { useTranslation } from 'react-i18next';
import { authSelectors } from 'redux/auth';
import { useSelector } from 'react-redux';
import useFeature from 'hooks/useFeature';
import MessageFilledIcon from 'components/Icons/MessageFilled';
import HomeIcon from 'components/Icons/Home';
import TrophyFilledIcon from 'components/Icons/TrophyFilled';
import DocumentIcon from 'components/Icons/Document';
import SunIcon from 'components/Icons/Sun';
import UsersFilledIcon from 'components/Icons/UsersFilled';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import SitemapIcon from 'components/Icons/Sitemap';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import GradeRoundedIcon from '@mui/icons-material/GradeRounded';
import { useLocation, matchPath } from 'react-router-dom';

const useNavigationItems = () => {

  const { t } = useTranslation(['common', 'component']);

  const workspace = useSelector(authSelectors.getWorkspace);

  const pagesFeature = useFeature('pages');

  const location = useLocation();

  const isActive = (...paths) => () => !!paths.find((path) => matchPath(location.pathname, path));

  const hasPermission = useSelector(authSelectors.hasPermissionSelector);
  const hasAnyPermission = useSelector(authSelectors.hasAnyPermissionSelector);
  const hasFeature = useSelector(authSelectors.hasFeatureSelector);
  const hasAnyFeature = useSelector(authSelectors.hasAnyFeatureSelector);

  const menuAboutUsesWorkspaceName = useFeature('menuAboutUsesWorkspaceName');
  const aboutLabel = menuAboutUsesWorkspaceName.enabled ? workspace.name : t('component:sidebar.companyProfile');

  const sidebarItems = [
    {
      member: { to: '/', children: t('pages.home'), exact: true },
      hub: { icon: HomeIcon, priority: 1 },
    },

    {
      visible: hasFeature('newsfeed'),
      member: { to: '/messages', children: t('pages.messages') },
      hub: { icon: MessageFilledIcon, priority: 2 },
    },

    {
      visible: hasFeature('analytics'),
      admin: { to: '/manage/analytics', children: t('component:sidebar.manageAnalytics'), visible: hasPermission('ADMIN_ANALYTICS') },
    },

    {
      admin: { to: '/manage/members', children: t('component:sidebar.manageMemebers'), visible: hasPermission('ADMIN_MEMBERS') },
    },

    {
      visible: hasFeature('groups'),
      admin: { to: '/manage/groups', children: t('component:sidebar.manageGroups'), visible: hasPermission('MANAGE_GROUPS') },
      member: { to: '/groups', children: t('pages.groups'), visible: hasPermission('VIEW_GROUPS') },
      hub: { icon: UsersFilledIcon, priority: 3 },
    },
    {
      visible: hasFeature('challenges'),
      admin: { to: '/manage/challenges', children: t('component:sidebar.manageChallenges'), visible: hasPermission('MANAGE_CHALLENGES') },
      member: { to: '/challenges', children: t('pages.challenges'), visible: hasPermission('VIEW_CHALLENGES') },
      hub: { icon: TrophyFilledIcon, priority: 4 },
    },
    {
      visible: hasFeature('calendar'),
      admin: { to: '/manage/calendar', children: t('component:sidebar.manageCalendar'), visible: hasPermission('MANAGE_CALENDAR') },
      member: { to: '/calendar', children: t('pages.calendar'), visible: hasPermission('VIEW_CALENDAR'), isActive: isActive('/calendar', '/event') },
      hub: { icon: TodayRoundedIcon, priority: 8 },
    },
    {
      visible: hasFeature('feedback'),
      admin: { to: '/manage/feedback', children: t('component:sidebar.manageFeedback'), visible: hasPermission('MANAGE_FEEDBACKS'), isActive: isActive('/manage/feedback', '/manage/feedback-types', '/manage/feedback-pulse') },
      member: { to: '/feedback', children: t('pages.feedback'), visible: hasPermission('VIEW_FEEDBACKS') },
      hub: { icon: RecordVoiceOverRoundedIcon, priority: 9 },
    },
    {
      visible: hasFeature('drive'),
      admin: { to: '/manage/drive', children: t('component:sidebar.manageDrive'), visible: hasPermission('MANAGE_DRIVE') },
      member: { to: '/drive', children: t('component:sidebar.drive'), visible: hasPermission('VIEW_DRIVE') },
      hub: { icon: BusinessCenterRoundedIcon, priority: 5 },
    },
    {
      visible: hasFeature('training'),
      admin: { to: '/manage/training', children: t('component:sidebar.manageTraining'), visible: hasPermission('MANAGE_TRAINING') },
      member: { to: '/training', children: t('component:sidebar.training'), visible: hasPermission('VIEW_TRAINING'), isActive: isActive('/training', '/course') },
      hub: { icon: SchoolRoundedIcon, priority: 6 },
    },
    {
      visible: hasFeature('learning'),
      admin: { to: '/manage/e-learning', children: t('component:sidebar.learning'), visible: hasPermission('MANAGE_LEARNING') },
      member: { to: '/e-learning/courses', children: t('component:sidebar.learning'), visible: hasPermission('VIEW_LEARNING'), isActive: isActive('/e-learning') },
      hub: { icon: SchoolRoundedIcon, priority: 6 },
    },
    {
      visible: hasFeature('document'),
      admin: { to: '/manage/documents', children: t('component:sidebar.documents'), visible: hasPermission('MANAGE_DOCUMENTS') },
      member: { to: '/documents', children: t('component:sidebar.documents'), visible: hasPermission('VIEW_DOCUMENTS') },
      hub: { icon: DocumentIcon, priority: 7 },
    },

    {
      visible: hasFeature('pages'),
      admin: { to: '/manage/pages', children: t('component:sidebar.managePages'), visible: hasPermission('MANAGE_PAGES') },
    },

    ...(pagesFeature?.pinned || []).map(page => ({
      visible: hasFeature('pages'),
      member: { to: `/page/${page.id}`, children: page.name },
      hub: { icon: GradeRoundedIcon, priority: 100 },
    })),

    {
      visible: hasFeature('about'),
      member: { to: '/profile', breakWord: menuAboutUsesWorkspaceName, children: aboutLabel, visible: hasPermission('VIEW_PROFILE') },
      hub: { icon: BusinessRoundedIcon, priority: 10 },
    },
    {
      admin: { to: '/manage/practices', children: t('component:sidebar.bestPractices'), visible: hasFeature('practices') && hasPermission('ADMIN_PRACTICES') },
      member: { to: '/guidance', children: t('pages.guidance'), visible: hasFeature('guidance') && hasPermission('VIEW_GUIDANCE') },
      hub: { icon: SunIcon, priority: 12 },
    },

    {
      admin: {
        to: '/manage/settings',
        children: t('component:sidebar.manageSettings'),
        visible:
          hasAnyPermission('MANAGE_PROFILE', 'ADMIN_SETTINGS', 'ADMIN_TERMS')
          || (hasPermission('ADMIN_INTEGRATIONS') && hasAnyFeature('coopIntegration', 'netvisorIntegration', 'personaIntegration')),
      },
    },

    {
      visible: hasFeature('subportalsPrototype'),
      member: { to: '/subportals', children: t('common:pages.subportals') },
      hub: { icon: LibraryBooksRoundedIcon, priority: 13 },
    },
    {
      visible: hasFeature('files'),
      member: { to: '/files', children: t('component:sidebar.files') },
      hub: { icon: DocumentIcon, priority: 7 },
    },

    {
      visible: hasFeature('peopleDirectory'),
      hub: { to: '/directory', icon: SitemapIcon, children: t('common:pages.contactDirectory'), priority: 11 },
    },
  ];

  const memberItems = sidebarItems
    .filter(({ member }) => !!member)
    .filter(({ member, visible }) => visible !== false && member.visible !== false)
    .map(({ member: { visible, ...rest } }) => rest);

  const adminItems = sidebarItems
    .filter(({ admin }) => !!admin)
    .filter(({ admin, visible }) => visible !== false && admin.visible !== false)
    .map(({ admin: { visible, ...rest } }) => rest);

  const hubItems = sidebarItems
    .filter(({ hub }) => !!hub) // Only keep hub enabled elements
    .map((data) => ({ ...data, hub: { ...data?.member, ...data.hub } })) // merge member info with hub overrides
    .filter(({ hub, visible }) => visible !== false && hub.visible !== false) // Check for visibility
    .sort((a, b) => a?.hub?.priority - b?.hub?.priority) // Order by priority first
    .map(({ hub: { visible, priority, ...rest } }) => rest); // Remove internal config fields (visible & priority)

  return { sidebarItems, memberItems, adminItems, hubItems };
};

export default useNavigationItems;

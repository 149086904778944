import combineReducers from 'combine-reducers-global-state';
import { addToPagination, createPagination, resetPagination } from '../helpers/pagination';
import types from './types';

const jobAnniversaryEventsOverview = (state = { today: [], upcoming: [] }, {
  type,
  payload,
}) => {
  switch (type) {
    case types.getOverviewSuccess:
      return { ...state, today: payload.today, upcoming: payload.upcoming };
    case types.getOverviewFailure:
      return { ...state, today: [], upcoming: [] };
    default:
      return state;
  }
};

const jobAnniversaryEvents = (state = createPagination(null, 'members'), { type, payload }) => {
  switch (type) {
    case types.getJobAnniversariesSuccess:
      return addToPagination(state, null, payload, 'members');
    default:
      return state;
  }
};

const jobAnniversaryEventOpened = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getJobAnniversaryEventSuccess:
      return { ...state, ...payload };
    case types.getJobAnniversaryEventFailure:
    case types.getJobAnniversaryEvent:
      return {};
    default:
      return state;
  }
};

const commentsByJobAnniversaryEventId = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCommentsSuccess:
      return addToPagination(state, payload.eventId, payload, 'commentIds');
    case types.addCommentSuccess:
      return resetPagination(state, payload.eventId, 'commentIds');
    default:
      return state;
  }
};

const jobAnniversaryReducers = combineReducers({
  jobAnniversaryEvents,
  jobAnniversaryEventsOverview,
  jobAnniversaryEventOpened,
  commentsByJobAnniversaryEventId,
});

export default { jobAnniversary: jobAnniversaryReducers };

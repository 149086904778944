import { useRef, useEffect } from 'react';
import { promiseListener } from 'redux/store';

const usePromiseSubmit = ({ start, resolve, reject, onSuccess, onFailure, onSubmit, beforeSubmit }) => {

  const unsubscribeFn = useRef();

  useEffect(() => {
    return () => unsubscribeFn.current ? unsubscribeFn.current() : ({});
  }, []);

  if (!start || !resolve || !reject) {
    // Simple submit
    return (data, form) => {
      const payload = beforeSubmit ? beforeSubmit(data, form) : data;
      if (onSuccess) onSuccess(payload, form); // Not actually checking if successful...
      return onSubmit(payload, form);
    };
  }

  // Promise submit
  return (data, form) => {

    const { asyncFunction, unsubscribe } = promiseListener.createAsyncFunction({ start, resolve, reject });

    unsubscribeFn.current = unsubscribe;

    const payload = beforeSubmit ? beforeSubmit(data, form) : data;

    const promise = payload instanceof Promise
      ? payload.then(resolvedPayload => asyncFunction(resolvedPayload))
      : asyncFunction(payload);

    return promise.then(
      response => {
        if (onSuccess) { onSuccess(response, form); }
      },
      err => {
        if (onFailure) { onFailure(err, form); }
        return err;
      },
    );
  };
};

export default usePromiseSubmit;

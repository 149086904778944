import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {

  getQuiz: data => createAction(types.getQuiz, data),
  getQuizSuccess: data => createAction(types.getQuizSuccess, data),
  getQuizFailure: data => createAction(types.getQuizFailure, data),

  storeAnswer: ({ optionId, quizId, ...data }) => createAction(types.storeAnswer, { optionId, quizId, ...data }),
  storeAnswerSuccess: data => createAction(types.storeAnswerSuccess, data),
  storeAnswerFailure: ({ optionId, quizId, ...data }) => createAction(types.storeAnswerFailure, { optionId, quizId, ...data }),

  downloadAnswers: data => createAction(types.downloadAnswers, data),
  downloadAnswersSuccess: data => createAction(types.downloadAnswersSuccess, data),
  downloadAnswersFailure: data => createAction(types.downloadAnswersFailure, data),

};

import React, { useRef, useState } from 'react';
import PollViewContext from './PollViewContext';

const PollProvider = (args) => {

  const save = useRef();

  const [closeAutomatically, setCloseAutomatically] = useState(undefined);

  return (
    <PollViewContext.Provider value={{ save, closeAutomatically, setCloseAutomatically }} {...args} />
  );
};

export default PollProvider;

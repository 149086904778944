import React, { useEffect } from 'react';
import Dialog from 'components/Dialog/Dialog';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import DialogActions from 'components/Dialog/DialogActions';
import DialogTitle from 'components/Dialog/DialogTitle';
import DialogContent from 'components/Dialog/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Avatar from 'components/Avatar';
import styled from '@emotion/styled/macro';
import NavLink from 'components/NavLink';
import { postActions, postSelectors } from 'redux/post';
import { commentActions, commentSelectors } from 'redux/comment';
import reactionService from 'services/reactionService';
import TextLoader from './TextLoader';

const StyledTable = styled(Table)`
  margin-top: 1rem;
  white-space: nowrap;

  td {
    border-bottom: none;
  }
`;

const AvatarLink = styled(NavLink)`
  text-decoration: none;

  &:hover, &:active, &:visited  {
    text-decoration: none;
  }
`;

const NameLink = styled(NavLink)`
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const JobTitle = styled('span')`
  color: #666;
  font-size: 0.9rem;
  margin-left: 0.5rem;
`;

const renderTableRow = reaction => {
  if (!reaction) {
    return null;
  }

  return (
    <TableRow key={reaction.id}>
      <TableCell padding="checkbox">
        <AvatarLink to={`/member/${reaction.member.id}`}>
          <Avatar
            size={32}
            src={reaction.member.avatar?.src}
            srcSet={reaction.member.avatar?.srcSet}
            firstName={reaction.member.firstName}
            lastName={reaction.member.lastName}
          />
        </AvatarLink>
      </TableCell>
      <TableCell>
        <NameLink to={`/member/${reaction.member.id}`}>{reaction.member.firstName} {reaction.member.lastName}</NameLink>
        {reaction.member.job && <JobTitle>({reaction.member.job})</JobTitle>}
      </TableCell>
      <TableCell align="right">
        {reactionService.getReaction(reaction.type)?.icon}
      </TableCell>
    </TableRow>
  );
};

const ReactionDialog = ({
  reactionableType,
  reactionableId,
  open,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(['component']);

  const dispatch = useDispatch();

  const reactions = useSelector((state) => {
    if (reactionableType === 'post' && open) {
      return postSelectors.getLikesByPostId(state, reactionableId);
    }

    if (reactionableType === 'comment' && open) {
      return commentSelectors.getLikesByCommentId(state, reactionableId);
    }
  });

  useEffect(() => {
    if (reactionableType === 'post' && open) {
      dispatch(postActions.getLikes({ postId: reactionableId }));
    }

    if (reactionableType === 'comment' && open) {
      dispatch(commentActions.getLikes({ commentId: reactionableId }));
    }
  }, [dispatch, open, reactionableId, reactionableType]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth {...rest}>
      <DialogTitle>{t('reactionDialog.title')}</DialogTitle>
      <DialogContent>
        <TableContainer>
          {(!reactions || reactions.length === 0) && <TextLoader />}
          {reactions && reactions.length > 0 && (
            <StyledTable>
              <TableBody>
                {reactions.map(reaction => renderTableRow(reaction))}
              </TableBody>
            </StyledTable>
          )}
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>{t('reactionDialog.closeButton')}</Button>
      </DialogActions>
    </Dialog>
  );
};

ReactionDialog.propTypes = {
  reactionableType: PropTypes.any.isRequired,
  reactionableId: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReactionDialog;

import React from 'react';
import AtIcon from 'components/Icons/At';
import PropTypes from 'prop-types';

const PostTargets = ({ targets }) => {

  if (!targets || targets.length === 0) {
    return null;
  }

  return (
    <>
      <AtIcon />
      <p>
        {targets.map(({ id, name }, idx) => (
          <React.Fragment key={id}>
            {idx !== 0 && <>&nbsp;&#65372;&nbsp;</>}
            {name}
          </React.Fragment>
        ))}
      </p>
    </>
  );
};


PostTargets.propTypes = {
  targets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
  })),
};

export default PostTargets;

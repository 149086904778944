import React from 'react';
import IconText from 'components/IconText';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';

const Text = styled('span')``;

const Wrapper = styled(IconText)`
  color: #F43756;
  font-weight: 600;
  font-size: 0.9rem;

  @media (max-width: 320px) { ${Text} { display: none; } }
`;

const PostHeaderImportant = args => {
  const { t } = useTranslation(['common']);

  return (
    <Wrapper startIcon={<ErrorIcon fontSize="small" />} {...args}>
      <Text>{t('post.important')}</Text>
    </Wrapper>
  );
};

export default PostHeaderImportant;

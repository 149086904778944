export default {
  getArticles: 'guidance/GET_ARTICLES',
  getArticlesSuccess: 'guidance/GET_ARTICLES_SUCCESS',
  getArticlesFailure: 'guidance/GET_ARTICLES_FAILURE',

  getArticleCategories: 'guidance/GET_ARTICLE_CATEGORIES',
  getArticleCategoriesSuccess: 'guidance/GET_ARTICLE_CATEGORIES_SUCCESS',
  getArticleCategoriesFailure: 'guidance/GET_ARTICLE_CATEGORIES_FAILURE',

  getArticle: 'guidance/GET_ARTICLE',
  getArticleSuccess: 'guidance/GET_ARTICLE_SUCCESS',
  getArticleFailure: 'guidance/GET_ARTICLE_FAILURE',

  addLike: 'guidance/ADD_LIKE',
  addLikeSuccess: 'guidance/ADD_LIKE_SUCCESS',
  addLikeFailure: 'guidance/ADD_LIKE_FAILURE',

  removeLike: 'guidance/REMOVE_LIKE',
  removeLikeSuccess: 'guidance/REMOVE_LIKE_SUCCESS',
  removeLikeFailure: 'guidance/REMOVE_LIKE_FAILURE',
};

import React from 'react';
import ImageText from 'components/ImageText';
import PropTypes from 'prop-types';
import chair from './chair.svg';

const EmptyState = args => <ImageText src={chair} {...args} />;

EmptyState.propTypes = {
  ...ImageText.propTypes,
  src: PropTypes.any,
};

export default EmptyState;

import React, { useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { animated } from '@react-spring/web';
import useZoom from 'hooks/useZoom';
import { isMobile } from 'helpers';
import ProgressiveImage from 'components/ProgressiveImage';

const Wrapper = styled('div')`
  position: relative;
  background: #252525;
  display: block;
  width: 100%;

  // Following only apply when parent has height. Needed for galleries
  height: 100%;
  max-height: 100%;

  /* Overflow hidden, because of the max-width 1px hack */
  overflow: hidden;

  ${({ onClick }) => onClick && 'cursor: pointer'};

  /* Webkit animation is leaving junk pixels behind on the screen */
  /* -webkit-backface-visibility: hidden; didn't seem to work */
  /* https://stackoverflow.com/questions/9983520/webkit-animation-is-leaving-junk-pixels-behind-on-the-screen */
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);

  @supports(filter: blur(20px)) {
    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      filter: blur(20px);
      background-image: url(${({ backgroundSrc }) => backgroundSrc});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      transform: scale(1.1); /* For better blur edges */
    }
  }
`;

const StyledImg = styled('img')`
  position: relative;
  display: block;
  object-fit: contain;
  max-height: ${isMobile ? '1000px' : '1500px'};
  margin: 0 auto;

  /*
   * max-width +1px to fix a rounding issue.
   * Images can't be rendered as half-pixels.
   * Our flexbox wrapper however can be like 771.31px wide.
   * Because of this some screens are left with a black 1px edge on the image.
   */
  max-width: calc(100% + 1px);
  width: calc(100% + 1px);
  height: 100%;

  user-drag: none;
`;

const AnimatedWrapper = animated(Wrapper);

const ZoomableWrapper = (({ onZoom, zoomProps, ...rest }) => {

  const domTarget = useRef();

  const { spring: [style] } = useZoom({
    ...zoomProps,
    ...(onZoom && { onChange: onZoom }),
    domTarget,
    enabled: true,
  });

  return (
    <AnimatedWrapper ref={domTarget} style={style} {...rest} />
  );
});

const BasicWrapper = ({ onZoom, ...rest }) => <Wrapper {...rest} />;

const PlatformWrapper = ({ zoomable, zoomProps, ...rest }) => zoomable
  ? <ZoomableWrapper {...rest} {...zoomProps} />
  : <BasicWrapper {...rest} />;

const wrapperProps = {
  onZoom: PropTypes.func,
  zoomable: PropTypes.bool,
  zoomProps: PropTypes.object,
};

PlatformWrapper.propTypes = wrapperProps;
BasicWrapper.propTypes = wrapperProps;
ZoomableWrapper.propTypes = wrapperProps;

const AttachmentImage = ({ sizes, src, srcSet, zoomable = isMobile, zoomProps, ...rest }) => {

  const imageRef = useRef({});
  const [bgSrc, setBgSrc] = useState();

  if (!src && !srcSet) {
    return null;
  }

  return (
    <PlatformWrapper backgroundSrc={bgSrc} zoomable={zoomable} zoomProps={zoomProps} {...rest}>
      <ProgressiveImage src={src} srcSet={srcSet} sizes={sizes}>
        {({ loading, ...image }) => (
          <StyledImg
            ref={imageRef}
            onLoad={() => setBgSrc(imageRef.current?.currentSrc)}
            {...image}
          />
        )}
      </ProgressiveImage>
    </PlatformWrapper>
  );
};

AttachmentImage.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  zoomable: PropTypes.bool,
  zoomProps: PropTypes.object,
};

export default AttachmentImage;

import { all, fork, takeEvery, select, put, takeLeading, call, delay, take } from 'redux-saga/effects';
import AuthService from 'services/authService';
import config from 'config';
import { decamelizeKeys } from 'humps';
import { authTypes } from 'redux/auth';
import { eventChannel } from 'redux-saga';
import types from './types';
import actions from './actions';
import selectors from './selectors';

function* onSendAnalyticsData() {

  // Throttle 200ms
  yield delay(200);

  // Prepare
  yield put(actions.sendAnalyticsData());

  const events = yield select(selectors.getPending);
  const token = yield call(AuthService.getToken);

  yield call(sendData, events, token);

  // Remove sent
  yield put(actions.sendAnalyticsDataSuccess());
}

const unloadChannel = eventChannel(emitter => {
  window.addEventListener('unload', emitter, { passive: true });

  return () => {
    window.removeEventListener('unload', emitter);
  };
});

function* unloadListener() {
  while (true) {
    yield take(unloadChannel);

    const events = yield select(selectors.getEvents);
    const token = yield call(AuthService.getTokenQuick);

    yield call(sendData, events, token, true);
  }
}

export default function* analyticsSagas() {
  yield all([
    takeLeading(types.trackEvent, onSendAnalyticsData),
    takeEvery(authTypes.logout, onSendAnalyticsData),

    fork(unloadListener),
  ]);
}

const sendData = (events, token, isUnloading = false) => {
  if (events.length === 0) {
    return;
  }

  const url = `${config.apiUrl}/user/action`;
  const data = decamelizeKeys(events);

  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  formData.append('token', token);

  // Make sure not to trigger preflight with options, as it will fail with keepalive:
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
  const sendWithFetch = () => fetch(url, {
    method: 'post',
    body: formData,
    ...(isUnloading && { keepalive: true }),
  });

  const sendWithBeacon = () => navigator.sendBeacon(url, formData);

  const sendWithXMLHttpRequest = () => {
    const x = (new XMLHttpRequest());

    // When unloading then use synchronous request for a better chance it to be sent.
    const [async, sync] = [true, false];
    const reqSync = isUnloading ? sync : async;

    x.open('POST', url, reqSync);
    x.setRequestHeader('Content-Type', 'application/json');
    x.setRequestHeader('Accept', 'application/json');

    if (token) { x.setRequestHeader('Authorization', `Bearer ${token}`); }

    x.send(JSON.stringify({ data }));
  };

  // Prefer sendBeacon as this doesn't keep waiting for the respose
  // SendBeacon should return false if it was unable to queue. (e.g. limit hit)
  if ('sendBeacon' in navigator && sendWithBeacon()) {
    return;
  }

  // Fetch can fail if too many and too big requests already in-flight
  if (fetch) {
    return sendWithFetch().catch(e => console.error(e) || sendWithXMLHttpRequest());
  }

  // Primitive
  return sendWithXMLHttpRequest();
};

import React from 'react';
import config from 'config';
// import bugsnag from '@bugsnag/js';
// import bugsnagReact from '@bugsnag/plugin-react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import store from 'redux/store';
import { authSelectors } from 'redux/auth';
import { isMobile } from 'helpers';

// There are some errors that happen before user
// id is present. One example being ChunkLoadError
// It's actually not important what the identifier
// is as long we get a sense how many different users
// are getting the errors. This random id based
// on first error time should be sufficient.
const getOrSetRandomKey = () => {
  let key = localStorage.getItem('bsrid');

  if (!key) {
    key = btoa(new Date().getTime()).replace('==', '');
    localStorage.setItem('bsrid', key);
  }

  return key;
};

const errorLogger = {
  debug: () => {},
  info: () => {},
  warn: () => {},
  error: console.error, // eslint-disable-line no-console
};

const options = {
  apiKey: config.bugsnag.key,
  plugins: [new BugsnagPluginReact(React)],
  appVersion: config.version || undefined,
  releaseStage: config.env,
  enabledReleaseStages: ['production', 'staging'],
  logger: config.bugsnag.logger ? console : errorLogger,
  collectUserIp: false,
  metadata: {
    isMobile,
    standalone: navigator?.standalone || matchMedia('(display-mode: standalone)').matches,
  },
  onError: (event) => new Promise((resolve) => {

    // CoreExceptions from the backend are generally
    // expected/handled and no need to report to Bugsnag.
    if (event?.originalError?.coreException) {
      return false;
    }

    // https://github.com/mui/base-ui/issues/167
    if (event?.originalError === 'ResizeObserver loop completed with undelivered notifications.') {
      return false;
    }

    const { id } = authSelectors.getMeUser(store.getState()) || {};

    event.setUser(id || getOrSetRandomKey());

    resolve();
  }),
};

if (config.bugsnag.key) {
  Bugsnag.start(options);
}

const ErrorBoundary = config.bugsnag.key
  ? Bugsnag.getPlugin('react').createErrorBoundary('react')
  : React.Fragment;

export default ErrorBoundary;

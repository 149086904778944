
// ~ https://stackoverflow.com/a/14919494/1016777
export const humanFileSize = (_bytes, t) => {
  let bytes = _bytes;
  const thresh = 1000;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  let units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (t) {
    units = units.map(value => t(`common:byteMeasurements.${value.toLowerCase()}`, value));
  }

  const dp = bytes <= 1000 * 1000 ? 0 : 1;
  const r = 10 ** dp;

  let u = -1;
  do { bytes /= thresh; ++u; }
  while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const extractUrlExtension = (url) => {
  const ext = url
    ? url.match(/\.([0-9a-z]+)(?:[?#]|$)/)
    : null;

  return ext ? ext[0] : null;
};

export default {
  humanFileSize,
  extractUrlExtension,
};

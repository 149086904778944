import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import Avatar from 'components/Avatar';
import ProgressiveBackgroundImage from 'components/ProgressiveBackgroundImage';
import { useTheme } from '@emotion/react';

const Wrapper = styled('div')`
  background: black;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${({ coverSrc }) => coverSrc && `background-image: url(${coverSrc});`}

  background-size: cover, cover;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-image: ${({ coverSrc }) => coverSrc
    ? `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 71%, rgba(0,0,0,0.53) 100%), url(${coverSrc})`
    : 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 71%, rgba(0,0,0,0.53) 100%)'};

  height: 250px;
  margin-bottom: calc(50px + 1rem);
`;

const StyledAvatar = styled(Avatar)`
  position: relative;
  transform: translateY(50%);
  margin: 0 auto;
  border: 10px solid #fff;
`;

const MemberCover = ({ cover, member }) => {
  const theme = useTheme();
  const avatar = member?.avatar;

  return (
    <ProgressiveBackgroundImage src={cover?.src} srcSet={cover?.srcSet} sizes={theme.feedImageSrcSetSizes}>
      {({ src }) => (
        <Wrapper coverSrc={src}>
          <StyledAvatar size={100} src={avatar?.src} srcSet={avatar?.srcSet} firstName={member?.firstName} lastName={member?.lastName} />
        </Wrapper>
      )}
    </ProgressiveBackgroundImage>
  );
};

MemberCover.propTypes = {
  cover: PropTypes.object,
  member: PropTypes.object,
};

export default MemberCover;

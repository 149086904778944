import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import history from 'providers/history';
import config from 'config';
import { isDev } from 'helpers';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';

const cleanUrl = () => {
  if (typeof URL !== 'function' || !history.replace) {
    return;
  }

  const url = new URL(window.location.href);

  const utmParams = Array.from(url.searchParams.keys())
    .filter(key => key.indexOf('utm_') === 0);

  if (utmParams.length > 0) {
    utmParams.forEach(key => url.searchParams.delete(key));

    history.replace({
      pathname: url.pathname,
      search: url.search,
      hash: url.hash,
    });
  }
};

const initializeGa = () => {
  if (!config.ga.enabled) return;

  ReactGA.initialize(config.ga.trackingId, {
    testMode: config.ga.testMode,
    debug: config.ga.debug,
  });

  if (config.ga.testMode && isDev) {
    window.ReactGA = ReactGA;
  }
};

const sendPageview = ({ page, role, isConfirmed }) => {
  ReactGA.set({
    page, // Is 'page' needed? Why? Some tutorials have it...
    ...(role && { dimension3: role }), // Currently sent with every request. Maybe there's a way to optimise it?
    ...(isConfirmed && { dimension4: isConfirmed }), // Currently sent with every request. Maybe there's a way to optimise it?
  });
  ReactGA.send('pageview');
  ReactGA.ga(cleanUrl);
};

const useTrackers = () => {
  const location = useLocation();
  const previous = useRef();

  const { role, isConfirmed } = useSelector(authSelectors.getWorkspace);
  const workspaceIsLoading = useSelector(authSelectors.getIsLoading);

  // Initialize
  useEffect(initializeGa, []);

  // On location change
  useEffect(() => {
    if (workspaceIsLoading) {
      return; // Wait until finished and we have values for custom dimensions
    }

    const page = location?.pathname + location?.search;
    const prev = previous?.pathname + previous?.search;

    if (page === prev) {
      return; // Location has not changed. Do nothing
    }

    previous.current = location;

    if (config.ga.enabled) {
      sendPageview({ page, role, isConfirmed });
    } else {
      cleanUrl();
    }

    if (window.Intercom) {
      window.Intercom('update');
    }
  }, [location, isConfirmed, role, workspaceIsLoading]);

};

export default useTrackers;

import React from 'react';
import styled from '@emotion/styled/macro';
import NavLink from 'components/NavLink';

const config = {
  shouldForwardProp: prop => !['breakWord'].includes(prop),
};


const Wrapper = styled(NavLink, config)`
  display: flex;
  align-items: center;

  font-weight: 600;
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  padding: 0.75em 1em;
  margin: 0.25em 0;
  border-radius: 4px;

  ${({ breakWord }) => breakWord && 'word-break: break-word;'}

  &:hover {
    background-color: #efefef;
    color: inherit;
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

const Content = styled('span')`
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;

const SidebarItem = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    <Content>{children}</Content>
  </Wrapper>
);

export default SidebarItem;

import history from 'providers/history';
import get from 'lodash/get';
import { getBirthdayLink, getJobAnniversaryLink } from 'containers/Side/MemberEvents/memberEventsService';
import { isMobile } from 'helpers';

const onNotificationClick = (notification, config = {}) => {

  const hasPermission = config?.hasPermission || (() => false);

  switch (notification.data.target) {
    case 'post':
      history.push(`/post/${notification.data.postId}`);
      break;

    case 'home':
      history.push('/');
      break;

    case 'event':
      history.push(`/event/${notification.data.eventId}`);
      break;

    case 'course':
      history.push(`/course/${notification.data.courseId}`);
      break;

    case 'record':
      history.push(`/record/${notification.data.recordId}`);
      break;

    case 'feedback':
      history.push(hasPermission('MANAGE_FEEDBACKS') && !isMobile
        ? `/manage/feedback/${notification.data.feedbackId}`
        : `/feedback/assigned/${notification.data.feedbackId}`);
      break;

    case 'feedbacks':
      history.push(hasPermission('MANAGE_FEEDBACKS') && !isMobile
        ? '/manage/feedback'
        : '/feedback/assigned');
      break;

    case 'calendar':
      history.push('/calendar');
      break;

    case 'learningCourses':
      history.push('/e-learning/courses/');
      break;

    case 'learningCourse':
      history.push(`/e-learning/course/${notification.data.courseId}`);
      break;

    case 'birthday':
      history.replace(
        getBirthdayLink(history.location, notification.data.memberId, notification.data.year),
      );
      break;

    case 'jobAnniversary':
      history.replace(
        getJobAnniversaryLink(history.location, notification.data.memberId, notification.data.year),
      );
      break;

    case 'challenge': {
      const id1 = get(notification.data, 'challenge.id', null); // Depreacted 20 December 2020
      const id2 = get(notification.data, 'challengeId', null);
      const id = id1 || id2 || '';
      history.push(`/challenges/${id}`);
      break;
    }
    case 'group': {
      const id1 = get(notification.data, 'group.id', null); // Depreacted 20 December 2020
      const id2 = get(notification.data, 'groupId', null);
      const id = id1 || id2 || '';
      history.push(`/groups/${id}`);
      break;
    }
    case 'pulse': {
      history.push('/feedback/pulse');
      break;
    }
    default:
      break;
  }
};

const setBadgeNumber = count => {
  const { FirebasePlugin } = window;
  if (!FirebasePlugin) { return; }

  if (count >= 0) {
    FirebasePlugin.setBadgeNumber(count);
  }
};

const clearAllNotifications = () => {
  const { FirebasePlugin } = window;
  if (!FirebasePlugin) { return; }

  FirebasePlugin.clearAllNotifications();
};

const askPermission = () => {
  const { FirebasePlugin } = window;
  if (!FirebasePlugin) { return; }

  // iOS only:
  FirebasePlugin.grantPermission(() => {});
};

export default {
  onNotificationClick,
  firebase: {
    setBadgeNumber,
    clearAllNotifications,
    askPermission,
  },
};

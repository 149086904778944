import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import getErrorProperties from 'components/FinalForm/getErrorProperties';
import fieldRenderProps from 'components/FinalForm/fieldRenderProps';
import Autocomplete from 'components/Fields/Autocomplete/Autocomplete';
import TextField from 'components/Fields/TextField';

const emptyArray = [];

const showPlaceholder = value => Array.isArray(value)
  ? value.length === 0
  : !value;

const AutoCompleteInput = ({ input: { value, onChange, multiple, ...input }, InputLabelProps, meta, label, placeholder, margin, ...rest }) => {

  useEffect(() => {
    if (multiple && !Array.isArray(value)) {
      onChange(value ? [value] : emptyArray);
    }
  }, [multiple, onChange, value]);

  return (
    <Autocomplete
      value={value || (multiple ? [] : null)}
      onChange={onChange}

      getOptionLabel={option => option ?? ''}
      multiple={multiple}

      renderInput={props => (
        <TextField
          autoComplete="off"
          label={label}
          placeholder={showPlaceholder(value) ? placeholder : undefined}
          margin={margin}
          {...getErrorProperties(meta)}
          {...input}
          {...props}
          InputLabelProps={{
            ...input.InputLabelProps,
            ...props.InputLabelProps,
            ...InputLabelProps,
          }}
        />
      )}

      size="small"
      label={label}
      placeholder={placeholder}
      margin={margin}

      {...getErrorProperties(meta)}
      {...input}
      {...rest}
    />
  );
};

AutoCompleteInput.propTypes = {
  ...fieldRenderProps,
};

const AutoCompleteField = args => <Field component={AutoCompleteInput} {...args} />;

export default AutoCompleteField;

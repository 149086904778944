import decode from 'jwt-decode';
import { isMobile } from 'helpers';

const TOKEN_KEY = 'guavaToken';
const DEVICE_KEY = 'deviceKey';

const deviceReadyPromise = (callback, timeout = 15000) => new Promise((resolve, reject) => {
  const abort = setTimeout(() => {
    document.removeEventListener('deviceready', onDeviceReady);
    reject(new Error('"deviceready" timeout'));
  }, timeout);

  const onDeviceReady = () => {
    clearTimeout(abort);
    callback(resolve, reject);
  };

  document.addEventListener('deviceready', onDeviceReady, { passive: true, once: true });
});

const silentDeviceReadyPromise = (...args) => deviceReadyPromise(...args).catch(err => {
  // If error is a NativeStorageError's ITEM_NOT_FOUND. Just ignore it.
  if (err.code && err.constructor?.ITEM_NOT_FOUND && err.code === err.constructor?.ITEM_NOT_FOUND) {
    return null;
  }
  console.error(err);
  return null;
});

const storage = {
  local: {
    get: async key => localStorage.getItem(key),
    set: async (key, value) => localStorage.setItem(key, value),
    remove: async key => localStorage.removeItem(key),
  },
  native: {
    get: key => silentDeviceReadyPromise((resolve, reject) => window.NativeStorage.getItem(key, resolve, reject)),
    set: (key, value) => silentDeviceReadyPromise((resolve, reject) => window.NativeStorage.setItem(key, value, resolve, reject)),
    remove: key => silentDeviceReadyPromise((resolve, reject) => window.NativeStorage.remove(key, resolve, reject)),
  },
};

const getToken = async () => {
  const token = await storage.local.get(TOKEN_KEY);

  if (!token && isMobile && window.cordova) {
    const backup = await storage.native.get(TOKEN_KEY);

    if (backup) {
      await storage.local.set(TOKEN_KEY, backup);
      return backup;
    }
  }

  return token;
};

// Skips backup.
// Needed for a quick retrieval during unload event.
// During unload event cordova/NativeStorage no longer functions.
const getTokenQuick = async () => storage.local.get(TOKEN_KEY);

const removeToken = () => isMobile
  ? Promise.all([storage.local.remove(TOKEN_KEY), storage.native.remove(TOKEN_KEY)]) // Important to clear both!
  : storage.local.remove(TOKEN_KEY);

const setToken = value => {
  if (isMobile) {
    storage.native.set(TOKEN_KEY, value); // Store backup
  }

  return storage.local.set(TOKEN_KEY, value);
};

const shouldRefreshToken = async () => {
  const token = await getToken();

  if (!token) {
    return;
  }

  const expires = decode(token).exp;
  const now = Date.now().valueOf() / 1000;

  return expires - 30 < now; // Refreshing 30 seconds before exp;
};

const login = token => {
  setToken(token);
};

const logout = () => {
  removeToken();

  if (window.Intercom) {
    // Web
    window.Intercom('shutdown');
  }
  if (window.intercom) {
    // Cordova
    window.intercom.logout();
  }
};

const setDeviceKey = token => localStorage.setItem(DEVICE_KEY, token);
const getDeviceKey = () => localStorage.getItem(DEVICE_KEY);

export default {
  getToken,
  getTokenQuick,
  shouldRefreshToken,
  setToken,

  login,
  logout,

  setDeviceKey,
  getDeviceKey,
};

import courseTypes from '../course/types';
import lessonTypes from '../lesson/types';

const learningCourseVariantsReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case lessonTypes.deleteLessonForAdminSuccess:
      return {
        ...state,
        [payload.courseVariantId]: {
          ...state[payload.courseVariantId],
          lessons: state[payload.courseVariantId].lessons.filter((value) => +value !== +payload.lessonId),
        },
      };

    case courseTypes.deleteCourseVariantForAdminSuccess:
    {
      const nextState = { ...state };
      delete nextState[payload.courseVariantId];
      return nextState;
    }

    default:
      return state;
  }
};

export default learningCourseVariantsReducer;

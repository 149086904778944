import { all, takeLeading, put } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import types from './types';
import actions from './actions';

function* onGetArticles({ payload }) {
  const { categories } = payload;
  const { ok, error, response } = yield apiCall(api.guidance.getArticles, payload);

  if (ok) {
    yield put(actions.getArticlesSuccess({ ...response, categories }));
  } else {
    yield put(actions.getArticlesFailure(error));
  }
}

function* onGetArticleCategories({ payload }) {
  const { ok, error, response } = yield apiCall(api.guidance.getArticleCategories, payload);

  if (ok) {
    yield put(actions.getArticleCategoriesSuccess(response));
  } else {
    yield put(actions.getArticleCategoriesFailure(error));
  }
}

function* onGetArticle({ payload }) {
  const { ok, error, response } = yield apiCall(api.guidance.getArticle, payload);

  if (ok) {
    yield put(actions.getArticleSuccess(response));
  } else {
    yield put(actions.getArticleFailure(error));
  }
}

function* onAddLike({ payload }) {
  const { ok, error } = yield apiCall(api.guidance.addLike, payload);

  if (ok) {
    yield put(actions.addLikeSuccess({ articleId: payload.articleId }));
  } else {
    yield put(actions.addLikeFailure(error));
  }
}

function* onRemoveLike({ payload }) {
  const { ok, error } = yield apiCall(api.guidance.removeLike, payload);

  if (ok) {
    yield put(actions.removeLikeSuccess({ articleId: payload.articleId }));
  } else {
    yield put(actions.removeLikeFailure(error));
  }
}

export default function* commentSagas() {
  yield all([
    takeLeading(types.getArticles, onGetArticles),
    takeLeading(types.getArticleCategories, onGetArticleCategories),
    takeLeading(types.getArticle, onGetArticle),
    takeLeading(types.addLike, onAddLike),
    takeLeading(types.removeLike, onRemoveLike),
  ]);
}

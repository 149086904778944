import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  // SINGLE COURSE REQUESTS
  getCourse: data => createAction(types.getCourse, data),
  getCourseSuccess: data => createAction(types.getCourseSuccess, data),
  getCourseFailure: data => createAction(types.getCourseFailure, data),

  createCourse: data => createAction(types.createCourse, data),
  createCourseSuccess: data => createAction(types.createCourseSuccess, data),
  createCourseFailure: error => createAction(types.createCourseFailure, error),

  updateCourse: data => createAction(types.updateCourse, data),
  updateCourseSuccess: data => createAction(types.updateCourseSuccess, data),
  updateCourseFailure: data => createAction(types.updateCourseFailure, data),

  deleteCourse: data => createAction(types.deleteCourse, data),
  deleteCourseSuccess: data => createAction(types.deleteCourseSuccess, data),
  deleteCourseFailure: data => createAction(types.deleteCourseFailure, data),

  // INDEX REQUESTS
  getCourses: data => createAction(types.getCourses, data),
  getCoursesSuccess: data => createAction(types.getCoursesSuccess, data),
  getCoursesFailure: data => createAction(types.getCoursesFailure, data),
};

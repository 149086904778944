import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconText from 'components/IconText';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Popover from '@mui/material/Popover';
import Grid from 'components/Grid';
import styled from '@emotion/styled/macro';
import TextLoader from 'components/TextLoader';

const popoverStyles = {
  pointerEvents: 'none',
  '& .MuiPaper-root': {
    padding: 2,
    fontSize: '0.9rem',
  },
};

const Subtitle = styled('div')`
  color: #888;
  font-size: 0.875rem;
`;

const StartIcon = () => <SmartphoneIcon style={{ fontSize: '1.2em' }} />;

const PostInfoSms = ({ content, totalSent, totalSpent, isRecent, isSent, ...rest }) => {
  const { t } = useTranslation(['component']);

  const anchorEl = useRef();
  const [open, setOpen] = useState(false);

  const timeout = useRef();

  const onMouseEnter = () => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setOpen(true), 200);
  };

  const onMouseLeave = () => {
    clearTimeout(timeout.current);
    setOpen(false);
  };

  const popoverProps = {
    open,
    sx: popoverStyles,
    anchorEl: anchorEl.current,
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
    onClose: () => setOpen(false),
    disableRestoreFocus: true,
    // TODO: Create a better solution. This leaves
    // the popover hanging in wrong place with scroll
    disableScrollLock: true,
  };

  return (
    <IconText
      startIcon={<StartIcon />}
      ref={anchorEl}
      // TODO: Add a onClick handler for mobile devices
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      {isSent && !isRecent ? totalSent : null}
      <Popover
        {...popoverProps}
        PaperProps={{
          style: { width: '250px' },
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {content}
          </Grid>
          {isSent && (
            <Grid item>
              {isRecent
                ? (<TextLoader direction="column" text={t('smsInfo.recentlySentPleaseWait')} />)
                : (
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Subtitle>{t('smsInfo.sent')} : {totalSent}</Subtitle>
                    </Grid>
                    <Grid item>
                      <Subtitle>{t('smsInfo.cost')} : {totalSpent}€</Subtitle>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          )}
        </Grid>
      </Popover>
    </IconText>
  );
};

PostInfoSms.propTypes = {
  content: PropTypes.string,
  totalSent: PropTypes.number,
  totalSpent: PropTypes.number,
  isRecent: PropTypes.bool,
  isSent: PropTypes.bool,
};

export default PostInfoSms;

import { schema } from 'normalizr';
import memberSchema from './member';
import feedbackFormSchema from './feedbackForm';
import labelSchema from './label';

const feedbackTypeSchema = new schema.Entity('feedbackTypes', {
  access: [{ member: memberSchema }],
  form: feedbackFormSchema,
  targets: [labelSchema],
});

export default feedbackTypeSchema;

import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'components/Grid';
import styled from '@emotion/styled/macro';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavLink from 'components/NavLink';
import Avatar from 'components/Avatar';
import { getDateString, isBirthday } from 'services/date-fns';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { getBirthdayLink } from '../memberEventsService';

const StyledListItem = styled(ListItem)`
  color: inherit;
  text-decoration: none;

  padding: ${({ theme }) => theme.spacing(2)};

  &:hover {
    background: #f8f8f8;
  }
`;

const EventName = styled(ListItemText)`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EventDate = styled('div')`
  color: #666;
  font-size: 0.9rem;
`;

const RightIcon = styled(ChevronRightIcon)`
  color: #ddd;
`;

const MemberListItem = ({ member }) => {
  const name = [member?.firstName, member?.lastName].filter(v => !!v).join(' ');

  const eventDate = new Date(member.birthday);
  const eventDateFormatted = getDateString(eventDate, { month: 'short', day: 'numeric' }, 'd MMM');

  return (
    <StyledListItem divider component={NavLink} to={location => getBirthdayLink(location, member.id, eventDate.getFullYear())} replace>
      <Grid container spacing={2} alignItems="center">
        <Grid item><Avatar size={32} sizes="32px" src={member.avatar?.src} srcSet={member.avatar?.srcSet} firstName={member.firstName} lastName={member.lastName} /></Grid>
        <Grid item xs zeroMinWidth><EventName>{name}</EventName></Grid>
        <Grid item>{!isBirthday(eventDate) && <EventDate>{eventDateFormatted}</EventDate>}</Grid>
        <Grid item><RightIcon /></Grid>
      </Grid>
    </StyledListItem>
  );
};

MemberListItem.propTypes = {
  member: PropTypes.object.isRequired,
};

export default MemberListItem;

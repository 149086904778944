import React, { useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import PropTypes from 'prop-types';
import { isMobile } from 'helpers';

const ItemContainer = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100vw;

  padding: 1rem 0;
  margin-top: -1rem;
  margin-bottom: -1rem;

  & > * + * {
    /* Space between elements */
    margin-left: 1rem;
  }

  ${({ hasOverflow, card }) => hasOverflow && css`
    /* Escape actual padding */
    margin-left: -1rem;
    width: calc(100% + 2rem);

    /* So that there's still some room under the scrollbar  */
    /* margin-bottom: 0; */

    overflow-x: auto;
    overflow-y: hidden;

    -webkit-overflow-scrolling: touch !important;

    /* Fake padding to sides */
    &::before { content: ''; min-width: 1rem; }
    &::after { content: ''; min-width: 1rem; }

    /* Numbers below should be put somewhere globally */
    /* Quickfixes to avoid horizontal scrollers */
    ${isMobile && css`
      margin-left: -0.75rem;
      width: calc(100% + 1.5rem);
    `}

    /* Same as Card / CardContent */
    ${card && css`
      @media(max-width: 400px) {
        margin-left: -0.5rem;
        width: calc(100% + 1rem);
      }
    `}
  `}
`;

const HorizontalScroller = ({ card, children, ...rest }) => {

  const innerContainer = useRef();
  const outerContainer = useRef();

  const [hasOverflow, setHasOverflow] = useState(false);

  const hasVerticalOverflow = () => {
    return innerContainer.current && innerContainer.current.scrollWidth > innerContainer.current.clientWidth;
  };

  const detectOverflow = useCallback(() => setHasOverflow(hasVerticalOverflow()), []);

  useEffect(() => {
    window.addEventListener('resize', detectOverflow, { passive: true });
    return () => window.removeEventListener('resize', detectOverflow, { passive: true });
  }, [detectOverflow]);

  useLayoutEffect(() => {
    detectOverflow();
  }, [detectOverflow, children]);

  const Outer = card ? Card : 'div';
  const Inner = card ? CardContent : React.Fragment;

  return (
    <Outer ref={outerContainer} {...rest}>
      <Inner>
        <ItemContainer card={card} hasOverflow={hasOverflow} ref={innerContainer}>
          {children}
        </ItemContainer>
      </Inner>
    </Outer>
  );
};

HorizontalScroller.propTypes = {
  card: PropTypes.bool,
};

export default HorizontalScroller;

import React, { } from 'react';
import IconButton from '@mui/material/IconButton';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { useTranslation } from 'react-i18next';
import NavLink from 'components/NavLink';
import PropTypes from 'propTypes';

const MemberProfileQuickAction = ({ memberId, ...rest }) => {

  const { t } = useTranslation(['component']);

  return (
    <IconButton
      component={NavLink}
      to={`/member/${memberId}`}
      aria-label={t('contactDirectory.itemInfo.gotoProfileButton')}
      title={t('contactDirectory.itemInfo.gotoProfileButton')}
      {...rest}
    >
      <PersonRoundedIcon />
    </IconButton>
  );
};

MemberProfileQuickAction.propTypes = {
  memberId: PropTypes.any.isRequired,
};

export default MemberProfileQuickAction;

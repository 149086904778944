import styled from '@emotion/styled/macro';
import React from 'react';
import ReactTwemoji from 'react-twemoji';
import PropTypes from 'prop-types';

const StyledTwemoji = styled(ReactTwemoji)`
  img.emoji {
    height: 1.4em;
    width: 1.4em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.4em;
  }
`;

const defaultOptions = {
  callback(icon) {
    if (icon === '1f1fa-1f1e6') {
      return '/assets/images/emoji/ua-emoji-heart.svg';
    }

    // Problem is that the default Twemoji images are licensed under CC,
    // so currently we're just replacing what we need, where we need it
    // and with attribution free images.
    return false;
  },
};

const Twemoji = ({ options, ...rest }) => {
  return (
    <StyledTwemoji options={{ ...defaultOptions, ...options }} {...rest} />
  );
};

Twemoji.propTypes = {
  options: PropTypes.object,
};

export default Twemoji;

import React, { useContext, useState } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import PinIcon from 'components/Icons/Pin';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import PostContext from './PostContext';

const Wrapper = styled('div')`
  padding: 0.5rem 1rem;
  font-size: 0.875rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #666;
  width: 100%;

  border-bottom: 1px solid #eee;

  ${({ darker }) => darker && css`
    color: #333;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  `};
`;

const GridItem = styled(Grid)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
`;

const leftGridConfig = {
  shouldForwardProp: prop => !['wrap'].includes(prop),
};

const LeftGrid = styled(GridItem, leftGridConfig)`
  svg {
    font-size: 1rem;
    margin-right: 0.25rem;
  }

  p {
    ${({ wrap }) => wrap ? css`
      white-space: pre-wrap;
      overflow-wrap: break-word;
      word-break: break-word;`
    : css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;`}

    margin: 0;
  }
`;

const rightGridConfig = {
  shouldForwardProp: prop => !['darker'].includes(prop),
};

const RightGrid = styled(GridItem, rightGridConfig)`
  font-weight: 500;
  min-width: fit-content;

  svg {
    font-size: 1rem;
  }

  color: #888;
  ${({ darker }) => darker && css`
    color: #666;
  `};
`;

const PostPreHeader = ({ children, ...rest }) => {

  const { t } = useTranslation('component');

  const { isCompact, isImportant, isPinnable, isPinned } = useContext(PostContext);

  const [wrap, setWrap] = useState(false);

  return (
    <Wrapper darker={isImportant && isCompact} {...rest}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" wrap="nowrap">
        <LeftGrid item zeroMinWidth wrap={wrap} onClick={() => setWrap(w => !w)}>{children}</LeftGrid>
        {isPinnable && isPinned && <RightGrid item darker={isImportant && isCompact}><PinIcon />&nbsp; {t('post.pinned')}</RightGrid>}
      </Grid>
    </Wrapper>
  );
};

export default PostPreHeader;

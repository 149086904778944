import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import ChallengeBadge from 'components/Challenge/ChallengeBadge';

const Wrapper = styled('div')`
  display: flex;

  width: 100%;
  background: black;
  background-image: ${({ cover }) => `url(${cover})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  height: 250px;
  max-height: 250px;

  margin-bottom: 56px;
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

const Title = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  margin: 5rem 0 1rem;
  padding: 0 1rem;

  text-align: center;
  color: #fff;
`;

const Avatar = styled(ChallengeBadge)`
  margin-bottom: -59px;
  flex-grow: 0;
  display: flex;
`;

const ChallengeHeader = ({ avatar, cover, title }) => {

  return (
    <Wrapper cover={cover}>
      <Content>
        <Title><h3>{title}</h3></Title>
        <Avatar src={avatar} size={118} />
      </Content>
    </Wrapper>
  );
};

ChallengeHeader.propTypes = {
  avatar: PropTypes.string,
  cover: PropTypes.string,
  title: PropTypes.string,
};

export default ChallengeHeader;

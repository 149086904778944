class ActionHandler {
  constructor() {
    this.listeners = new Map();

    this.addListener = (hash, type, listener) => {
      this.listeners.set(hash, { type, listener });
    };

    this.removeListener = (hash) => {
      this.listeners.delete(hash);
    };

    this.cleanup = () => {
      this.listeners.clear();
    };
  }
}

export default ActionHandler;

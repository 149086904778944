import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'helpers';
import PostFormHeaderTitle from '../Partials/PostFormHeaderTitle';
import useBackClick from './useBackClick';

const SmsViewHeader = () => {

  const { t } = useTranslation('component');

  const onBackClick = useBackClick();

  if (isMobile) {
    return null;
  }

  return (
    <PostFormHeaderTitle onBackClick={onBackClick}>{t('smsForm.title')}</PostFormHeaderTitle>
  );
};

export default SmsViewHeader;

export default {
  getQuiz: 'quiz/GET_QUIZ',
  getQuizSuccess: 'quiz/GET_QUIZ_SUCCESS',
  getQuizFailure: 'quiz/GET_QUIZ_FAILURE',

  storeAnswer: 'quiz/STORE_ANSWER',
  storeAnswerSuccess: 'quiz/STORE_ANSWER_SUCCESS',
  storeAnswerFailure: 'quiz/STORE_ANSWER_FAILURE',

  deleteAnswer: 'quiz/DELETE_ANSWER',
  deleteAnswerSuccess: 'quiz/DELETE_ANSWER_SUCCESS',
  deleteAnswerFailure: 'quiz/DELETE_ANSWER_FAILURE',

  downloadAnswers: 'quiz/DOWNLOAD_ANSWERS',
  downloadAnswersSuccess: 'quiz/DOWNLOAD_ANSWERS_SUCCESS',
  downloadAnswersFailure: 'quiz/DOWNLOAD_ANSWERS_FAILURE',
};

import EmojiSadColoredIcon from 'components/Icons/EmojiSadColored';
import EmojiMehColoredIcon from 'components/Icons/EmojiMehColored';
import EmojiSmileColoredIcon from 'components/Icons/EmojiSmileColored';
import EmojiHappyColoredIcon from 'components/Icons/EmojiHappyColored';
import styled from '@emotion/styled/macro';

const getStyledIcon = (Icon, color) => styled(Icon)`
  color: ${color};
`;

const choices = ({
  bad: {
    Emoji: getStyledIcon(EmojiSadColoredIcon, '#F43756'),
    value: 'bad',
    color: '#F43756',
  },
  notGood: {
    Emoji: getStyledIcon(EmojiMehColoredIcon, '#FFE17D'),
    value: 'not_good',
    color: '#FFE17D',
  },
  good: {
    Emoji: getStyledIcon(EmojiSmileColoredIcon, '#A0CC7C'),
    value: 'good',
    color: '#A0CC7C',
  },
  great: {
    Emoji: getStyledIcon(EmojiHappyColoredIcon, '#4DAF4F'),
    value: 'great',
    color: '#4DAF4F',
  },
});

const activeChoices = [
  choices.bad,
  choices.notGood,
  choices.good,
  choices.great,
];

export default {
  getChoice: value => choices[value],
  getActiveChoices: () => activeChoices,
};

import merge from 'lodash/merge';
import { schema } from 'normalizr';

/**
 * Produces output like
 *
 * entities: {
 *    en: {
 *      post: {
 *        123: {
 *          body: 'Body translation',
 *          field: 'Some other translation'
 *        }
 *      }
 *    }
 * }
 */

const processStrategy = value => ({
  [value.translatableType]: {
    [value.translatableId]: {
      [value.field]: value.value,
    },
  },
});

// Deep merge
const mergeStrategy = (a, b) => merge(a, b);

const translationSchema = new schema.Entity('translations', undefined, { idAttribute: 'language', processStrategy, mergeStrategy });

export default translationSchema;

import { useContext, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { channelSelectors } from 'redux/channel';
import useFeature from 'hooks/useFeature';
import { isMobile } from 'helpers';
import PostFormContext from './PostFormContext';

const useCapabilities = () => {

  const postSmsFeature = useFeature('postSms');

  const { values: { channelId, isDraft, postedAt } } = useFormState({
    subscription: {
      values: true,
      initialValues: true,
    },
  });

  const { editing, disableChannelSelect } = useContext(PostFormContext);

  const channel = useSelector(channelSelectors.getChannelSelector)(channelId);

  const capabilities = useMemo(() => {
    const permissions = channel && channel.permissions ? channel.permissions : {};

    return {
      media: permissions.media ?? true,
      attachments: permissions.attachments ?? true,
      pin: permissions.pin ?? false,
      important: permissions.pin ?? false,

      poll: (!editing || isDraft || (editing && !postedAt)) && (permissions.poll ?? true),
      sms: postSmsFeature.enabled && (!editing || isDraft) && (permissions.sms ?? false),
      quiz: (!editing || isDraft || (editing && !postedAt)) && (permissions.quiz ?? false),
      target: !!permissions.target,

      channel: !disableChannelSelect, // Little bit misleading. Channel must always be present, but this just hides it. Value must be set by using other means
      activity: permissions.activity ?? false,

      rich: !isMobile,
    };

  }, [channel, editing, isDraft, postedAt, postSmsFeature.enabled, disableChannelSelect]);

  return capabilities;
};

export default useCapabilities;

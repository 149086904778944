import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import ColorfulButton from 'components/ColorfulButton';
import TranslateIcon from 'components/Icons/Translate';
import { useTranslation } from 'react-i18next';

const StyledColorfulButton = styled(ColorfulButton)`
  /* Align icon with rest of the body content  */
  min-width: initial;
  background: none !important;
  padding: 3px;

  .MuiButton-startIcon {
    margin-left: 2px;
  }
`;

const CommentTranslateButton = ({ onClick, active, ...rest }) => {

  const { t } = useTranslation(['component']);

  return (
    <StyledColorfulButton darker={active} startIcon={<TranslateIcon />} size="small" onClick={onClick} {...rest}>
      {active ? t('component:translation.translated') : t('component:translation.translate')}
    </StyledColorfulButton>
  );
};

CommentTranslateButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

export default CommentTranslateButton;

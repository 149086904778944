import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getFoldersForMember: data => createAction(types.getFoldersForMember, data),
  getFoldersForMemberSuccess: data => createAction(types.getFoldersForMemberSuccess, data),
  getFoldersForMemberFailure: error => createAction(types.getFoldersForMemberFailure, error),

  getFolderForMember: data => createAction(types.getFolderForMember, data),
  getFolderForMemberSuccess: data => createAction(types.getFolderForMemberSuccess, data),
  getFolderForMemberFailure: error => createAction(types.getFolderForMemberFailure, error),

  getFoldersForAdmin: data => createAction(types.getFoldersForAdmin, data),
  getFoldersForAdminSuccess: data => createAction(types.getFoldersForAdminSuccess, data),
  getFoldersForAdminFailure: error => createAction(types.getFoldersForAdminFailure, error),

  getFolderForAdmin: data => createAction(types.getFolderForAdmin, data),
  getFolderForAdminSuccess: data => createAction(types.getFolderForAdminSuccess, data),
  getFolderForAdminFailure: error => createAction(types.getFolderForAdminFailure, error),

  postFolderForAdmin: data => createAction(types.postFolderForAdmin, data),
  postFolderForAdminSuccess: data => createAction(types.postFolderForAdminSuccess, data),
  postFolderForAdminFailure: error => createAction(types.postFolderForAdminFailure, error),

  putFolderForAdmin: data => createAction(types.putFolderForAdmin, data),
  putFolderForAdminSuccess: data => createAction(types.putFolderForAdminSuccess, data),
  putFolderForAdminFailure: error => createAction(types.putFolderForAdminFailure, error),

  deleteFolderVariantForAdmin: data => createAction(types.deleteFolderVariantForAdmin, data),
  deleteFolderVariantForAdminSuccess: data => createAction(types.deleteFolderVariantForAdminSuccess, data),
  deleteFolderVariantForAdminFailure: error => createAction(types.deleteFolderVariantForAdminFailure, error),
};

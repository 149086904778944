import { all, put, takeLeading } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { takeLeadingPerKey } from 'redux/helpers/saga';
import types from './types';
import actions from './actions';
import { snackbarActions } from '../snackbar';

function* onGetQuestionsByLessonForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getQuestions, payload);

  if (ok) {
    yield put(actions.getQuestionsByLessonForMemberSuccess({ lessonId: payload.lessonId, ...response }));
  } else {
    yield put(actions.getQuestionsByLessonForMemberFailure(error));
  }
}

function* onGetQuestionForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.getQuestion, payload);

  if (ok) {
    yield put(actions.getQuestionForMemberSuccess(response));
  } else {
    yield put(actions.getQuestionForMemberFailure(error));
  }
}

function* onPostQuestionAnswerForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.learning.postQuestionAnswer, payload);

  if (ok) {
    yield put(actions.postQuestionAnswerForMemberSuccess(response));
    if (response.isCorrect === false) {
      yield put(snackbarActions.createFailure('questions.incorrectAnswer', { translate: true }));
    }
  } else {
    yield put(actions.postQuestionAnswerForMemberFailure(error));
  }
}

export default function* questionSagas() {
  yield all([
    takeLeadingPerKey(types.getQuestionsByLessonForMember, onGetQuestionsByLessonForMember, ({ payload }) => payload.lessonId),
    takeLeading(types.getQuestionForMember, onGetQuestionForMember),
    takeLeading(types.postQuestionAnswerForMember, onPostQuestionAnswerForMember),
  ]);
}

import { all, takeLeading, put, takeEvery } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import types from './types';
import actions from './actions';

function* onGetChannel({ payload }) {
  const { ok, response, error } = yield apiCall(api.channel.get, payload);

  if (ok) {
    yield put(actions.getChannelSuccess(response));
  } else {
    yield put(actions.getChannelFailure(error));
  }
}

function* onSubscribeToChannel({ payload }) {
  yield apiCall(api.channel.subscribe, payload);
}

export default function* channelSagas() {
  yield all([
    takeEvery(types.getChannel, onGetChannel),
    takeLeading(types.subscribeToChannel, onSubscribeToChannel),
  ]);
}

import React, { useContext, useEffect } from 'react';
import NavLink from 'components/NavLink';
import SideContext from 'containers/Side/SideContext';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { jobAnniversaryActions, jobAnniversarySelectors } from 'redux/jobAnniversary';
import MemberList from './MemberList';
import { getCloseButtonLink } from '../memberEventsService';

const JobAnniversaries = (args) => {

  const dispatch = useDispatch();
  const { t } = useTranslation(['component']);

  const { members, pagination } = useSelector(jobAnniversarySelectors.getJobAnniversaryEvents);

  const loadMore = () => dispatch(jobAnniversaryActions.getJobAnniversaries({ page: pagination.nextPage }));

  useEffect(() => {
    dispatch(jobAnniversaryActions.getJobAnniversaries());
  }, [dispatch]);

  const { setTitle } = useContext(SideContext);
  useEffect(() => {
    setTitle(({
      backButton: { component: NavLink, to: getCloseButtonLink },
      children: t('jobAnniversaryEvents.title'),
    }));
  }, [setTitle, t]);

  return (
    <MemberList
      type="JobAnniversary"
      members={members}
      currentPage={pagination.currentPage}
      hasMore={pagination.hasMore}
      loadMore={loadMore}
      useWindow={false}

      texts={{
        loading: t('jobAnniversaryEvents.loadingJobAnniversaries'),
        loadingMore: t('jobAnniversaryEvents.loadingMoreJobAnniversaries'),
        noEvents: t('jobAnniversaryEvents.noJobAnniversaries'),
        noMoreEvents: t('jobAnniversaryEvents.noMoreJobAnniversaries'),
      }}

      {...args}
    />
  );
};

export default JobAnniversaries;

import React from 'react';
import styled from '@emotion/styled/macro';

const CheckboxSvg = args => (
  <span {...args}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" strokeWidth="2">
      <polyline points="1,9 7,14 15,4" />
      <circle cx="10" cy="10" r="9" transform="rotate(-174, 10, 10)" />
    </svg>
  </span>
);

const VisualCheckbox = styled(CheckboxSvg)`
  position: relative;
  display: block;
  cursor: pointer;

  svg {
    width: 26px;
    height: 26px;

    padding: 1px;
    stroke: #fff;
    transition: all .15s ease;
    transform: transale3d(0,0,0);

    circle {
      stroke-dasharray: 60;
      stroke-dashoffset: 0;
      transition: stroke-dasharray .15s linear, stroke-dashoffset .15s linear;
      transition-delay: .15s;
    }
    polyline {
      stroke-dasharray: 22;
      stroke-dashoffset: 66;
      transition: stroke-dasharray .15s linear, stroke-dashoffset .15s linear;
    }
  }
`;

const ActualCheckbox = styled('input')`
  opacity: 0;
  position: absolute;

  &:checked ~ ${VisualCheckbox} {
    svg {
      circle {
        stroke-dashoffset: 60;
        transition: stroke-dasharray .15s linear, stroke-dashoffset .15s linear;
      }
      polyline {
        stroke-dashoffset: 42;
        transition: stroke-dasharray .15s linear, stroke-dashoffset .15s linear;
        transition-delay: .15s;
      }
    }
  }
`;

const BoostCheckbox = ({ ...rest }) => (
  <>
    <ActualCheckbox type="checkbox" {...rest} />
    <VisualCheckbox />
  </>
);

export default BoostCheckbox;

import React from 'react';
import { getPeriodDisplay } from 'services/datetimeService';
import styled from '@emotion/styled/macro';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import PropTypes from 'prop-types';
import NavLink from 'components/NavLink';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'helpers';
import IconText from './IconText';
import UserIcon from './Icons/User';
import ProgressiveBackgroundImage from './ProgressiveBackgroundImage';

const Wrapper = styled(NavLink)`
  min-width: 200px;
  width: 33%;
  max-width: 50%;
  width: 100%;

  ${({ onClick, href }) => (onClick || href) && 'cursor: pointer'};

  text-decoration: none;
  color: inherit;
`;

const TopContainer = styled('div')`
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 120px;
  border-radius: 4px;
  margin-bottom: 0.5rem;

  background-color: #eee;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  color: #ccc;

  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Date = styled('div')`
  color: #888;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0.25rem 0;
`;

const Name = styled('div')`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0.25rem 0;
`;

const Target = styled(IconText)`
  position: absolute;
  background: ${({ theme }) => theme.palette.primary.main}; /* Fallback */
  background: ${({ theme }) => theme.palette.primary.main}95; /* Opacity */
  bottom: 0;
  width: 100%;
  padding: 0.25em 0.5em;

  font-size: 0.9rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-align: center;
`;

const EventBox = ({ id, name, cover, startAt, endAt, allDay, hasTargets, targetUsersCount }) => {

  const { t } = useTranslation('calendar');

  const sizes = isMobile ? '50vw' : '(max-width: 682px) 50vw, 375px';

  return (
    <Wrapper to={`/event/${id}`}>
      <ProgressiveBackgroundImage src={cover?.src} srcSet={cover?.srcSet} sizes={sizes}>
        {({ src }) => (
          <TopContainer style={src ? { backgroundImage: `url(${src})` } : null}>
            {!src && <TodayRoundedIcon color="inherit" fontSize="large" />}
            {hasTargets && (
              <Target startIcon={<UserIcon style={{ fontSize: '1.2em' }} />}>
                {t('eventBox.members', { count: targetUsersCount })}
              </Target>
            )}
          </TopContainer>
        )}
      </ProgressiveBackgroundImage>

      <Date title={getPeriodDisplay(startAt, endAt, allDay)}>
        {getPeriodDisplay(startAt, endAt, allDay)}
      </Date>
      <Name>{name}</Name>
    </Wrapper>
  );
};

EventBox.propTypes = {
  id: PropTypes.any.isRequired,

  name: PropTypes.string.isRequired,
  startAt: PropTypes.string.isRequired,
  endAt: PropTypes.string,
  allDay: PropTypes.bool,

  cover: PropTypes.shape({
    src: PropTypes.string,
    srcSet: PropTypes.string,
  }),
  hasTargets: PropTypes.bool,
  targetUsersCount: PropTypes.number,
};

export default EventBox;

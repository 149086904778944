import React, { useRef, useMemo, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { useField } from 'react-final-form';
import Paperclip from 'components/Icons/Paperclip';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import useVideoUploader from 'hooks/useVideoUploader';
import PropTypes from 'prop-types';
import { isAndroid } from 'react-device-detect';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: -0.5rem;
  padding: 0.5rem;

  svg {
    ${({ active, theme }) => active && `color: ${theme.palette.primary.main};`}
  }

  ${({ showReset }) => showReset && css`
    &::after {
      content: "\\00D7";
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 0px;
      right: 0px;

      background: #eee;
      border-radius: 50%;
      color: #333;

      width: 11px;
      height: 11px;
      font-size: 12px;
    }
  `}
`;

const ProgressBar = styled('div')`
  content: ' ';
  height: 3px;
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 4px;

  border: 1px solid #ddd;
  border-radius: 2px;

  &::after {
    content: ' ';
    position: absolute;

    height: 3px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 2px;
    left: -1px;
    bottom: -1px;
    top: -1px;

    background: ${({ theme }) => theme.palette.primary.main};
    width: ${({ progress }) => `${progress * 100}%`};
  }
`;

// Capture default true only on Android
// (Android & capture=true) Gives the option to choose from photos, files or camera
// (Apple & capture=true) Takes directly to the camera without the option to choose from existing photos
const captureDefault = isAndroid ? true : undefined;
const SingleButtonAttachmentField = ({ namePrefix = '', onChange, capture = captureDefault, ...rest }) => {

  const inputRef = useRef();

  const videoUploader = useVideoUploader();

  const { input: { value: file, onChange: setFileValue } } = useField(`${namePrefix}file`);
  const { input: { value: image, onChange: setImageValue } } = useField(`${namePrefix}image`);
  const { input: { value: video, onChange: setVideoValue } } = useField(`${namePrefix}video`, {
    validate: () => videoUploader.uploading ? 'Wait for upload to finish' : undefined,
    data: videoUploader.uploading, // To trigger updates to the validator
  });

  const onClick = () => {
    if (videoUploader.uploading || file || image || video) {
      reset();
    } else {
      reset(); // To make sure file input is empty, selecting same file again is possible etc.
      inputRef.current.click();
    }
  };

  const reset = () => {
    videoUploader.reset();

    setFileValue(undefined);
    setImageValue(undefined);
    setVideoValue(undefined);
  };

  useEffect(() => {
    if (onChange) {
      onChange({ file, image, video, videoUploading: videoUploader.uploading });
    }
  }, [onChange, file, image, video, videoUploader.uploading]);

  const onFileChange = ({ target: { files: [selectedFile] } }) => {
    if (selectedFile.type && selectedFile.type.match('image/.*')) {
      setImageValue(selectedFile);
    } else if (selectedFile.type && selectedFile.type.match('video/.*')) {
      videoUploader.upload(selectedFile)
        .then(data => setVideoValue(data.cdnUrl))
        .catch(() => setVideoValue(undefined));
    } else {
      setFileValue(selectedFile);
    }

    inputRef.current.value = null;
  };

  const icon = useMemo(() => {
    if (image) return <CameraAltOutlinedIcon fontSize="small" />;
    if (video || videoUploader.uploading) return <PlayCircleOutlineOutlinedIcon fontSize="small" />;
    if (file) return <InsertDriveFileOutlinedIcon fontSize="small" />;
    return <Paperclip fontSize="small" />;
  }, [image, video, file, videoUploader.uploading]);

  return (
    <Wrapper onClick={onClick} active={image || file || video} showReset={image || file || video || videoUploader.uploading} {...rest}>
      {icon}
      {videoUploader.uploading && <ProgressBar progress={videoUploader.progress} />}
      <input type="file" hidden ref={inputRef} capture={capture} onChange={onFileChange} />
    </Wrapper>
  );
};

SingleButtonAttachmentField.propTypes = {
  namePrefix: PropTypes.string,
  onChange: PropTypes.func,
  capture: PropTypes.bool,
};

export default SingleButtonAttachmentField;

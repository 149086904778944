import CyclingIcon from 'components/Icons/Cycling';
import RunningIcon from 'components/Icons/Running';
import SwimmingIcon from 'components/Icons/Swimming';
import WalkingIcon from 'components/Icons/Walking';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import NoteIcon from 'components/Icons/Note';
import BookIcon from 'components/Icons/Book';
import CharityIcon from 'components/Icons/Charity';
import BrushIcon from 'components/Icons/Brush';
import ChessboardIcon from 'components/Icons/Chessboard';
import StopwatchIcon from 'components/Icons/Stopwatch';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import EcoRoundedIcon from 'components/Icons/EcoRounded';
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import capitalize from 'lodash/capitalize';
import RecycleIcon from 'components/Icons/Recycle';
import { formatString } from 'services/date-fns';

const iconMap = {
  board_game: ChessboardIcon,
  book: BookIcon,
  charity: CharityIcon,
  concert: NoteIcon,
  cross_country_skiing: DownhillSkiingIcon,
  cycling: CyclingIcon,
  generic: StopwatchIcon,
  green_deed: EcoRoundedIcon,
  bottle_recycling: RecycleIcon,
  handicraft: BrushIcon,
  movie: PlayCircleOutlineOutlinedIcon,
  photography: PhotoCameraRoundedIcon,
  running: RunningIcon,
  swimming: SwimmingIcon,
  walking: WalkingIcon,
};

export const getCategoryIcon = category => iconMap[category?.toLowerCase()] || StarBorderRoundedIcon;

const normalize = (str) => str ? capitalize(str?.replace('_', ' ')) : str;

export const getCategoryTranslation = (t, name) => {
  const $name = name?.toLowerCase() || '';

  return t(`common:activityCategory.${$name}`, normalize($name));
};

export const getUnitTranslation = (t, value, unit, category) => {
  const $category = category?.toLowerCase();
  const $unit = unit?.toLowerCase();

  return t(`common:activityFieldUnitByCategory.${$category}.${$unit}`, { count: +value, value, defaultValue: null })
    || t(`common:activityFieldUnit.${$unit}`, { defaultValue: null })
    || unit;
};

export const getValueWithUnitTranslation = (t, value, unit, category, type = null) => {
  let $category = category;
  let $value = value;

  if (category && Array.isArray(category)) {
    $category = category?.length === 1 ? category[0] : null;
  }

  if (typeof $category !== 'string') {
    $category = $category?.name;
  }

  const $unit = getUnitTranslation(t, $value, unit, $category);

  if (type && type?.toLowerCase() === 'date') {
    $value = formatString($value, 'P');
  }

  return [$value, $unit].filter(v => v !== null && v !== undefined && v !== '').join(' ');
};

export const getFieldLabelTranslation = (t, name, category = null) => {
  const $name = name?.toLowerCase();
  const $category = category?.toLowerCase();

  return t(`common:activityFieldByCategory.${$category}.${$name}Label`, '')
    || t(`common:activityField.${$name}Label`, normalize($name) || ''); // Fallback
};

export const getFieldPlaceholderTranslation = (t, name, category = null) => {
  const $name = name?.toLowerCase();
  const $category = category?.toLowerCase();

  return t(`common:activityFieldByCategory.${$category}.${$name}Placeholder`, '')
    || t(`common:activityField.${$name}Placeholder`, '');
};

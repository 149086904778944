import memoize from 'lodash/memoize';
import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';


const getPageEntities = ({ entities: { pages } }) => pages;

const getPageSelector = createSelector(
  [getPageEntities],
  (entities) => memoize(id => entities?.[id]),
);

const getPagesPagination = ({ pages: { allPages } }) => selectPagination(allPages, null, 'pageIds');
const getPages = createSelector(
  [getPagesPagination, getPageSelector],
  ({ pageIds }, getPage) => pageIds.map(getPage),
);


export default {
  getPageSelector,

  getPagesPagination,
  getPages,
};

import IconButton from '@mui/material/IconButton';
import SitemapIcon from 'components/Icons/Sitemap';
import React from 'react';
import useContactDirectoryQueryParams from './useContactDirectoryQueryParams';

const ContactDirectoryIconButton = (args) => {

  const { onSideOpenClick } = useContactDirectoryQueryParams();

  return (
    <IconButton onClick={onSideOpenClick} {...args}>
      <SitemapIcon />
    </IconButton>
  );
};

export default ContactDirectoryIconButton;

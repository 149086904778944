import emailValidator from 'validator/lib/isEmail';
import byteLengthValidator from 'validator/lib/isByteLength';
import i18n from 'providers/i18n';
import get from 'lodash/get';

export const isRequired = value => !!value;
isRequired.ruleName = 'isRequired';

export const isNotEmpty = value => !!value && value.length > 0;
isNotEmpty.ruleName = 'isNotEmpty';

export const isEmail = value => !value || emailValidator(value);
isEmail.ruleName = 'isEmail';

export const isRequiredWithout = withoutName => {
  const fn = (value, attribute, values) => isRequired(get(values, withoutName)) ? true : isRequired(value);
  fn.attributes = { withoutName };
  fn.ruleName = 'isRequiredWithout';
  return fn;
};

export const isGTE = min => {
  const fn = value => value >= min;
  fn.attributes = { min };
  fn.ruleName = 'isGTE';
  return fn;
};

export const minLength = min => {
  const fn = value => !value || byteLengthValidator(value, { min });
  fn.attributes = { min };
  fn.ruleName = 'minLength';
  return fn;
};

export const maxLength = max => {
  const fn = value => !value || byteLengthValidator(value, { max });
  fn.attributes = { max };
  fn.ruleName = 'maxLength';
  return fn;
};

const validateAttribute = (attribute, value, rules, values) => {
  const validators = Array.isArray(rules) ? rules : [rules];

  return validators.reduce((err, fn) => {
    if (err) {
      return err;
    }

    if (fn && !fn(value, attribute, values)) {
      return i18n.t([
        `validation:${attribute}.${fn.ruleName}`,
        `validation:${fn.ruleName}`,
      ], { attribute, value, attributes: fn.attributes });
    }

    return undefined;
  }, undefined);
};

export const validate = (data, rules) => {
  return Object.keys(rules).reduce((errors, attribute) => ({
    ...errors,
    [attribute]: validateAttribute(attribute, get(data, attribute), rules[attribute], data),
  }), {});
};

export const fieldValidator = (attribute, rules) => (value, values) => (
  i18n.loadNamespaces('validation')
    .then(() => validateAttribute(attribute, value, rules, values)));

export default rules => values => i18n.loadNamespaces('validation')
  .then(() => validate(values, rules));

import React from 'react';
import PropTypes from 'prop-types';
import { format as $format, isValid, differenceInSeconds, formatDistanceToNowStrict, getDateString } from 'services/date-fns';
import Time from './Time';

const DateFormat = ({ date: $date, format = 'PPPp', fromNowDuring = 0, addSuffix = true, ...rest }) => {

  const date = $date instanceof Date ? $date : new Date($date);

  if (!$date || !isValid(date)) {
    return null;
  }

  if (fromNowDuring) {
    const difference = Math.abs(differenceInSeconds(date, new Date()));

    if (difference < fromNowDuring || fromNowDuring === true) {
      return (
        <Time date={date} {...rest}>
          {formatDistanceToNowStrict(date, { addSuffix })}
        </Time>
      );
    }
  }

  if (typeof format === 'string' || format instanceof String) {
    return (
      <Time date={date} {...rest}>
        {$format(date, format)}
      </Time>
    );
  }

  // Assume object { year: numeric, ... }
  return (
    <Time date={date} {...rest}>
      {getDateString(date, format, 'PPPp')}
    </Time>
  );
};

DateFormat.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  format: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fromNowDuring: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]), /* in seconds */
  addSuffix: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default DateFormat;

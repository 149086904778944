
export default {

  getComment: 'comment/GET_COMMENT',
  getCommentSuccess: 'comment/GET_COMMENT_SUCCESS',
  getCommentFailure: 'comment/GET_COMMENT_FAILURE',

  removeLike: 'comment/REMOVE_LIKE',
  removeLikeSuccess: 'comment/REMOVE_LIKE_SUCCESS',
  removeLikeFailure: 'comment/REMOVE_LIKE_FAILURE',

  getLikes: 'comment/GET_LIKES',
  getLikesSuccess: 'comment/GET_LIKES_SUCCESS',
  getLikesFailure: 'comment/GET_LIKES_FAILURE',

  addLike: 'comment/ADD_LIKE',
  addLikeSuccess: 'comment/ADD_LIKE_SUCCESS',
  addLikeFailure: 'comment/ADD_LIKE_FAILURE',

  delete: 'comment/DELETE',
  deleteSuccess: 'comment/DELETE_SUCCESS',
  deleteFailure: 'comment/DELETE_FAILURE',

  update: 'comment/UPDATE',
  updateSuccess: 'comment/UPDATE_SUCCESS',
  updateFailure: 'comment/UPDATE_FAILURE',

  getTranslation: 'comment/GET_TRANSLATION',
  getTranslationSuccess: 'comment/GET_TRANSLATION_SUCCESS',
  getTranslationFailure: 'comment/GET_TRANSLATION_FAILURE',
};

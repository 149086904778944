import showdown from 'showdown';
import { sanitizePostBody } from 'services/sanitizationService';
import { htmlToText } from 'html-to-text';

/* eslint-disable no-undef, no-underscore-dangle, no-param-reassign */
const disableShowdownParser = name => {
  showdown.subParser(name, (text, options, globals) => {
    text = globals.converter._dispatch(`${name}.before`, text, options, globals);
    text = globals.converter._dispatch(`${name}.after`, text, options, globals);
    return text;
  });
};
/* eslint-enable no-undef, no-underscore-dangle, no-param-reassign */

[
  'blockQuotes', 'codeBlocks', 'codeSpans', 'ellipsis', 'emoji',
  'encodeCode', 'githubCodeBlocks', 'hashCodeTags', 'hashPreCodeTags',
  'headers', 'horizontalRule', 'images', 'italicsAndBold', 'strikethrough',
  'tables',
].forEach(disableShowdownParser);

export const postTextToHtml = text => {
  const options = {
    literalMidWordUnderscores: true,
    simplifiedAutoLink: true,
    excludeTrailingPunctuationFromURLs: true,
    ghCodeBlocks: false,
    openLinksInNewWindow: true,

    simpleLineBreaks: true,
  };

  const converter = new showdown.Converter(options);
  const html = converter.makeHtml(text);

  return sanitizePostBody(html);
};

export const postHtmlToText = text => {
  const options = {
    wordwrap: false,
    preserveNewlines: false,
    unorderedListItemPrefix: ' - ',
    hideLinkHrefIfSameAsText: true,
  };

  return htmlToText(text, options);
};

import { createSelector } from 'reselect';
import { selectPagination } from 'redux/helpers/pagination';
import get from 'lodash/get';
import memoize from 'lodash/memoize';

const getProfile = ({ workspace: { profile } }) => profile;

const getChannelEntities = ({ entities: { channels } }) => channels;
const getWorkspaceChannelIds = ({ workspace: { channelIds } }) => channelIds;

const getWorkspaceChannels = createSelector(
  [getChannelEntities, getWorkspaceChannelIds],
  (entities, channelIds) => channelIds.map(id => entities[id]),
);

const getWorkspaceChannel = ({ entities: { channels } }, id) => channels[id];

const getChannelSelector = createSelector(
  [getChannelEntities],
  (entities) => memoize(channelId => entities?.[channelId]),
);

const getGroupIds = ({ workspace: { groups } }) => groups;
const getGroups = ({ entities: { groups }, workspace: { groups: ids } }) => ids.map(id => groups[id]);

const getMemberIds = ({ workspace: { members } }) => members.all;

const getMemberEntities = ({ entities: { members } }) => members;

const getMembers = createSelector([getMemberIds, getMemberEntities], (memberIds, entities) => memberIds.map(id => entities[id]));

const getAllMemberIdsPagination = ({ workspace: { members: { all } } }) => selectPagination(all, null, 'memberIds');

const getMemberSelector = createSelector(
  [getMemberEntities],
  members => memoize(
    memberId => members?.[memberId],
  ),
);

const getMember = ({ entities: { members } }, id) => members?.[id];

const getLabelEntities = ({ entities: { labels } }) => labels;

const getLabelMembers = ({ workspace: { labelMembers } }) => labelMembers;
const getLabelMemberIds = createSelector(
  [getLabelMembers],
  labelMembers => memoize(
    labelId => labelMembers?.[labelId] ?? [],
  ),
);

const getLabels = createSelector([getLabelEntities], (labels) => Object.values(labels)?.filter(v => v.type === 'DEFAULT'));
const getSortedLabels = createSelector([getLabels], (labels) => labels.sort((a, b) => a.name.localeCompare(b.name)));
const getSortedLabelNames = createSelector([getSortedLabels], (labels) => labels.map(({ name }) => name));

const getAnalyticalLabels = createSelector([getLabelEntities], (labels) => Object.values(labels)?.filter(v => v.type === 'ANALYTICAL'));
const getSortedAnalyticalLabels = createSelector([getAnalyticalLabels], (labels) => labels.sort((a, b) => a.name.localeCompare(b.name)));
const getSortedAnalyticalLabelNames = createSelector([getSortedAnalyticalLabels], (labels) => labels.map(({ name }) => name));

const getLabelSelector = createSelector(
  [getLabelEntities],
  labels => memoize(
    (labelId) => labels?.[labelId],
  ),
);

const getAnalyticalLabelsLoading = ({ workspace: { analyticalLabelsLoading } }) => analyticalLabelsLoading;


const getInvitation = ({ workspace: { invitation } }) => invitation;

const getSettings = ({ workspace: { settings } }) => settings;

const getPulseSettings = ({ workspace: { pulseSettings } }) => pulseSettings;

const getUser = ({ workspace: { user } }) => user;

const getAllChallenges = ({ workspace: { challenges } }) => selectPagination(challenges, 'all', 'challengeIds');
const getActiveChallenges = ({ workspace: { challenges } }) => selectPagination(challenges, 'active', 'challengeIds');
const getFinishedChallenges = ({ workspace: { challenges } }) => selectPagination(challenges, 'finished', 'challengeIds');

const getChallenge = ({ entities: { challenges } }, id) => challenges[id];

const getChallengeTemplates = ({ workspace: { challenges: { templates } } }) => templates;

const getWorkspaceAnalyticsLoading = ({ workspace: { analytics } }, type) => analytics[type].loading;
const getWorkspaceAnalytics = ({ workspace: { analytics } }, type, period, labelId = null) => get(analytics, `${type}.${period}.${labelId || 'all'}`, undefined);

const getSearch = ({ workspace: { search } }) => selectPagination(search, null, 'results');
const getSearchAutocomplete = ({ workspace: { search: { autocomplete } } }) => autocomplete;

const getRoles = ({ workspace: { members: { roles: { all } } } }) => all;
const getRoleEntities = ({ entities: { roles } }) => roles;
const getRoleSelector = createSelector([getRoleEntities], roles => memoize((roleId) => roles?.[roleId]));
const getRolesLoading = ({ workspace: { members: { roles: { loading } } } }) => loading;

const getContactDirectoryItemList = ({ workspace: { contactDirectory: { list } } }) => selectPagination(list, null, 'items');
const getContactDirectoryMembersTree = ({ workspace: { contactDirectory: { tree } } }) => selectPagination(tree, null, 'items');

const getContactEntities = ({ entities: { contacts } }) => contacts;
const getContactSelector = createSelector([getContactEntities], contacts => memoize((contactId) => contacts?.[contactId]));

const getContactDirectoryItemSelector = createSelector(
  [getMemberSelector, getContactSelector],
  (memberSelector, contactSelector) => memoize((itemType, itemId) => {
    switch (itemType) {
      case 'member': return memberSelector(itemId);
      case 'contact': return contactSelector(itemId);
      default: return null;
    }
  }, (itemType, itemId) => itemType + itemId),
);

export default {
  getWorkspaceChannels,
  getWorkspaceChannel,

  getChannelSelector,

  getGroupIds,
  getGroups,

  getLabels,
  getSortedLabels,
  getSortedLabelNames,
  getLabelSelector,

  getLabelMemberIds,

  getMember,
  getMemberIds,
  getAllMemberIdsPagination,
  getMembers,
  getMemberSelector,

  getAnalyticalLabels,
  getSortedAnalyticalLabels,
  getSortedAnalyticalLabelNames,
  getAnalyticalLabelsLoading,

  getInvitation,

  getSettings,
  getPulseSettings,

  getUser,

  getProfile,

  getAllChallenges,
  getActiveChallenges,
  getFinishedChallenges,
  getChallenge,
  getChallengeTemplates,

  getWorkspaceAnalytics,
  getWorkspaceAnalyticsLoading,

  getSearch,
  getSearchAutocomplete,

  getRoles,
  getRolesLoading,
  getRoleSelector,

  getContactDirectoryItemList,
  getContactDirectoryMembersTree,
  getContactDirectoryItemSelector,
};

import combineReducers from 'combine-reducers-global-state';
import { addToPagination } from 'redux/helpers/pagination';
import groupTypes from './types';

const membersByGroup = (state = {}, { type, payload }) => {
  switch (type) {
    case groupTypes.getMembersSuccess:
      return addToPagination(state, payload.groupId, payload, 'memberIds');
    default:
      return state;
  }
};

const groupReducer = combineReducers({
  membersByGroup,
});

export default { group: groupReducer };

import { schema } from 'normalizr';
import activityCategory from './activityCategory';
import channel from './channel';

const challengeSchema = new schema.Entity('challenges', {
  channel,
  categories: [activityCategory],
});

export default challengeSchema;

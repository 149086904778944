import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getPages: data => createAction(types.getPages, data),
  getPage: (data) => createAction(types.getPage, data),
  update: (data) => createAction(types.update, data),
  store: (data) => createAction(types.store, data),
  patch: (data) => createAction(types.patch, data),
  delete: (data) => createAction(types.delete, data),
};

import React, { useRef, useState, useContext } from 'react';
import { useField, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Grid from 'components/Grid';
import Button from 'components/Button';
import OverflowMenu from 'components/OverflowMenu';
import { isMobile } from 'helpers';
import ErrorIcon from '@mui/icons-material/Error';
import PinIcon from 'components/Icons/Pin';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import PostFormFooter from '../Partials/PostFormFooter';
import useImportantButtonProps from './Fields/useImportantButtonProps';
import useMediaButtonProps from './Fields/useMediaButtonProps';
import useAttachmentsButtonProps from './Fields/useAttachmentsButtonProps';
import usePollButtonProps from './Fields/usePollButtonProps';
import usePinButtonProps from './Fields/usePinButtonProps';
import useQuizButtonProps from './Fields/useQuizButtonProps';
import PostFormContext from '../PostFormContext';
import useSmsButtonProps from './Fields/useSmsButtonProps';

const importantProps = {
  key: 'important',
  ...(isMobile && { startIcon: <ErrorIcon /> }),
};

const attachmentProps = {
  key: 'attachments',
  name: 'attachments',
};

const pinProps = {
  key: 'pin',
  ...(isMobile && { startIcon: <PinIcon /> }),
};

const BaseViewFooter = () => {
  const { t } = useTranslation(['component']);

  const { disableScheduling } = useContext(PostFormContext);

  const form = useForm();
  const { submit } = form;
  const { submitting } = useFormState();

  const attachments = useAttachmentsButtonProps(attachmentProps);
  const important = useImportantButtonProps(importantProps);
  const media = useMediaButtonProps({ key: 'media' });
  const poll = usePollButtonProps({ key: 'poll' });
  const quiz = useQuizButtonProps({ key: 'quiz' });
  const pin = usePinButtonProps(pinProps);
  const sms = useSmsButtonProps({ key: 'sms' });

  const { input: { value: schedule, onChange: setSchedule } } = useField('$schedule');
  const { input: { onChange: setDraft } } = useField('is_draft');

  const anchorRef = useRef();
  const [open, setOpen] = useState(false);

  const gridContainer = isMobile
    ? { container: true, direction: 'column', wrap: 'nowrap', spacing: 5 }
    : { container: true, justify: 'space-between', wrap: 'nowrap', spacing: 2 };

  const gridItemFirst = isMobile
    ? { item: true, xs: 12 } // On mobile always separate rows
    : { item: true, xs: 12, zeroMinWidth: true }; // On web take as much space as possible

  const gridItemSecond = isMobile
    ? { item: true, xs: 12 } // On mobile always separate rows
    : { item: true };

  const onSubmitClick = () => {
    setDraft(false); // There's another function to save a draft. This one should always be false
    submit();
  };

  const onScheduleToggle = () => {
    if (!schedule) {
      form.change('scheduledAt', null);
    }
    setSchedule(!schedule);
    setOpen(false);
  };

  const onDraftChoose = () => {
    setDraft(true);
    submit();
  };

  const submitText = schedule
    ? t('newPostDialog.scheduleButton')
    : t('newPostDialog.shareButton');

  const menuItems = [media, attachments, poll, quiz, important, pin, sms].filter(item => item.hidden !== true);

  return (
    <PostFormFooter>
      <Grid container {...gridContainer}>
        <Grid {...gridItemFirst}>
          <OverflowMenu items={menuItems} variant={isMobile ? 'drawer' : undefined} />
        </Grid>
        <Grid {...gridItemSecond}>
          <ButtonGroup variant="contained" color="primary" disabled={submitting}>
            <Button type="submit" size={isMobile ? 'large' : undefined} fullWidth={isMobile} onClick={onSubmitClick} loading={submitting} nowrap>{submitText}</Button>
            {!disableScheduling && (
              <Button type="button" ref={anchorRef} size="small" aria-haspopup="menu" style={{ paddingLeft: 4, paddingRight: 4, minWidth: 'initial' }} onClick={() => setOpen(o => !o)}>
                <ArrowDropDownIcon />
              </Button>
            )}
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} transition placement="bottom-end" style={{ zIndex: 1300 }}>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <MenuList>
                      <MenuItem onClick={onScheduleToggle}>{schedule ? t('newPostDialog.removeSchedule') : t('newPostDialog.scheduleForFuture')}</MenuItem>
                      <MenuItem onClick={onDraftChoose}>{t('newPostDialog.saveAsDraft')}</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </PostFormFooter>
  );
};

export default BaseViewFooter;

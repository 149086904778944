import memoize from 'lodash/memoize';
import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';

const getRecordTypes = ({ drive: { recordTypes } }) => recordTypes;

const getRecords = ({ drive: { records } }) => selectPagination(records, 'all', 'recordIds');
const getRecordsByType = createSelector(
  [state => state.drive.records],
  records => memoize(
    type => selectPagination(records, type || 'all', 'recordIds'),
  ),
);

const getRecordEntities = ({ entities: { records } }) => records;
const getRecordSelector = createSelector(
  [getRecordEntities],
  records => memoize(
    recordId => records[recordId],
  ),
);

export default {
  getRecordTypes,

  getRecords,
  getRecordsByType,

  getRecordSelector,
};

import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getContract: data => createAction(types.getContract, data),
  getContractSuccess: data => createAction(types.getContractSuccess, data),
  getContractFailure: data => createAction(types.getContractFailure, data),

  downloadContract: data => createAction(types.downloadContract, data),
  downloadContractSuccess: data => createAction(types.downloadContractSuccess, data),
  downloadContractFailure: data => createAction(types.downloadContractFailure, data),

  getWorkspaceContracts: data => createAction(types.getWorkspaceContracts, data),
  getWorkspaceContractsSuccess: data => createAction(types.getWorkspaceContractsSuccess, data),
  getWorkspaceContractsFailure: data => createAction(types.getWorkspaceContractsFailure, data),

  downloadWorkspaceContract: data => createAction(types.downloadWorkspaceContract, data),
  downloadWorkspaceContractSuccess: data => createAction(types.downloadWorkspaceContractSuccess, data),
  downloadWorkspaceContractFailure: data => createAction(types.downloadWorkspaceContractFailure, data),

  acceptWorkspaceContract: data => createAction(types.acceptWorkspaceContract, data),
  acceptWorkspaceContractSuccess: data => createAction(types.acceptWorkspaceContractSuccess, data),
  acceptWorkspaceContractFailure: data => createAction(types.acceptWorkspaceContractFailure, data),
};

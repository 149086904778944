import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getLessonsByCourseVariantForMember: data => createAction(types.getLessonsByCourseVariantForMember, data),
  getLessonsByCourseVariantForMemberSuccess: data => createAction(types.getLessonsByCourseVariantForMemberSuccess, data),
  getLessonsByCourseVariantForMemberFailure: error => createAction(types.getLessonsByCourseVariantForMemberFailure, error),

  getLessonForMember: data => createAction(types.getLessonForMember, data),
  getLessonForMemberSuccess: data => createAction(types.getLessonForMemberSuccess, data),
  getLessonForMemberFailure: error => createAction(types.getLessonForMemberFailure, error),

  getLessonsByCourseVariantForAdmin: data => createAction(types.getLessonsByCourseVariantForAdmin, data),
  getLessonsByCourseVariantForAdminSuccess: data => createAction(types.getLessonsByCourseVariantForAdminSuccess, data),
  getLessonsByCourseVariantForAdminFailure: error => createAction(types.getLessonsByCourseVariantForAdminFailure, error),

  getLessonForAdmin: data => createAction(types.getLessonForAdmin, data),
  getLessonForAdminSuccess: data => createAction(types.getLessonForAdminSuccess, data),
  getLessonForAdminFailure: error => createAction(types.getLessonForAdminFailure, error),

  postLessonImageForAdmin: data => createAction(types.postLessonImageForAdmin, data),
  postLessonImageForAdminSuccess: data => createAction(types.postLessonImageForAdminSuccess, data),
  postLessonImageForAdminFailure: error => createAction(types.postLessonImageForAdminFailure, error),

  postLessonForAdmin: data => createAction(types.postLessonForAdmin, data),
  postLessonForAdminSuccess: data => createAction(types.postLessonForAdminSuccess, data),
  postLessonForAdminFailure: error => createAction(types.postLessonForAdminFailure, error),

  putLessonForAdmin: data => createAction(types.putLessonForAdmin, data),
  putLessonForAdminSuccess: data => createAction(types.putLessonForAdminSuccess, data),
  putLessonForAdminFailure: error => createAction(types.putLessonForAdminFailure, error),

  deleteLessonForAdmin: data => createAction(types.deleteLessonForAdmin, data),
  deleteLessonForAdminSuccess: data => createAction(types.deleteLessonForAdminSuccess, data),
  deleteLessonForAdminFailure: error => createAction(types.deleteLessonForAdminFailure, error),

  reorderLessonsForAdmin: data => createAction(types.reorderLessonsForAdmin, data),
  reorderLessonsForAdminSuccess: data => createAction(types.reorderLessonsForAdminSuccess, data),
  reorderLessonsForAdminFailure: data => createAction(types.reorderLessonsForAdminFailure, data),
};

import folderTypes from '../documentFolder/types';
import sectionTypes from '../documentSection/types';

const documentFolderVariantsReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case sectionTypes.deleteSectionForAdminSuccess:
      // eslint-disable-next-line eqeqeq
      return { ...state, [payload.folderVariantId]: { ...state[payload.folderVariantId], sections: state[payload.folderVariantId].sections.filter((value) => value != payload.sectionId) } };

    case folderTypes.deleteFolderVariantForAdminSuccess:
    {
      const nextState = { ...state };
      delete nextState[payload.folderVariantId];
      return nextState;
    }

    default:
      return state;
  }
};

export default documentFolderVariantsReducer;

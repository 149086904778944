export default {
  getEvent: 'calendar/GET_EVENT',
  getEventSuccess: 'calendar/GET_EVENT_SUCCESS',
  getEventFailure: 'calendar/GET_EVENT_FAILURE',

  createEvent: 'calendar/CREATE_EVENT',
  createEventSuccess: 'calendar/CREATE_EVENT_SUCCESS',
  createEventFailure: 'calendar/CREATE_EVENT_FAILURE',

  updateEvent: 'calendar/UPDATE_EVENT',
  updateEventSuccess: 'calendar/UPDATE_EVENT_SUCCESS',
  updateEventFailure: 'calendar/UPDATE_EVENT_FAILURE',

  deleteEvent: 'calendar/DELETE_EVENT',
  deleteEventSuccess: 'calendar/DELETE_EVENT_SUCCESS',
  deleteEventFailure: 'calendar/DELETE_EVENT_FAILURE',

  downloadIcs: 'calendar/DOWNLOAD_ICS',
  downloadIcsSuccess: 'calendar/DOWNLOAD_ICS_SUCCESS',
  downloadIcsFailure: 'calendar/DOWNLOAD_ICS_FAILURE',

  getEvents: 'calendar/GET_EVENTS',
  getEventsSuccess: 'calendar/GET_EVENTS_SUCCESS',
  getEventsFailure: 'calendar/GET_EVENTS_FAILURE',
};

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EcoRoundedIcon = args => (
  <SvgIcon {...args}>
    <svg className="MuiSvgIcon-root jss174" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M6.05 8.05c-2.73 2.73-2.73 7.15-.02 9.88 1.47-3.4 4.09-6.24 7.36-7.93-2.77 2.34-4.71 5.61-5.39 9.32 2.6 1.23 5.8.78 7.95-1.37C19.43 14.47 20 4 20 4S9.53 4.57 6.05 8.05z" /></svg>
  </SvgIcon>
);

export default EcoRoundedIcon;

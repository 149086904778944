import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import NavLink from 'components/NavLink';
import Linkify from 'linkify-react';
import CommentContext from '../CommentContext';
import CommentTranslateButton from './CommentTranslateButton';

const Wrapper = styled('div')`
  margin: 0.5rem 0;
`;

const CommentStyles = styled('div')`
  display: inline;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;

  a {
    padding: 0.5rem;
    margin: -0.5rem;
  }

  a:link, a:visited, a:hover, a:active {
    color: #15c;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const Author = styled('span')`
  font-weight: 700;
  color: inherit;
  text-decoration: none;

  margin-right: 0.4em;
`;

const ButtonRow = styled('div')`
  margin: 0.5rem 0px;
`;

const linkifyOptions = {
  target: { url: '_blank' },
  attributes: { rel: 'noopener noreferrer nofollow' },
};

const CommentBody = ({ body, name, url, translatable, onTranslateClick, translated, ...rest }) => {

  const { isMe } = useContext(CommentContext);

  return (
    <Wrapper {...rest}>
      <Author translate="no" hidden={isMe} as={url ? NavLink : undefined} to={url} tabIndex={-1}>{name}</Author>
      <CommentStyles>
        <Linkify options={linkifyOptions}>{body}</Linkify>
      </CommentStyles>
      {translatable && (
        <ButtonRow>
          <CommentTranslateButton active={translated} onClick={onTranslateClick} />
        </ButtonRow>
      )}
    </Wrapper>
  );
};

CommentBody.propTypes = {
  body: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  isMe: PropTypes.bool,

  translatable: PropTypes.bool,
  translated: PropTypes.bool,
  onTranslateClick: PropTypes.func,
};

export default CommentBody;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { isMobile } from 'helpers';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Stack from '@mui/material/Stack';

const wrapperConfig = {
  shouldForwardProp: prop => !['hasSubtitle'].includes(prop),
};

const Wrapper = styled('div', wrapperConfig)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  border-bottom: 2px solid #eee;
  padding: ${isMobile ? '1rem' : '1rem 1.5rem'};
  min-height: 4.5rem;

  ${({ hasSubtitle }) => hasSubtitle ? 'padding: 1rem 1.5rem;' : ''}
`;

const Left = styled('div')`
  margin-right: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Right = styled('div')`
  display: flex;
  align-items: center;
`;

const Title = styled('h3')`
  margin: 0.5rem 0;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
`;

const Subtitle = styled('div')`
  font-size: 0.9rem;

  ${Title} + & {
    margin-top: -0.25rem;
    margin-bottom: 0.5rem;
  }
`;

const PaperTitle = ({ title, subtitle, children, onBackClick, ...rest }) => (
  <Wrapper hasSubtitle={!!subtitle} {...rest}>
    <Left>
      <Stack direction="row" alignItems="center" spacing={1}>
        {onBackClick && (<IconButton size="small" edge="start" onClick={onBackClick}><ArrowBackIosRoundedIcon /></IconButton>)}
        <Title>{title}</Title>
      </Stack>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Left>
    <Right>
      {children}
    </Right>
  </Wrapper>
);

PaperTitle.propTypes = {
  onBackClick: PropTypes.func,
  title: PropTypes.any,
  subtitle: PropTypes.any,
};

export default PaperTitle;

/* eslint-disable no-console */
import uniqueId from 'lodash/uniqueId';
import reactAdapter from './adapter';
import ActionHandler from './actionHandler';

export const createMiddleware = () => {
  const actionHandler = new ActionHandler();

  const middleware = (store) => {
    const dispatchImmediate = (action) => {
      setTimeout(() => store.dispatch(action), 0);
    };

    return (next) => (action) => {
      actionHandler.listeners.forEach(({ type, listener }) => {
        const isMatch = type === action.type;
        const isArrayMatch = type.constructor === Array && type?.indexOf(action.type) > -1;

        if (!isMatch && !isArrayMatch) {
          return;
        }

        listener(dispatchImmediate, action);
      });

      next(action);
    };
  };

  middleware.listeners = actionHandler.listeners;
  middleware.addListener = (type, listener) => {
    const hash = uniqueId();
    actionHandler.addListener(hash, type, listener);
  };
  middleware.actionHandler = actionHandler;
  middleware.cleanup = actionHandler.cleanup;

  reactAdapter(middleware.actionHandler);

  return middleware;
};

export default createMiddleware;

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { postSelectors } from 'redux/post';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import PostContext from 'components/Post/PostContext';
import ColorfulButton from 'components/ColorfulButton';
import TranslateIcon from 'components/Icons/Translate';
import ReactionBar from 'components/ReactionBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from 'components/Button';
import PostMenu from './PostMenu';
import PostReactionButton from './PostReactionButton';
import PostCommentButton from './PostCommentButton';

const Wrapper = styled('div')`
`;

const TopRow = styled('div')`
  margin: 0.25rem 0;
  padding: 0 1em;
`;

const BottomRow = styled('div')`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0.1rem 0.25rem 0.1rem 0.75rem;
  ${({ darker }) => darker && 'border-top: 1px solid rgba(0, 0, 0, 0.12)'};
`;

const PostFooter = ({ postId, ...rest }) => {
  const { t } = useTranslation(['component']);

  const isSmallScreen = useMediaQuery('(max-width: 359px');
  const translateOnFirstRow = useMediaQuery('(max-width: 499px)');

  const { isImportant, isCompact, translated, setTranslated, hasMenu } = useContext(PostContext);

  const post = useSelector(state => postSelectors.getPost(state, postId));

  if (!post) {
    return null;
  }

  const canLike = post.permissions.like;
  const canComment = post.permissions.comment;

  const showLikeButton = canLike; // canLike also incorporates the channel/post specific like's disable
  const showCommentButton = isCompact
    ? post.show?.comments // Feed view: Only hide the button, when all comments for post are disabled
    : canComment; // Post view: Without permission to comment

  const darker = isImportant && isCompact;

  const translateProps = {
    startIcon: <TranslateIcon />,
    onClick: () => setTranslated(current => !current),
    children: translated ? t('component:translation.translated') : t('component:translation.translate'),
  };

  if (!post.reactions && !post.translatable && !showLikeButton && !showCommentButton && !hasMenu) {
    return null;
  }

  return (
    <Wrapper {...rest}>

      {(post.reactions || (post.translatable && translateOnFirstRow))
        && (
          <TopRow>

            <Grid container alignItems="center" wrap="nowrap" spacing={1}>
              {post.reactions && (
                <Grid item>
                  <ReactionBar reactionableType="post" reactionableId={postId} reactions={post.reactions} viewReactions={post.permissions.viewReactions} />
                </Grid>
              )}

              {translateOnFirstRow && post.translatable && (
                <Grid item style={{ marginLeft: 'auto' }}>
                  <Button variant="text" color="inherit" size="small" {...translateProps} />
                </Grid>
              )}
            </Grid>
          </TopRow>
        )}

      {(showLikeButton || showCommentButton || post.translatable || hasMenu) && (
        <BottomRow darker={darker}>
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" spacing={isSmallScreen ? 0 : 1}>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap" spacing={isSmallScreen ? 0 : 1}>
                {showLikeButton && (
                  <Grid item>
                    <PostReactionButton postId={postId} size={isSmallScreen ? 'small' : 'medium'} />
                  </Grid>
                )}

                {/* TODO: Focus comment input if in correct view */}
                {showCommentButton && (
                  <Grid item style={{ marginRight: 'auto' }} zeroMinWidth>
                    <PostCommentButton postId={postId} size={isSmallScreen ? 'small' : 'medium'} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap" spacing={isSmallScreen ? 0 : 1}>
                {!translateOnFirstRow && post.translatable && (
                  <Grid item>
                    <ColorfulButton {...translateProps} size={isSmallScreen ? 'small' : 'medium'} />
                  </Grid>
                )}

                <PostMenu postId={postId} />
              </Grid>
            </Grid>
          </Grid>
        </BottomRow>
      )}
    </Wrapper>
  );
};

PostFooter.propTypes = {
  postId: PropTypes.any,
};

export default PostFooter;

import React from 'react';
import PropTypes from 'prop-types';
import { format as $format, isValid, formatRFC3339 } from 'services/date-fns';

const Time = ({ date: $date, showTitle = true, ...rest }) => {

  const date = $date instanceof Date ? $date : new Date($date);

  if (!$date || !isValid(date)) {
    return null;
  }

  const dateTime = formatRFC3339(date);
  const title = showTitle ? $format(date, 'PPPp') : null;

  return <time dateTime={dateTime} title={title} {...rest} />;
};

Time.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  showTitle: PropTypes.bool,
};

export default Time;

import React from 'react';
import Grid from 'components/Grid';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { commentSelectors, commentActions } from 'redux/comment';
import ReactionBar from 'components/ReactionBar';
import ReactionButton from 'components/ReactionButton';
import reactionService from 'services/reactionService';
import CommentDate from 'components/Comment/Partials/CommentDate';
import CommentFooterComponent from 'components/Comment/CommentFooter';
import CommentMenu from 'components/Comment/Partials/CommentMenu';

const CommentFooter = ({ commentId, onUpdateClick, onDeleteClick }) => {

  const dispatch = useDispatch();

  const comment = useSelector(state => commentSelectors.getComment(state, commentId));

  if (!comment) {
    return null;
  }

  return (
    <CommentFooterComponent
      left={(
        <Grid container spacing={0.5} wrap="nowrap" alignItems="center">

          {comment.permissions?.react && (
            <Grid item>
              <ReactionButton
                size="small"
                reactions={reactionService.commentReactionsArray}
                selected={comment.userReaction}
                onReactionAdd={type => dispatch(commentActions.addLike({ commentId, type }))}
                onReactionRemove={() => dispatch(commentActions.removeLike({ commentId }))}
              />
            </Grid>
          )}

          {comment.reactions && (
            <Grid item>
              <ReactionBar compact reactionableType="comment" reactionableId={commentId} reactions={comment.reactions} viewReactions={comment.permissions.viewReactions} />
            </Grid>
          )}

        </Grid>
      )}
      right={(
        <Grid container spacing={1} wrap="nowrap" alignItems="center">

          <Grid item>
            <CommentDate date={comment.commentedAt} />
          </Grid>

          {(comment?.permissions?.update || comment?.permissions?.delete) && (
            <Grid item>
              <CommentMenu
                onUpdateClick={comment?.permissions?.update ? onUpdateClick : undefined}
                onDeleteClick={comment?.permissions?.delete ? onDeleteClick : undefined}
              />
            </Grid>
          )}

        </Grid>
      )}
    />
  );

};

CommentFooter.propTypes = {
  commentId: PropTypes.any.isRequired,

  onUpdateClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default CommentFooter;

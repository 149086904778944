import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  // SINGLE RECORD REQUESTS
  getRecord: data => createAction(types.getRecord, data),
  getRecordSuccess: data => createAction(types.getRecordSuccess, data),
  getRecordFailure: data => createAction(types.getRecordFailure, data),

  createRecord: data => createAction(types.createRecord, data),
  createRecordSuccess: data => createAction(types.createRecordSuccess, data),
  createRecordFailure: error => createAction(types.createRecordFailure, error),

  updateRecord: data => createAction(types.updateRecord, data),
  updateRecordSuccess: data => createAction(types.updateRecordSuccess, data),
  updateRecordFailure: data => createAction(types.updateRecordFailure, data),

  deleteRecord: data => createAction(types.deleteRecord, data),
  deleteRecordSuccess: data => createAction(types.deleteRecordSuccess, data),
  deleteRecordFailure: data => createAction(types.deleteRecordFailure, data),

  // TYPES
  getRecordTypes: data => createAction(types.getRecordTypes, data),
  getRecordTypesSuccess: data => createAction(types.getRecordTypesSuccess, data),
  getRecordTypesFailure: data => createAction(types.getRecordTypesFailure, data),

  // INDEX REQUESTS
  getRecords: data => createAction(types.getRecords, data),
  getRecordsSuccess: data => createAction(types.getRecordsSuccess, data),
  getRecordsFailure: data => createAction(types.getRecordsFailure, data),
};

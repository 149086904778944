import React, { useEffect, useState, useMemo } from 'react';
import { authSelectors } from 'redux/auth';
import { useSelector } from 'react-redux';
import config from 'config';
import { isMobile } from 'helpers';
import useFeature from 'hooks/useFeature';

const fallbackUser = {};
const fallbackIntercom = {};
const fallbackWorkspace = {};

const IntercomProvider = args => {

  const [deviceReady, setDeviceReady] = useState(false);
  const user = useSelector(authSelectors.getMeUser) || fallbackUser;
  const intercom = useSelector(authSelectors.getIntercom) || fallbackIntercom;
  const workspace = useSelector(authSelectors.getWorkspace) || fallbackWorkspace;

  const intercomFeature = useFeature('intercom');

  const userData = useMemo(() => ({
    name: user.name,
    language_override: user.locale,

    ...(user.avatar && {
      avatar: {
        type: 'avatar',
        image_url: user.avatar,
      },
    }),

    ...(workspace.id && {
      role_in_app: workspace.role,
      company: {
        company_id: workspace.id,
        name: workspace.name,
      },
    }),
  }), [workspace, user]);

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('deviceready', () => setDeviceReady(true), { passive: true });
    }
  }, []);

  // MOBILE
  if (isMobile && deviceReady && window.intercom && intercomFeature.enabled) {
    if (!user.id || !window.device) {
      window.intercom.registerUnidentifiedUser();
    } else {
      window.intercom.registerIdentifiedUser({ userId: user.id });
      window.intercom.setUserHash(
        window.device.platform && window.device.platform.toLowerCase() === 'android'
          ? intercom.android
          : intercom.ios,
      );
      window.intercom.updateUser(userData);
    }
  }

  const webData = useMemo(() => ({
    user_id: intercom.web ? user.id : null,
    user_hash: intercom.web ? intercom.web : null,
    ...userData,
  }), [intercom.web, user.id, userData]);

  // If user.id changes, always clean up before booting
  useEffect(() => {
    if (isMobile || !window.Intercom || !intercomFeature.enabled) return;

    if (!window.Intercom.booted) {
      window.Intercom('boot', { app_id: config.intercom.appId }); // Shutdown doesn't work without boot.
    }
    window.Intercom('shutdown'); // What we actually want is it to simply get rid of the data, cookies etc
  }, [intercomFeature.enabled, user.id]);

  useEffect(() => {
    if (isMobile || !window.Intercom || !intercomFeature.enabled) return;

    window.Intercom('boot', { app_id: config.intercom.appId, ...webData });
  }, [intercomFeature.enabled, user.id, webData]);

  // Update data
  useEffect(() => {
    if (isMobile || !window.Intercom || !intercomFeature.enabled) return;
    window.Intercom('update', webData);
  }, [intercomFeature.enabled, user.id, webData]);

  return <React.Fragment {...args} />;
};

export default IntercomProvider;

import React, { createRef, useContext, useRef, useEffect, useMemo } from 'react';
import Grid from 'components/Grid';
import Button from 'components/Button';
import FormField from 'components/FinalForm/Fields/FormField';
import { useFieldArray } from 'react-final-form-arrays';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TrashIcon from 'components/Icons/Trash';
import { useForm, useFormState } from 'react-final-form';
import DateTimePickerField from 'components/FinalForm/Fields/DateTimePickerField';
import { startOfDay, endOfMonth } from 'services/date-fns';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';
import PostFormContent from '../Partials/PostFormContent';
import PostFormContext from '../PostFormContext';
import PollViewContext from './PollViewContext';

const RemoveOptionAdornment = args => (
  <InputAdornment position="end">
    <IconButton color="inherit" edge="end" {...args}>
      <TrashIcon fontSize="small" />
    </IconButton>
  </InputAdornment>
);

const minDate = startOfDay(new Date());

export function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

const optionValidation = (current, values, idx, t) => {

  if (values.filter(({ option }) => !!option).length === 0 && idx === 0) {
    return t('pollForm.noValidOptions');
  }

  const trimmed = values.map(({ option }) => option ? option.trim() : option).filter(v => !!v);

  if (!hasDuplicates(trimmed)) {
    return undefined;
  }

  if (hasDuplicates(trimmed) && trimmed.filter(option => option === current).length > 1 && idx !== 0) {
    return t('pollForm.duplicatesError');
  }

  return undefined;
};

const PollViewContent = () => {

  const { t } = useTranslation('component');

  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });

  const { editing } = useContext(PostFormContext);
  const { closeAutomatically } = useContext(PollViewContext);

  const form = useForm();

  const initialValue = useMemo(() => (!form.getState().initialValues?.options || form.getState().initialValues?.options.length === 0)
    ? [{ option: '' }, { option: '' }]
    : form.getState().initialValues?.options, []); // eslint-disable-line

  const { fields } = useFieldArray('options', {
    initialValue,
  });

  const inputRefs = useRef([]);

  useEffect(() => {
    const { values: { closeAt: closeAtValue } } = form.getState();
    if (!closeAutomatically) {
      form.change('closeAt', undefined);
    } else if (!closeAtValue) {
      form.change('closeAt', endOfMonth(new Date()));
    }
  }, [closeAutomatically, form]);

  const getOrCreateInputRef = idx => {
    if (!inputRefs.current[idx]) {
      inputRefs.current[idx] = createRef();
    }

    return inputRefs.current[idx];
  };

  const focusInput = idx => {
    const ref = inputRefs.current[idx];
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  const isFieldEmpty = idx => !fields.value[idx].option || fields.value[idx].option === '';

  const onEnterKeyPress = (e, idx) => {
    if (isFieldEmpty(idx)) {
      return; // Current field is empty. Ignore enter
    }

    const lastIndex = fields.length - 1;

    if (lastIndex >= idx + 1 && isFieldEmpty(idx + 1)) {
      // If next field is empty, then don't add new
    } else {
      fields.insertAt(idx + 1, { option: '' });
    }

    setTimeout(() => focusInput(idx + 1));
  };

  const onDeleteKeyPress = (e, idx) => {
    if (isFieldEmpty(idx) && fields.length > 1) {
      fields.remove(idx);
      setTimeout(() => focusInput(idx - 1), 0);
    }
  };

  const onKeyPress = (e, idx) => {
    if (e.key === 'Enter') {
      onEnterKeyPress(e, idx);
    }
  };

  const onKeyDown = (e, idx) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      onDeleteKeyPress(e, idx);
    }
  };

  return (
    <PostFormContent>
      <Grid container direction="column" wrap="nowrap" spacing={2}>

        {closeAutomatically && (
          <Grid item>
            <DateTimePickerField
              name="closeAt"
              label={t('pollForm.endDateLabel')}
              placeholder={t('pollForm.endDatePlaceholder')}
              margin="dense"

              showTodayButton
              autoOk
              minDate={!editing ? minDate : undefined}
            />
          </Grid>
        )}

        <Grid item>
          <FormField name="question" label={t('pollForm.questionLabel')} placeholder={t('pollForm.questionPlaceholder')} margin="dense" inputProps={values.question ? { style: { fontWeight: 600 } } : {}} />
        </Grid>

        {fields.map((fieldName, idx) => (
          <Grid item key={fieldName}>
            <FormField
              name={`${fieldName}.option`}
              label={t('pollForm.nthOptionLabel', { count: idx + 1 })}
              placeholder={t('pollForm.nthOptionPlaceholder', { count: idx + 1 })}
              margin="dense"
              inputRef={getOrCreateInputRef(idx)}
              InputProps={{
                // Tabindex -1, since this can also be done with backspace & delete.
                // Feels better, if tab takes to next field
                endAdornment: <RemoveOptionAdornment onClick={() => fields.remove(idx)} tabIndex={-1} />,
              }}
              onKeyPress={e => onKeyPress(e, idx)}
              onKeyDown={e => onKeyDown(e, idx)}
              validate={(value, allValues) => optionValidation(value, allValues.options, idx, t)}
            />
          </Grid>
        ))}

        <Grid item>
          <Button color="default" size="small" onClick={() => fields.push({ option: '' })}>{t('pollForm.addOption')}</Button>
        </Grid>

        {form.getState().error && (
          <Grid item>
            <FormHelperText error>
              {form.getState().error}
            </FormHelperText>
          </Grid>
        )}

      </Grid>
    </PostFormContent>
  );
};

export default PollViewContent;

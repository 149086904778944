export default {
  getQuestionsByLessonForMember: 'question/GET_QUESTIONS_BY_LESSON_FOR_MEMBER',
  getQuestionsByLessonForMemberSuccess: 'question/GET_QUESTIONS_BY_LESSON_FOR_MEMBER_SUCCESS',
  getQuestionsByLessonForMemberFailure: 'question/GET_QUESTIONS_BY_LESSON_FOR_MEMBER_FAILURE',

  getQuestionForMember: 'question/GET_QUESTION_FOR_MEMBER',
  getQuestionForMemberSuccess: 'question/GET_QUESTION_FOR_MEMBER_SUCCESS',
  getQuestionForMemberFailure: 'question/GET_QUESTION_FOR_MEMBER_FAILURE',

  postQuestionAnswerForMember: 'question/POST_QUESTION_ANSWER_FOR_MEMBER',
  postQuestionAnswerForMemberSuccess: 'question/POST_QUESTION_ANSWER_FOR_MEMBER_SUCCESS',
  postQuestionAnswerForMemberFailure: 'question/POST_QUESTION_ANSWER_FOR_MEMBER_FAILURE',
  postQuestionAnswerForMemberReset: 'question/POST_QUESTION_ANSWER_FOR_MEMBER_RESET',
};

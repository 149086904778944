import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { isMobile } from 'helpers';

const mobilePaddingSnippet = (value) => css`
  padding-left: ${value};
  padding-right: ${value};

  @supports((padding: constant(safe-area-inset-right))) {
    padding-left: calc(${value} + constant(safe-area-inset-left));
    padding-right: calc(${value} + constant(safe-area-inset-right));
  }

  @supports(padding: env(safe-area-inset-right)) {
    padding-left: calc(${value} + env(safe-area-inset-left));
    padding-right: calc(${value} + env(safe-area-inset-right));
  }
`;

const MaxWidthContainer = styled('div')`
  position: relative;

  max-width: ${({ theme }) => theme.maxWidth};
  width: 100%;
  margin: 0 auto;

  ${({ disableGutters }) => !disableGutters && css`

    padding-left: 1rem;
    padding-right: 1rem;

    ${isMobile && css`

      ${mobilePaddingSnippet('1.5rem')}

      @media(max-width: 420px) {
        ${mobilePaddingSnippet('1rem')}
      }

      @media(max-width: 360px) {
        ${mobilePaddingSnippet('0.5rem')}
      }
    `}
  `}
`;

export default MaxWidthContainer;

import styled from '@emotion/styled/macro';

const CardContent = styled('div')`
  padding: 1rem;

  @media (max-width: 420px) {
    padding: 0.75rem;
  }

  @media (max-width: 320px) {
    padding: 0.5rem;
  }
`;

export default CardContent;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { boostActions, boostSelectors } from 'redux/boost';
import styled from '@emotion/styled/macro';
import Boost from 'components/Boost';
import { useTranslation } from 'react-i18next';
import Grid from 'components/Grid';
import SectionTitle from 'components/SectionTitle';
import useFeature from 'hooks/useFeature';

const StyledBoost = styled(Boost)`
  height: 100%;

  @media( max-width: 320px ) {
    min-height: 0;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 42px;

    span:last-child { margin-top: 0; margin-left: 0.5rem; font-size: 14px !important; }
    img { height: 100%;  }
  }
`;

const GridContainer = styled(Grid)`
  width: calc(100% + 1rem);
  margin: -0.5rem;

  @media( max-width: 360px ) { width: calc(100% + 0.4rem); margin: -0.2rem; }
  @media( max-width: 320px ) { flex-direction: column }
`;

const GridItem = styled(Grid)`
  width: 33%;
  flex-grow: 1;
  flex-basis: 0;

  margin: 0.5rem;

  @media( max-width: 360px ) { margin: 0.2rem; }
  @media( max-width: 320px ) { width: 100%; }
`;

const BoostsContainer = args => {

  const dispatch = useDispatch();
  const { t } = useTranslation(['component']);

  const { enabled } = useFeature('boosts');

  const boosts = useSelector(boostSelectors.getActiveBoosts);

  useEffect(() => {
    if (enabled) {
      dispatch(boostActions.getBoosts());
    }
  }, [dispatch, enabled]);

  if (boosts.length === 0 || !enabled) {
    return null;
  }

  const onChange = (event, boost) => {
    dispatch(boostActions.update({
      boostId: boost.id,
      completed: event.target.checked,
    }));
  };

  return (
    <div {...args}>
      <SectionTitle>{t('boosts.title')}</SectionTitle>
      <GridContainer container wrap="nowrap">
        {boosts.map((boost, idx) => (
          <GridItem item key={idx}>
            <StyledBoost label={boost.action} checked={boost.completed} onChange={e => onChange(e, boost)} />
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

export default BoostsContainer;

import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import { isMobile } from 'helpers';

const Wrapper = styled('div')`
  width: 100%;
  height: 400px;
  max-height: 100%;

  video {
    max-height: 100%;
    height: 400px;
  }

  // background-color: #252525;
  background-color: #000;
`;

const VideoPlayer = ({ url, poster, ...rest }) => {

  const player = useRef();

  const onPlay = () => {
    Array.from(document.querySelectorAll('video, audio'))
      .filter(media => media !== player.current)
      .forEach(media => media.pause());

    document.documentElement.classList.add('has-video-playing');
  };

  const onPause = () => {
    document.documentElement.classList.remove('has-video-playing');
  };

  useEffect(() => {
    return () => document.documentElement.classList.remove('has-video-playing');
  }, []);

  const inViewChange = inView => {
    if (!inView && player.current) {
      player.current.pause();
    }
  };

  const inViewArgs = {
    threshold: 0,
    initialInView: true,
    onChange: inViewChange,
    triggerOnce: false,
  };

  return (
    <InView as={Wrapper} {...inViewArgs} {...rest}>
      <video
        width="100%"
        height="100%"
        controls
        playsInline
        onPlay={onPlay}
        onPause={onPause}
        preload={isMobile ? 'none' : 'auto'}
        poster={poster}
        ref={player}
      >
        <source src={url} type="video/mp4" />
      </video>
    </InView>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  poster: PropTypes.string,
};

export default VideoPlayer;

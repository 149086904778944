import React from 'react';
import styled from '@emotion/styled/macro';
import IconText from 'components/IconText';
import PropTypes from 'prop-types';
import { getCategoryIcon, getFieldLabelTranslation, getCategoryTranslation, getValueWithUnitTranslation } from 'services/challengeService';
import { useTranslation } from 'react-i18next';
import Grid from 'components/Grid';

const Wrapper = styled('div')`
  position: relative;
  margin: 1rem;

  color: inherit;
  text-decoration: none;

  padding: ${({ theme }) => theme.spacing(1 / 2)};
  padding-left: 0.5rem;
  border-left: 5px solid #efefef;
`;

const TypesWrapper = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(1)};
`;

const StyledIconText = styled(IconText)`
  font-weight: 600;
`;

const Column = styled(Grid)`
  min-width: 100px;
  font-size: 0.9rem;
`;

const ColumnTitle = styled(Grid)`
  white-space: nowrap;
`;

const ColumnValue = styled(Grid)`
  font-weight: 600;
`;

const PostActivityTypes = ({ types, categoryName }) => {

  const { t } = useTranslation(['common']);

  return (
    <TypesWrapper container spacing={2}>
      {types.map(({ id, type, typeValue, unit }) => (
        <Grid item key={id}>
          <Column container direction="column" wrap="nowrap" spacing={1}>
            <ColumnTitle item>{getFieldLabelTranslation(t, type, categoryName)}</ColumnTitle>
            <ColumnValue item>{getValueWithUnitTranslation(t, typeValue, unit, categoryName, type)}</ColumnValue>
          </Column>
        </Grid>
      ))}
    </TypesWrapper>
  );
};

PostActivityTypes.propTypes = {
  categoryName: PropTypes.string,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      type: PropTypes.string,
      typeValue: PropTypes.string,
      unit: PropTypes.string,
    }),
  ).isRequired,
};

const PostActivity = ({ activity, ...rest }) => {

  const { t } = useTranslation(['common']);

  const Icon = getCategoryIcon(activity.category.name);

  return (
    <Wrapper {...rest}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledIconText startIcon={<Icon fontSize="large" />}>
            {getCategoryTranslation(t, activity.category.name)}
          </StyledIconText>
        </Grid>
        <Grid item xs={12}>
          {activity.types?.length && <PostActivityTypes types={activity.types} categoryName={activity.category.name} />}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

PostActivity.propTypes = {
  activity: PropTypes.object,
};

export default PostActivity;

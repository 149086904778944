import React, { useContext, useEffect } from 'react';

import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import useCapabilities from '../../useCapabilities';
import PostFormContext from '../../PostFormContext';

const usePollButtonProps = (args) => {
  const { t } = useTranslation(['component']);

  const { poll } = useCapabilities();

  const { setView, editing } = useContext(PostFormContext);

  const { change } = useForm();

  useEffect(() => {
    if (!poll && !editing) change('poll', undefined);
  }, [change, editing, poll]);

  return {
    startIcon: <FormatListBulletedRoundedIcon />,
    children: t('postForm.addPollButton'),
    onClick: () => setView('poll'),
    hidden: !poll,
    ...args,
  };
};

export default usePollButtonProps;

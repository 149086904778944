import merge from 'lodash/merge';
import { contactTypes } from 'redux/contact';

const contactsReducer = (state = {}, { type, payload }) => {

  switch (type) {

    case contactTypes.deleteContactSuccess: {
      return merge({}, state, { [payload.request.contactId]: null });
    }

    default:
      return state;
  }
};

export default contactsReducer;

import React, { } from 'react';
import IconButton from '@mui/material/IconButton';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { useTranslation } from 'react-i18next';
import PropTypes from 'propTypes';

const EmailQuickAction = ({ value, ...rest }) => {

  const { t } = useTranslation(['component']);

  return (
    <IconButton
      component="a"
      href={`mailto:${value}`}
      aria-label={t('contactDirectory.itemInfo.mailToButton')}
      title={t('contactDirectory.itemInfo.mailToButton')}
      {...rest}
    >
      <EmailRoundedIcon />
    </IconButton>
  );
};

EmailQuickAction.propTypes = {
  value: PropTypes.string.isRequired,
};

export default EmailQuickAction;

import { createSelector } from 'reselect';

const getBoostEntities = state => state.entities.boosts;
const getActiveBoostIds = state => state.boost.activeIds;
const getIsLoading = state => state.boost.getIsLoading;

const getActiveBoosts = createSelector([getActiveBoostIds, getBoostEntities], (ids, boosts) => ids.map(id => boosts[id]));

export default {
  getActiveBoostIds,
  getIsLoading,
  getActiveBoosts,
};

import { useState, useRef, useMemo, useCallback } from 'react';
import config from 'config';
import UploadClient, { CancelController } from '@uploadcare/upload-client';
import { useDispatch } from 'react-redux';
import { snackbarActions } from 'redux/snackbar';

const useVideoUploader = () => {

  const dispatch = useDispatch();

  const cancelController = useRef(new CancelController());

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState({});

  const onProgress = useCallback(({ value }) => setProgress(value), []);

  const reset = useCallback(() => {
    cancelController.current.cancel();
    cancelController.current = new CancelController();

    setProgress(0);
    setUploading(false);
    setFile(null);
  }, [cancelController]);

  const onSuccess = useCallback(data => {
    setUploading(false);

    if (data.isImage) {
      reset();
      throw new Error('Not a video');
    }

    return data;
  }, [reset]);

  const onFailure = useCallback(e => {
    reset();

    if (e.message !== 'Request canceled') {
      dispatch(snackbarActions.createFailure(e.message || 'Upload failed'));
    }

    throw e;
  }, [dispatch, reset]);

  const upload = useCallback(_file => {
    setUploading(true);

    setFile(_file);

    return new UploadClient({
      publicKey: config.uploadcare.key,
      cancel: cancelController.current,
    })
      .uploadFile(_file, { onProgress })
      .then(onSuccess)
      .catch(onFailure);
  }, [onFailure, onProgress, onSuccess]);

  return useMemo(() => ({
    upload,
    reset,
    file,
    uploading,
    progress,
  }), [file, progress, upload, uploading, reset]);
};

export default useVideoUploader;

import combineReducers from 'combine-reducers-global-state';
import { addToPagination } from 'redux/helpers/pagination';
import types from './types';

const feedByMemberId = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMemberFeedSuccess:
      return addToPagination(state, payload.memberId, payload, 'postIds');

    default:
      return state;
  }
};

const searchFieldMembers = (state = [], { type, payload }) => {
  switch (type) {
    case types.getMembersForSearchField:
      return [];
    case types.getMembersForSearchFieldSuccess:
      return payload.data;
    default:
      return state;
  }
};

const memberReducer = combineReducers({
  feedByMemberId,
  searchFieldMembers,
});

export default { member: memberReducer };

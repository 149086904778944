import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';
import Logo from 'components/Logo';
import { useTranslation } from 'react-i18next';

const LayoutLogo = (args) => {

  const { t } = useTranslation(['common']);

  const { logo, name } = useSelector(authSelectors.getWorkspace);

  return (
    <Logo
      src={logo?.src}
      srcSet={logo?.srcSet}
      alt={name || t('common:guavaHR')}
      {...args}
    />
  );
};

export default LayoutLogo;

import { createBrowserHistory, createHashHistory } from 'history';
import { isMobile } from 'helpers';

const history = isMobile ? createHashHistory() : createBrowserHistory();

export const forcePush = history.push;

let lastLocation = history.location;

history.listen((location) => { lastLocation = location; });

history.push = (pathname, state) => {
  if (lastLocation === null
    || pathname !== lastLocation.pathname + lastLocation.search + lastLocation.hash
    || JSON.stringify(state) !== JSON.stringify(lastLocation.state)
  ) {
    forcePush(pathname, state);
  }
};

history.forcePush = forcePush;

export default history;

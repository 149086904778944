import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import MenuItem from '@mui/material/MenuItem';
import SelectField from 'components/FinalForm/Fields/SelectField';
import { useSelector, useDispatch } from 'react-redux';
import { workspaceActions, workspaceSelectors } from 'redux/workspace';
import Checkbox from '@mui/material/Checkbox';
import { useField } from 'react-final-form';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';

const Placeholder = styled('span')`
  color: inherit;
`;

const emptyArray = [];

const valueOrEmptyArray = v => v || emptyArray;

const LabelSelectField = ({ name, placeholder, ...rest }) => {

  const dispatch = useDispatch();

  const { input: { value } } = useField(name);

  const labels = useSelector(workspaceSelectors.getSortedLabels);
  const getLabelSelector = useSelector(workspaceSelectors.getLabelSelector);

  useEffect(() => {
    dispatch(workspaceActions.getLabels());
  }, [dispatch]);

  const renderValue = selected => {
    if (selected.length === 0) {
      return <Placeholder>{placeholder}</Placeholder>;
    }

    const displayValue = selected
      .map(id => getLabelSelector(id)?.name)
      .filter(val => !!val)
      .join(', ');

    return <span title={displayValue}>{displayValue}</span>;
  };

  return (
    <SelectField
      name={name}
      renderValue={renderValue}
      displayEmpty
      multiple
      parse={valueOrEmptyArray}
      format={valueOrEmptyArray}
      {...rest}
    >
      {labels.map(label => (
        <MenuItem dense key={label.id} value={label.id}>
          <Checkbox edge="start" value={label.id} checked={value.includes(label.id)} />
          <ListItemText primary={label.name} />
        </MenuItem>
      ))}
    </SelectField>
  );
};

LabelSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default LabelSelectField;

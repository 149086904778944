import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import DocumentDialog from 'containers/DocumentDialog';
import NavLink from 'components/NavLink';
import NavLinkSwitch from 'components/NavLinkSwitch';
import Grid from 'components/Grid';
import { Switch, Route, matchPath } from 'react-router-dom';
import Search from 'containers/Search/Search';
import config from 'config';
import SidebarGroup from './Partials/SidebarGroup';
import SidebarFooter from './Partials/SidebarFooter';
import SidebarItem from './Partials/SidebarItem';
import SidebarWrapper from './Partials/SidebarWrapper';
import useNavigationItems from './useNavigationItems';

const DocAnchor = styled('a')`
  cursor: pointer;
`;

const Sidebar = React.forwardRef((args, ref) => {

  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);

  const { t, i18n } = useTranslation(['common', 'component']);

  const { sidebarItems, memberItems, adminItems } = useNavigationItems();

  const showAdmin = adminItems?.length > 0;

  const faqUrl = `${config.apiUrl}/api/help?locale=${i18n.language}`;

  const onSwitchToUser = ({ pathname }) => {
    // Try to find current root from all items
    const match = sidebarItems.find(({ admin }) => admin?.to && matchPath(pathname, { path: admin.to, exact: admin.exact }));

    // On match, go to the match alternative. Otherwise try simple replace
    return match?.member?.to || pathname.replace('/manage', '');
  };

  const onSwitchToAdmin = ({ pathname }) => {
    // If already on admin, do nothing
    if (matchPath(pathname, { path: '/manage' })) {
      return pathname;
    }
    // Try to find root menu item containing both member and admin props
    const match = sidebarItems.find(({ member }) => member?.to && matchPath(pathname, { path: member.to, exact: member.exact }));

    // If admin alternative exists, switch there
    if (match?.admin?.to) {
      return match?.admin?.to;
    }

    return adminItems?.[0]?.to;
  };

  const isAdminActive = (match, location) => location.pathname.indexOf('/manage') === 0;
  const isUserActive = (...props) => !isAdminActive(...props);

  return (
    <SidebarWrapper ref={ref} {...args}>
      <Grid container spacing={3} direction="column">

        {showAdmin && (
          <Grid item>
            <NavLinkSwitch>
              <NavLink to={onSwitchToUser} isActive={isUserActive}>{t('component:sidebar.userTab')}</NavLink>
              <NavLink to={onSwitchToAdmin} isActive={isAdminActive}>{t('component:sidebar.adminTab')}</NavLink>
            </NavLinkSwitch>
          </Grid>
        )}

        <Grid item>
          <Search />
        </Grid>

        <Grid item>
          <Switch>

            {adminItems?.length && (
              <Route path="/manage">
                <SidebarGroup>
                  {adminItems.map(({ to, ...props }) => <SidebarItem key={to} to={to} {...props} />)}
                </SidebarGroup>
              </Route>
            )}

            {memberItems?.length && (
              <SidebarGroup>
                {memberItems.map(({ to, ...props }) => <SidebarItem key={to} to={to} {...props} />)}
              </SidebarGroup>
            )}

          </Switch>
        </Grid>
      </Grid>

      <SidebarFooter>
        <a href={faqUrl} target="_blank" rel="noopener noreferrer nofollow">{t('link.faq')}</a>
        <DocAnchor onClick={() => setTermsOpen(true)}>{t('link.terms')}</DocAnchor>
        <DocAnchor onClick={() => setPrivacyOpen(true)}>{t('link.privacy')}</DocAnchor>
      </SidebarFooter>

      <DocumentDialog type="user_terms" open={termsOpen} onClose={() => setTermsOpen(false)} />
      <DocumentDialog type="user_privacy" open={privacyOpen} onClose={() => setPrivacyOpen(false)} />

    </SidebarWrapper>
  );
});

export default Sidebar;

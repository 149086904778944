import { all, takeLeading, put, select, takeEvery } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import { snackbarActions } from 'redux/snackbar';
import { takeLatestPerKey } from 'redux/helpers/saga';
import types from './types';
import actions from './actions';

function* onGetPersonaSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.persona.get, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.getPersonaSettingsSuccess(response));
  } else {
    yield put(actions.getPersonaSettingsFailure(error));
  }
}

function* onUpdatePersonaSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.persona.update, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(snackbarActions.createSuccess('integrations:settingsForm.settingsSaved', { translate: true }));
    yield put(actions.updatePersonaSettingsSuccess(response));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.updatePersonaSettingsFailure(error));
  }
}

function* onGetCoopSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.coop.get, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.getCoopSettingsSuccess(response));
  } else {
    yield put(actions.getCoopSettingsFailure(error));
  }
}

function* onUpdateCoopSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.coop.update, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(snackbarActions.createSuccess('integrations:settingsForm.settingsSaved', { translate: true }));
    yield put(actions.updateCoopSettingsSuccess(response));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.updateCoopSettingsFailure(error));
  }
}

function* onSyncCoop({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.coop.sync, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.syncCoopSuccess(response));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.syncCoopFailure(error));
  }
}

function* onGetNetvisorSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.netvisor.get, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.getNetvisorSettingsSuccess(response));
  } else {
    yield put(actions.getNetvisorSettingsFailure(error));
  }
}

function* onUpdateNetvisorSettings({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.netvisor.update, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(snackbarActions.createSuccess('integrations:settingsForm.settingsSaved', { translate: true }));
    yield put(actions.updateNetvisorSettingsSuccess(response));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.updateNetvisorSettingsFailure(error));
  }
}

function* onSyncNetvisor({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.netvisor.sync, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(snackbarActions.createSuccess('integrations:settingsForm.syncStarted', { translate: true }));
    yield put(actions.syncNetvisorSuccess(response));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.syncNetvisorFailure(error));
  }
}

function* onGetIntegrationRules({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.rules.index, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.getIntegrationRulesSuccess({ ...response, integrationId: payload.integrationId }));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.getIntegrationRulesFailure(error));
  }
}

function* onUpdateIntegrationRule({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.rules.update, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.updateIntegrationRuleSuccess(response));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.updateIntegrationRuleFailure(error));
  }
}

function* onCreateIntegrationRule({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.rules.create, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.createIntegrationRuleSuccess(response));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.createIntegrationRuleFailure(error));
  }
}

function* onGetIntegrations({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.index, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.getIntegrationsSuccess(response));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.getIntegrationsFailure(error));
  }
}

function* onIntegrationRuleMassAction({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.rules.massaction, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.integrationRuleMassActionSuccess({ ...response, request: payload }));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.integrationRuleMassActionFailure(error));
  }
}

function* onPatchIntegration({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.integration.patch, ({ workspaceId, ...payload }));

  if (ok) {
    yield put(actions.patchIntegrationSuccess({ ...response, request: payload }));
  } else {
    yield put(snackbarActions.createFailure(error.message));
    yield put(actions.patchIntegrationFailure(error));
  }
}

export default function* integrationSagas() {
  yield all([
    takeLeading(types.getIntegrations, onGetIntegrations),
    takeLeading(types.patchIntegration, onPatchIntegration),
    takeLatestPerKey(types.integrationRuleMassAction, onIntegrationRuleMassAction, ({ payload }) => payload.integrationId),

    // Rules
    takeLeading(types.getIntegrationRules, onGetIntegrationRules),
    takeEvery(types.updateIntegrationRule, onUpdateIntegrationRule),
    takeEvery(types.createIntegrationRule, onCreateIntegrationRule),

    // Persona
    takeLeading(types.getPersonaSettings, onGetPersonaSettings),
    takeLeading(types.updatePersonaSettings, onUpdatePersonaSettings),

    // Netvisor
    takeLeading(types.getNetvisorSettings, onGetNetvisorSettings),
    takeLeading(types.updateNetvisorSettings, onUpdateNetvisorSettings),
    takeLeading(types.syncNetvisor, onSyncNetvisor),

    // Coop
    takeLeading(types.getCoopSettings, onGetCoopSettings),
    takeLeading(types.updateCoopSettings, onUpdateCoopSettings),
    takeLeading(types.syncCoop, onSyncCoop),
  ]);
}

import { userActions } from 'redux/user';
import { authTypes } from 'redux/auth';
import AuthService from 'services/authService';
import config from 'config';
import Echo from 'laravel-echo';

require('pusher-js');

let echo; // Our singleton echo instance

const initEcho = token => new Echo({
  broadcaster: 'pusher',
  key: config.pusher.key,
  cluster: config.pusher.cluster,
  authEndpoint: config.pusher.authEndpoint,
  forceTLS: config.pusher.tls,
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  },
});

const closeSocket = () => echo ? echo.disconnect() : false;

const openSocket = (user, dispatch) => {
  AuthService.getToken().then(token => {
    closeSocket();

    echo = initEcho(token);

    echo
      .private(`user.${user.id}`)
      .notification(() => dispatch(userActions.newNotification()));
  });
};

export default store => next => action => {

  const { type, payload } = action;

  switch (type) {
    case authTypes.loginSuccess:
    case authTypes.refreshTokenSuccess:
      openSocket(payload.user, store.dispatch);
      break;
    case authTypes.logoutSuccess:
      closeSocket();
      break;
    default:
      break;
  }

  next(action);
};

import styled from '@emotion/styled/macro';

const Tablist = styled('div')`
  display: ${({ hidden }) => hidden ? 'none' : 'flex'};
  justify-content: center;
  flex-wrap: wrap;

  margin: -0.2rem;

  /* TODO: Supposedly a fix for android. Check if still required */
  max-width: 100%;
`;

export default Tablist;

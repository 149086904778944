import React from 'react';
import { Field } from 'react-final-form';
import TextField from 'components/Fields/TextField';
import withFlatArgs from 'components/FinalForm/withFlatArgs';

const parseFn = v => v;

const FormFieldComponent = withFlatArgs(TextField, 'TextField');
const FormField = (args) => <Field component={FormFieldComponent} parse={parseFn} {...args} />;

export default FormField;

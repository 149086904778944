import React from 'react';
import Dialog from 'components/Dialog/Dialog';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useFormDialogCloseConfirm from 'components/Confirmation/useFormDialogCloseConfirm';
import CreatePostForm from './CreatePostForm';

const CreatePostDialog = ({ open, onClose, ...rest }) => {

  const { t } = useTranslation(['component']);

  const { FormSpy, onConfirmClose } = useFormDialogCloseConfirm(onClose, {
    title: t('createPostDialog.dirtyFormCloseConfirmation.title'),
    description: t('createPostDialog.dirtyFormCloseConfirmation.description'),
    submit: t('createPostDialog.dirtyFormCloseConfirmation.submit'),
    cancel: t('createPostDialog.dirtyFormCloseConfirmation.cancel'),
  });

  return (
    <Dialog maxWidth="md" open={open} onClose={onConfirmClose}>
      <CreatePostForm
        onSuccess={onClose}
        formHeader={FormSpy}

        asDialog
        onClose={onConfirmClose}
        {...rest}
      />
    </Dialog>
  );
};

CreatePostDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ...CreatePostForm.propTypes,
};

export default CreatePostDialog;

import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import guavaSvg from './guava.svg';
import BoostCheckbox from './BoostCheckbox';

const BoostLabel = styled('label')`
  border-radius: 4px;
  overflow: hidden;
  background: #4DAF4F;
  position: relative;
  min-height: 150px;
  display: block;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* To fix Safari border-radius corner issue */
  /* -webkit-mask-image: -webkit-radial-gradient(white, black); */
  transform: translateZ(0);

  /* To fix click issues on mobile phones */
  & > span, & > img {
    pointer-events: none;
  }

  padding: 0.8rem;
  @media(max-width: 420px) { padding: 0.5rem; }

  &:hover, &:focus, &:focus-within {
    background: linear-gradient(40deg, #4DAF4F 0%, #58C447 100%);
  }
`;

const GuavaImage = styled('img')`
  width: auto;
  position: absolute;
  right: 0;
  top: 0;
  margin: auto;
  opacity: .5;
  transition: opacity 0.2s;

  /* TranslateZ is for Safari, iOS flickering */
  transform: translateZ(0);

  /* To fix Safari border-radius corner issue */
  -webkit-backface-visibility: hidden;
`;

const BoostName = styled('span')`
  font-weight: 700;
  color: #fff;
  position: relative;

  font-size: 0.9rem; // TODO: Used to be dynamic with react-fittext. Didn't find easy replacement for React 18
  margin-top: 0.8rem;
`;

const Checkbox = styled(BoostCheckbox)`
  &:checked ~ ${GuavaImage} {
    opacity: 1;
    transition: opacity 0.2s;
  }

  &:checked ~ ${BoostName} {
    text-decoration: line-through;
  }
`;

const Boost = ({ label, className, ...args }) => (
  <BoostLabel className={className}>
    <Checkbox {...args} />
    <GuavaImage src={guavaSvg} alt="Guava" />
    <BoostName>{label}</BoostName>
  </BoostLabel>
);

Boost.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Boost;

import { all, takeLeading, put } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import types from './types';
import actions from './actions';

function* onSend({ payload }) {
  const { ok, response, error } = yield apiCall(api.verification.send, payload);

  if (ok) {
    yield put(actions.sendSuccess(response));
  } else {
    yield put(actions.sendFailure(error));
  }
}

function* onValidate({ payload }) {
  const { ok, response, error } = yield apiCall(api.verification.validate, payload);

  if (ok) {
    yield put(actions.validateSuccess(response));
  } else {
    yield put(actions.validateFailure(error));
  }
}

export default function* verificationSagas() {
  yield all([
    takeLeading(types.send, onSend),
    takeLeading(types.validate, onValidate),
  ]);
}

import React from 'react';
import Grid from 'components/Grid';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

const Wrapper = styled('div')`
  font-size: 0.9em;
  color: #666;

  margin: 0.25rem 0.75rem;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background: #ddd;
    display: block;
    position: relative;
    margin: 0.25rem 0;
  }
`;

const RightGridItem = styled(Grid)`
  margin-left: auto; // Makes it better when it wraps
`;

const CommentFooter = ({ left, right }) => (
  <Wrapper>
    <Grid container spacing={1} alignItems="center" justifyContent="space-between">
      <Grid item>{left}</Grid>
      {right && <RightGridItem item>{right}</RightGridItem>}
    </Grid>
  </Wrapper>
);

CommentFooter.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
};

export default CommentFooter;

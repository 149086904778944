import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import Poll from 'components/Poll';
import { useForm, useFormState } from 'react-final-form';
import Button from 'components/Button';
import EditIcon from 'components/Icons/Edit';
import IconButton from '@mui/material/IconButton';
import TrashIcon from 'components/Icons/Trash';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import PostFormContext from '../../PostFormContext';
import useCapabilities from '../../useCapabilities';
import PollSettingsButton from '../../PollView/PollSettingsButton';

const Wrapper = styled('div')`
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 1.5rem;
`;

const PollExhibit = () => {

  const { t } = useTranslation('component');

  const { change } = useForm();

  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });

  const { setView } = useContext(PostFormContext);

  const { poll } = useCapabilities();

  const editPoll = () => {
    setView('poll');
  };

  const deletePoll = () => {
    change('poll', undefined);
  };

  return (
    <Wrapper>
      <Poll
        disabled
        disableAnimations
        question={values.poll.question}
        options={values.poll.options}
        closeAt={values.poll.closeAt}
        anonymous={values.poll.anonymous}
        hideVotes={values.poll.hideVotes}

        maxVotes={values.poll.maxVotes}
        addOptions={values.poll.addOptions}

        pollActions={(
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item hidden={!!poll}>
              <PollSettingsButton hideButtons={['closeAt', 'multiple']} fieldPrefix="poll." />
            </Grid>
            <Grid item hidden={!poll}>
              <Button color="default" startIcon={<EditIcon />} onClick={editPoll}>{t('pollForm.editButton')}</Button>
            </Grid>
            <Grid item hidden={!poll}>
              <IconButton color="inherit" edge="end" onClick={deletePoll}>
                <TrashIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      />
    </Wrapper>
  );
};

export default PollExhibit;

import React, { useContext } from 'react';
import WebFullScreenLayout from './FullScreen/WebFullScreenLayout';
import WebCommonLayout from './Common/WebCommonLayout';
import { LayoutReadContext } from '../LayoutProvider';

const WebDefaultLayout = ({ children, ...rest }) => {
  const { fullScreen, ...layoutProps } = useContext(LayoutReadContext);

  return fullScreen
    ? <WebFullScreenLayout {...layoutProps} {...rest}>{children}</WebFullScreenLayout>
    : <WebCommonLayout {...layoutProps} {...rest}>{children}</WebCommonLayout>;
};

export default WebDefaultLayout;

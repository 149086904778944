import stubTrue from 'lodash/stubTrue';
import combineReducers from 'combine-reducers-global-state';
import types from './types';

const votesByOption = (state = { loading: false }, { type, payload }) => {
  switch (type) {
    case types.getVotesSuccess:
      return {
        ...state,
        [payload.optionId]: payload.data,
      };
    default:
      return state;
  }
};

const votesByOptionLoading = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getVotes:
      return { ...state, [payload.optionId]: stubTrue };
    case types.getVotesSuccess:
    case types.getVotesFailure:
      return { ...state, [payload.optionId]: false };
    default:
      return state;
  }
};

const pollReducers = combineReducers({
  votesByOption,
  votesByOptionLoading,
});


export default { poll: pollReducers };

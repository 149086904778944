import React, { useContext, useEffect } from 'react';

import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import useCapabilities from '../../useCapabilities';
import PostFormContext from '../../PostFormContext';

const useQuizButtonProps = args => {
  const { t } = useTranslation(['component']);

  const { quiz } = useCapabilities();

  const { setView, editing } = useContext(PostFormContext);

  const { change } = useForm();

  useEffect(() => {
    if (!quiz && !editing) change('quiz', undefined);
  }, [change, editing, quiz]);

  return {
    startIcon: <AssignmentTurnedInOutlinedIcon />,
    children: t('postForm.addQuizButton'),
    onClick: () => setView('quiz'),
    hidden: !quiz,
    ...args,
  };
};

export default useQuizButtonProps;

export default {
  getPoll: 'poll/GET_POLL',
  getPollSuccess: 'poll/GET_POLL_SUCCESS',
  getPollFailure: 'poll/GET_POLL_FAILURE',

  addOption: 'poll/ADD_OPTION',
  addOptionSuccess: 'poll/ADD_OPTION_SUCCESS',
  addOptionFailure: 'poll/ADD_OPTION_FAILURE',

  removeOption: 'poll/REMOVE_OPTION',
  removeOptionSuccess: 'poll/REMOVE_OPTION_SUCCESS',
  removeOptionFailure: 'poll/REMOVE_OPTION_FAILURE',

  getVotes: 'poll/GET_VOTES',
  getVotesSuccess: 'poll/GET_VOTES_SUCCESS',
  getVotesFailure: 'poll/GET_VOTES_FAILURE',

  storeVote: 'poll/STORE_VOTE',
  storeVoteSuccess: 'poll/STORE_VOTE_SUCCESS',
  storeVoteFailure: 'poll/STORE_VOTE_FAILURE',

  deleteVote: 'poll/DELETE_VOTE',
  deleteVoteSuccess: 'poll/DELETE_VOTE_SUCCESS',
  deleteVoteFailure: 'poll/DELETE_VOTE_FAILURE',

  downloadVotes: 'poll/DOWNLOAD_VOTES',
  downloadVotesSuccess: 'poll/DOWNLOAD_VOTES_SUCCESS',
  downloadVotesFailure: 'poll/DOWNLOAD_VOTES_FAILURE',
};

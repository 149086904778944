import { schema } from 'normalizr';
import { initialPagination } from 'redux/helpers/pagination';
import channel from './channel';
import user from './user';

const groupSchema = new schema.Entity('groups', {
  channel,
  subscribers: [user],
  posts: {
    postIds: [],
    pagination: initialPagination,
  },
});

export default groupSchema;

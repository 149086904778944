import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getFeedbacks: data => createAction(types.getFeedbacks, data),
  getFeedbacksSuccess: data => createAction(types.getFeedbacksSuccess, data),
  getFeedbacksFailure: data => createAction(types.getFeedbacksFailure, data),

  downloadIndex: data => createAction(types.downloadIndex, data),
  downloadIndexSuccess: data => createAction(types.downloadIndexSuccess, data),
  downloadIndexFailure: data => createAction(types.downloadIndexFailure, data),

  store: data => createAction(types.store, data),
  storeSuccess: data => createAction(types.storeSuccess, data),
  storeFailure: data => createAction(types.storeFailure, data),

  publicStore: data => createAction(types.publicStore, data),
  publicStoreSuccess: data => createAction(types.publicStoreSuccess, data),
  publicStoreFailure: data => createAction(types.publicStoreFailure, data),

  patch: data => createAction(types.patch, data),
  patchSuccess: data => createAction(types.patchSuccess, data),
  patchFailure: data => createAction(types.patchFailure, data),

  massAction: data => createAction(types.massAction, data),
  massActionSuccess: data => createAction(types.massActionSuccess, data),
  massActionFailure: data => createAction(types.massActionFailure, data),

  get: data => createAction(types.get, data),
  getSuccess: data => createAction(types.getSuccess, data),
  getFailure: data => createAction(types.getFailure, data),

  download: data => createAction(types.download, data),
  downloadSuccess: data => createAction(types.downloadSuccess, data),
  downloadFailure: data => createAction(types.downloadFailure, data),

  delete: data => createAction(types.delete, data),
  deleteSuccess: data => createAction(types.deleteSuccess, data),
  deleteFailure: data => createAction(types.deleteFailure, data),

  storeType: data => createAction(types.storeType, data),
  storeTypeSuccess: data => createAction(types.storeTypeSuccess, data),
  storeTypeFailure: data => createAction(types.storeTypeFailure, data),

  updateFeedbackType: data => createAction(types.updateFeedbackType, data),
  updateFeedbackTypeSuccess: data => createAction(types.updateFeedbackTypeSuccess, data),
  updateFeedbackTypeFailure: data => createAction(types.updateFeedbackTypeFailure, data),

  patchFeedbackType: data => createAction(types.patchFeedbackType, data),
  patchFeedbackTypeSuccess: data => createAction(types.patchFeedbackTypeSuccess, data),
  patchFeedbackTypeFailure: data => createAction(types.patchFeedbackTypeFailure, data),

  getFeedbackTypes: data => createAction(types.getFeedbackTypes, data),
  getFeedbackTypesSuccess: data => createAction(types.getFeedbackTypesSuccess, data),
  getFeedbackTypesFailure: data => createAction(types.getFeedbackTypesFailure, data),

  getFeedbackType: data => createAction(types.getFeedbackType, data),
  getFeedbackTypeSuccess: data => createAction(types.getFeedbackTypeSuccess, data),
  getFeedbackTypeFailure: data => createAction(types.getFeedbackTypeFailure, data),

  getFeedbackTypePublicUrl: data => createAction(types.getFeedbackTypePublicUrl, data),
  getFeedbackTypePublicUrlSuccess: data => createAction(types.getFeedbackTypePublicUrlSuccess, data),
  getFeedbackTypePublicUrlFailure: data => createAction(types.getFeedbackTypePublicUrlFailure, data),

  deleteFeedbackType: data => createAction(types.deleteFeedbackType, data),
  deleteFeedbackTypeSuccess: data => createAction(types.deleteFeedbackTypeSuccess, data),
  deleteFeedbackTypeFailure: data => createAction(types.deleteFeedbackTypeFailure, data),

  getSettings: data => createAction(types.getSettings, data),
  getSettingsSuccess: data => createAction(types.getSettingsSuccess, data),
  getSettingsFailure: data => createAction(types.getSettingsFailure, data),

  updateSettings: data => createAction(types.updateSettings, data),
  updateSettingsSuccess: data => createAction(types.updateSettingsSuccess, data),
  updateSettingsFailure: data => createAction(types.updateSettingsFailure, data),

  getFeedbackStatuses: data => createAction(types.getFeedbackStatuses, data),
  getFeedbackStatusesSuccess: data => createAction(types.getFeedbackStatusesSuccess, data),
  getFeedbackStatusesFailure: data => createAction(types.getFeedbackStatusesFailure, data),



  addComment: data => createAction(types.addComment, data),
  addCommentSuccess: data => createAction(types.addCommentSuccess, data),
  addCommentFailure: data => createAction(types.addCommentFailure, data),

  getComments: data => createAction(types.getComments, data),
  getCommentsSuccess: data => createAction(types.getCommentsSuccess, data),
  getCommentsFailure: data => createAction(types.getCommentsFailure, data),

  getComment: data => createAction(types.getComment, data),
  getCommentSuccess: data => createAction(types.getCommentSuccess, data),
  getCommentFailure: data => createAction(types.getCommentFailure, data),

  updateComment: data => createAction(types.updateComment, data),
  updateCommentSuccess: data => createAction(types.updateCommentSuccess, data),
  updateCommentFailure: data => createAction(types.updateCommentFailure, data),

  deleteComment: data => createAction(types.deleteComment, data),
  deleteCommentSuccess: data => createAction(types.deleteCommentSuccess, data),
  deleteCommentFailure: data => createAction(types.deleteCommentFailure, data),

  getCommentTranslation: data => createAction(types.getCommentTranslation, data),
  getCommentTranslationSuccess: data => createAction(types.getCommentTranslationSuccess, data),
  getCommentTranslationFailure: data => createAction(types.getCommentTranslationFailure, data),
};

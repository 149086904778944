import { combineReducers } from 'redux';
import { addToPagination, createPagination, resetPagination } from 'redux/helpers/pagination';
import types from './types';

const eventsInitialState = {
  upcoming: createPagination(null, 'eventIds'),
  past: createPagination(null, 'eventIds'),
};
const events = (state = eventsInitialState, { type, payload }) => {
  switch (type) {
    case types.getEventsSuccess:
      return addToPagination(state, payload.status || 'upcoming', payload, 'eventIds');
    case types.createEventSuccess:
      return resetPagination(state, '*', 'eventIds');
    default:
      return state;
  }
};

const calendarReducers = combineReducers({
  events,
});

export default { calendar: calendarReducers };

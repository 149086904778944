import combineReducers from 'combine-reducers-global-state';

import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import articlesReducer from './articles';
import documentFoldersReducer from './documentFolders';
import documentFolderVariantsReducer from './documentFolderVariants';
import documentSectionsReducer from './documentSections';
import boostsReducer from './boosts';
import contactsReducer from './contacts';
import commentsReducer from './comments';
import eventsReducer from './events';
import feedbackReducer from './feedbacks';
import feedbackTypesReducer from './feedbackTypes';
import feedbackCommentsReducer from './feedbackComments';
import fileGroupsReducer from './fileGroups';
import groupsReducer from './groups';
import integrationRulesReducer from './integrationRules';
import membersReducer from './members';
import pagesReducer from './pages';
import pollOptionsReducer from './pollOptions';
import pollsReducer from './polls';
import postsReducer from './posts';
import recordsReducer from './records';
import coursesReducer from './courses';
import learningCourses from './learningCourses';
import learningCourseVariants from './learningCourseVariants';
import learningLessons from './learningLessons';

const noop = (state = {}) => state;

const worker = combineReducers({
  activityCategories: noop,
  activityCategoryTypes: noop,
  documentFolders: documentFoldersReducer,
  documentFolderVariants: documentFolderVariantsReducer,
  documentSections: documentSectionsReducer,
  articles: articlesReducer,
  boosts: boostsReducer,
  challenges: noop,
  channels: noop,
  contacts: contactsReducer,
  comments: commentsReducer,
  events: eventsReducer,
  integrations: noop,
  integrationRules: integrationRulesReducer,
  feedbackComments: feedbackCommentsReducer,
  feedbackForms: noop,
  feedbacks: feedbackReducer,
  feedbackTypes: feedbackTypesReducer,
  fileGroups: fileGroupsReducer,
  groups: groupsReducer,
  labels: noop,
  learningCourses,
  learningCourseVariants,
  learningLessons,
  learningQuestions: noop,
  members: membersReducer,
  notifications: noop,
  pages: pagesReducer,
  pollOptions: pollOptionsReducer,
  polls: pollsReducer,
  posts: postsReducer,
  records: recordsReducer,
  roles: noop,
  courses: coursesReducer,
  quizes: noop,
  quizOptions: noop,
  translations: noop,
  users: noop,
});

// For entities if there's a key with an array value, then rarely
// these need to be merged together in our app, thus override
const mergeFn = (objValue, srcValue) => {
  return isArray(srcValue) ? srcValue : undefined;
};

const entities = (state, action) => {

  let next = state;

  if (action?.payload?.entities) {
    next = mergeWith({}, next, action.payload.entities, mergeFn);
  }

  return worker(next, action);
};

export const entitiesReducers = { entities };

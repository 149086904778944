import { useContext } from 'react';
import { useForm } from 'react-final-form';
import PostFormContext from '../PostFormContext';
import SmsViewContext from './SmsViewContext';

const useBackClick = () => {
  const { setView } = useContext(PostFormContext);
  const { save } = useContext(SmsViewContext);
  const { getState } = useForm();

  return () => {
    save.current = getState().values;
    setView('base');
  };
};

export default useBackClick;

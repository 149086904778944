import React, { useEffect } from 'react';
import Grid from 'components/Grid';
import Button from 'components/Button';
import { useField, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'helpers';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { postActions, postSelectors } from 'redux/post';
import PostFormFooter from '../Partials/PostFormFooter';
import useBackClick from './useBackClick';

const SmsViewFooter = ({ channelId, target }) => {

  const { t } = useTranslation('component');

  const dispatch = useDispatch();

  const { submit } = useForm();

  const onBackClick = useBackClick();

  const smsContent = useField('content');

  const smsExpenseEstimation = useSelector(postSelectors.getPostSmsExpenseEstimation);

  useEffect(() => {
    // On init reset, but also send out a request to fetch new values
    dispatch(postActions.smsExpenseEstimationReset());
  }, [dispatch]);

  useEffect(() => {
    dispatch(postActions.getSmsExpenseEstimation({
      smsContent: smsContent.input.value,
      channelId: channelId ?? '',
      target: target ?? '',
    }));
  }, [channelId, dispatch, smsContent.input.value, target]);

  if (isMobile) {
    return (
      <PostFormFooter>
        <Grid container spacing={4} justifyContent="space-between" direction="column" wrap="nowrap">
          <Grid item xs={12}>
            {t('smsForm.estimatedCost')} : {smsExpenseEstimation.totalExpense}€
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="text" onClick={onBackClick}>{t('smsForm.back')}</Button>
              </Grid>
              <Grid item>
                <Button onClick={() => submit()}>{t('smsForm.addToPost')}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PostFormFooter>
    );
  }

  return (
    <PostFormFooter>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item>
          {t('smsForm.estimatedCost')} : {smsExpenseEstimation.totalExpense}€
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="text" onClick={onBackClick}>{t('smsForm.back')}</Button>
            </Grid>
            <Grid item>
              <Button onClick={() => submit()}>{t('smsForm.addToPost')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PostFormFooter>
  );
};

export default SmsViewFooter;

SmsViewFooter.propTypes = {
  channelId: PropTypes.number,
  target: PropTypes.array,
};

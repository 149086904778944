import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import LabelSelectField from 'components/FinalForm/Fields/LabelSelectField';
import useCapabilities from '../../useCapabilities';

const PostTargetField = ({ ...rest }) => {

  const { t } = useTranslation(['component']);

  const { target } = useCapabilities();

  const form = useForm();

  const beforeSubmit = () => {
    const { values } = form.getState();
    if (!target && values.target && values.target.length > 0) {
      form.change('target', []);
    }
  };

  return (
    <LabelSelectField
      name="target"
      label={t('postLabelSelect.label')}
      placeholder={t('postLabelSelect.emptyStatePlaceholder')}
      beforeSubmit={beforeSubmit}
      {...rest}
    />
  );
};

export default PostTargetField;

import styled from '@emotion/styled';
import OpengraphImage from '../OpengraphImage';

export const Thumbnail = styled(OpengraphImage)`
  height: 50px;
  max-width: 75px;

  border-radius: 4px;

  object-fit: contain;
  object-position: center center;
  background-color: #fff;

  vertical-align: middle;

  border: 1px solid rgba(0, 0, 0, 0.06);;
`;

export const Title = styled('h3')`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  margin: 0;
  font-size: 0.9rem;
`;

export const Description = styled('p')`
  font-size: 0.8rem;
  margin: 0;

  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

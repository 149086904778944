import { all, takeLeading } from 'redux-saga/effects';
import { api, commonApiSaga } from 'redux/helpers/api';
import history from 'providers/history';
import types from './types';

export default function* pageSagas() {
  yield all([
    takeLeading(types.delete, commonApiSaga, { apiFn: api.pages.delete, workspaceId: true, successMessage: 'page.deleted' }),
    takeLeading(types.store, commonApiSaga, { apiFn: api.pages.store, workspaceId: true, successMessage: 'page.stored' }),
    takeLeading(types.patch, commonApiSaga, { apiFn: api.pages.patch, workspaceId: true, successMessage: 'page.updated' }),
    takeLeading(types.update, commonApiSaga, { apiFn: api.pages.update, workspaceId: true, successMessage: 'page.updated' }),
    takeLeading(types.getPage, commonApiSaga, { apiFn: api.pages.show, workspaceId: true, onFailure: () => history.push('/') }),

    takeLeading(types.getPages, commonApiSaga, { apiFn: api.pages.index, workspaceId: true }),
  ]);
}

import React, { useContext } from 'react';
import DialogContent from 'components/Dialog/DialogContent';
import Grid from 'components/Grid';
import PostFormContext from '../PostFormContext';

const PostFormContent = args => useContext(PostFormContext).asDialog
  ? <DialogContent {...args} />
  : <Grid item {...args} />;

export default PostFormContent;

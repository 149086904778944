import React from 'react';
import styled from '@emotion/styled/macro';
import Grid from 'components/Grid';
import AvatarRow from 'components/AvatarRow';
import PropTypes from 'prop-types';

const Subtitle = styled('span')`
  font-weight: 500;
  font-size: 0.8rem;
  color: #666;
`;

const WidgetData = ({ title, users, max = 4 }) => {
  return (
    <Grid container direction="row" wrap="nowrap" spacing={2}>
      <Grid item>
        <Subtitle>{title}</Subtitle>
      </Grid>
      <Grid item>
        <AvatarRow users={users} max={max} total={users.length} />
      </Grid>
    </Grid>
  );
};

WidgetData.propTypes = {
  title: PropTypes.string,
  users: PropTypes.array,
  max: PropTypes.number,
};

export default WidgetData;

import React from 'react';
import { useTranslation } from 'react-i18next';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

const PostHeaderDraft = () => {

  const { t } = useTranslation(['component']);

  return (
    <>
      <SaveRoundedIcon />
      <p>{t('post.draft')}</p>
    </>
  );
};

export default PostHeaderDraft;

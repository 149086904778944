import MuiDialogActions from '@mui/material/DialogActions';
import styled from '@emotion/styled/macro';

const DialogActions = styled(MuiDialogActions, {
  shouldForwardProp: prop => !['gray'].includes(prop),
})`
  ${({ gray }) => gray && 'background-color: #F9F9F9;'}
`;

export default DialogActions;

export default {
  getPages: 'pages/GET_PAGES',
  getPagesSuccess: 'pages/GET_PAGES_SUCCESS',
  getPagesFailure: 'pages/GET_PAGES_FAILURE',

  getPage: 'pages/GET_PAGE',
  getPageSuccess: 'pages/GET_PAGE_SUCCESS',
  getPageFailure: 'pages/GET_PAGE_FAILURE',

  store: 'pages/STORE',
  storeSuccess: 'pages/STORE_SUCCESS',
  storeFailure: 'pages/STORE_FAILURE',

  patch: 'PAGES/PATCH',
  patchSuccess: 'PAGES/PATCH_SUCCESS',
  patchFailure: 'PAGES/PATCH_FAILURE',

  update: 'pages/UPDATE',
  updateSuccess: 'pages/UPDATE_SUCCESS',
  updateFailure: 'pages/UPDATE_FAILURE',

  delete: 'pages/DELETE',
  deleteSuccess: 'pages/DELETE_SUCCESS',
  deleteFailure: 'pages/DELETE_FAILURE',
};


import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { birthdayActions, birthdaySelectors } from 'redux/birthday';
import CommentFeed from 'containers/Comment/CommentFeed';
import DrawerContent from 'containers/Side/Drawer/DrawerContent';
import { useTranslation } from 'react-i18next';

const BirthdayComments = ({ eventId }) => {
  const { t } = useTranslation(['component']);
  const dispatch = useDispatch();

  const { commentIds, pagination } = useSelector(state => birthdaySelectors.getComments(state, eventId));
  const loadMore = () => dispatch(birthdayActions.getComments({ eventId, page: pagination.nextPage }));

  return (
    <CommentFeed
      element={DrawerContent}

      compact

      commentIds={commentIds}
      currentPage={pagination.currentPage}
      hasMore={pagination.hasMore}
      loadMore={loadMore}
      useWindow={false}

      texts={{
        loading: t('birthdayEvents.loadingBirthdayComments'),
        loadingMore: t('birthdayEvents.loadingMoreBirthdayComments'),
        noComments: t('birthdayEvents.noBirthdayComments'),
        noCommentsSubtitle: t('birthdayEvents.noBirthdayCommentsSubtitle'),
        noMoreComments: t('birthdayEvents.noMoreBirthdayComments'),
      }}
    />
  );
};

BirthdayComments.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default BirthdayComments;

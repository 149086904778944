import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postActions, postSelectors } from 'redux/post';
import PropTypes from 'prop-types';
import { PostInfoTimestamp, PostInfoChannel, PostInfoReach } from 'components/Post';
import { channelSelectors } from 'redux/channel';
import NavLink from 'components/NavLink';
import PostInfoSms from 'components/Post/PostInfoSms';
import Skeleton from '@mui/material/Skeleton';

const Separated = ({ children, disabled }) => disabled ? children : <>&#65372;{children}</>;

Separated.propTypes = {
  disabled: PropTypes.bool,
};

const PostInfo = ({ postId }) => {

  const post = useSelector(state => postSelectors.getPost(state, postId));

  const channel = useSelector(channelSelectors.getChannelSelector)(post ? post.channel : null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (post.permissions?.viewReach && !post.reach) {
      dispatch(postActions.getReachPercentage({ postId: post.id }));
    }
  }, [dispatch, post.id, post.permissions?.viewReach, post.reach]);

  if (!post) return;

  return (
    <>
      {post.postedAt && <PostInfoTimestamp date={new Date(post.postedAt)} as={NavLink} to={`/post/${post.id}`} />}
      {channel?.name && <Separated disabled={!post.postedAt}><PostInfoChannel channel={channel} /></Separated>}

      {post.permissions?.viewReach && (
        <Separated>
          {!post.reach
            ? <Skeleton width={40} height={15} />
            : <PostInfoReach percentage={+post.reach.reachPercentage} max={+post.reach.maxReach} />}
        </Separated>
      )}

      {post.sms && (
        <Separated>
          <PostInfoSms
            content={post.sms?.content}
            totalSent={post.sms?.totalSent}
            totalSpent={post.sms?.totalSpent}
            isRecent={post.sms?.isRecent}
            isSent={post.sms?.isSent}
          />
        </Separated>
      )}
    </>
  );
};

PostInfo.propTypes = {
  postId: PropTypes.any,
};

export default PostInfo;

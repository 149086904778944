import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';

const config = {
  shouldForwardProp: prop => !['nowrap', 'unstyled'].includes(prop),
};

const Button = styled(LoadingButton, config)`
  ${({ nowrap }) => nowrap && 'white-space: nowrap'};

  ${({ unstyled }) => unstyled && css`
      text-transform: inherit;
      font-weight: inherit;
      color: inherit;
      background: transparent;
      padding: initial;
      min-width: initial;
      &:hover {
        background-color: transparent
      }
  `}
`;

Button.propTypes = {
  nowrap: PropTypes.bool,
  unstyled: PropTypes.bool,
};

export default Button;

import React from 'react';
import AuthenticatedGreeting from 'containers/AuthenticatedGreeting';
import Boosts from 'containers/Boosts';
import HomeFeed from 'containers/HomeFeed';
import ActiveChallenges from 'containers/Challenge/ActiveChallenges';
import Grid from 'components/Grid';
import CalendarWidget from 'containers/Calendar/CalendarWidget';
import BirthdayAndJobAnniversaryWidget from 'containers/BirthdayAndJobAnniversaryWidget/BirthdayAndJobAnniversaryWidget';

const greetingProps = {
  avatarProps: {
    style: { display: 'none' },
  },
};

const Home = () => (
  <Grid container spacing={5} direction="column" wrap="nowrap">
    <Grid item><AuthenticatedGreeting {...greetingProps} /></Grid>
    <Grid item component={Boosts} />
    <Grid item component={ActiveChallenges} />
    <Grid item component={CalendarWidget} />
    <Grid item component={BirthdayAndJobAnniversaryWidget} />
    <Grid item component={HomeFeed} />
  </Grid>
);

export default Home;

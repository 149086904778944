import { all, takeLeading, put } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { snackbarActions } from 'redux/snackbar';
import types from './types';
import actions from './actions';
import { takeLeadingPerKey } from '../helpers/saga';

function* onGetComment({ payload }) {
  const { ok, error, response } = yield apiCall(api.comment.get, payload);

  if (ok) {
    yield put(actions.getCommentSuccess({ commentId: payload.commentId, ...response }));
  } else {
    yield put(actions.getCommentFailure(error));
  }
}

function* onGetLikes({ payload }) {
  const { ok, response, error } = yield apiCall(api.comment.getLikes, payload);

  if (ok) {
    response.commentId = payload.commentId;
    yield put(actions.getLikesSuccess(response));
  } else {
    yield put(actions.getLikesFailure(error));
  }
}

function* onAddLike({ payload }) {
  const { ok, error } = yield apiCall(api.comment.addLike, payload);

  yield put(actions.getComment({ commentId: payload.commentId }));

  if (ok) {
    yield put(actions.addLikeSuccess(payload));
  } else {
    yield put(actions.addLikeFailure(error));
  }
}

function* onRemoveLike({ payload }) {
  const { ok, error } = yield apiCall(api.comment.removeLike, payload);

  yield put(actions.getComment({ commentId: payload.commentId }));

  if (ok) {
    yield put(actions.removeLikeSuccess(payload));
  } else {
    yield put(actions.removeLikeFailure(error));
  }
}

function* onCommentUpdate({ payload }) {
  const { ok, error } = yield apiCall(api.comment.update, payload);

  yield put(actions.getComment({ commentId: payload.commentId }));

  if (ok) {
    yield put(actions.updateSuccess(payload));
    yield put(snackbarActions.createSuccess('comment.updated'));
  } else {
    yield put(actions.updateFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onCommentDelete({ payload }) {
  const { ok, error } = yield apiCall(api.comment.delete, payload);

  if (ok) {
    yield put(actions.deleteSuccess(payload));
    yield put(snackbarActions.createSuccess('comment.deleted'));
  } else {
    yield put(actions.deleteFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetTranslation({ payload }) {
  const { ok, error, response } = yield apiCall(api.comment.getTranslation, payload);

  if (ok) {
    response.commentId = payload.commentId;
    yield put(actions.getTranslationSuccess(response));
  } else {
    yield put(actions.getTranslationFailure(error));
  }
}

export default function* commentSagas() {
  yield all([
    takeLeading(types.getComment, onGetComment),
    takeLeading(types.update, onCommentUpdate),
    takeLeading(types.delete, onCommentDelete),
    takeLeadingPerKey(types.getLikes, onGetLikes, ({ payload }) => payload.commentId),
    takeLeading(types.addLike, onAddLike),
    takeLeading(types.removeLike, onRemoveLike),
    takeLeading(types.getTranslation, onGetTranslation),
  ]);
}

export default {
  getContact: 'contact/GET_CONTACT',
  getContactSuccess: 'contact/GET_CONTACT_SUCCESS',
  getContactFailure: 'contact/GET_CONTACT_FAILURE',

  storeContact: 'contact/STORE_CONTACT',
  storeContactSuccess: 'contact/STORE_CONTACT_SUCCESS',
  storeContactFailure: 'contact/STORE_CONTACT_FAILURE',

  updateContact: 'contact/UPDATE_CONTACT',
  updateContactSuccess: 'contact/UPDATE_CONTACT_SUCCESS',
  updateContactFailure: 'contact/UPDATE_CONTACT_FAILURE',

  deleteContact: 'contact/DELETE_CONTACT',
  deleteContactSuccess: 'contact/DELETE_CONTACT_SUCCESS',
  deleteContactFailure: 'contact/DELETE_CONTACT_FAILURE',
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceActions, workspaceSelectors } from 'redux/workspace';
import debounce from 'lodash/debounce';
import TopBarContext from './Contexts/TopBarContext';
import SettersContext from './Contexts/SettersContext';
import ViewContext from './Contexts/ViewContext';

const ContactDirectoryProvider = ({ filters, onAddContactClick, onItemClick, children }) => {

  const dispatch = useDispatch();

  const [tree, $setTree] = useState(true);
  const [search, $setSearch] = useState('');
  const [buttons, setButtons] = useState(null);

  const { items, pagination } = useSelector(tree
    ? workspaceSelectors.getContactDirectoryMembersTree
    : workspaceSelectors.getContactDirectoryItemList);

  const load = useCallback((overrides) => {
    const payload = {
      sort: (overrides?.tree ?? tree) ? 'structural_unit' : 'name',
      page: overrides?.page ?? 0,
      search: overrides?.search ?? search ?? undefined,
      ...filters,
    };

    dispatch(workspaceActions.getContactDirectory(payload));
  }, [dispatch, filters, search, tree]);

  const debouncedLoad = useMemo(() => debounce(load, 300), [load]);

  // Load first page on init
  useEffect(() => {
    load({ page: 1 });
  }, [filters, load]);

  const setSearch = useCallback((nextSearch) => {
    $setSearch(nextSearch);
    debouncedLoad({ page: 1, search: nextSearch });
  }, [debouncedLoad]);

  const setTree = useCallback((nextTree) => {
    $setTree(nextTree);
    load({ page: 1, tree: nextTree });
  }, [load]);

  const loadMore = useCallback(() => {
    load({ page: pagination?.nextPage });
  }, [load, pagination?.nextPage]);

  const settersContext = useMemo(() => ({ setTree, setSearch, setButtons }), [setSearch, setTree]);

  const viewContext = useMemo(() => ({ tree, items, pagination, loadMore, onItemClick, onAddContactClick }), [items, loadMore, onAddContactClick, onItemClick, pagination, tree]);

  const topBarContext = useMemo(() => ({ search, buttons }), [buttons, search]);

  return (
    <SettersContext.Provider value={settersContext}>
      <ViewContext.Provider value={viewContext}>
        <TopBarContext.Provider value={topBarContext}>
          {children}
        </TopBarContext.Provider>
      </ViewContext.Provider>
    </SettersContext.Provider>
  );
};

ContactDirectoryProvider.propTypes = {
  onItemClick: PropTypes.func.isRequired,

  filters: PropTypes.object,
  onAddContactClick: PropTypes.func,
};

export default ContactDirectoryProvider;

import memoize from 'lodash/memoize';
import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';

const getEventsByStatus = createSelector(
  [state => state.calendar.events],
  events => memoize(
    status => selectPagination(events, status || 'upcoming', 'eventIds'),
  ),
);
const getUpcomingEvents = state => getEventsByStatus(state)('upcoming');

const getEventEntities = ({ entities: { events } }) => events;
const getEventSelector = createSelector(
  [getEventEntities],
  events => memoize(
    eventId => events[eventId],
  ),
);

export default {
  getEventsByStatus,
  getUpcomingEvents,

  getEventSelector,
};

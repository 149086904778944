export default {
  get: 'group/GET',
  getSuccess: 'group/GET_SUCCESS',
  getFailure: 'group/GET_FAILURE',

  store: 'group/STORE',
  storeSuccess: 'group/STORE_SUCCESS',
  storeFailure: 'group/STORE_FAILURE',

  update: 'group/UPDATE',
  updateSuccess: 'group/UPDATE_SUCCESS',
  updateFailure: 'group/UPDATE_FAILURE',

  delete: 'group/DELETE',
  deleteSuccess: 'group/DELETE_SUCCESS',
  deleteFailure: 'group/DELETE_FAILURE',

  subscribe: 'group/SUBSCRIBE',
  subscribeSuccess: 'group/SUBSCRIBE_SUCCESS',
  subscribeFailure: 'group/SUBSCRIBE_FAILURE',

  getMembers: 'group/GET_MEMBERS',
  getMembersSuccess: 'group/GET_MEMBERS_SUCCESS',
  getMembersFailure: 'group/GET_MEMBERS_FAILURE',
};

import React, { useRef, useState, useEffect } from 'react';
import useResize from 'hooks/useResize';
import { isMobile } from 'helpers';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

const generateCss = ({ wrapperWidth, keyboardHeight }) => {
  // This logic is written here to avoid doing the same calcualations
  // in every post and to reduce listeners

  // Allow image height be % of width (3/4, 1, 9/16 etc.)
  const ratio = 3 / 2;

  // Calculate preferred height. Never allow it to go below 300px
  // +5 to avoid possible rounding errors
  const ratioHeight = wrapperWidth * ratio + 5;
  const maxHeight = ratioHeight < 300 ? 300 : ratioHeight;

  // Percentage
  const maxOfScreen = isMobile ? 0.8 : 0.9;

  return css`
    object-fit: contain;

    width: 100%;
    height: 100%;

    /* Start with sensible default if all else fails */
    max-height: 400px;

    /* Set max-height to be calculated height value */
    max-height: ${maxHeight}px;

    /* If max-height is more than xx% of whole screen,
      then limit the maximum height to be yy%

      keyboardHeight is larger than 0 only on mobile devices when keyboard is open */
    @media(max-height: ${maxHeight / maxOfScreen + (keyboardHeight || 0)}px) {
      max-height: ${maxOfScreen * 100}vh;
    }

    /* ...also make sure that the previous yy% doesn't go below 300px */
    @media(max-height: ${300 / maxOfScreen}px) {
      max-height: 300px;
    }
  `;
};

const config = {
  shouldForwardProp: prop => !['wrapperWidth', 'keyboardHeight'].includes(prop),
};

const StyledFeed = styled('div', config)`
  & .feed-image img,
  & img.feed-image {
    ${generateCss};
  }
`;

const Feed = ({ children, ...rest }) => {

  const ref = useRef();

  const [wrapperWidth, setWrapperWidth] = useState(750);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const calculateWidth = () => {
    setWrapperWidth(current => ref.current && ref.current.offsetWidth ? ref.current.offsetWidth : current);
  };

  useResize(calculateWidth, { passive: true });
  useEffect(() => {
    calculateWidth();
  }, [ref]);

  const fnSet = event => setKeyboardHeight(event.keyboardHeight);
  const fnRemove = () => setKeyboardHeight(0);

  useEffect(() => {
    if (!isMobile) return;

    window.addEventListener('keyboardWillShow', fnSet, { passive: true });
    window.addEventListener('keyboardWillHide', fnRemove, { passive: true });
    return () => {
      window.removeEventListener('keyboardWillShow', fnSet, { passive: true });
      window.removeEventListener('keyboardWillHide', fnRemove, { passive: true });
    };
  }, []);

  return (
    <StyledFeed
      ref={ref}
      wrapperWidth={wrapperWidth}
      keyboardHeight={keyboardHeight}
      {...rest}
    >
      {children}
    </StyledFeed>
  );
};

export default Feed;

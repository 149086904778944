import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import styled from '@emotion/styled/macro';
import { ReactComponent as EmojiSadColoredSvg } from './assets/emoji-sad-colored.svg';

const StyledSvgIcon = styled(SvgIcon)`
  color: #FF878A;
`;

const EmojiSadColoredIcon = args => <StyledSvgIcon component={EmojiSadColoredSvg} {...args} />;

export default EmojiSadColoredIcon;

import React, { useCallback, useEffect, useState } from 'react';
import Grid from 'components/Grid';
import FormField from 'components/FinalForm/Fields/FormField';
import { useField, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';
import { count } from 'sms-length';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import { postSelectors } from 'redux/post';
import getErrorProperties from 'components/FinalForm/getErrorProperties';
import PostFormContent from '../Partials/PostFormContent';

const SmsContentHelperText = ({ charactersCount, partsCount }) => {

  const { t } = useTranslation('component');
  const { meta } = useField('content');
  const contentErrors = getErrorProperties(meta);

  return (
    <Grid container justifyContent="space-between">
      <Grid item>{contentErrors.helperText}</Grid>
      <Grid item>
        <Grid container wrap="nowrap" spacing={4} justifyContent="flex-end">
          <Grid item>
            {t('smsForm.characterCountLabel')}: {charactersCount}
          </Grid>
          <Grid item>
            {t('smsForm.partCountLabel')}: {partsCount}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SmsContentHelperText.propTypes = {
  charactersCount: PropTypes.number.isRequired,
  partsCount: PropTypes.number.isRequired,
};

const SmsTargetHelperText = ({ targetMembersCount }) => {

  const { t } = useTranslation('component');

  return (
    <Grid container direction="row" wrap="nowrap" spacing={4} justifyContent="flex-end">
      <Grid item>
        {t('smsForm.targetMembersTitle')}: {targetMembersCount}
      </Grid>
    </Grid>
  );
};

SmsTargetHelperText.propTypes = {
  targetMembersCount: PropTypes.number.isRequired,
};

const SmsViewContent = () => {

  const { t } = useTranslation('component');

  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });

  const [smsCharactersCount, setSmsCharactersCount] = useState(0);
  const [smsPartsCount, setSmsPartsCount] = useState(0);

  const smsExpenseEstimation = useSelector(postSelectors.getPostSmsExpenseEstimation);

  const handleSmsContent = useCallback((content = '') => {
    setSmsCharactersCount(content.length);
    setSmsPartsCount(count(content).messages);
  }, [setSmsCharactersCount, setSmsPartsCount]);

  useEffect(() => {
    handleSmsContent(values.content);
  }, [handleSmsContent, values.content]);

  return (
    <PostFormContent>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <FormControl margin="none">
            <InputLabel id="target">{t('smsForm.targetMembersTitle')}</InputLabel>
            <Select labelId="target" value="ALL_PHONE_WITHOUT_EMAIL">
              <MenuItem value="ALL_PHONE_WITHOUT_EMAIL">
                {t('smsForm.targetMembersDescription')}
              </MenuItem>
            </Select>
            <FormHelperText>
              <SmsTargetHelperText targetMembersCount={smsExpenseEstimation.totalRecipient} />
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <FormField
            name="content"
            label={t('smsForm.contentLabel')}
            placeholder={t('smsForm.contentPlaceholder')}
            multiline
            rows={4}
            helperText={(
              <SmsContentHelperText
                charactersCount={smsCharactersCount}
                partsCount={smsPartsCount}
              />
            )}
          />
        </Grid>
      </Grid>
    </PostFormContent>
  );
};

export default SmsViewContent;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import DateFormat from 'components/DateFormat';
import Nbsp from 'components/Nbsp';

const PostHeaderScheduled = ({ scheduledAt }) => (
  <>
    <AccessTimeRoundedIcon />
    <Trans debug i18nKey="component:post.scheduled" values={{ scheduledAt }}>
      Post is scheduled for<Nbsp /><DateFormat date={scheduledAt} />
    </Trans>
  </>
);

PostHeaderScheduled.propTypes = {
  scheduledAt: PropTypes.any, // TODO
};

export default PostHeaderScheduled;

import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { workspaceActions, workspaceSelectors } from 'redux/workspace';
import { useTranslation } from 'react-i18next';
import AutoCompleteField from 'components/FinalForm/Fields/AutoCompleteField';
import orderBy from 'lodash/orderBy';
import { isMobile } from 'helpers';
import SelectField from 'components/FinalForm/Fields/SelectField';

const prepareChannels = (channels, t) => channels
  .filter(c => !!c.permissions.post)
  .map(channel => ({
    id: channel.id,
    label: t(`channel.${channel.name}`),
  }));

const prepareGroups = (groups, t, getChannelSelector) => {
  const options = groups
    .filter(group => !!getChannelSelector(group.channel)?.permissions?.post)
    .map(group => ({
      id: group.channelId,
      label: `#${group.name}${group.private ? ` (${t('common:generic.private')})` : ''}`,
    }));

  return orderBy(options, ['label'], ['asc']);
};

const ChannelSelectField = args => {

  const dispatch = useDispatch();

  const { t } = useTranslation(['common', 'component']);

  const channels = useSelector(workspaceSelectors.getWorkspaceChannels);
  const groups = useSelector(workspaceSelectors.getGroups);
  const getChannelSelector = useSelector(workspaceSelectors.getChannelSelector);

  useEffect(() => {
    dispatch(workspaceActions.getGroups());
    dispatch(workspaceActions.getWorkspaceChannels());
  }, [dispatch]);

  const options = useMemo(() => [
    ...prepareChannels(channels, t),
    ...prepareGroups(groups, t, getChannelSelector),
  ], [channels, groups, getChannelSelector, t]);

  if (isMobile) {
    return (
      <SelectField name="channelId" native label={t('component:postChannelSelect.label')} displayEmpty {...args}>
        <option value="">{t('component:postChannelSelect.emptyStateChannelPlaceholder')}</option>
        {options.map(option => (
          <option value={option.id} key={option.id}>{option.label}</option>
        ))}
      </SelectField>
    );
  }

  return (
    <AutoCompleteField
      name="channelId"
      label={t('component:postChannelSelect.label')}
      placeholder={t('component:postChannelSelect.emptyStateChannelPlaceholder')}
      options={options}
      getOptionLabel={option => option?.label ?? ''}
      disableClearable
      parse={option => option.id}
      format={id => options.find(option => option.id === id)}
      {...args}
    />
  );
};

export default ChannelSelectField;

import React, { } from 'react';
import Button from 'components/Button';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import PropTypes from 'propTypes';
import { useTranslation } from 'react-i18next';

const ManageContactsButton = ({ isManageMode, ...rest }) => {

  const { t } = useTranslation(['component']);

  return (
    <Button
      variant="text"
      size="small"
      startIcon={isManageMode ? <DoneRoundedIcon /> : <ManageAccountsRoundedIcon />}
      {...rest}
    >
      {isManageMode ? t('contactDirectory.manageContacts.finish') : t('contactDirectory.manageContacts.start')}
    </Button>
  );
};

ManageContactsButton.propTypes = {
  isManageMode: PropTypes.bool,
};

export default ManageContactsButton;

import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import Poll from 'containers/Poll/Poll';
import { PostContext } from 'components/Post';

const Wrapper = styled('div')`
  margin: 1rem;
`;

const PostPoll = args => {

  const { translated } = useContext(PostContext);

  return (
    <Wrapper>
      <Poll
        {...args}
        translated={translated}
      />
    </Wrapper>
  );
};

export default PostPoll;

import React, { } from 'react';
import IconButton from '@mui/material/IconButton';
import CallIcon from '@mui/icons-material/Call';
import { useTranslation } from 'react-i18next';
import PropTypes from 'propTypes';

const TelephoneQuickAction = ({ value, ...rest }) => {

  const { t } = useTranslation(['component']);

  return (
    <IconButton
      component="a"
      href={`tel:${value}`}
      aria-label={t('contactDirectory.itemInfo.callToButton')}
      title={t('contactDirectory.itemInfo.callToButton')}
      {...rest}
    >
      <CallIcon />
    </IconButton>
  );
};

TelephoneQuickAction.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TelephoneQuickAction;

export default {
  getLessonsByCourseVariantForMember: 'lesson/GET_LESSONS_BY_COURSE_VARIANT_FOR_MEMBER',
  getLessonsByCourseVariantForMemberSuccess: 'lesson/GET_LESSONS_BY_COURSE_VARIANT_FOR_MEMBER_SUCCESS',
  getLessonsByCourseVariantForMemberFailure: 'lesson/GET_LESSONS_BY_COURSE_VARIANT_FOR_MEMBER_FAILURE',

  getLessonForMember: 'lesson/GET_LESSON_FOR_MEMBER',
  getLessonForMemberSuccess: 'lesson/GET_LESSON_FOR_MEMBER_SUCCESS',
  getLessonForMemberFailure: 'lesson/GET_LESSON_FOR_MEMBER_FAILURE',

  getLessonsByCourseVariantForAdmin: 'lesson/GET_LESSONS_BY_COURSE_VARIANT_FOR_ADMIN',
  getLessonsByCourseVariantForAdminSuccess: 'lesson/GET_LESSONS_BY_COURSE_VARIANT_FOR_ADMIN_SUCCESS',
  getLessonsByCourseVariantForAdminFailure: 'lesson/GET_LESSONS_BY_COURSE_VARIANT_FOR_ADMIN_FAILURE',

  getLessonForAdmin: 'lesson/GET_LESSON_FOR_ADMIN',
  getLessonForAdminSuccess: 'lesson/GET_LESSON_FOR_ADMIN_SUCCESS',
  getLessonForAdminFailure: 'lesson/GET_LESSON_FOR_ADMIN_FAILURE',

  postLessonImageForAdmin: 'lesson/POST_LESSON_IMAGE_FOR_ADMIN',
  postLessonImageForAdminSuccess: 'lesson/POST_LESSON_IMAGE_FOR_ADMIN_SUCCESS',
  postLessonImageForAdminFailure: 'lesson/POST_LESSON_IMAGE_FOR_ADMIN_FAILURE',

  postLessonForAdmin: 'lesson/POST_LESSON_FOR_ADMIN',
  postLessonForAdminSuccess: 'lesson/POST_LESSON_FOR_ADMIN_SUCCESS',
  postLessonForAdminFailure: 'lesson/POST_LESSON_FOR_ADMIN_FAILURE',

  putLessonForAdmin: 'lesson/PUT_LESSON_FOR_ADMIN',
  putLessonForAdminSuccess: 'lesson/PUT_LESSON_FOR_ADMIN_SUCCESS',
  putLessonForAdminFailure: 'lesson/PUT_LESSON_FOR_ADMIN_FAILURE',

  deleteLessonForAdmin: 'lesson/DELETE_LESSON_FOR_ADMIN',
  deleteLessonForAdminSuccess: 'lesson/DELETE_LESSON_FOR_ADMIN_SUCCESS',
  deleteLessonForAdminFailure: 'lesson/DELETE_LESSON_FOR_ADMIN_FAILURE',

  reorderLessonsForAdmin: 'lesson/REORDER_LESSONS_FOR_ADMIN',
  reorderLessonsForAdminSuccess: 'lesson/REORDER_LESSONS_FOR_ADMIN_SUCCESS',
  reorderLessonsForAdminFailure: 'lesson/REORDER_LESSONS_FOR_ADMIN_FAILURE',
};

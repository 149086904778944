import React from 'react';
import fieldRenderProps from './fieldRenderProps';
import getErrorProperties from './getErrorProperties';

const withFlatArgs = (Component, debugName = null) => {
  const FlatArgsComponent = ({ input, meta, ...rest }) => <Component {...input} {...getErrorProperties(meta)} {...rest} />;

  const componentName = debugName || Component.displayName || Component.name || 'Component';
  const displayName = `withFlatArgs(${componentName})`;

  FlatArgsComponent.displayName = displayName;
  Object.defineProperty(FlatArgsComponent, 'name', { value: displayName });

  FlatArgsComponent.propTypes = {
    ...fieldRenderProps,
  };

  return FlatArgsComponent;
};

export default withFlatArgs;

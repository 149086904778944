import React from 'react';
import PropTypes from 'propTypes';
import LargeOpengraphPreview from './Previews/LargeOpengraphPreview';
import SmallOpengraphPreview from './Previews/SmallOpengraphPreview';
import InternalOpengraphPreview from './Previews/InternalOpengraphPreview';
import TinyOpengraphPreview from './Previews/TinyOpengraphPreview';

const ExternalPreview = ({ variant, ...rest }) => {
  switch (variant) {
    case 'large':
      return <LargeOpengraphPreview {...rest} />;
    case 'small':
      return <SmallOpengraphPreview {...rest} />;
    case 'tiny':
    default:
      return <TinyOpengraphPreview {...rest} />;
  }
};

ExternalPreview.propTypes = {
  variant: PropTypes.oneOf(['tiny', 'small', 'large']),
};

const OpengraphPreview = ({ guavaInternal, guavaModelId, guavaModelType, ...rest }) => guavaInternal
  ? <InternalOpengraphPreview data-user-content guavaModelId={guavaModelId} guavaModelType={guavaModelType} {...rest} />
  : <ExternalPreview data-user-content {...rest} />;

OpengraphPreview.propTypes = {
  guavaInternal: PropTypes.bool,
  guavaModelId: PropTypes.any,
  guavaModelType: PropTypes.string,

  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ src: PropTypes.string, srcSet: PropTypes.string }),
  ]),
};

export default OpengraphPreview;

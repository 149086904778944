import sanitizeHtml from 'sanitize-html';

const removeEmptyExclusionFilter = frame => ['div', 'p', 'span'].indexOf(frame.tag) > -1
  && !frame.text.trim()
  && (!frame.mediaChildren || frame.mediaChildren.length === 0); // Doesn't have any pictures included

// Disallow everything special in excerpts
export const guidancePostExcerptConfig = { allowedTags: [] };
export const sanitizeGuidancePostExcerpt = text => (text
  ? sanitizeHtml(text, guidancePostExcerptConfig)
  : '');

export const guidancePostConfig = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['span', 'sup', 'img']),
  allowedAttributes: {
    a: ['href', 'name', 'target', 'ref'],
    img: ['src', 'alt', 'srcset'],
    '*': ['style'],
  },
  selfClosing: ['img'],
  allowedStyles: {
    '*': {
      color: [/^(?!#000000).*$/], // Ignore black. Use default
      'font-size': [/^[0-9](\.[0-9]*)?[a-zA-Z%]*$/], // Allow only numbers smaller than 10, whatever unit...
      'text-align': [/^justify$/, /^center$/, /^right$/, /^left$/],
      'text-decoration': [/^underline$/],
      'font-weight': [/^.*$/],
      'font-style': [/^italic$/],
      'vertical-align': [/^super$/],
    },
  },
  exclusiveFilter: removeEmptyExclusionFilter,
};
export const sanitizeGuidancePostBody = text => sanitizeHtml(text, guidancePostConfig);

export const genericConfigValidElements = [
  'h2',
  'h3',
  'p',
  'blockquote',
  'a[href|target=_blank|rel<noopener?nofollow?nofollow]',
  'ul',
  'li',
  'ol',
  'b',
  'i',
  'strong',
  'em',
  'u',
  'strike',
  'br',
].join(',');

const generic = {
  allowedTags: 'h2, h3, p, blockquote, a, ul, li, ol, b, i, strong, em, u, strike, br',
  allowedAttributes: {
    a: ['href', 'name', 'target', 'rel', 'data-user-content'],
  },
  allowedSchemes: ['http', 'https', 'mailto', 'tel', 'sms', 'geo'],
  exclusiveFilter: removeEmptyExclusionFilter,
  transformTags: {
    a: (tagName, attribs) => ({
      tagName,
      attribs: {
        ...attribs,
        'data-user-content': true,
      },
    }),
  },
};

const lessonBodyAllowedStyles = {
  div: ['top', 'left', 'position', 'padding-bottom', 'max-width'],
  iframe: ['width', 'height', 'position'],
  ul: ['list-style-type'],
  img: ['vertical-align', 'max-height', 'max-width'],
  td: ['background-color', 'background'],
  span: ['background-color'],
};

const getLessonAllowedRegex = (allowedStyles) => !allowedStyles || allowedStyles?.length === 0
  ? new RegExp('^$')
  : new RegExp(`(?:${(allowedStyles).join('|')}):[\\s\\w\\d.,()%]+`);

const parseLessonInlineStyles = (style, allowedStyles) => style.split(';')
  .map(e => e.trim())
  .filter(e => getLessonAllowedRegex(allowedStyles).test(e)).join('; ');

window.getLessonAllowedRegex = getLessonAllowedRegex;

const parseStyles = (attribs, allowedStyles) => {

  const result = attribs;

  if (attribs.style) {
    const parsedStyles = parseLessonInlineStyles(attribs.style, allowedStyles);

    if (!parsedStyles) {
      delete result.style;
    } else {
      result.style = parsedStyles;
    }
  }

  return result;
};

const lessonBodySanitizationRules = {
  allowedTags: 'h1, h2, h3, h4, p, blockquote, a, ul, li, ol, b, i, strong, em, u, strike, br, img, iframe, table, thead, th, tbody, tr, td, hr, audio, figure, div, span',
  allowedAttributes: {
    a: ['href', 'name', 'target', 'rel', 'data-user-content'],
    ol: ['type', 'start'],
    ul: ['style'],
    img: ['src', 'alt', 'srcset', 'width', 'style'],
    table: ['style'],
    td: ['width', 'colspan', 'rowspan', 'align', 'style'],
    th: ['align', 'colspan'],
    iframe: ['width', 'height', 'src', 'frameborder', 'allow', 'allowfullscreen', 'style'],
    audio: ['src', 'controls'],
    figure: ['class', 'style'],
    div: ['style', 'data-oembed-url'],
    '*': ['data-ephox-embed-iri'],
    p: ['style'],
    span: ['style'],
  },
  allowedSchemes: ['http', 'https', 'mailto', 'tel', 'sms', 'geo'],
  parseStyleAttributes: false, // Style parsing doesn't work in browsers!
  allowedClasses: {
    figure: ['media', 'image', 'image_resized'],
  },
  transformTags: {
    // TODO: Iframe styles, resolve for all and disallow styles
    div: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, ['top', 'left', 'position', 'padding-bottom', 'max-width']) }),
    ul: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, ['list-style-type']) }),
    li: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, []) }),
    img: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, ['vertical-align', 'max-height', 'max-width']) }),
    table: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, []) }),
    td: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, ['background-color', 'background']) }),
    iframe: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, ['width', 'height', 'position']) }),
    figure: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, []) }),
    p: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, []) }),
    span: (tagName, attribs) => ({ tagName, attribs: parseStyles(attribs, ['background-color']) }),
    a: (tagName, attribs) => ({
      tagName,
      attribs: {
        ...attribs,
        ...(attribs.href?.[0] !== '#' && { target: '_blank' }),
        'data-user-content': true,
        rel: 'noopener noreferrer nofollow',
      },
    }),
  },
};

export const lessonBodyTinyMceValidElements = lessonBodySanitizationRules.allowedTags.split(',').map(tag => {
  const allowedTag = tag.trim();
  const allowedAttributes = lessonBodySanitizationRules.allowedAttributes[allowedTag] || [];

  return allowedAttributes.length > 0 ? `${allowedTag}[${allowedAttributes.join('|')}]` : allowedTag;
}).join(',');

export const lessonBodyTinyMceValidStyles = Object.entries(lessonBodyAllowedStyles)
  .reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value.join(','),
  }), {});

export const pageContentSanitizationRules = lessonBodySanitizationRules;
export const pageContentTinyMceValidElements = lessonBodyTinyMceValidElements;
export const pageContentTinyMceValidStyles = lessonBodyTinyMceValidStyles;

export const sanitizePostBody = (text, config) => text ? sanitizeHtml(text, { ...generic, ...config }) : text;
export const sanitizeWorkspaceProfile = (text, config) => text ? sanitizeHtml(text, { ...generic, ...config }) : text;
export const sanitizeEventDescription = (text, config) => text ? sanitizeHtml(text, { ...generic, ...config }) : text;
export const sanitizeCourseDescription = (text, config) => text ? sanitizeHtml(text, { ...generic, ...config }) : text;
export const sanitizeQuizExplanation = (text, config) => text ? sanitizeHtml(text, { ...generic, ...config }) : text;
export const sanitizePageContent = (text, config) => text ? sanitizeHtml(text, { ...pageContentSanitizationRules, ...config }) : text;
export const sanitizeLessonBody = (text, config) => text ? sanitizeHtml(text, { ...lessonBodySanitizationRules, ...config }) : text;
export const sanitizeSectionDescription = (text, config) => text ? sanitizeHtml(text, { ...generic, ...config }) : text;

export const sanitizeLinkify = (text, config) => sanitizeHtml(text, {
  allowedTags: 'a',
  allowedAttributes: {
    a: ['href', 'name', 'target', 'rel'],
  },
  allowedSchemes: ['http', 'https', 'mailto', 'tel', 'sms', 'geo'],
  disallowedTagsMode: 'escape',
  ...config,
});

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { InView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { analyticsActions } from 'redux/analytics';
import PostContext from './PostContext';

const Wrapper = styled('div')`
  background: #fff;

  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
              0px 2px 6px rgba(0, 0, 0, 0.05);

  border-radius: 4px;

  border: 1px solid rgba(0, 0, 0, 0.04);

  ${({ isImportant, isCompact }) => isImportant && isCompact && css`
    background: rgba(244, 55, 86, 0.04);
    border: 2px solid rgba(244, 55, 86, 1);
  `};

  ${({ isDraft }) => isDraft && css`
    border: 2px dashed #ccc;
    box-shadow: none;
  `}
`;

const PostWrapper = React.forwardRef(({ postId, ...rest }, ref) => {

  const dispatch = useDispatch();

  const { isImportant, isCompact, isDraft } = useContext(PostContext);

  const onInViewChange = inView => {
    if (!inView || !postId) {
      return;
    }

    dispatch(analyticsActions.trackEvent({ type: isCompact ? 'post-seen' : 'post-read', postId }));
  };

  const inViewArgs = {
    threshold: isCompact ? 0.5 : 0,
    onChange: onInViewChange,
    triggerOnce: true,
  };

  return (
    <InView as={Wrapper} isCompact={isCompact} isImportant={isImportant} isDraft={isDraft} {...inViewArgs} ref={ref} {...rest} />
  );
});

PostWrapper.propTypes = {
  postId: PropTypes.any,
};

export default PostWrapper;

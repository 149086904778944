import { all, put, take, race } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { takeLatestPerKey, takeLeadingPerKey } from 'redux/helpers/saga';
import { snackbarActions } from 'redux/snackbar';
import types from './types';
import actions from './actions';

function* onGetQuiz({ payload }) {

  const abortPattern = action => (action.type === types.storeAnswer || action.type === types.deleteAnswer)
    && action.payload.quizId === payload.quizId;

  const { request, abort } = yield race({
    request: apiCall(api.quiz.getQuiz, payload),
    abort: take(abortPattern),
  });

  if (abort) {
    put(actions.getQuiz({ quizId: payload.quizId }));
    return;
  }

  const { ok, error, response } = request;

  if (ok) {
    yield put(actions.getQuizSuccess(response));
  } else {
    yield put(actions.getQuizFailure(error));
  }
}

function* onStoreAnswer({ payload }) {
  const { optionId, quizId } = payload;
  const { ok, response, error } = yield apiCall(api.quiz.storeAnswer, payload);

  if (ok) {
    yield put(actions.storeAnswerSuccess(response));
  } else {
    yield put(actions.storeAnswerFailure({ optionId, quizId, ...error }));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDownloadAnswers({ payload }) {
  const { ok, error } = yield apiCall(api.quiz.downloadAnswers, payload);

  if (ok) {
    yield put(actions.downloadAnswersSuccess(payload));
  } else {
    yield put(actions.downloadAnswersFailure(error));
  }
}

export default function* postSagas() {
  yield all([
    takeLatestPerKey(types.getQuiz, onGetQuiz, ({ payload }) => payload.quizId),
    takeLeadingPerKey(types.storeAnswer, onStoreAnswer, ({ payload }) => payload.optionId),
    takeLeadingPerKey(types.downloadAnswers, onDownloadAnswers, ({ payload }) => payload.quizId),
  ]);
}

import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

const getContactEntities = ({ entities: { contacts } }) => contacts;
const getContactSelector = createSelector(
  [getContactEntities],
  (contacts) => memoize((contactId) => contacts?.[contactId]),
);

export default {
  getContactSelector,
};

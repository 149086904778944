import memoize from 'lodash/memoize';
import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';

const getPostEntities = state => state.entities.posts;

const getFeeds = ({ post: { feeds } }) => feeds;

const getFeedSelector = createSelector(
  [getFeeds],
  (feeds) => memoize(feedName => selectPagination(feeds, feedName, 'postIds')),
);

const getPost = (state, postId) => getPostEntities(state)[postId];

const getPostSelector = createSelector(
  [getPostEntities],
  entities => memoize(postId => entities?.[postId]),
);

const getPostCommentFeed = ({ post: { commentsByPostId } }, postId) => selectPagination(commentsByPostId, postId, 'commentIds');

const getLikesByPostId = (state, postId) => {
  const getLikeEntities = state.entities.likes;
  const getLikeIdsByPostId = state.post.likesByPostId[postId];

  if (getLikeEntities && getLikeIdsByPostId) {
    return getLikeIdsByPostId.map(id => getLikeEntities[id]);
  }

  return null;
};

export const getPostSmsExpenseEstimation = ({ post: { smsExpense } }) => smsExpense;

export default {
  getFeedSelector,

  getPost,
  getPostSelector,
  getPostCommentFeed,
  getLikesByPostId,
  getPostSmsExpenseEstimation,
};

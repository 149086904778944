import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jobAnniversaryActions, jobAnniversarySelectors } from 'redux/jobAnniversary';
import DrawerFooter from 'containers/Side/Drawer/DrawerFooter';
import DrawerBody from 'containers/Side/Drawer/DrawerBody';
import SideContext from 'containers/Side/SideContext';
import NavLink from 'components/NavLink';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { useQueryParam, NumberParam } from 'use-query-params';
import EventHeader from '../Commons/EventHeader';
import AddComment from '../Commons/AddComment';
import JobAnniversaryComments from './JobAnniversaryComments';
import { getCloseButtonLink, getJobAnniversariesLink } from '../memberEventsService';
import CountBadge from '../Commons/CountBadge';

const Name = styled('span')``;

const Title = styled('div')`
  display: flex;

  ${Name} {
    margin-right: 0.5rem;
  }
`;

const JobAnniversary = ({ memberId }) => {
  const dispatch = useDispatch();

  const { setTitle } = useContext(SideContext);

  const { id, member, yearsCount, cover, permissions } = useSelector(jobAnniversarySelectors.getJobAnniversaryEvent);

  const [year] = useQueryParam('year', NumberParam);

  useEffect(() => {
    if (!memberId) return;
    dispatch(jobAnniversaryActions.getJobAnniversaryEvent({ memberId, year }));
  }, [dispatch, memberId, year]);

  useEffect(() => {
    setTitle({
      children: (
        <Title>
          <Name>{[member?.firstName, member?.lastName].filter(v => !!v).join(' ')}</Name>
          {yearsCount !== undefined && <CountBadge>{yearsCount}</CountBadge>}
        </Title>
      ),
      closeButton: { component: NavLink, to: getCloseButtonLink },
      backButton: { component: NavLink, to: getJobAnniversariesLink },
    });
  }, [member, setTitle, yearsCount]);

  return (
    <>
      <DrawerBody>
        <EventHeader cover={cover} member={member} />
        {id && <JobAnniversaryComments eventId={id} />}
      </DrawerBody>
      <DrawerFooter>
        <AddComment type="JobAnniversary" disabled={!permissions?.comment} eventId={id} />
      </DrawerFooter>
    </>
  );
};

JobAnniversary.propTypes = {
  memberId: PropTypes.any,
};

export default JobAnniversary;

import React, { } from 'react';
import styled from '@emotion/styled/macro';
import Stack from '@mui/material/Stack';
import PropTypes from 'propTypes';
import OpengraphImage from '../OpengraphImage';

const Wrapper = styled('div')`
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
`;

const Image = styled(OpengraphImage)`
  max-width: 30%;
  min-width: 100px;
  flex: 0.2 1 20%;
`;

const Url = styled('div')`
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
`;

const Description = styled('p')`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

const Title = styled('h3')`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;


const Content = styled('div')`
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;

  flex: 1 0.2 80%;
`;


const SmallOpengraphPreview = ({ url, title, description, image, ...rest }) => (
  <Wrapper {...rest}>
    {image && <Image src={image} />}
    <Content>
      <Stack direction="column" spacing={0.5}>
        {url && <Url>{url}</Url>}
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </Stack>
    </Content>
  </Wrapper>
);

SmallOpengraphPreview.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ src: PropTypes.string, srcSet: PropTypes.string }),
  ]),
};

export default SmallOpengraphPreview;

import { all, put, take, race, takeLeading } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { takeLatestPerKey, takeLeadingPerKey } from 'redux/helpers/saga';
import { snackbarActions } from 'redux/snackbar';
import types from './types';
import actions from './actions';

function* onGetPoll({ payload }) {

  const abortPattern = action => (action.type === types.storeVote || action.type === types.deleteVote)
    && action.payload.pollId === payload.pollId;

  const { request, abort } = yield race({
    request: apiCall(api.poll.getPoll, payload),
    abort: take(abortPattern),
  });

  if (abort) {
    put(actions.getPoll({ pollId: payload.pollId }));
    return;
  }

  const { ok, error, response } = request;

  if (ok) {
    yield put(actions.getPollSuccess(response));
  } else {
    yield put(actions.getPollFailure(error));
  }
}

function* onAddOption({ payload }) {
  const { pollId } = payload;
  const { ok, error, response } = yield apiCall(api.poll.addOption, payload);

  if (ok) {
    yield put(actions.addOptionSuccess(response));
    yield put(actions.getPoll({ pollId }));
  } else {
    yield put(actions.addOptionFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onRemoveOption({ payload }) {
  const { pollId, optionId } = payload;
  const { ok, error } = yield apiCall(api.poll.removeOption, {
    pollId,
    optionId,
  });

  if (ok) {
    yield put(actions.removeOptionSuccess(payload));
    yield put(actions.getPoll({ pollId }));
  } else {
    yield put(actions.removeOptionFailure({ pollId, optionId, ...error }));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetVotes({ payload }) {
  const { optionId } = payload;
  const { ok, error, response } = yield apiCall(api.poll.getVotes, { optionId });

  if (ok) {
    yield put(actions.getVotesSuccess({ ...response, optionId }));
  } else {
    yield put(actions.getVotesFailure({ ...error, optionId }));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onStoreVote({ payload }) {
  const { optionId, pollId } = payload;
  const { ok, error } = yield apiCall(api.poll.storeVote, { optionId });

  if (ok) {
    yield put(actions.storeVoteSuccess(payload));
  } else {
    yield put(actions.storeVoteFailure({ optionId, pollId, ...error }));
    yield put(snackbarActions.createFailure(error.message));
  }
  yield put(actions.getPoll({ pollId }));
}

function* onDeleteVote({ payload }) {
  const { optionId, pollId } = payload;

  const { ok, error } = yield apiCall(api.poll.deleteVote, { optionId });

  if (ok) {
    yield put(actions.deleteVoteSuccess(payload));
  } else {
    yield put(actions.deleteVoteFailure({ optionId, pollId, ...error }));
    yield put(snackbarActions.createFailure(error.message));
  }
  yield put(actions.getPoll({ pollId }));
}

function* onDownloadVotes({ payload }) {
  const { ok, error } = yield apiCall(api.poll.downloadVotes, payload);

  if (ok) {
    yield put(actions.downloadVotesSuccess(payload));
  } else {
    yield put(actions.downloadVotesFailure(error));
  }
}

export default function* postSagas() {
  yield all([
    takeLatestPerKey(types.getPoll, onGetPoll, ({ payload }) => payload.pollId),
    takeLeadingPerKey(types.addOption, onAddOption, ({ payload }) => `${payload.pollId}-${payload.option}`),
    takeLeading(types.removeOption, onRemoveOption),
    takeLeadingPerKey(types.getVotes, onGetVotes, ({ payload }) => payload.optionId),
    takeLeadingPerKey(types.storeVote, onStoreVote, ({ payload }) => payload.optionId),
    takeLeadingPerKey(types.deleteVote, onDeleteVote, ({ payload }) => payload.optionId),
    takeLeadingPerKey(types.downloadVotes, onDownloadVotes, ({ payload }) => payload.pollId),
  ]);
}

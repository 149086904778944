import { createAction } from 'redux/helpers/actions';
import types from './types';

const actions = {
  getMemberFeed: data => createAction(types.getMemberFeed, data),
  getMemberFeedSuccess: data => createAction(types.getMemberFeedSuccess, data),
  getMemberFeedFailure: data => createAction(types.getMemberFeedFailure, data),

  getMembersForSearchField: data => createAction(types.getMembersForSearchField, data),
  getMembersForSearchFieldSuccess: data => createAction(types.getMembersForSearchFieldSuccess, data),
  getMembersForSearchFieldFailure: data => createAction(types.getMembersForSearchFieldFailure, data),
};

export default actions;

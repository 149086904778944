import { all, put, takeLeading } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { takeLeadingPerKey } from 'redux/helpers/saga';
import history from 'providers/history';
import types from './types';
import actions from './actions';
import { snackbarActions } from '../snackbar';

function* onGetFoldersForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.getFolders, payload);

  if (ok) {
    yield put(actions.getFoldersForMemberSuccess(response));
  } else {
    yield put(actions.getFoldersForMemberFailure(error));
  }
}

function* onGetFolderForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.getFolder, payload);

  if (ok) {
    yield put(actions.getFolderForMemberSuccess(response));
  } else {
    yield put(actions.getFolderForMemberFailure(error));
  }
}

function* onGetFoldersForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.getFoldersForAdmin, payload);

  if (ok) {
    yield put(actions.getFoldersForAdminSuccess(response));
  } else {
    yield put(actions.getFoldersForMemberFailure(error));
  }
}

function* onGetFolderForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.getFolderForAdmin, payload);

  if (ok) {
    yield put(actions.getFolderForAdminSuccess(response));
  } else {
    yield put(actions.getFolderForAdminFailure(error));
  }
}

function* onPostFolderForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.postFolderForAdmin, payload);

  if (ok) {
    yield put(actions.postFolderForAdminSuccess(response));
    yield put(snackbarActions.createSuccess('folders.successfulPostFolder', { translate: true }));
  } else {
    yield put(actions.postFolderForAdminFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onPutFolderForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.putFolderForAdmin, payload);

  if (ok) {
    yield put(actions.putFolderForAdminSuccess(response));
    yield put(snackbarActions.createSuccess('folders.successfulPutFolder', { translate: true }));
  } else {
    yield put(actions.putFolderForAdminFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteFolderVariantForAdmin({ payload }) {
  const { ok, error } = yield apiCall(api.documents.deleteFolderVariantForAdmin, payload);

  if (ok) {
    yield put(actions.deleteFolderVariantForAdminSuccess({ folderId: payload.folderId, folderVariantId: payload.folderVariantId }));
    yield put(snackbarActions.createSuccess('folders.successfulDeleteFolder', { translate: true }));
    history.push('/manage/documents');
  } else {
    yield put(actions.deleteFolderVariantForAdminFailure(error));
    yield put(snackbarActions.createFailure('folders.unsuccessfulDeleteFolder', { translate: true }));
  }
}

export default function* folderSagas() {
  yield all([
    takeLeadingPerKey(types.getFoldersForMember, onGetFoldersForMember, ({ payload }) => payload.filter),
    takeLeadingPerKey(types.getFolderForMember, onGetFolderForMember, ({ payload }) => payload.folderId),
    takeLeading(types.getFoldersForAdmin, onGetFoldersForAdmin),
    takeLeadingPerKey(types.getFolderForAdmin, onGetFolderForAdmin, ({ payload }) => payload.folderId),
    takeLeading(types.postFolderForAdmin, onPostFolderForAdmin),
    takeLeading(types.putFolderForAdmin, onPutFolderForAdmin),
    takeLeading(types.deleteFolderVariantForAdmin, onDeleteFolderVariantForAdmin),
  ]);
}

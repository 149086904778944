import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

const getQuiz = ({ entities: { quizes } }, quizId) => quizes[quizId];

const getOptionEntities = ({ entities: { quizOptions } }) => quizOptions;
const getQuizes = ({ entities: { quizes } }) => quizes;
const getOptionsForQuiz = createSelector(
  [getOptionEntities, getQuizes],
  (options, quizes) => memoize(
    quizId => ((quizes[quizId] || {}).options || []).map(id => options[id]),
  ),
);

export default {
  getQuiz,
  getOptionsForQuiz,
};

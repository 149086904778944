import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { isMobile } from 'helpers';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  ${isMobile && 'margin-bottom: 0.75rem;'}

  line-height: 1.3;
`;

const Title = styled('h3')`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
  line-height: inherit;

  ${isMobile && 'font-size: 1rem;'}

  /* To remove "top part" of line-height, but keep line-height on wrap */
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.3) * 0.5em);
  }
`;

const SeeMore = styled(Button)`
  flex: 0 0 auto;
  color: #888;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: inherit;
  white-space: nowrap;

  padding: 0.5rem;
  margin: -0.5rem;
  margin-left: 0.5rem;
`;

const SectionTitle = ({ seeMore, children, ...rest }) => {

  const { t } = useTranslation(['common']);

  return (
    <Wrapper {...rest}>
      <Title>{children}</Title>
      {seeMore && <SeeMore variant="text" size="small" endIcon={<NavigateNextIcon fontSize="inherit" style={{ marginLeft: -4 }} />} {...seeMore}>{seeMore.children || t('generic.seeMore')}</SeeMore>}
    </Wrapper>
  );
};

SectionTitle.propTypes = {
  seeMore: PropTypes.object,
};

export default SectionTitle;

export default {
  getFeedbacks: 'feedback/GET_FEEDBACKS',
  getFeedbacksSuccess: 'feedback/GET_FEEDBACKS_SUCCESS',
  getFeedbacksFailure: 'feedback/GET_FEEDBACKS_FAILURE',

  downloadIndex: 'feedback/DOWNLOAD_FEEDBACKS',
  downloadIndexSuccess: 'feedback/DOWNLOAD_FEEDBACKS_SUCCESS',
  downloadIndexFailure: 'feedback/DOWNLOAD_FEEDBACKS_FAILURE',

  store: 'feedback/STORE',
  storeSuccess: 'feedback/STORE_SUCCESS',
  storeFailure: 'feedback/STORE_FAILURE',

  publicStore: 'feedback/PUBLIC_STORE',
  publicStoreSuccess: 'feedback/PUBLIC_STORE_SUCCESS',
  publicStoreFailure: 'feedback/PUBLIC_STORE_FAILURE',

  patch: 'feedback/PATCH',
  patchSuccess: 'feedback/PATCH_SUCCESS',
  patchFailure: 'feedback/PATCH_FAILURE',

  massAction: 'feedback/MASS_ACTION',
  massActionSuccess: 'feedback/MASS_ACTION_SUCCESS',
  massActionFailure: 'feedback/MASS_ACTION_FAILURE',

  get: 'feedback/GET',
  getSuccess: 'feedback/GET_SUCCESS',
  getFailure: 'feedback/GET_FAILURE',

  download: 'feedback/DOWNLOAD',
  downloadSuccess: 'feedback/DOWNLOAD_SUCCESS',
  downloadFailure: 'feedback/DOWNLOAD_FAILURE',

  delete: 'feedback/DELETE',
  deleteSuccess: 'feedback/DELETE_SUCCESS',
  deleteFailure: 'feedback/DELETE_FAILURE',

  storeType: 'feedback/STORE_TYPE',
  storeTypeSuccess: 'feedback/STORE_TYPE_SUCCESS',
  storeTypeFailure: 'feedback/STORE_TYPE_FAILURE',

  updateFeedbackType: 'feedback/UPDATE_FEEDBACK_TYPE',
  updateFeedbackTypeSuccess: 'feedback/UPDATE_FEEDBACK_TYPE_SUCCESS',
  updateFeedbackTypeFailure: 'feedback/UPDATE_FEEDBACK_TYPE_FAILURE',

  patchFeedbackType: 'feedback/PATCH_FEEDBACK_TYPE',
  patchFeedbackTypeSuccess: 'feedback/PATCH_FEEDBACK_TYPE_SUCCESS',
  patchFeedbackTypeFailure: 'feedback/PATCH_FEEDBACK_TYPE_FAILURE',

  getFeedbackTypes: 'feedback/GET_FEEDBACK_TYPES',
  getFeedbackTypesSuccess: 'feedback/GET_FEEDBACK_TYPES_SUCCESS',
  getFeedbackTypesFailure: 'feedback/GET_FEEDBACK_TYPES_FAILURE',

  getFeedbackType: 'feedback/GET_FEEDBACK_TYPE',
  getFeedbackTypeSuccess: 'feedback/GET_FEEDBACK_TYPE_SUCCESS',
  getFeedbackTypeFailure: 'feedback/GET_FEEDBACK_TYPE_FAILURE',

  getFeedbackTypePublicUrl: 'feedback/GET_FEEDBACK_TYPE_PUBLIC_URL',
  getFeedbackTypePublicUrlSuccess: 'feedback/GET_FEEDBACK_TYPE_PUBLIC_URL_SUCCESS',
  getFeedbackTypePublicUrlFailure: 'feedback/GET_FEEDBACK_TYPE_PUBLIC_URL_FAILURE',

  deleteFeedbackType: 'feedback/DELETE_FEEDBACK_TYPE',
  deleteFeedbackTypeSuccess: 'feedback/DELETE_FEEDBACK_TYPE_SUCCESS',
  deleteFeedbackTypeFailure: 'feedback/DELETE_FEEDBACK_TYPE_FAILURE',

  getSettings: 'feedback/GET_SETTINGS',
  getSettingsSuccess: 'feedback/GET_SETTINGS_SUCCESS',
  getSettingsFailure: 'feedback/GET_SETTINGS_FAILURE',

  updateSettings: 'feedback/UPDATE_SETTINGS',
  updateSettingsSuccess: 'feedback/UPDATE_SETTINGS_SUCCESS',
  updateSettingsFailure: 'feedback/UPDATE_SETTINGS_FAILURE',

  getFeedbackStatuses: 'feedback/GET_FEEDBACK_STATUSES',
  getFeedbackStatusesSuccess: 'feedback/GET_FEEDBACK_STATUSES_SUCCESS',
  getFeedbackStatusesFailure: 'feedback/GET_FEEDBACK_STATUSES_FAILURE',

  addComment: 'feedback/ADD_COMMENT',
  addCommentSuccess: 'feedback/ADD_COMMENT_SUCCESS',
  addCommentFailure: 'feedback/ADD_COMMENT_FAILURE',

  getComments: 'feedback/GET_COMMENTS',
  getCommentsSuccess: 'feedback/GET_COMMENTS_SUCCESS',
  getCommentsFailure: 'feedback/GET_COMMENTS_FAILURE',

  getComment: 'feedback/GET_COMMENT',
  getCommentSuccess: 'feedback/GET_COMMENT_SUCCESS',
  getCommentFailure: 'feedback/GET_COMMENT_FAILURE',

  updateComment: 'feedback/UPDATE_COMMENT',
  updateCommentSuccess: 'feedback/UPDATE_COMMENT_SUCCESS',
  updateCommentFailure: 'feedback/UPDATE_COMMENT_FAILURE',

  deleteComment: 'feedback/DELETE_COMMENT',
  deleteCommentSuccess: 'feedback/DELETE_COMMENT_SUCCESS',
  deleteCommentFailure: 'feedback/DELETE_COMMENT_FAILURE',

  getCommentTranslation: 'feedback/GET_COMMENT_TRANSLATION',
  getCommentTranslationSuccess: 'feedback/GET_COMMENT_TRANSLATION_SUCCESS',
  getCommentTranslationFailure: 'feedback/GET_COMMENT_TRANSLATION_FAILURE',
};

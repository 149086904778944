export default {
  login: 'auth/LOGIN',
  loginSuccess: 'auth/LOGIN_SUCCESS',
  loginFailure: 'auth/LOGIN_FAILURE',

  refreshToken: 'auth/REFRESH_TOKEN',
  refreshTokenSuccess: 'auth/REFRESH_TOKEN_SUCCESS',
  refreshTokenFailure: 'auth/REFRESH_TOKEN_FAILURE',

  logout: 'auth/LOGOUT',
  logoutSuccess: 'auth/LOGOUT_SUCCESS',
  logoutFailure: 'auth/LOGOUT_FAILURE',

  sendForgotEmail: 'auth/SEND_FORGOT_EMAIL',
  resetPassword: 'auth/RESET_PASSWORD',

  verifyToken: 'auth/VERIFY_TOKEN',
  verifyEmail: 'auth/VERIFY_EMAIL',
  validateEmailVerificationCode: 'auth/VALIDATE_EMAIL_VERIFICATION_CODE',

  register: 'auth/REGISTER',
  join: 'auth/JOIN',

  getProfile: 'auth/GET_PROFILE',
  getProfileSuccess: 'auth/GET_PROFILE_SUCCESS',
  getProfileFailure: 'auth/GET_PROFILE_FAILURE',

  getZendeskToken: 'auth/GET_ZENDESK_TOKEN',
  getZendeskTokenSuccess: 'auth/GET_ZENDESK_TOKEN_SUCCESS',
  getZendeskTokenFailure: 'auth/GET_ZENDESK_TOKEN_FAILURE',
};

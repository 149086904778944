import React, { useContext } from 'react';
import MobileFullScreenLayout from './FullScreen/MobileFullScreenLayout';
import MobileCommonLayout from './Common/MobileCommonLayout';
import { LayoutReadContext } from '../LayoutProvider';

const MobileDefaultLayout = ({ children, ...rest }) => {
  const { fullScreen, ...layoutProps } = useContext(LayoutReadContext);

  return fullScreen
    ? <MobileFullScreenLayout {...layoutProps} {...rest}>{children}</MobileFullScreenLayout>
    : <MobileCommonLayout {...layoutProps} {...rest}>{children}</MobileCommonLayout>;
};

export default MobileDefaultLayout;

export default {
  getOverview: 'jobAnniversary/GET_OVERVIEW',
  getOverviewSuccess: 'jobAnniversary/GET_OVERVIEW_SUCCESS',
  getOverviewFailure: 'jobAnniversary/GET_OVERVIEW_FAILURE',

  getJobAnniversaries: 'jobAnniversary/GET_JOB_ANNIVERSARIES',
  getJobAnniversariesSuccess: 'jobAnniversary/GET_JOB_ANNIVERSARIES_SUCCESS',
  getJobAnniversariesFailure: 'jobAnniversary/GET_JOB_ANNIVERSARIES_FAILURE',

  getJobAnniversaryEvent: 'jobAnniversary/GET_JOB_ANNIVERSARY_EVENT',
  getJobAnniversaryEventSuccess: 'jobAnniversary/GET_JOB_ANNIVERSARY_EVENT_SUCCESS',
  getJobAnniversaryEventFailure: 'jobAnniversary/GET_JOB_ANNIVERSARY_EVENT_FAILURE',

  addComment: 'jobAnniversary/ADD_COMMENT',
  addCommentSuccess: 'jobAnniversary/ADD_COMMENT_SUCCESS',
  addCommentFailure: 'jobAnniversary/ADD_COMMENT_FAILURE',

  getComments: 'jobAnniversary/GET_COMMENTS',
  getCommentsSuccess: 'jobAnniversary/GET_COMMENTS_SUCCESS',
  getCommentsFailure: 'jobAnniversary/GET_COMMENTS_FAILURE',
};

import isString from 'lodash/isString';

const getErrorProperties = ({ touched, error, submitError }) => {

  const strSubmitError = isString(submitError) ? submitError : null;

  if (touched && (error || submitError)) {
    return {
      helperText: error || strSubmitError,
      error: true,
    };
  }

  return {};
};

export default getErrorProperties;

import React, { useLayoutEffect, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { isMobile } from 'helpers';
import debounce from 'lodash/debounce';
import PropTypes from 'propTypes';

export const htmlContentCss = css`
  /* Body for tinymce editor */
  html {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    line-height: 1.25;
    font-size: ${isMobile ? '14px' : '15px'};
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0.75em 0;
  }

  h2 {
    font-size: 1.35rem;
    font-weight: 700;
    margin: 0.75em 0;
  }

  h3 {
    font-size: 1.17rem;
    font-weight: 700;
    margin: 0.75em 0;
  }

  h4 {
    font-size: 1.075rem;
    font-weight: 700;
    margin: 0.5em 0;
  }

  p {
    margin: 0.75em 0;
  }

  .mce-content-body {
    text-decoration: none;
    color: inherit;
    line-height: 1.25;
  }

  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    color: #aaa;
    font-size: inherit;
  }

  a:link, a:visited, a:hover, a:active {
    color: #15c;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  blockquote, .mce-content-body:not([dir=rtl]) blockquote {
    margin: 1em 0 1em 1.5em;
    padding: 1px 0 1px 1em; /* 1px top and bottom to disable margin collapse */
    border-left: 5px solid rgba(0, 0, 0, 0.1);
  }

  img, iframe {
    max-width: 100%;
  }

  iframe {
    border: none;
  }

  audio {
    width: 100%;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #eee;
    margin: 1.5rem 0;
  }

  table {
    border-spacing: none;
    border-collapse: collapse;
    margin: 1.5rem 0;
    max-width: 100% !important;
  }

  table td, table th {
    border: 1px solid #ccc;
    padding: 0.25rem 0.5rem;
    min-width: 40px;
  }

  table td {
    vertical-align: top;
  }

  /* table td :first-child {
    margin-top: 0;
  } */

  /* table td :last-child {
    margin-bottom: 0;
  } */

  ul, ol {
    padding-left: 2rem;
  }

  li {
    padding: 0.2em 0;
  }
`;

// Split in half.
// Base is for to be used around TinyMCE wrapper
// And the other is for displaying actual final content
export const HtmlContentBase = styled('div')`
  ${htmlContentCss}
`;

const tableOverflowWrapperClassName = 'table-overflow-wrapper';
const tableOverflowScrollWrapperClassName = 'table-overflow-scroll-wrapper';

export const HtmlContentWrapper = styled(HtmlContentBase)`
  display: block;

  overflow-wrap: break-word;
  word-break: break-word;

  text-decoration: none;
  color: inherit;
  line-height: 1.25;

  position: relative;

  a {
    padding: 0.5rem;
    margin: -0.5rem;
  }

  .${tableOverflowWrapperClassName} {

    width: 100%;
    overflow: hidden;
    position: relative;

    /* Remove table margins, and add to current wrapper instead */
    margin-top: 1rem;
    margin-bottom: 1rem;
    table {
      margin-top: 0;
      margin-bottom: 0;
    }

    .${tableOverflowScrollWrapperClassName} {
      width: 100%;
      overflow-x: auto;
    }

      &.not-at-right::after,
      &.not-at-left::before {
        content: '';
        height: 100%;
        width: 15px;
        top: 0;
        position: absolute;
    }

      &.not-at-right::after {
        right: 0;
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.2));
    }

      &.not-at-left::before {
        left: 0;
        background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0));
      }

      table {
        margin-top: 0;
        margin-bottom: 0;
      }

      td.short-content {
        min-width: 100px;
      }
      td.single-word-content {
        white-space: nowrap;
      }
      td {
        min-width: 150px;
      }
      td.long-content {
        min-width: 200px;
      }
  }
`;

const HtmlContent = ({ withOverflowingTables, ...rest }) => {

  const contentRef = useRef();

  useLayoutEffect(() => {
    if (!withOverflowingTables) {
      return;
    }

    const listenerRemoveFns = [];

    Array.from(contentRef.current?.querySelectorAll(`:not(.${tableOverflowScrollWrapperClassName}) > table`) || [])
      .forEach(table => {

        // toggle some min widths
        Array.from(table.querySelectorAll('td') || [])
          .forEach(cell => {
            const content = cell.textContent || '';

            cell.classList.toggle('long-content', content.length > 100);
            cell.classList.toggle('short-content', content.length < 40);
            cell.classList.toggle('single-word-content', content.length < 30 && !/\s/.test(content.trim()));
          });

        // Wrap with outer overflow wrapper
        const overflowWrapper = document.createElement('div');
        overflowWrapper.className = tableOverflowWrapperClassName;

        const scrollWrapper = document.createElement('div');
        scrollWrapper.className = tableOverflowScrollWrapperClassName;

        table.parentNode.insertBefore(overflowWrapper, table);
        overflowWrapper.appendChild(scrollWrapper);
        scrollWrapper.appendChild(table);

        const scrollFn = () => {
          const scrollPos = scrollWrapper.scrollLeft;
          const maxScroll = scrollWrapper.scrollWidth - scrollWrapper.clientWidth;

          overflowWrapper.classList.toggle('not-at-left', scrollPos > 0);
          overflowWrapper.classList.toggle('not-at-right', maxScroll > 0 && scrollPos < maxScroll);
        };

        const debouncedScrollFn = debounce(scrollFn, 50);

        scrollWrapper.addEventListener('scroll', scrollFn);
        window.addEventListener('resize', debouncedScrollFn, { passive: true });

        scrollFn();

        listenerRemoveFns.push(() => scrollWrapper.removeEventListener('scroll', scrollFn));
        listenerRemoveFns.push(() => window.removeEventListener('resize', debouncedScrollFn));
      });

    return () => listenerRemoveFns.forEach(removeListener => removeListener());
  }, [withOverflowingTables]);

  return <HtmlContentWrapper ref={contentRef} {...rest} />;
};

HtmlContent.propTypes = {
  withOverflowingTables: PropTypes.bool,
};

export default HtmlContent;

import React from 'react';
import PropTypes from 'propTypes';
import ContactDirectoryDataContact from './Data/ContactDirectoryDataContact';
import ContactDirectoryDataMember from './Data/ContactDirectoryDataMember';

const ContactDirectoryItem = ({ itemType, itemId, ...rest }) => {
  return itemType === 'contact'
    ? <ContactDirectoryDataContact contactId={itemId} {...rest} />
    : <ContactDirectoryDataMember memberId={itemId} {...rest} />;
};

ContactDirectoryItem.propTypes = {
  ContentWrapper: PropTypes.elementType.isRequired,

  itemType: PropTypes.oneOf(['member', 'contact']),
  itemId: PropTypes.any,
};

export default ContactDirectoryItem;

import types from './types';

const crumbReducer = (state = [], { type, payload }) => {

  switch (type) {

    case types.create:
      return [...state, payload];

    case types.update:
      return state.map(crumb => crumb.id === payload.id ? payload : crumb);

    case types.remove:
      return state.filter(crumb => crumb.id !== payload.id);


    default:
      return state;
  }
};

export default { crumbs: crumbReducer };

import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { userSelectors } from 'redux/user';
import Avatar from 'components/Avatar';
import DateFormat from 'components/DateFormat';
import NotificationService from 'services/notificationService';
import Grid from 'components/Grid';
import { authSelectors } from 'redux/auth';
import { css } from '@emotion/react';

const Wrapper = styled('div')`
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.04);
  cursor: pointer;

  ${({ isRead }) => isRead === false && css`
    background: rgba(120,180,255,0.05);
    font-weight: 500;
  `}
`;
const Name = styled('div')`
  font-weight: 600;
`;

const Timestamp = styled('div')`
  color: #888;
  font-size: 0.9rem;
  font-weight: 600;

  margin-left: auto;
  white-space: nowrap;
`;

const Body = styled('div')`
`;

const Notification = ({ notificationId, onClose }) => {

  const notification = useSelector(state => userSelectors.getNotification(state, notificationId));

  const hasPermission = useSelector(authSelectors.hasPermissionSelector);

  const onClick = () => {
    NotificationService.onNotificationClick(notification, { hasPermission });
    if (onClose) {
      onClose();
    }
  };

  const { data: { originator = {} }, read } = notification;

  const name = originator.name || [originator.firstName, originator.lastName].filter(v => !!v).join(' ');
  const src = originator.image || originator.avatar;

  return (
    <Wrapper onClick={onClick} isRead={read}>
      <Grid container spacing={1} wrap="nowrap" direction="column">
        <Grid item>
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            {(name || src) && (
              <>
                <Grid item><Avatar size={28} name={name} src={src} /></Grid>
                <Grid item xs zeroMinWidth><Name>{name}</Name></Grid>
              </>
            )}
            <Grid item>
              <Timestamp>
                <DateFormat date={notification.createdAt} fromNowDuring={30 * 24 * 60 * 60} format="PP" />
              </Timestamp>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Body>
            {notification.data.body}
          </Body>
        </Grid>
      </Grid>
    </Wrapper>
  );

};

Notification.propTypes = {
  notificationId: PropTypes.string,
  onClose: PropTypes.func,
};

export default Notification;

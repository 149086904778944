import { guidanceTypes } from 'redux/guidance';
import update from 'immutability-helper';

const articlesReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case guidanceTypes.removeLikeSuccess:
      return update(state, {
        [payload.articleId]: {
          userLiked: { $set: false },
          likesCount: { $apply: c => c - 1 },
        },
      });

    case guidanceTypes.addLikeSuccess:
      return update(state, {
        [payload.articleId]: {
          userLiked: { $set: true },
          likesCount: { $apply: c => c + 1 },
        },
      });

    default:
      return state;
  }
};

export default articlesReducer;

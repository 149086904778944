import { schema } from 'normalizr';
import feedbackTypeSchema from 'schemas/feedbackType';
import memberSchema from './member';

const feedbackSchema = new schema.Entity('feedbacks', {
  type: feedbackTypeSchema,
  access: [{ member: memberSchema }],
});

export default feedbackSchema;

import styled from '@emotion/styled/macro';

const SidebarGroup = styled('div')`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 2rem;
  }
`;

export default SidebarGroup;

import styled from '@emotion/styled/macro';

const SidebarFooter = styled('div')`
  margin-top: 40px;
  color: #666;
  display: flex;
  flex-direction: column;

  font-size: 14px;

  a {
    text-decoration: none;
    padding: 0.5rem 1rem;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default SidebarFooter;

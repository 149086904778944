import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'components/ProgressBar';
import DateFormat from 'components/DateFormat';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;

  min-width: 230px;
  width: 100%;

  padding: 1rem 1rem 1.5rem;

  background-color: rgba(0,0,0,0.04);
  background-image: url(${({ cover }) => cover});
  background-size: cover;
  background-position: center center;

  border-radius: 4px;

  text-decoration: none;
  color: #fff;

  transition: opacity 300ms;
  &:hover {
    opacity: .90;
  }
`;

const Badge = styled('div')`
  display: flex;
  flex: 0 0 118px;
  width: 118px;
  height: 118px;

  align-self: flex-end;
  margin-bottom: 0.5em;

  background-color: transparent;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-size: cover;
  background-repeat: no-repeat;

  margin-bottom: auto;
`;

const Title = styled('span')`
  margin-bottom: 1em;

  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  text-shadow: 0 0 3px rgba(0, 0, 0, .8);
`;

const StyledProgressBar = styled(ProgressBar)`
  height: 24px;
`;

const StartsAt = styled('div')`
  font-size: 0.9em;
  color: #fff;
  font-weight: 700;
  text-shadow: 0 0 3px rgba(0, 0, 0, .8);
`;

const Challenge = ({ title, cover, badge, progress, startsAt, active, ...rest }) => {

  const { t } = useTranslation(['component']);

  return (
    <Wrapper cover={cover} {...rest}>
      <Badge imageSrc={badge} />
      <Title>{title}</Title>
      {active
        ? <StyledProgressBar variant="white" progress={progress} />
        : (
          <StartsAt>
            {t('challenge.startsAt')} <DateFormat date={startsAt} />
          </StartsAt>
        )}
    </Wrapper>
  );
};

Challenge.propTypes = {
  title: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  badge: PropTypes.string.isRequired,

  progress: PropTypes.number.isRequired,

  startsAt: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Challenge;

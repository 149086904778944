export default {

  getContract: 'contract/GET_CONTRACT',
  getContractSuccess: 'contract/GET_CONTRACT_SUCCESS',
  getContractFailure: 'contract/GET_CONTRACT_FAILURE',

  downloadContract: 'contract/DOWNLOAD_CONTRACT',
  downloadContractSuccess: 'contract/DOWNLOAD_CONTRACT_SUCCESS',
  downloadContractFailure: 'contract/DOWNLOAD_CONTRACT_FAILURE',

  getWorkspaceContracts: 'contract/GET_WORKSPACE_CONTRACTS',
  getWorkspaceContractsSuccess: 'contract/GET_WORKSPACE_CONTRACTS_SUCCESS',
  getWorkspaceContractsFailure: 'contract/GET_WORKSPACE_CONTRACTS_FAILURE',

  downloadWorkspaceContract: 'contract/DOWNLOAD_WORKSPACE_CONTRACT',
  downloadWorkspaceContractSuccess: 'contract/DOWNLOAD_WORKSPACE_CONTRACT_SUCCESS',
  downloadWorkspaceContractFailure: 'contract/DOWNLOAD_WORKSPACE_CONTRACT_FAILURE',

  acceptWorkspaceContract: 'contract/ACCEPT_WORKSPACE_CONTRACT',
  acceptWorkspaceContractSuccess: 'contract/ACCEPT_WORKSPACE_CONTRACT_SUCCESS',
  acceptWorkspaceContractFailure: 'contract/ACCEPT_WORKSPACE_CONTRACT_FAILURE',

};

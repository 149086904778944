import combineReducers from 'combine-reducers-global-state';
import { createPagination, resetPagination, addToPagination } from 'redux/helpers/pagination';
import types from './types';

const notifications = (state = createPagination(null, 'notificationIds'), { type, payload }) => {
  switch (type) {
    case types.newNotification:
      return resetPagination(state, null, 'notificationIds');

    case types.getNotificationsSuccess:
      return addToPagination(state, null, payload, 'notificationIds');

    default:
      return state;
  }
};

const notificationSettings = (state = [], { type, payload }) => {
  switch (type) {
    case types.getNotificationSettingsSuccess:
      return payload;
    default:
      return state;
  }
};

const settings = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getSettingsSuccess:
      return payload;
    default:
      return state;
  }
};

const userReducer = combineReducers({
  notifications,
  notificationSettings,
  settings,
});

export default { user: userReducer };

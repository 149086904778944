import React from 'react';
import MaxWidthContainer from 'components/MaxWidthContainer';
import PropTypes from 'prop-types';
import MobileBottomDrawer from 'components/MobileBottomDrawer';


const Hub = ({ children, open, onClose, ...args }) => (
  <MobileBottomDrawer open={open} onOpen={() => {}} onClose={onClose} {...args}>
    <MaxWidthContainer>
      {children}
    </MaxWidthContainer>
  </MobileBottomDrawer>
);

Hub.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Hub;

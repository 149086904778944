import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  // SINGLE EVENT REQUESTS
  getEvent: data => createAction(types.getEvent, data),
  getEventSuccess: data => createAction(types.getEventSuccess, data),
  getEventFailure: data => createAction(types.getEventFailure, data),

  createEvent: data => createAction(types.createEvent, data),
  createEventSuccess: data => createAction(types.createEventSuccess, data),
  createEventFailure: error => createAction(types.createEventFailure, error),

  updateEvent: data => createAction(types.updateEvent, data),
  updateEventSuccess: data => createAction(types.updateEventSuccess, data),
  updateEventFailure: data => createAction(types.updateEventFailure, data),

  deleteEvent: data => createAction(types.deleteEvent, data),
  deleteEventSuccess: data => createAction(types.deleteEventSuccess, data),
  deleteEventFailure: data => createAction(types.deleteEventFailure, data),

  downloadIcs: data => createAction(types.downloadIcs, data),
  downloadIcsSuccess: data => createAction(types.downloadIcsSuccess, data),
  downloadIcsFailure: data => createAction(types.downloadIcsFailure, data),

  // INDEX REQUESTS
  getEvents: data => createAction(types.getEvents, data),
  getEventsSuccess: data => createAction(types.getEventsSuccess, data),
  getEventsFailure: data => createAction(types.getEventsFailure, data),
};

import merge from 'lodash/merge';
import { commentTypes } from 'redux/comment';


const commentsReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case commentTypes.getCommentSuccess:
      return { ...state, [payload.commentId]: payload };

    case commentTypes.updateSuccess:
      return merge({}, state, { [payload.commentId]: { body: payload.body } });

    // TODO: Preferably add { deleted: true } instead
    case commentTypes.deleteSuccess:
      return merge({}, state, { [payload.commentId]: null });

    case commentTypes.getTranslationSuccess:
      return merge({}, state, { [payload.commentId]: { translation: payload } });

    default:
      return state;
  }
};

export default commentsReducer;

import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const Wrapper = styled('span')`
  white-space: nowrap;
  display: inline-flex;
  align-items: center;

  ${({ onClick }) => onClick && 'cursor: pointer;'}

  ${({ color, theme }) => color === 'primary' && `color: ${theme.palette.primary.main};`}

  margin-left: -0.15em;
  margin-right: -0.15em;

  & > * {
    margin-left: 0.15em;
    margin-right: 0.15em;
  }
`;

const IconText = React.forwardRef(({ startIcon, endIcon, children, ...rest }, ref) => (
  <Wrapper ref={ref} {...rest}>
    {startIcon || null}
    <span>{children}</span>
    {endIcon || null}
  </Wrapper>
));

IconText.propTypes = {
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

export default IconText;

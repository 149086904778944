import React, { useRef, useState, useCallback } from 'react';
import { useDrag } from 'react-use-gesture';
import { useSprings, animated } from '@react-spring/web';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import IconButton from '@mui/material/IconButton';
import LensRoundedIcon from '@mui/icons-material/LensRounded';
import PropTypes from 'prop-types';
import { isMobile } from 'helpers';
import useZoom from 'hooks/useZoom';
import clamp from 'lodash/clamp';

const Wrapper = styled('div')``;

const BackgroundContainer = styled('div')`
  background: #000;
  position: relative;
  width: 100%;
`;

const SlideContainerDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  will-change: transform;

  position: relative;
  width: 100%;

  & > div:not(:first-of-type) {
    position: absolute;
  }
`;
const SlideContainer = animated(SlideContainerDiv);

const AnimatedItemDiv = styled('div')`
  height: 100%;
  width: 100%;
  will-change: transform;

  display: flex;
  align-items: center;
  flex: 0 0 100%;
`;
const AnimatedItem = animated(AnimatedItemDiv);

const fadeCss = css`
  opacity: 0.5;
  background: rgba(0,0,0,.5);
  backdrop-filter: blur(40px);

  transition: opacity 0.2s ease-in-out,
              background 0.2s ease-in-out;

  ${Wrapper}:hover & {
    opacity: 1;
  }

  &&[hidden] {
    display: block;
    opacity: 0;
    pointer-events: none;
  }

  .has-video-playing && {
    opacity: 0;
  }
`;

const ArrowButton = styled(IconButton)`
  width: 2.5rem;
  height: 2.5rem;
  background: rgba(0,0,0,.5);
  backdrop-filter: blur(40px);
  color: #fff;

  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 1rem;

  ${fadeCss};
`;

const ArrowButtonLeft = styled(ArrowButton)`
  left: 0;
`;

const ArrowButtonRight = styled(ArrowButton)`
  right: 0;
`;

const Counter = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.25rem 0.5rem;
  margin: 0.75rem;
  font-size: 0.9rem;
  color: #fff;
  font-weight: 600;
  border-radius: 1rem;

  ${fadeCss};
`;

const Indicators = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0.25rem 0;
`;

const indicatorConfig = {
  shouldForwardProp: prop => !['active'].includes(prop),
};
const Indicator = styled(LensRoundedIcon, indicatorConfig)`
  width: 11px;
  height: 11px;
  padding: 1px;
  margin: 1px;
  color: #a8a8a8;
  transition: all .2s ease-in-out;
  cursor: pointer;

  ${({ active, theme }) => active && `color: ${theme.palette.primary.main};`}
`;

const MediaGallery = ({ items, children, ...rest }) => {

  const [zooming, setZooming] = useState(false);
  const [dragging, setDragging] = useState(false);

  const domTarget = useRef();

  const idx = useCallback(id => clamp(id, 0, items.length - 1), [items.length]);

  const [slide, _setSlide] = useState(0);
  const setSlide = next => {
    Array.from(document.querySelectorAll('video, audio'))
      .forEach(media => media.pause());

    _setSlide(current => {
      return typeof next === 'function' ? idx(next(current)) : idx(next);
    });
  };

  const [springs, set] = useSprings(items.length, i => ({
    x: `${(i - slide) * 100}%`,
  }), [slide]);

  const onDrag = ({ swipe: [xSwipe] = [], movement: [xMove] = [], first, last, distance, active }) => {
    if (zooming) {
      setDragging(false);
      return;
    }

    if (first || last) {
      setDragging(first);
    }

    const width = domTarget.current.offsetWidth;

    // Swipe seems to not work very well on low end devices
    if (xSwipe !== 0 || (last && distance > width / 2)) {
      const next = idx(slide + (xMove > 0 ? -1 : 1));
      if (next !== slide) {
        setSlide(next);
        return;
      }
    }

    set(i => ({
      x: `${((i - slide) + (active ? xMove / width : 0)) * 100}%`,
      immediate: active, // When actively moving, then follow the finger
    }));
  };

  useDrag(onDrag, {
    domTarget,
    enabled: !zooming && isMobile && items.length > 1,
    rubberband: false,
    axis: 'x',
    lockDirection: true,
    experimental_preventWindowScrollY: true,
    swipeDuration: Infinity,
    swipeDistance: domTarget.current ? Math.min(domTarget.current.offsetWidth * 0.1, 50) : 50,
    filterTaps: true,
    delay: 200,
  });

  const { spring: [zoomStyles] } = useZoom({
    onChange: value => setZooming(value),
    domTarget,
    enabled: !dragging && isMobile,
  });

  return (
    <Wrapper {...rest}>
      <BackgroundContainer>
        <SlideContainer ref={domTarget} style={zoomStyles}>
          {springs.map((style, i) => (
            <AnimatedItem key={items[i].id} style={style}>
              {children(items[i])}
            </AnimatedItem>
          ))}
        </SlideContainer>

        {items.length > 1 && (
          <>
            <Counter hidden={zooming}>{slide + 1}/{items.length}</Counter>

            <ArrowButtonLeft size="small" onClick={() => setSlide(slide - 1)} hidden={zooming || slide === 0}>
              <NavigateBeforeRoundedIcon />
            </ArrowButtonLeft>

            <ArrowButtonRight size="small" onClick={() => setSlide(slide + 1)} hidden={zooming || slide === items.length - 1}>
              <NavigateNextRoundedIcon />
            </ArrowButtonRight>
          </>
        )}

      </BackgroundContainer>

      {items.length > 1 && (
        <Indicators>
          {items.map((el, i) => <Indicator onClick={() => setSlide(i)} active={slide === i} key={i} />)}
        </Indicators>
      )}

    </Wrapper>
  );
};

MediaGallery.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
    }),
  ),
};

export default MediaGallery;

export default {

  getIntegrations: 'integration/GET_INTEGRATIONS',
  getIntegrationsSuccess: 'integration/GET_INTEGRATIONS_SUCCESS',
  getIntegrationsFailure: 'integration/GET_INTEGRATIONS_FAILURE',

  patchIntegration: 'integration/PATCH_INTEGRATION',
  patchIntegrationSuccess: 'integration/PATCH_INTEGRATION_SUCCESS',
  patchIntegrationFailure: 'integration/PATCH_INTEGRATION_FAILURE',

  // Rules
  getIntegrationRules: 'integration/GET_INTEGRATION_RULES',
  getIntegrationRulesSuccess: 'integration/GET_INTEGRATION_RULES_SUCCESS',
  getIntegrationRulesFailure: 'integration/GET_INTEGRATION_RULES_FAILURE',

  updateIntegrationRule: 'integration/UPDATE_INTEGRATION_RULE',
  updateIntegrationRuleSuccess: 'integration/UPDATE_INTEGRATION_RULE_SUCCESS',
  updateIntegrationRuleFailure: 'integration/UPDATE_INTEGRATION_RULE_FAILURE',

  createIntegrationRule: 'integration/CREATE_INTEGRATION_RULE',
  createIntegrationRuleSuccess: 'integration/CREATE_INTEGRATION_RULE_SUCCESS',
  createIntegrationRuleFailure: 'integration/CREATE_INTEGRATION_RULE_FAILURE',

  integrationRuleMassAction: 'integration/INTEGRATION_RULE_MASSACTION',
  integrationRuleMassActionSuccess: 'integration/INTEGRATION_RULE_MASSACTION_SUCCESS',
  integrationRuleMassActionFailure: 'integration/INTEGRATION_RULE_MASSACTION_FAILURE',

  // Persona
  getPersonaSettings: 'integration/GET_PERSONA_SETTINGS',
  getPersonaSettingsSuccess: 'integration/GET_PERSONA_SETTINGS_SUCCESS',
  getPersonaSettingsFailure: 'integration/GET_PERSONA_SETTINGS_FAILURE',

  updatePersonaSettings: 'integration/UPDATE_PERSONA_SETTINGS',
  updatePersonaSettingsSuccess: 'integration/UPDATE_PERSONA_SETTINGS_SUCCESS',
  updatePersonaSettingsFailure: 'integration/UPDATE_PERSONA_SETTINGS_FAILURE',

  // Netvisor
  getNetvisorSettings: 'integration/GET_NETVISOR_SETTINGS',
  getNetvisorSettingsSuccess: 'integration/GET_NETVISOR_SETTINGS_SUCCESS',
  getNetvisorSettingsFailure: 'integration/GET_NETVISOR_SETTINGS_FAILURE',

  updateNetvisorSettings: 'integration/UPDATE_NETVISOR_SETTINGS',
  updateNetvisorSettingsSuccess: 'integration/UPDATE_NETVISOR_SETTINGS_SUCCESS',
  updateNetvisorSettingsFailure: 'integration/UPDATE_NETVISOR_SETTINGS_FAILURE',

  syncNetvisor: 'integration/SYNC_NETVISOR',
  syncNetvisorSuccess: 'integration/SYNC_NETVISOR_SUCCESS',
  syncNetvisorFailure: 'integration/SYNC_NETVISOR_FAILURE',

  // Coop
  getCoopSettings: 'integration/GET_COOP_SETTINGS',
  getCoopSettingsSuccess: 'integration/GET_COOP_SETTINGS_SUCCESS',
  getCoopSettingsFailure: 'integration/GET_COOP_SETTINGS_FAILURE',

  updateCoopSettings: 'integration/UPDATE_COOP_SETTINGS',
  updateCoopSettingsSuccess: 'integration/UPDATE_COOP_SETTINGS_SUCCESS',
  updateCoopSettingsFailure: 'integration/UPDATE_COOP_SETTINGS_FAILURE',

  syncCoop: 'integration/SYNC_COOP',
  syncCoopSuccess: 'integration/SYNC_COOP_SUCCESS',
  syncCoopFailure: 'integration/SYNC_COOP_FAILURE',

};

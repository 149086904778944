import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function ScrollToTop(args) {

  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      if (document.documentElement.classList.contains('disable-scroll-to-top')) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }, [history]);

  return <React.Fragment {...args} />;
}

export default ScrollToTop;

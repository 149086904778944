import courseTypes from '../course/types';

const learningCoursesReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case courseTypes.putCourseForAdminSuccess:
      return { ...state, [payload.id]: payload };

    case courseTypes.deleteCourseVariantForAdminSuccess:
      // eslint-disable-next-line eqeqeq
      return { ...state, [payload.courseId]: { ...state[payload.courseId], variants: state[payload.courseId].variants.filter((value) => value != payload.courseVariantId) } };

    default:
      return state;
  }
};

export default learningCoursesReducer;

import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postSelectors, postActions } from 'redux/post';
import { PostContext } from 'components/Post';
import ReactionButton from 'components/ReactionButton';
import PropTypes from 'prop-types';
import reactionService from 'services/reactionService';

const PostReactionButton = ({ postId, ...rest }) => {

  const dispatch = useDispatch();

  const { isImportant, isCompact } = useContext(PostContext);

  const post = useSelector(state => postSelectors.getPost(state, postId));

  if (!post) {
    return null;
  }

  return (
    <ReactionButton
      reactions={reactionService.postReactionsArray}
      selected={post.userReaction}
      onReactionAdd={type => dispatch(postActions.addLike({ postId, type }))}
      onReactionRemove={() => dispatch(postActions.removeLike({ postId }))}
      darker={isImportant && isCompact}
      {...rest}
    />
  );

};

PostReactionButton.propTypes = {
  postId: PropTypes.any,
};

export default PostReactionButton;

import React, { useMemo, useState } from 'react';
import NavLink from 'components/NavLink';
import ViewContext from './ViewContext';
import { getCloseButtonLink } from './MemberEvents/memberEventsService';
import SideContext from './SideContext';
import TitleContext from './TitleContext';
import useSideQueryParam from './useSideQueryParam';

const SideProvider = (args) => {

  const [title, setTitle] = useState({
    closeButton: { component: NavLink, to: getCloseButtonLink },
  });

  const [side, setSide] = useSideQueryParam();

  const sideContext = useMemo(() => ({ setTitle, setSide }), [setTitle, setSide]);

  return (
    <TitleContext.Provider value={{ title }}>
      <ViewContext.Provider value={{ side }}>
        <SideContext.Provider value={sideContext} {...args} />
      </ViewContext.Provider>
    </TitleContext.Provider>
  );
};

export default SideProvider;

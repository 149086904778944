import React, { useCallback, useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import ConfirmationContext from './ConfirmationContext';

const ConfirmationProvider = ({ children }) => {
  const [[resolve, reject], setResolveReject] = useState([]);
  const [options, setOptions] = useState({});


  const useConfirmFn = useCallback(opts => new Promise((res, rej) => {
    setResolveReject([res, rej]);
    setOptions(opts);
  }), []);

  const onClose = useCallback((e, reason) => {
    setResolveReject([]);
    if (reject) {
      // Reason can be one of the following
      // "escapeKeyDown"
      // "backdropClick"
      // "cancel"
      reject(reason || 'cancel');
    }
  }, [reject]);

  const onSubmit = useCallback(() => {
    setResolveReject([]);

    if (resolve) {
      resolve();
    }
  }, [resolve]);

  return (
    <>
      <ConfirmationContext.Provider value={useConfirmFn}>
        {children}
      </ConfirmationContext.Provider>
      <ConfirmationDialog
        open={!!(resolve && reject)}
        onClose={onClose}
        onSubmit={onSubmit}
        {...options}
      />
    </>
  );

};

export default ConfirmationProvider;

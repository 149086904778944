import React, { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from 'components/Icons/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { useForm, useField } from 'react-final-form';
import Form from 'components/FinalForm/Form';
import history from 'providers/history';
import qs from 'query-string';
import { useQueryParam, StringParam } from 'use-query-params';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceActions, workspaceSelectors } from 'redux/workspace';
import validator, { minLength } from 'validators';
import getErrorProperties from 'components/FinalForm/getErrorProperties';
import { useTranslation } from 'react-i18next';
import usePrevious from 'hooks/usePrevious';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';

const autocompleteStyles = {
  '& .MuiInputBase-root': {
    borderRadius: 1,
    background: '#eee',
    padding: '10px 8px',
  },
};

const SearchField = ({ popperPlacement, reverseOptions, ...rest }) => {

  const { t } = useTranslation(['component']);

  const dispatch = useDispatch();

  const form = useForm();
  const { input: { value, onChange: setValue }, meta } = useField('search');

  const [open, setOpen] = useState(false);

  // Prefill with ?q= param
  const [q] = useQueryParam('q', StringParam);
  const prevQ = usePrevious(q);
  useEffect(() => {
    if (q !== prevQ) {
      setValue(q);
    }
  }, [prevQ, q, setValue]);

  const suggestions = useSelector(workspaceSelectors.getSearchAutocomplete);

  const options = useMemo(() => {
    if (!value || value.length < 3) {
      return [];
    }

    const uniqueSuggestions = uniq(suggestions ?? []);

    const result = [
      ...(value && [{ label: t('search.searchValue', { value }), value }]),
      ...(uniqueSuggestions.map(suggestion => ({ label: suggestion, value: suggestion }))),
    ];

    return reverseOptions ? result.reverse() : result;
  }, [reverseOptions, suggestions, t, value]);


  useEffect(() => {
    if (!value || value.length < 3) {
      return;
    }
    dispatch(workspaceActions.workspaceSearchAutocomplete({
      term: value,
    }));
  }, [dispatch, value]);

  const onChange = (event, val, reason) => {
    setValue(val && val.value ? val.value : val);

    if (reason === 'selectOption') {
      form.submit();
    }
  };

  // TODO: Error message

  return (
    <Autocomplete
      {...rest}
      freeSolo
      disableClearable
      sx={autocompleteStyles}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value2) => option?.value === value2?.value}
      getOptionLabel={option => typeof option === 'string' ? option : option.label}
      onInputChange={(e, val) => setValue(val)}
      onChange={onChange}
      value={value}
      options={options}
      slotProps={{
        popper: {
          ...(popperPlacement && { placement: popperPlacement }),
        },
      }}
      filterOptions={noop => noop}
      renderInput={params => (
        <TextField
          {...params}
          {...getErrorProperties(meta)}
          placeholder={t('search.searchWorkspacePlaceholder')}
          onFocus={e => e.target.select()}
          margin="none"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            sx: autocompleteStyles,
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
        />
      )}
    />
  );
};

const validate = validator({
  search: minLength(3),
});

const Search = ({ popperPlacement, reverseOptions }) => {

  const { pathname } = useLocation();

  // Quickfix: Rerenders the search field
  // which in turn hides the suggestions box.
  const [updates, setUpdates] = useState(0);

  const onSubmit = ({ search }) => {
    history.push({
      pathname: pathname.indexOf('/search') === 0 ? pathname : '/search',
      search: qs.stringify({ q: search }),
    });

    setUpdates(c => c + 1);
  };

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <SearchField key={updates} popperPlacement={popperPlacement} reverseOptions={reverseOptions} />
        </form>
      )}
    </Form>
  );
};

const propTypes = {
  popperPlacement: PropTypes.string,
  reverseOptions: PropTypes.bool,
};
Search.propTypes = propTypes;
SearchField.propTypes = propTypes;

export default Search;

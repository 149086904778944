import React from 'react';
import LikeColoredIcon from 'components/Icons/LikeColored';
import LoveColoredIcon from 'components/Icons/LoveColored';
import ThinkColoredIcon from 'components/Icons/ThinkColored';
import LaughingColoredIcon from 'components/Icons/LaughingColored';
import SadColoredIcon from 'components/Icons/SadColored';
import ClapColoredIcon from 'components/Icons/ClapColored';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import capitalize from 'lodash/capitalize';

const green = [
  { text: '#138A1F', background: 'rgba(77, 175, 79, 0.08)' }, // Normal
  { text: '#138A1F', background: 'rgba(77, 175, 79, 0.16)' }, // Dark
  { text: '#138A1F', background: 'rgba(77, 175, 79, 0.24)' }, // Darker
];

const red = [
  { text: '#F43756', background: 'rgba(244, 55, 86, 0.05)' }, // Normal
  { text: '#F43756', background: 'rgba(244, 55, 86, 0.16)' }, // Dark
  { text: '#F43756', background: 'rgba(244, 55, 86, 0.22)' }, // Darker
];

const yellow = [
  { text: '#7A5E00', background: 'rgba(255, 225, 125, 0.20)' }, // Normal
  { text: '#7A5E00', background: 'rgba(255, 225, 125, 0.24)' }, // Dark
  { text: '#7A5E00', background: 'rgba(255, 225, 125, 0.28)' }, // Darker
];

const blue = [
  { text: '#376CF4', background: 'rgba(55, 108, 244, 0.08)' }, // Normal
  { text: '#376CF4', background: 'rgba(55, 108, 244, 0.16)' }, // Dark
  { text: '#376CF4', background: 'rgba(55, 108, 244, 0.22)' }, // Darker
];

const like = {
  Icon: LikeColoredIcon,
  icon: <LikeColoredIcon />,
  getTranslation: (t) => t('component:reaction.like', 'like'),
  color: green,
};

const love = {
  Icon: LoveColoredIcon,
  icon: <LoveColoredIcon />,
  getTranslation: (t) => t('component:reaction.love', 'love'),
  color: red,
};

const think = {
  Icon: ThinkColoredIcon,
  icon: <ThinkColoredIcon />,
  getTranslation: (t) => t('component:reaction.think', 'think'),
  color: yellow,
};

const laugh = {
  Icon: LaughingColoredIcon,
  icon: <LaughingColoredIcon />,
  getTranslation: (t) => t('component:reaction.laugh', 'laugh'),
  color: yellow,
};

const sad = {
  Icon: SadColoredIcon,
  icon: <SadColoredIcon />,
  getTranslation: (t) => t('component:reaction.sad', 'sad'),
  color: yellow,
};

const clap = {
  Icon: ClapColoredIcon,
  icon: <ClapColoredIcon />,
  getTranslation: (t) => t('component:reaction.clap', 'clap'),
  color: blue,
};

const reactionsArray = ['like', 'love', 'clap', 'think', 'laugh', 'sad'];
const reactionsMap = { like, love, clap, think, laugh, sad };

const commentReactionsArray = reactionsArray;
const postReactionsArray = reactionsArray;

const getUnknownReaction = (reaction) => ({
  Icon: HelpOutlineRoundedIcon,
  icon: <HelpOutlineRoundedIcon />,
  getTranslation: () => capitalize(reaction),
  color: yellow,
});

const getReaction = (reaction, fallback = null) => {
  const data = (reactionsMap)[reaction];

  if (!data && fallback) {
    return getReaction(fallback, null);
  }

  return !data
    ? getUnknownReaction(reaction)
    : data;
};

export default {
  getReaction,

  reactionsArray,
  reactionsMap,

  commentReactionsArray,
  postReactionsArray,
};

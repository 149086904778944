import combineReducers from 'combine-reducers-global-state';
import { createPagination, addToPagination, resetPagination } from 'redux/helpers/pagination';
import { userTypes } from 'redux/user';
import types from './types';
import { getGuidanceCategoriesIndex } from './helpers';

const articles = (state = createPagination('all', 'articleIds'), { type, payload }) => {
  switch (type) {
    case types.getArticlesSuccess:
      return addToPagination(state, getGuidanceCategoriesIndex(payload.categories), payload, 'articleIds');

    case userTypes.changeLanguageSuccess:
      return resetPagination(state, '*', 'articleIds');

    default:
      return state;
  }
};

const guidanceReducer = combineReducers({
  articles,
});

export default { guidance: guidanceReducer };

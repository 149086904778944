import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import OpengraphPreview from 'containers/Opengraph/OpengraphPreview';
import PropTypes from 'propTypes';
import React from 'react';

const StyledOpengraphPreview = styled(OpengraphPreview)`
  background: #f0f0f0;

  ${({ href, onClick }) => (href || onClick) && css`
    &:hover {
      background: #e8e8e8;
    }
  `}
`;

const CommentOpenGraph = ({ url, ...rest }) => {
  return (
    <StyledOpengraphPreview
      variant="tiny"
      url={url}

      component="a"
      href={url}
      target="_blank"
      {...rest}
    />
  );
};

CommentOpenGraph.propTypes = {
  url: PropTypes.string.isRequired,
};

export default CommentOpenGraph;

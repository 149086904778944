import React, { useRef, useContext, useState } from 'react';
import ColorfulButton from 'components/ColorfulButton';
import styled from '@emotion/styled/macro';
import { useSelector, useDispatch } from 'react-redux';
import { postSelectors, postActions } from 'redux/post';
import { PostContext } from 'components/Post';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isMobile } from 'helpers';
import history from 'providers/history';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useConfirm from 'components/Confirmation/useConfirm';
import EditPostDialog from 'containers/Post/PostForm/EditPostDialog';
import { pollActions } from 'redux/poll';
import Divider from '@mui/material/Divider';
import Grid from 'components/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import NotificationsOffRoundedIcon from '@mui/icons-material/NotificationsOffRounded';
import TrashIcon from 'components/Icons/Trash';
import EditIcon from 'components/Icons/Edit';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import DownloadIcon from 'components/Icons/Download';
import ErrorIcon from '@mui/icons-material/Error';
import ReportOffRoundedIcon from '@mui/icons-material/ReportOffRounded';
import PinIcon from 'components/Icons/Pin';
import UnpinIcon from 'components/Icons/Unpin';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { snackbarActions } from 'redux/snackbar';
import config from 'config';

const ColorfulMenuButton = styled(ColorfulButton)`
  padding: 0.5em;
  min-width: auto;
`;

const PostMenu = ({ postId }) => {

  const dispatch = useDispatch();
  const confirm = useConfirm();

  const { t } = useTranslation(['component']);

  const menuButton = useRef();

  const [menuOpen, setMenuOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const post = useSelector(state => postSelectors.getPost(state, postId));

  const { isImportant, isCompact, isPinned, isPinnable, isFollowing, hasMenu } = useContext(PostContext);

  if (!post || !post.permissions || !hasMenu) {
    return null;
  }

  const canToggleFollow = post.permissions.follow;
  const canTogglePin = post.permissions.pin && isPinnable;
  const canToggleImportant = post.permissions.important;
  const canDownloadVotes = post.permissions.poll.downloadVotes && !isMobile;
  const canUpdate = post.permissions.update;
  const canDelete = post.permissions.delete;
  const { isDraft } = post;

  const onDeleteClick = () => {
    setMenuOpen(false);
    confirm({
      title: t('deletePostDialog.title'),
      description: t('deletePostDialog.info'),
      submit: t('deletePostDialog.deleteButton'),
      cancel: t('deletePostDialog.cancelButton'),
    })
      .then(() => dispatch(postActions.delete({ postId })))
      .catch(() => {});
  };

  const onEditClick = () => {
    setMenuOpen(false);
    if (isMobile) {
      history.push(`/post/${postId}/edit`);
    } else {
      setEditOpen(true);
    }
  };

  const onPinClick = () => {
    setMenuOpen(false);
    dispatch(postActions.patch({ postId, pinned: !isPinned }));
  };

  const onFollowClick = () => {
    const action = isFollowing ? postActions.unfollow : postActions.follow;

    dispatch(action({ postId }));
  };

  let postUrl = null;
  try {
    const relativePath = `/post/${postId}`;
    postUrl = new URL(relativePath, config.appUrl).href;
  } catch (e) { } // eslint-disable-line no-empty

  const copyPostLink = () => {
    setMenuOpen(false);
    navigator.clipboard.writeText(postUrl).then(() => {
      dispatch(snackbarActions.createSuccess(t('component:copyToClipboardAdornment.copied')));
    });
  };

  const onImportantClick = () => {
    setMenuOpen(false);
    dispatch(postActions.patch({ postId, important: !isImportant }));
  };

  const onPublishClick = () => {
    setMenuOpen(false);
    dispatch(postActions.patch({ postId, isDraft: false }));
  };

  const onDownloadClick = () => {
    setMenuOpen(false);
    dispatch(pollActions.downloadVotes({ pollId: post.poll }));
  };

  return (
    <Grid item>
      <ColorfulMenuButton
        onClick={() => setMenuOpen(true)}
        ref={menuButton}
        darker={isImportant && isCompact}
        aria-label={t('postActions.menu')}
        aria-haspopup="true"
        aria-controls={menuOpen ? `post-footer-menu-${post.id}` : undefined}
      >
        <MoreVertIcon />
      </ColorfulMenuButton>
      <Menu
        id={`post-footer-menu-${post.id}`}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={menuButton.current}
        MenuListProps={{ dense: true }}
      >
        {canToggleFollow && (
          <MenuItem onClick={onFollowClick}>
            <ListItemIcon>
              {isFollowing ? <NotificationsOffRoundedIcon /> : <NotificationsNoneRoundedIcon />}
            </ListItemIcon>
            <ListItemText
              primary={t(isFollowing ? 'postActions.unfollow' : 'postActions.follow')}
              secondary={t(isFollowing ? 'postActions.unfollowSecondary' : 'postActions.followSecondary')}
            />
          </MenuItem>
        )}

        {postUrl && (
          <MenuItem onClick={copyPostLink}>
            <ListItemIcon>
              <LinkRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={t('postActions.copyLink')} />
          </MenuItem>
        )}

        <Divider light hidden={(!canTogglePin && !canToggleImportant && !canDownloadVotes && !isDraft) || (!canToggleFollow)} />

        {canTogglePin && (
          <MenuItem onClick={onPinClick}>
            <ListItemIcon>{isPinned ? <UnpinIcon /> : <PinIcon />}</ListItemIcon>
            <ListItemText primary={t(isPinned ? 'postActions.unpin' : 'postActions.pin')} />
          </MenuItem>
        )}

        {canToggleImportant && (
          <MenuItem onClick={onImportantClick}>
            <ListItemIcon>{isImportant ? <ReportOffRoundedIcon /> : <ErrorIcon />}</ListItemIcon>
            <ListItemText primary={t(isImportant ? 'postActions.removeImportant' : 'postActions.addImportant')} />
          </MenuItem>
        )}

        {canDownloadVotes && (
          <MenuItem onClick={onDownloadClick}>
            <ListItemIcon><DownloadIcon /></ListItemIcon>
            <ListItemText primary={t('postActions.downloadPollResponses')} />
          </MenuItem>
        )}

        {isDraft && (
          <MenuItem onClick={onPublishClick}>
            <ListItemIcon><PublishedWithChangesRoundedIcon /></ListItemIcon>
            <ListItemText primary={t('postActions.publishNow')} />
          </MenuItem>
        )}

        <Divider light hidden={(!canTogglePin && !canToggleImportant && !canDownloadVotes && !isDraft) || (!canUpdate && !canDelete)} />

        {canUpdate && (
          <MenuItem onClick={onEditClick}>
            <ListItemIcon><EditIcon /></ListItemIcon>
            <ListItemText primary={t('postActions.edit')} />
          </MenuItem>
        )}
        {canDelete && (
          <MenuItem onClick={onDeleteClick}>
            <ListItemIcon><TrashIcon /></ListItemIcon>
            <ListItemText primary={t('postActions.delete')} />
          </MenuItem>
        )}
      </Menu>
      <EditPostDialog open={editOpen} onClose={() => setEditOpen(false)} postId={postId} />
    </Grid>
  );
};

PostMenu.propTypes = {
  postId: PropTypes.any,
};

export default PostMenu;

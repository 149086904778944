import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isDev } from 'helpers';
import HttpApi from 'i18next-http-backend';
import config from 'config';
import Pseudo from 'i18next-pseudo';

const supportedLngs = ['et', 'en', 'ru', 'fi', 'lv', 'lt', 'sv', 'uk', 'pl', 'hi', 'de'];

const useDebug = isDev && process.env.REACT_APP_I18N_DEBUG === 'true';
const usePseudo = isDev && process.env.REACT_APP_I18N_PSEUDO === 'true';

const resources = supportedLngs.reduce((acc, lang) => ({
  ...acc,
  [lang]: {
    translation: {}, // This key is here because otherwise a failing request is sent out for this...
  },
}), {});

if (usePseudo) {
  // Pseudo only works in en
  const languageChanged = (lng) => lng !== 'en' ? i18next.changeLanguage('en') : null;

  i18next
    .use(new Pseudo({ enabled: usePseudo }))
    .on('languageChanged', languageChanged);
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    defaultNS: 'common',
    fallbackLng: 'en',
    supportedLngs,
    partialBundledLanguages: true,
    resources,

    debug: useDebug,
    saveMissing: useDebug,
    missingKeyHandler: useDebug && ((...args) => console.debug(...args)), // eslint-disable-line no-console

    ...(usePseudo && { postProcess: ['pseudo'] }),

    backend: {
      loadPath: '/assets/i18n/{{lng}}/{{ns}}.json',
      queryStringParams: { v: config.buildDateTime || config.version },
    },

    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;

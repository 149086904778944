import { createAction } from 'redux/helpers/actions';
import uniqueId from 'lodash/uniqueId';
import types from './types';

const create = ({ options, ...rest }) => createAction(types.create, {
  notification: {
    key: uniqueId('snack'),
    options,
    ...rest,
  },
});

export default {
  createSuccess: (message, options) => create({ message: message || 'generic.success', options: { variant: 'success', translate: true, ...options } }),
  createFailure: (message, options) => create({ message, options: { variant: 'error', translate: false, ...options } }),
};

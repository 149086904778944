export default {
  getRecord: 'drive/GET_RECORD',
  getRecordSuccess: 'drive/GET_RECORD_SUCCESS',
  getRecordFailure: 'drive/GET_RECORD_FAILURE',

  createRecord: 'drive/CREATE_RECORD',
  createRecordSuccess: 'drive/CREATE_RECORD_SUCCESS',
  createRecordFailure: 'drive/CREATE_RECORD_FAILURE',

  updateRecord: 'drive/UPDATE_RECORD',
  updateRecordSuccess: 'drive/UPDATE_RECORD_SUCCESS',
  updateRecordFailure: 'drive/UPDATE_RECORD_FAILURE',

  deleteRecord: 'drive/DELETE_RECORD',
  deleteRecordSuccess: 'drive/DELETE_RECORD_SUCCESS',
  deleteRecordFailure: 'drive/DELETE_RECORD_FAILURE',

  getRecordTypes: 'drive/GET_RECORD_TYPES',
  getRecordTypesSuccess: 'drive/GET_RECORD_TYPES_SUCCESS',
  getRecordTypesFailure: 'drive/GET_RECORD_TYPES_FAILURE',

  getRecords: 'drive/GET_RECORDS',
  getRecordsSuccess: 'drive/GET_RECORDS_SUCCESS',
  getRecordsFailure: 'drive/GET_RECORDS_FAILURE',
};

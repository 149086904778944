import React, { useRef, useState } from 'react';
import ColorfulButton from 'components/ColorfulButton';
import styled from '@emotion/styled/macro';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ColorfulMenuButton = styled(ColorfulButton)`
  padding: 0.5em;
  min-width: auto;
`;

const CommentMenu = ({ onUpdateClick, onDeleteClick }) => {

  const { t } = useTranslation(['component']);

  const menuButton = useRef();

  const [open, setOpen] = useState(false);

  if (!onUpdateClick && !onDeleteClick) {
    return null;
  }

  const callFn = (fn) => {
    setOpen(false);
    fn();
  };

  return (
    <>
      <ColorfulMenuButton size="small" onClick={() => setOpen(true)} ref={menuButton}>
        <MoreVertIcon fontSize="small" />
      </ColorfulMenuButton>

      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={menuButton.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {onUpdateClick && <MenuItem onClick={() => callFn(onUpdateClick)}>{t('comment.edit')}</MenuItem>}
        {onDeleteClick && <MenuItem onClick={() => callFn(onDeleteClick)}>{t('comment.delete')}</MenuItem>}
      </Menu>
    </>
  );
};

CommentMenu.propTypes = {
  onDeleteClick: PropTypes.func,
  onUpdateClick: PropTypes.func,
};

export default CommentMenu;

import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getOverview: data => createAction(types.getOverview, data),
  getOverviewSuccess: data => createAction(types.getOverviewSuccess, data),
  getOverviewFailure: error => createAction(types.getOverviewFailure, error),

  getJobAnniversaries: data => createAction(types.getJobAnniversaries, data),
  getJobAnniversariesSuccess: data => createAction(types.getJobAnniversariesSuccess, data),
  getJobAnniversariesFailure: error => createAction(types.getJobAnniversariesFailure, error),

  getJobAnniversaryEvent: data => createAction(types.getJobAnniversaryEvent, data),
  getJobAnniversaryEventSuccess: data => createAction(types.getJobAnniversaryEventSuccess, data),
  getJobAnniversaryEventFailure: error => createAction(types.getJobAnniversaryEventFailure, error),

  addComment: data => createAction(types.addComment, data),
  addCommentSuccess: data => createAction(types.addCommentSuccess, data),
  addCommentFailure: error => createAction(types.addCommentFailure, error),

  getComments: data => createAction(types.getComments, data),
  getCommentsSuccess: data => createAction(types.getCommentsSuccess, data),
  getCommentsFailure: error => createAction(types.getCommentsFailure, error),
};

import React from 'react';
import styled from '@emotion/styled/macro';
import Stack from '@mui/material/Stack';
import PropTypes from 'propTypes';
import Box from '@mui/material/Box';
import { css } from '@emotion/react';
import OpengraphImage from '../OpengraphImage';

const Wrapper = styled(Box)`
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 4px;

  display: block;
  text-decoration: none;
  color: inherit;

  ${({ href, onClick }) => (href || onClick) && css`
    &:hover {
      background: #e7e7e7;
    }
    &:hover img {
      opacity: .9;
    }
  `}
`;

const Image = styled(OpengraphImage)`
  max-height: 300px;
  max-width: 100%;
  width: 100%;
`;

const Url = styled('div')`
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
`;

const Description = styled('p')`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

const Title = styled('h3')`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

const Content = styled('div')`
  padding: 0.5rem;
  font-size: 0.9rem;

  ${Image} + & {
    padding: 0.25rem 0.5rem 0.5rem;
  }
`;

const LargeOpengraphPreview = ({ url, title, description, image, ...rest }) => (
  <Wrapper {...rest}>
    {image && <Image src={image} />}
    <Content>
      <Stack direction="column" spacing={0.5}>
        {url && <Url>{url}</Url>}
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </Stack>
    </Content>
  </Wrapper>
);

LargeOpengraphPreview.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ src: PropTypes.string, srcSet: PropTypes.string }),
  ]),
};

export default LargeOpengraphPreview;

import { schema } from 'normalizr';
import userSchema from './user';
import postSchema from './post';
import groupSchema from './group';
import eventSchema from './event';
import fileGroupSchema from './fileGroup';
import memberSchema from './member';
import learningCourseSchema from './learningCourse';
import pageSchema from './page';

const unknownSchema = new schema.Entity('unknown');

export default {
  data: entity => ({
    course: learningCourseSchema,
    event: eventSchema,
    file_group: fileGroupSchema,
    group: groupSchema,
    member: memberSchema,
    page: pageSchema,
    post: postSchema,
    user: userSchema,
  })[entity.type] || unknownSchema,
};

import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import { useForm, useFormState } from 'react-final-form';
import Button from 'components/Button';
import EditIcon from 'components/Icons/Edit';
import IconButton from '@mui/material/IconButton';
import TrashIcon from 'components/Icons/Trash';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import Quiz from 'components/Quiz';
import PostFormContext from '../../PostFormContext';
import useCapabilities from '../../useCapabilities';

const Wrapper = styled('div')`
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 1.5rem;
`;

const QuizExhibit = () => {

  const { t } = useTranslation('component');

  const { change } = useForm();

  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });

  const { setView } = useContext(PostFormContext);

  const { quiz } = useCapabilities();

  const editQuiz = () => {
    setView('quiz');
  };

  const deleteQuiz = () => {
    change('quiz', undefined);
  };

  return (
    <Wrapper>
      <Quiz
        disabled
        question={values.quiz.question}
        explanation={values.quiz.explanation}
        options={values.quiz.options}
        revealed
        value={null}
        setValue={() => {}}

        quizActions={(
          <Grid container spacing={1} wrap="nowrap" alignItems="center" hidden={!quiz}>
            <Grid item>
              <Button color="default" startIcon={<EditIcon />} onClick={editQuiz}>{t('quizForm.editButton')}</Button>
            </Grid>
            <Grid item>
              <IconButton color="inherit" edge="end" onClick={deleteQuiz}>
                <TrashIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      />
    </Wrapper>
  );
};

export default QuizExhibit;

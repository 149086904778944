import IconButton from '@mui/material/IconButton';
import React, { useContext } from 'react';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Grid from 'components/Grid';
import PropTypes from 'prop-types';
import PostFormContext from '../PostFormContext';
import PostFormHeader from './PostFormHeader';

const PostFormHeaderTitle = ({ children, onBackClick }) => {

  const { setView } = useContext(PostFormContext);

  return (
    <PostFormHeader>
      <Grid container wrap="nowrap" alignItems="center">
        <Grid item>
          <IconButton onClick={onBackClick || (() => setView('base'))} edge="start" color="inherit">
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </PostFormHeader>
  );
};

PostFormHeaderTitle.propTypes = {
  onBackClick: PropTypes.func.isRequired,
};

export default PostFormHeaderTitle;

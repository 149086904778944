import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconText from 'components/IconText';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Popover from '@mui/material/Popover';

const StartIcon = () => <VisibilityOutlinedIcon style={{ fontSize: '1.2em' }} />;

const popoverStyles = {
  pointerEvents: 'none',
  '& .MuiPaper-root': {
    padding: 1,
    fontSize: '0.9rem',
  },
};

const PostInfoReach = ({ percentage, max: count, ...rest }) => {
  const { t } = useTranslation(['component']);

  const anchorEl = useRef();
  const [open, setOpen] = useState(false);

  const timeout = useRef();

  const onMouseEnter = () => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setOpen(true), 200);
  };

  const onMouseLeave = () => {
    clearTimeout(timeout.current);
    setOpen(false);
  };

  const popoverProps = {
    open,
    sx: popoverStyles,
    anchorEl: anchorEl.current,
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
    onClose: () => setOpen(false),
    disableRestoreFocus: true,
    // TODO: Create a better solution. This leaves
    // the popover hanging in wrong place with scroll
    disableScrollLock: true,
  };

  return (
    <IconText startIcon={<StartIcon />} ref={anchorEl} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...rest}>
      {percentage}%
      <Popover {...popoverProps}>{t('component:post.reachPercentageTitle', { percentage, count })}</Popover>
    </IconText>
  );
};

PostInfoReach.propTypes = {
  percentage: PropTypes.number,
  max: PropTypes.number,
};

export default PostInfoReach;

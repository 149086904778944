import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import PlayIcon from 'components/Icons/Play';

const VideoPreview = styled('div')`
  height: 115px;

  min-width: 180px;
  max-width: 320px;

  padding: 0.5rem;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #f5f5f5;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  svg {
    margin-right: 0.5rem;
  }

  ${({ imageSrc }) => imageSrc && css`
    background-color: #333;
    background-image: url(${imageSrc});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
`;

const Name = styled('div')`
  margin-top: auto;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Progress = styled('div')`
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #333;
  font-size: 1.2rem;
  font-weight: 400;
`;

const StyledPlay = styled(PlayIcon)`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 50px;
  opacity: .5;
`;

const DarkPlay = styled(StyledPlay)`
  color: #ddd;
`;

const LightPlay = styled(StyledPlay)`
  color: #fff;
`;

const PreviewVideo = ({ uploading, progress, name, poster }) => {

  const PlayButton = poster ? LightPlay : DarkPlay;

  return (
    <VideoPreview imageSrc={poster}>
      {uploading
        ? <Progress>{Math.round(progress * 100, 0)}%</Progress>
        : <PlayButton />}
      {name && <Name>{name}</Name>}
    </VideoPreview>
  );
};

PreviewVideo.propTypes = {
  uploading: PropTypes.bool,
  progress: PropTypes.number,
  name: PropTypes.string,
  poster: PropTypes.string,
};

export default PreviewVideo;

import React from 'react';
import styled from '@emotion/styled/macro';

const Wrapper = styled('div')`
  border-radius: 100px;
  background-color: #eee;
  border: 6px solid #eee;
  width: 100%;
  display: flex;
  justify-content: stretch;

  transition: background-color 200ms;
  &:hover {
    background-color: #ddd;
  }

  & > a {
    text-align: center;
    text-decoration: none;
    padding: 6px 12px;
    border-radius: 100px;
    width: 100%;
    color: inherit;
    font-weight: 500;

    &.active {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }
`;

const NavLinkSwitch = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

export default NavLinkSwitch;

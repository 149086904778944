import React from 'react';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import Grid from 'components/Grid';
import { jobAnniversarySelectors } from 'redux/jobAnniversary';
import NavLink from 'components/NavLink';
import { getJobAnniversariesLink } from 'containers/Side/MemberEvents/memberEventsService';
import WidgetData from './WidgetData';
import useWidgetCapDetector from './useWidgetCapDetector';

const StyledCard = styled(Card)`
  overflow: hidden;
`;

const Title = styled('span')`
  font-weight: 600;
  font-size: 1.1rem;
  white-space: nowrap;

  @media(max-width: 360px) { font-size: 1rem; }
  @media(max-width: 325px) { font-size: 0.9rem; }
`;

const JobAnniversaryEventLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
`;

const JobAnniversaryWidget = (args) => {
  const { t } = useTranslation(['component']);

  const today = useSelector(jobAnniversarySelectors.getJobAnniversaryEventsOverviewToday);
  const upcoming = useSelector(jobAnniversarySelectors.getJobAnniversaryEventsOverviewUpcoming);

  const [wrapperRef, [todayCap, upcomingCap]] = useWidgetCapDetector();

  return (
    <StyledCard {...args} ref={wrapperRef}>
      <JobAnniversaryEventLink to={getJobAnniversariesLink} replace>
        <CardContent>
          <Grid container direction="column" wrap="nowrap" spacing={1}>
            <Grid item>
              <Title>{t('jobAnniversaryWidget.title')} <span role="img" aria-label="emoji">&#x1F3C6;</span></Title>
            </Grid>
            <Grid item>
              <Grid container direction="row" wrap="nowrap" spacing={2}>
                {today.length > 0 && todayCap > 0 && (
                  <Grid item>
                    <WidgetData title={t('jobAnniversaryWidget.today')} users={today} max={todayCap} />
                  </Grid>
                )}
                {upcoming.length > 0 && upcomingCap > 0 && (
                  <Grid item>
                    <WidgetData title={t('jobAnniversaryWidget.upcoming')} users={upcoming} max={upcomingCap} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </JobAnniversaryEventLink>
    </StyledCard>
  );
};

export default JobAnniversaryWidget;

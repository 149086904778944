export default {
  getSectionsByFolderVariantForMember: 'documentSection/GET_SECTIONS_BY_FOLDER_VARIANT_FOR_MEMBER',
  getSectionsByFolderVariantForMemberSuccess: 'documentSection/GET_SECTIONS_BY_FOLDER_VARIANT_FOR_MEMBER_SUCCESS',
  getSectionsByFolderVariantForMemberFailure: 'documentSection/GET_SECTIONS_BY_FOLDER_VARIANT_FOR_MEMBER_FAILURE',

  postSectionClickForMember: 'documentSection/POST_SECTION_CLICK_FOR_MEMBER',
  postSectionClickForMemberSuccess: 'documentSection/POST_SECTION_CLICK_FOR_MEMBER_SUCCESS',
  postSectionClickForMemberFailure: 'documentSection/POST_SECTION_CLICK_FOR_MEMBER_FAILURE',

  getSectionsByFolderVariantForAdmin: 'documentSection/GET_SECTIONS_BY_FOLDER_VARIANT_FOR_ADMIN',
  getSectionsByFolderVariantForAdminSuccess: 'documentSection/GET_SECTIONS_BY_FOLDER_VARIANT_FOR_ADMIN_SUCCESS',
  getSectionsByFolderVariantForAdminFailure: 'documentSection/GET_SECTIONS_BY_FOLDER_VARIANT_FOR_ADMIN_FAILURE',

  getSectionForAdmin: 'documentSection/GET_SECTION_FOR_ADMIN',
  getSectionForAdminSuccess: 'documentSection/GET_SECTION_FOR_ADMIN_SUCCESS',
  getSectionForAdminFailure: 'documentSection/GET_SECTION_FOR_ADMIN_FAILURE',

  postSectionForAdmin: 'documentSection/POST_SECTION_FOR_ADMIN',
  postSectionForAdminSuccess: 'documentSection/POST_SECTION_FOR_ADMIN_SUCCESS',
  postSectionForAdminFailure: 'documentSection/POST_SECTION_FOR_ADMIN_FAILURE',

  putSectionForAdmin: 'documentSection/PUT_SECTION_FOR_ADMIN',
  putSectionForAdminSuccess: 'documentSection/PUT_SECTION_FOR_ADMIN_SUCCESS',
  putSectionForAdminFailure: 'documentSection/PUT_SECTION_FOR_ADMIN_FAILURE',

  deleteSectionForAdmin: 'documentSection/DELETE_SECTION_FOR_ADMIN',
  deleteSectionForAdminSuccess: 'documentSection/DELETE_SECTION_FOR_ADMIN_SUCCESS',
  deleteSectionForAdminFailure: 'documentSection/DELETE_SECTION_FOR_ADMIN_FAILURE',

  reorderSectionsForAdmin: 'documentSection/REORDER_SECTIONS_FOR_ADMIN',
  reorderSectionsForAdminSuccess: 'documentSection/REORDER_SECTIONS_FOR_ADMIN_SUCCESS',
  reorderSectionsForAdminFailure: 'documentSection/REORDER_SECTIONS_FOR_ADMIN_FAILURE',
};

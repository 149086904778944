import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as linkify from 'linkifyjs';

import { api } from 'redux/helpers/api';
import debounce from 'lodash/debounce';
import OpengraphActionsBar from './OpengraphActionsWrapper';
import OpengraphPreview from './OpengraphPreview';

const debounceDelay = 300;
const debounceOptions = {
  leading: false,
  trailing: true,
  maxWait: 5000,
};

const detectLinksFromHtml = (rawHtml) => {
  const doc = document.createElement('html');

  doc.innerHTML = rawHtml;

  return Array.from(doc.getElementsByTagName('a'))
    .filter(el => el.innerHTML !== '')
    .map(el => el.getAttribute('href'));
};

const detectLinksFromText = (text) => {
  return linkify.find(text, 'url').map(({ href }) => href);
};

const useOpengraph = ({ variant, disabled, anonymous, initialValue }) => {

  const linkCache = useRef({});
  const [choices, setChoices] = useState([]);

  const activeRef = useRef(null);
  const [active, setActiveOriginal] = useState(null);

  const setActive = useCallback((value) => {
    setActiveOriginal(value);
    activeRef.current = value;
  }, []);

  const initialValueRef = useRef(initialValue);
  useEffect(() => {
    if (!initialValueRef.current) {
      return;
    }
    setActive(initialValueRef.current, false);
  }, [setActive]);

  const fetchLink = useCallback((url) => {
    if (linkCache.current?.[url] !== undefined) {
      return Promise.resolve(linkCache.current[url]);
    }

    return api.opengraph.fetch({ url, anonymous: !!anonymous || false })
      .then(({ response }) => {
        if (response?.exception) {
          return null;
        }
        linkCache.current[url] = response;
        return response;
      });
  }, [anonymous]);

  const fetchLinks = useCallback(async (links) => {
    if (!links.length || disabled) {
      setActive(null);
      return null;
    }

    const promises = links.map(fetchLink);
    const nextChoices = await Promise.all(promises)
      .then(resolved => resolved.filter(v => !!v));

    setChoices(nextChoices.filter(v => !!v));

    const activeUrl = activeRef.current?.url;

    const isInvalid = !!activeUrl && !links.includes(activeUrl);
    const isEmpty = !activeUrl && nextChoices.length > 0;

    if (!isInvalid && !isEmpty) {
      return;
    }

    if (nextChoices.length > 0) {
      setActive(nextChoices[0]);
    } else {
      setActive(null);
    }
  }, [disabled, fetchLink, setActive]);

  const parseHtml = useMemo(() => debounce((html) => fetchLinks(detectLinksFromHtml(html)), debounceDelay, debounceOptions), [fetchLinks]);
  const parseText = useMemo(() => debounce((text) => fetchLinks(detectLinksFromText(text)), debounceDelay, debounceOptions), [fetchLinks]);

  const reset = useCallback(() => {
    linkCache.current = {};
    setActive(null);
  }, [setActive]);

  useEffect(() => {
    if (disabled) {
      setActive(null);
    }
  }, [disabled, setActive]);

  return useMemo(() => ({
    parseHtml,
    parseText,
    reset,
    linkData: active, // TODO: Some ID? Limit only to required fields

    active,
    setActive,

    choices,

    preview: (!active ? null : (
      <OpengraphActionsBar
        choices={choices}
        onChoiceClick={setActive}
        renderChoice={(choice) => <OpengraphPreview variant={variant} {...choice} />}

        onDelete={() => setActive(null)}
      >
        <OpengraphPreview variant={variant} {...active} />
      </OpengraphActionsBar>
    )
    ),
  }), [parseHtml, parseText, reset, active, setActive, choices, variant]);
};

export default useOpengraph;

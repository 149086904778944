import { css } from '@emotion/react';
import styled from '@emotion/styled';
import OpengraphPreview from 'containers/Opengraph/OpengraphPreview';
import PropTypes from 'propTypes';
import React from 'react';

const StyledOpengraphPreview = styled(OpengraphPreview)`

  ${({ variant, guavaInternal }) => variant === 'large' && !guavaInternal && css`
    width: 100%;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0;
  `}

  ${({ guavaInternal }) => guavaInternal && css`
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  `}
`;

const PostOpengraph = ({ url, ...rest }) => {
  return (
    <StyledOpengraphPreview url={url} {...rest} variant="large" component="a" href={url} target="_blank" />
  );
};

PostOpengraph.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PostOpengraph;

import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getArticles: payload => createAction(types.getArticles, payload),
  getArticlesSuccess: payload => createAction(types.getArticlesSuccess, payload),
  getArticlesFailure: payload => createAction(types.getArticlesFailure, payload),

  getArticleCategories: () => createAction(types.getArticleCategories),
  getArticleCategoriesSuccess: payload => createAction(types.getArticleCategoriesSuccess, payload),
  getArticleCategoriesFailure: payload => createAction(types.getArticleCategoriesFailure, payload),

  getArticle: ({ articleId }) => createAction(types.getArticle, { articleId }),
  getArticleSuccess: payload => createAction(types.getArticleSuccess, payload),
  getArticleFailure: payload => createAction(types.getArticleFailure, payload),

  addLike: ({ articleId }) => createAction(types.addLike, { articleId }),
  addLikeSuccess: payload => createAction(types.addLikeSuccess, payload),
  addLikeFailure: payload => createAction(types.addLikeFailure, payload),

  removeLike: ({ articleId }) => createAction(types.removeLike, { articleId }),
  removeLikeSuccess: payload => createAction(types.removeLikeSuccess, payload),
  removeLikeFailure: payload => createAction(types.removeLikeFailure, payload),
};

export const getBirthdaysLink = (location) => {
  const search = new URLSearchParams(location.search);
  search.set('side', 'birthdays');
  search.delete('memberId');
  search.delete('year');

  return { ...location, search: search.toString() };
};

export const getBirthdayLink = (location, memberId, year) => {
  const search = new URLSearchParams(location.search);
  search.set('side', 'birthdays');
  search.set('memberId', memberId);
  search.set('year', year);

  return { ...location, search: search.toString() };
};

export const getJobAnniversariesLink = location => {
  const search = new URLSearchParams(location.search);
  search.set('side', 'jobAnniversaries');
  search.delete('memberId');
  search.delete('year');

  return { ...location, search: search.toString() };
};

export const getJobAnniversaryLink = (location, memberId, year) => {
  const search = new URLSearchParams(location.search);
  search.set('side', 'jobAnniversaries');
  search.set('memberId', memberId);
  search.set('year', year);

  return { ...location, search: search.toString() };
};

export const getCloseButtonLink = (location) => {
  const search = new URLSearchParams(location.search);
  search.delete('memberId');
  search.delete('side');
  search.delete('year');

  return { ...location, search: search.toString() };
};

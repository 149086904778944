import { useState, useLayoutEffect } from 'react';
import { useActionListener } from 'hooks/actionListener/useActionListener';

const useReduxLoading = (start, end, initial = false, key = null) => {

  const [loading, setLoading] = useState(initial);

  useLayoutEffect(() => {
    setLoading(current => current === initial ? current : initial);
  }, [initial, key]);

  useActionListener(start, () => setLoading(true));

  useActionListener(end, () => setLoading(false));

  return loading;
};

export default useReduxLoading;

import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

const getPersonaSettings = ({ integration: { persona: { settings } } }) => settings;
const getNetvisorSettings = ({ integration: { netvisor: { settings } } }) => settings;
const getCoopSettings = ({ integration: { coop: { settings } } }) => settings;

const getIntegrationRuleEntities = ({ entities: { integrationRules } }) => integrationRules;
const getIntegrationRuleSelector = createSelector(
  [getIntegrationRuleEntities],
  (entities) => memoize(integrationRuleId => entities?.[integrationRuleId]),
);

const getIntegrationToRuleMap = ({ integration: { rulesByIntegration } }) => rulesByIntegration;
const getIntegrationRuleIdsForIntegration = createSelector(
  [getIntegrationToRuleMap],
  integrationToRuleMap => memoize(integrationId => integrationToRuleMap?.[integrationId] || []),
);

const getIntegrationRulesForIntegration = createSelector(
  [getIntegrationRuleIdsForIntegration, getIntegrationRuleSelector],
  (collectionSelector, ruleSelector) => memoize(integrationId => collectionSelector(integrationId).map(ruleSelector).filter(v => v !== undefined)),
);

const getIntegrationEntities = ({ entities: { integrations } }) => integrations;
const getIntegrationIds = ({ integration: { integrations } }) => integrations;
const getIntegrationSelector = createSelector(
  [getIntegrationEntities],
  (entities) => memoize(integrationId => entities?.[integrationId]),
);
const getIntegrations = createSelector(
  [getIntegrationIds, getIntegrationSelector],
  (integrationIds, getIntegration) => integrationIds.map(getIntegration).filter(v => v !== undefined),
);

export default {
  getIntegrations,
  getIntegrationSelector,

  getPersonaSettings,
  getNetvisorSettings,
  getCoopSettings,

  getIntegrationRuleSelector,
  getIntegrationRulesForIntegration,
};

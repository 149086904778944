import React from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Component = styled('a')`
  background: #fff;
  text-decoration: none;
  color: inherit;
  padding: 0.5rem;
  position: fixed;
  top: 5rem;
  left: 1rem;
  transform: translateY(-100%);
  transition: transform 0.3s;
  opacity: 0;
  z-index: -1;
  font-weight: 500;

  &:focus {
    opacity: 1;
    z-index: ${({ theme }) => theme.zIndex.navbar};
    transform: translateY(0%);
  }
`;


const SkipToContent = ({ contentId = '#content', ...rest }) => {

  const { t } = useTranslation('common');

  return (
    <Component href={contentId} {...rest}>{t('generic.skipToContent')}</Component>
  );
};

SkipToContent.propTypes = {
  contentId: PropTypes.string,
};

export default SkipToContent;

import React, { useCallback, useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ContactDirectoryProvider from 'containers/ContactDirectory/ContactDirectoryProvider';
import useContactDirectoryQueryParams from 'containers/Side/ContactDirectory/useContactDirectoryQueryParams';
import ContactDirectoryItem from 'containers/ContactDirectory/Item/ContactDirectoryItem';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';
import ContactDirectoryList from 'containers/ContactDirectory/List/ContactDirectoryList';
import ContactDirectoryDataContact from 'containers/ContactDirectory/Item/Data/ContactDirectoryDataContact';
import DrawerContent from '../Drawer/DrawerContent';
import ContactDirectorySingle from './ContactDirectorySingle';
import ContactDirectoryWide from './ContactDirectoryWide';

const ContactDirectory = (args) => {

  const { onItemToggleClick } = useContactDirectoryQueryParams();

  const useWideLayout = useMediaQuery('(min-width: 800px)');

  const hasPermission = useSelector(authSelectors.hasPermissionSelector);
  const { onItemOpenClick, onItemCloseClick, itemType, itemId, isContactManageMode, isContactCreateMode } = useContactDirectoryQueryParams();

  const onCreateSuccess = useCallback(({ data }) => {
    onItemOpenClick('contact', data);
  }, [onItemOpenClick]);

  const onDeleteSuccess = useCallback(() => {
    onItemCloseClick();
  }, [onItemCloseClick]);

  const item = useMemo(() => {
    if (isContactCreateMode) {
      return <ContactDirectoryDataContact variant="create" ContentWrapper={DrawerContent} onSuccess={onCreateSuccess} />;
    } if (itemType && itemId) {
      return (
        <ContactDirectoryItem
          itemType={itemType}
          itemId={itemId}
          ContentWrapper={DrawerContent}

          variant={isContactManageMode && hasPermission('MANAGE_PEOPLE_DIRECTORY') ? 'editable' : 'view'} // Contacts only
          onDeleteSuccess={onDeleteSuccess}
        />
      );
    }

    return null;

  }, [hasPermission, isContactCreateMode, isContactManageMode, itemId, itemType, onCreateSuccess, onDeleteSuccess]);

  const list = useMemo(() => (
    <ContactDirectoryList
      useWindow={false}
      topWrapperProps={{
        sx: {
          position: 'sticky',
          top: 0,
          background: '#fff',
          zIndex: 1,
        },
      }}
    />
  ), []);

  return (
    <ContactDirectoryProvider onItemClick={onItemToggleClick} {...args}>
      {useWideLayout
        ? <ContactDirectoryWide item={item} list={list} />
        : <ContactDirectorySingle item={item} list={list} />}
    </ContactDirectoryProvider>
  );
};

export default ContactDirectory;

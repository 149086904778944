import { useSnackbar } from 'notistack';
import { snackbarTypes } from 'redux/snackbar';
import { useTranslation } from 'react-i18next';
import { useActionListener } from 'hooks/actionListener/useActionListener';

const SnackbarDisplayer = () => {

  const { t } = useTranslation(['snackbar']);

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  useActionListener(snackbarTypes.create, (dispatch, action) => {

    const notification = action?.payload?.notification;

    if (!notification) {
      return;
    }

    const { translate, ...options } = notification.options;

    const message = translate ? t(notification.message) : notification.message;

    enqueueSnackbar(message, {
      key: notification.key,
      onClick: () => closeSnackbar(notification.key),
      ...options,
    });
  });

  return null;
};

export default SnackbarDisplayer;

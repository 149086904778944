import styled from '@emotion/styled/macro';
import MuiGrid from '@mui/material/Grid';

const config = {
  shouldForwardProp: prop => !['alignSelf'].includes(prop),
};

const Grid = styled(MuiGrid, config)`
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
`;

Grid.propTypes = MuiGrid.propTypes;

export default Grid;

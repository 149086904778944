import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { commentSelectors, commentActions, commentTypes } from 'redux/comment';
import CommentBody from 'components/Comment/Partials/CommentBody';
import CommentMedia from 'components/Comment/CommentMedia';
import { authSelectors } from 'redux/auth';
import CommentWrapper from 'components/Comment/CommentWrapper';
import CommentAttachments from 'components/Comment/Partials/CommentAttachments';
import CommentContent from 'components/Comment/CommentContent';
import useConfirm from 'components/Confirmation/useConfirm';
import { useTranslation } from 'react-i18next';
import NavLink from 'components/NavLink';
import CommentEditForm from 'containers/Comment/CommentEditForm';
import { translationSelectors } from 'redux/translation';
import CommentFooter from './CommentFooter';
import CommentOpenGraph from './CommentOpenGraph';

const Comment = ({ commentId, compact }) => {

  const dispatch = useDispatch();
  const confirm = useConfirm();

  const { t } = useTranslation(['component']);

  const [editMode, setEditMode] = useState(false);
  const [translated, setTranslated] = useState(false);

  const comment = useSelector(state => commentSelectors.getComment(state, commentId));
  const workspace = useSelector(authSelectors.getWorkspace);
  const getTranslation = useSelector(translationSelectors.getTranslationSelector);

  const onUpdateClick = useCallback(() => setEditMode(true), []);
  const beforeUpdateSubmit = useCallback((values) => ({ ...values, commentId }), [commentId]);
  const onUpdateSuccess = useCallback(() => setEditMode(false), []);
  const updateFormInitialValues = useMemo(() => ({
    body: comment?.body,
    link: comment?.opengraph,
  }), [comment?.body, comment?.opengraph]);

  const body = useMemo(() => translated
    ? getTranslation('comment', commentId, 'body', comment?.body)
    : comment?.body,
  [comment?.body, commentId, getTranslation, translated]);

  const onDeleteClick = useCallback(() => {
    confirm({
      title: t('deleteCommentDialog.title'),
      submit: t('deleteCommentDialog.deleteButton'),
      cancel: t('deleteCommentDialog.cancelButton'),
    })
      .then(() => dispatch(commentActions.delete({ commentId })))
      .catch(() => { });
  }, [commentId, confirm, dispatch, t]);

  const onTranslateClick = useCallback(() => {
    setTranslated(c => {
      const next = !c;

      if (next) {
        dispatch(commentActions.getTranslation({ commentId }));
      }

      return next;
    });
  }, [commentId, dispatch]);

  if (!comment) {
    return null;
  }

  return (
    <CommentWrapper
      isMe={workspace.memberId === comment.member.id}
      compact={compact}
      avatarProps={{
        src: comment.member.avatar?.src,
        srcSet: comment.member.avatar?.srcSet,
        name: comment.member.name,
        component: NavLink,
        to: `/member/${comment.member.id}`,
      }}
    >
      <CommentMedia media={comment.media} />
      {editMode
        ? (
          <CommentEditForm
            start={commentTypes.update}
            reject={commentTypes.updateFailure}
            resolve={commentTypes.updateSuccess}
            beforeSubmit={beforeUpdateSubmit}
            onSuccess={onUpdateSuccess}
            initialValues={updateFormInitialValues}
            onReset={() => setEditMode(false)}
          />
        ) : (
          <>
            <CommentContent>
              <CommentBody
                onTranslateClick={onTranslateClick}
                translatable={comment.translatable}
                translated={translated}

                body={body}
                name={comment.member.name}
                url={`/member/${comment.member.id}`}
              />
              {comment.opengraph && <CommentOpenGraph {...comment.opengraph} />}
              <CommentAttachments attachments={comment.attachments} />
            </CommentContent>
            <CommentFooter
              onUpdateClick={onUpdateClick}
              onDeleteClick={onDeleteClick}
              commentId={commentId}
            />
          </>
        )}


    </CommentWrapper>
  );
};

Comment.propTypes = {
  commentId: PropTypes.number.isRequired,
  compact: PropTypes.bool,
};

export default Comment;

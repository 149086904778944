import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const EndOfSomething = styled('div')`
  color: #666;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

EndOfSomething.propTypes = {
  children: PropTypes.string.isRequired,
};

export default EndOfSomething;

import styled from '@emotion/styled/macro';

const ChallengeBadge = styled('div', {
  shouldForwardProp: prop => !['src'].includes(prop),
})`
  display: flex;
  flex: 0 0 ${({ size }) => size || 50}px;
  width: ${({ size }) => size || 50}px;
  height: ${({ size }) => size || 50}px;

  /* border: 5px solid #fff; */
  border-radius: 50%;

  margin: 0.5rem 1rem;

  background-color: #fff;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
`;

export default ChallengeBadge;

import { schema } from 'normalizr';
import labelSchema from './label';
import groupSchema from './group';

const integrationRuleSchema = new schema.Entity('integrationRules', {
  labels: [labelSchema],
  analyticalLabels: [labelSchema],
  groups: [groupSchema],
});

export default integrationRuleSchema;

import React, { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import SearchField from './SearchField';
import TopBarContext from '../Contexts/TopBarContext';
import SettersContext from '../Contexts/SettersContext';
import ViewContext from '../Contexts/ViewContext';
import AddNewContactButton from '../AddNewContactButton';

const Wrapper = styled('div')`
  padding: 1rem 1rem 0rem;
`;

const ContactDirectoryTopBar = () => {

  const { t } = useTranslation(['component']);

  const { setTree, setSearch } = useContext(SettersContext);
  const { onAddContactClick } = useContext(ViewContext);
  const { search } = useContext(TopBarContext);

  return (
    <Wrapper>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs>
              <SearchField
                placeholder={t('contactDirectory.searchPlaceholder')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={0} alignItems="center">
                <TopBarContext.Consumer>
                  {({ buttons }) => buttons && <Grid item>{buttons}</Grid>}
                </TopBarContext.Consumer>
                <ViewContext.Consumer>
                  {({ tree }) => (
                    <Grid item>
                      <IconButton
                        onClick={() => setTree(c => !c)}
                        title={tree ? t('contactDirectory.showInAlphabeticalOrder') : t('contactDirectory.groupByStructuralUnit')}
                        aria-label={tree ? t('contactDirectory.showInAlphabeticalOrder') : t('contactDirectory.groupByStructuralUnit')}
                        edge="end"
                      >
                        {tree ? <SortByAlphaRoundedIcon /> : <AccountTreeRoundedIcon />}
                      </IconButton>
                    </Grid>
                  )}
                </ViewContext.Consumer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {onAddContactClick && (
          <Grid item sx={{ textAlign: 'center' }}>
            <AddNewContactButton onClick={onAddContactClick} />
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};

export default ContactDirectoryTopBar;

import { useEffect, useRef, useId } from 'react';
import { Adapter } from './adapter';

export const useActionListener = (actionName, callback) => {
  const callbackRef = useRef(callback);

  const hash = useId();

  const { actionHandler } = Adapter;

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    actionHandler?.addListener(hash, actionName, (action, dispatch) => {
      callbackRef.current(action, dispatch);
    });

    return () => actionHandler?.removeListener(hash);
  }, [actionHandler, actionName, hash]);
};

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { jobAnniversaryActions, jobAnniversarySelectors } from 'redux/jobAnniversary';
import CommentFeed from 'containers/Comment/CommentFeed';
import DrawerContent from 'containers/Side/Drawer/DrawerContent';
import { useTranslation } from 'react-i18next';

const JobAnniversaryComments = ({ eventId }) => {
  const { t } = useTranslation(['component']);
  const dispatch = useDispatch();

  const { commentIds, pagination } = useSelector(state => jobAnniversarySelectors.getComments(state, eventId));
  const loadMore = () => dispatch(jobAnniversaryActions.getComments({ eventId, page: pagination.nextPage }));

  return (
    <CommentFeed
      element={DrawerContent}

      compact

      commentIds={commentIds}
      currentPage={pagination.currentPage}
      hasMore={pagination.hasMore}
      loadMore={loadMore}
      useWindow={false}

      texts={{
        loading: t('jobAnniversaryEvents.loadingJobAnniversaryComments'),
        loadingMore: t('jobAnniversaryEvents.loadingMoreJobAnniversaryComments'),
        noComments: t('jobAnniversaryEvents.noJobAnniversaryComments'),
        noMoreComments: t('jobAnniversaryEvents.noMoreJobAnniversaryComments'),
      }}
    />
  );
};

JobAnniversaryComments.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default JobAnniversaryComments;

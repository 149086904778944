import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import ProgressiveImage from './ProgressiveImage';

const FakeImg = styled('img')`
  width: 0px;
  height: 0px;
  position: absolute;
`;

const ProgressiveBackgroundImage = ({ src, srcSet, sizes, children, ...rest }) => {

  const [active, setActive] = useState();

  return (
    <ProgressiveImage src={src} srcSet={srcSet} sizes={sizes} {...rest}>
      {({ loading, ...image }) => (
        <div>
          <FakeImg {...image} onLoad={e => setActive(e.target.currentSrc)} />
          {children({ src: active, loading })}
        </div>
      )}
    </ProgressiveImage>
  );
};

ProgressiveBackgroundImage.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  children: PropTypes.func,
};

export default ProgressiveBackgroundImage;

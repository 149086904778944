import React, { useContext } from 'react';
import MediaGallery from 'components/MediaGallery';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { isMobile } from 'helpers';
import PostContext from './PostContext';
import AttachmentImage from '../AttachmentImage';
import VideoPlayer from '../VideoPlayer';

const Wrapper = styled('div')`
  width: 100%;
`;

const PostMedia = ({ media, ...rest }) => {

  const { isCompact } = useContext(PostContext);

  const preferMaxSize = !isCompact && !isMobile;

  const theme = useTheme();

  return (
    <Wrapper>
      <MediaGallery items={media} {...rest}>
        {item => (
          item.type === 'video'
            ? <VideoPlayer url={item.url} poster={item.poster} {...rest} />
            : (
              <AttachmentImage
                className={isCompact ? 'feed-image' : ''}
                src={item.src}
                {...(!preferMaxSize && { srcSet: item.srcSet })}
                sizes={theme.feedImageSrcSetSizes}
                zoomable={false}
                {...rest}
              />
            )
        )}
      </MediaGallery>
    </Wrapper>
  );
};

PostMedia.propTypes = {
  media: PropTypes.array,
};

export default PostMedia;

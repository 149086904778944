require('react-app-polyfill/ie11');
require('react-app-polyfill/stable');
require('intersection-observer');
require('eventlistener-polyfill');

// Polyfill:
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
    || Element.prototype.webkitMatchesSelector;
}

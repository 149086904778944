import combineReducers from 'combine-reducers-global-state';
import update from 'immutability-helper';
import types from './types';
import { addToPagination } from '../helpers/pagination';

const coursesAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCoursesAnalyticsSuccess:
      return addToPagination(state, null, payload, 'courses');
    default:
      return state;
  }
};

const courseMembersAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCourseMembersAnalyticsSuccess: {
      return payload.refreshOnly
        ? update(state, {
          [payload.courseId]: {
            members: {
              $apply: (stateMembers) => stateMembers.map(
                stateMember => payload.data.find(
                  payloadMember => payloadMember.id === stateMember.id,
                ) ?? stateMember,
              ),
            },
          },
        })
        : addToPagination(state, payload.courseId, payload, 'members');
    }
    default:
      return state;
  }
};

const courseVariantQuestionsAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCourseVariantQuestionsAnalyticsSuccess:
      return { ...state, [payload.courseVariantId]: payload.data };
    default:
      return state;
  }
};

const courseLessonsAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCourseLessonsAnalyticsSuccess:
      return { ...state, [payload.courseId]: payload.data };
    default:
      return state;
  }
};

const lessonQuestionsAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLessonQuestionsAnalyticsSuccess:
      return { ...state, [payload.lessonId]: payload.data };
    default:
      return state;
  }
};

const membersAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMembersAnalyticsSuccess:
      return addToPagination(state, null, payload, 'members');
    default:
      return state;
  }
};

const memberCoursesAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMemberCoursesAnalyticsSuccess: {
      return payload.refreshOnly
        ? update(state, {
          [payload.memberId]: {
            courses: {
              $apply: (stateCourses) => stateCourses.map(
                stateCourse => payload.data.find(
                  payloadCourse => payloadCourse.id === stateCourse.id,
                ) ?? stateCourse,
              ),
            },
          },
        })
        : addToPagination(state, payload.memberId, payload, 'courses');
    }
    default:
      return state;
  }
};

const memberCourseAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMemberCourseAnalyticsSuccess:
      return addToPagination(state, `${payload.memberId}-${payload.courseId}`, payload, 'questions');
    default:
      return state;
  }
};

const labelsAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLabelsAnalyticsSuccess:
      return addToPagination(state, null, payload, 'labels');
    default:
      return state;
  }
};

const labelCoursesAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLabelCoursesAnalyticsSuccess:
      return addToPagination(state, payload.labelId, payload, 'courses');
    default:
      return state;
  }
};

const memberCourseVariantsAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMemberCourseVariantsAnalyticsSuccess:
      return {
        ...state,
        [`${payload.request.memberId}-${payload.request.courseId}`]: payload.data,
      };
    default:
      return state;
  }
};

const memberCourseVariantAnalytics = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMemberCourseVariantAnalyticsSuccess:
      return {
        ...state,
        [`${payload.request.memberId}-${payload.request.courseVariantId}`]: payload.data,
      };
    default:
      return state;
  }
};

const learningAnalyticsReducers = combineReducers({
  coursesAnalytics,
  courseMembersAnalytics,
  courseLessonsAnalytics,
  courseVariantQuestionsAnalytics,
  lessonQuestionsAnalytics,
  membersAnalytics,
  memberCoursesAnalytics,
  memberCourseAnalytics,
  labelsAnalytics,
  labelCoursesAnalytics,

  memberCourseVariantAnalytics,
  memberCourseVariantsAnalytics,
});

export default { learningAnalytics: learningAnalyticsReducers };

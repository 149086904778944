import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

const getPoll = ({ entities: { polls } }, pollId) => polls[pollId];

const getVotesByOptionLoading = createSelector(
  [({ poll: { votesByOptionLoading } }) => votesByOptionLoading],
  votesByOptionLoading => memoize(
    optionId => votesByOptionLoading[optionId],
  ),
);

const getMemberEntities = ({ entities: { members } }) => members;
const getVotesByOption = ({ poll: { votesByOption } }) => votesByOption;
const getVotedMembersForOption = createSelector(
  [getMemberEntities, getVotesByOption],
  (members, votesByOption) => memoize(
    optionId => (votesByOption[optionId] || []).map(id => members[id]),
  ),
);

const getOptionEntities = ({ entities: { pollOptions } }) => pollOptions;
const getPolls = ({ entities: { polls } }) => polls;
const getOptionsForPoll = createSelector(
  [getOptionEntities, getPolls],
  (options, polls) => memoize(
    pollId => ((polls[pollId] || {}).options || []).map(id => options[id]),
  ),
);

export default {
  getPoll,
  getVotesByOptionLoading,
  getVotedMembersForOption,
  getOptionsForPoll,
};

import { all, takeLeading, put, select } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import { snackbarActions } from 'redux/snackbar';
import { workspaceActions } from 'redux/workspace';
import { takeLeadingPerKey } from 'redux/helpers/saga';
import actions from './actions';
import types from './types';
import selectors from './selectors';

function* onGet({ payload }) {
  const { ok, error, response } = yield apiCall(api.group.get, payload);

  if (ok) {
    yield put(actions.getSuccess(response));
  } else {
    yield put(actions.getFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onSubscribe({ payload }) {
  const { ok, error } = yield apiCall(api.group.subscribe, payload);

  const group = yield select(state => selectors.getGroup(state, payload.id));

  if (ok) {
    yield put(actions.subscribeSuccess(payload));
    const key = payload.subscribe ? 'subscribed' : 'unsubscribed';
    yield put(snackbarActions.createSuccess(`group.${key}`));

    if (group?.private && !payload.subscribe) {
      // ?
    } else {
      yield put(actions.get({ id: payload.id }));
    }
  } else {
    yield put(actions.subscribeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onStore({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.group.store, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.storeSuccess(response));
    yield put(snackbarActions.createSuccess('group.created'));
  } else {
    yield put(actions.storeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdate({ payload }) {
  const { ok, response, error } = yield apiCall(api.group.update, payload);

  if (ok) {
    yield put(actions.updateSuccess(response));
    yield put(snackbarActions.createSuccess('group.updated'));
  } else {
    yield put(actions.updateFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDelete({ payload }) {
  const { ok, error } = yield apiCall(api.group.delete, payload);

  if (ok) {
    yield put(actions.deleteSuccess(payload));
    yield put(workspaceActions.getGroups());
    yield put(snackbarActions.createSuccess('group.deleted'));
  } else {
    yield put(actions.deleteFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetMembers({ payload }) {
  const { ok, error, response } = yield apiCall(api.group.getMembers, payload);

  if (ok) {
    yield put(actions.getMembersSuccess({ ...response, groupId: payload.groupId }));
  } else {
    yield put(actions.getMembersFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

export default function* groupSagas() {
  yield all([
    takeLeading(types.get, onGet),
    takeLeadingPerKey(types.subscribe, onSubscribe, ({ payload }) => payload.id),
    takeLeading(types.store, onStore),
    takeLeading(types.update, onUpdate),
    takeLeading(types.delete, onDelete),
    takeLeading(types.getMembers, onGetMembers),
  ]);
}

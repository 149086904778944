import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from 'components/Icons/Search';
import fieldRenderProps from 'components/FinalForm/fieldRenderProps';

const SearchField = ({ value, onChange, InputProps, ...rest }) => (
  <TextField
    name="search"
    variant="filled"
    value={value}
    onChange={onChange}
    fullWidth
    margin="none"
    hiddenLabel
    InputProps={{
      disableUnderline: true,
      size: 'small',
      sx: { borderRadius: '100px' },
      startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
      ...InputProps,
    }}
    {...rest}
  />
);

SearchField.propTypes = {
  ...fieldRenderProps,
};

export default SearchField;

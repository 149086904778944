import React, { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import RichEditor from 'components/RichEditor';
import fieldRenderProps from 'components/FinalForm/fieldRenderProps';
import getErrorProperties from 'components/FinalForm/getErrorProperties';

const RichEditorProxy = ({ input: { onChange, ...input }, meta, onEditorChange, ...rest }) => {

  const { batch } = useForm();

  const errorProps = getErrorProperties(meta);

  const combinedOnChange = useCallback(content => {
    batch(() => {
      onChange(content);
      onEditorChange?.(content);
    });
  }, [batch, onChange, onEditorChange]);

  return (
    <RichEditor onChange={combinedOnChange} {...input} {...errorProps} {...rest} />
  );
};

RichEditorProxy.propTypes = {
  ...fieldRenderProps,
  toggleRich: PropTypes.func,
};

const RichEditorField = args => <Field component={RichEditorProxy} fullWidth parse={v => v} {...args} />;

export default RichEditorField;

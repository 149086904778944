import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import AuthService from 'services/authService';
import { saveAs } from 'file-saver';
import { toFormData, getFullUrl, getCommonHeaders, createQueryParams, getFileName, getGenericFileName, decamelizeKeys, composeError } from './utils';
import { ensureValidToken } from './token';

const fetchAndDownloadBlob = (url, options) => fetch(url, options)
  .then(async response => {
    const contentDisposition = response.headers.get('Content-Disposition');

    const fileName = contentDisposition ? getFileName(contentDisposition) : getGenericFileName();

    if (!response.ok && response.json) {
      const jsonResponse = camelizeKeys(await response.json());

      return { ok: response.ok, error: composeError(jsonResponse) };
    }

    return response.blob().then(blob => {
      saveAs(blob, fileName);

      return { ok: response.ok };
    });
  });

const fetchAndParseResponse = (url, options, schema) => fetch(url, options)
  .then(async response => {
    if (response.status === 204) {
      return { ok: response.ok, error: null, response: {} };
    }

    const jsonResponse = camelizeKeys(await response.json());
    const { data, meta, links, exception } = jsonResponse;

    if (!response.ok || exception) {
      return { ok: response.ok, error: composeError(jsonResponse), response: undefined };
    }

    const { result, entities } = schema ? normalize(data, schema) : { result: data };

    return {
      ok: true,
      error: null,
      response: schema || meta || links
        ? { data: result, entities, links, meta } // Pagination & schema results
        : result, // Everything else
    };
  })
  .catch(error => ({ ok: false, error, response: undefined }));

const request = async (endpoint, method = 'GET', data = {}, { auth = true, schema = null, formData = false, blob = false, anonymous = false, fetchOptions = {}, headers = {} } = {}) => {

  if (auth && !anonymous) {
    await ensureValidToken();
  }

  const commonHeaders = anonymous ? getCommonHeaders() : getCommonHeaders(await AuthService.getToken());

  const options = {
    ...fetchOptions,
    method,
    headers: {
      ...(!formData && !blob && { 'Content-Type': 'application/json' }),
      ...commonHeaders,
      ...headers,
    },
  };

  // Compile the url
  let fullUrl = getFullUrl(endpoint);
  if (method === 'GET' && data && Object.keys(data).length !== 0) {
    fullUrl += `?${createQueryParams(decamelizeKeys(data, { split: /(?=[A-Z0-9])/ }))}`;
  }

  if (formData) {
    options.body = toFormData(data);
  } else if (method !== 'GET' && data) {
    options.body = JSON.stringify(decamelizeKeys(data, { split: /(?=[A-Z0-9])/ }));
  }

  return blob
    ? fetchAndDownloadBlob(fullUrl, options)
    : fetchAndParseResponse(fullUrl, options, schema);
};

export default request;

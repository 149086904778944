import { all, takeLeading, select, put, take, call } from 'redux-saga/effects';
import { authSelectors } from 'redux/auth';
import { api, apiCall } from 'redux/helpers/api';
import find from 'lodash/find';
import { eventChannel, END } from 'redux-saga';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { snackbarActions } from 'redux/snackbar';
import authService from 'services/authService';
import { getResumableUploader } from 'redux/helpers/resumable';
import types from './types';
import actions from './actions';

function* onGetFiles({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, response, error } = yield apiCall(api.files.getFiles, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getFilesSuccess(response));
  } else {
    yield put(actions.getFilesFailure(error));
  }
}

function getResumableChannel(token, files) {
  const resumable = getResumableUploader(token);

  const channel = eventChannel(emit => {
    resumable.on('catchAll', (event, ...args) => {

      if (['fileAdded', 'filesAdded', 'progress', 'fileSuccess', 'fileProgress', 'fileError', 'complete'].includes(event)) {
        const payload = { event, resumable, args };
        emit(actions.uploadEvent(payload));
      }

      if (event === 'complete') {
        emit(END);
      }
    });

    // Unsubscribe. Don't think it's possible with Resumable
    return () => {};
  });

  resumable.on('filesAdded', () => resumable.upload());

  resumable.addFiles(files);

  return channel;
}

function* uploadFiles(files) {
  if (files.length === 0) {
    return files;
  }

  const token = yield call(authService.getToken);

  const channel = getResumableChannel(token, files);

  while (true) {
    const action = yield take(channel);
    const { payload: { event, resumable, args } } = action;

    yield put(action);

    if (event === 'fileSuccess') {
      Object.defineProperty(args[0], 'guavaResponse', { value: args[1] });
    }

    if (event === 'complete') {
      return resumable.files;
    }
  }
}

function prepareDataForSending(data) {
  return data.map((group, groupIdx) => ({
    id: group.id,
    title: group.title,
    description: group.description,
    targets: group.targets,
    deleted: group.deleted,
    order: groupIdx,
    files: group.files.map((file, fileIdx) => ({
      id: file.id,
      deleted: file.deleted,
      order: fileIdx,
    })),
  }));
}

const updateDataWithUploadedFiles = (data, uploadedFiles) => data.map(group => ({
  ...group,
  files: group.files.map(file => {
    if (!file.file) {
      return file;
    }

    // If same file is part of multiple filegroups, then resumable
    // only attaches uniqueIdentifier to just one of the files. additionally,
    // matching by uniqueIdentifier or file Object itself is then no longer possible.
    const resumableFile = find(uploadedFiles, uploadedFile => {
      const compare = ['name', 'path', 'size', 'lastModified', 'lastModifiedDate'];

      const a = pick(uploadedFile.file, compare);
      const b = pick(file.file, compare);

      return isEqual(a, b);
    });

    try {
      return {
        ...file,
        id: JSON.parse(resumableFile.guavaResponse).id,
        file: null,
      };
    } catch (e) {
      console.error('Failed to match file with resumableFile', e);
      return file;
    }
  }),
}));

function* onSaveFiles({ payload }) {
  /* Remove useless */
  // Only keep groups which are already saved or new ones whcih are not deleted
  let data = yield payload.filter(group => group.id || (!group.id && !group.deleted));

  /* Gather files */
  const newFiles = yield data
    .filter(group => !group.deleted)
    .reduce((arr, group) => [
      ...arr,
      ...group.files
        .map(file => file.file)
        .filter(val => !!val),
    ], []);

  /* Upload files */
  const files = yield uploadFiles(newFiles);

  /* Match files & update id */
  data = yield updateDataWithUploadedFiles(data, files);

  /* Clean up */
  data = yield prepareDataForSending(data);

  /* Send to server */
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, response, error } = yield apiCall(api.files.saveFiles, { workspaceId, ...data });

  if (ok) {
    yield put(actions.saveFilesSuccess(response));
    yield put(actions.getFiles());
  } else {
    yield put(actions.saveFilesFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

export default function* fileSagas() {
  yield all([
    takeLeading(types.getFiles, onGetFiles),
    takeLeading(types.saveFiles, onSaveFiles),
  ]);
}

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import notificationsMiddleware from 'middleware/notifications';
import i18nMiddleware from 'middleware/i18n';
import globalMiddleware from 'middleware/global';
import createReduxPromiseListener from 'redux-promise-listener';
import { createMiddleware as createActionListenerMiddleware } from 'hooks/actionListener/createMiddleware';

import analyticsTypes from 'redux/analytics/types'; // Direct import, to avoid circular dependencies

import Reducers from './reducers';
import Sagas from './sagas';


/* eslint-disable */
// For Redux DevTools (Optional plugin)
const composeSetup = process.env.NODE_ENV !== 'production'
  && process.env.NODE_ENV !== 'test'
  && typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
/* eslint-enable */


const sagaMiddleware = createSagaMiddleware();
const promiseListener = createReduxPromiseListener();
const actionListener = createActionListenerMiddleware();

const middleware = [sagaMiddleware, promiseListener.middleware, notificationsMiddleware, i18nMiddleware, globalMiddleware, actionListener];

if (['local', 'development'].indexOf(process.env.NODE_ENV) > -1) {
  // eslint-disable-next-line global-require
  const { createLogger } = require('redux-logger');
  const loggerIgnoredTypes = [
    analyticsTypes.trackEvent,
  ];
  const loggerMiddleware = createLogger({
    collapsed: true,
    predicate: (getState, action) => loggerIgnoredTypes.indexOf(action.type) === -1,
  });

  middleware.push(loggerMiddleware);
}

const store = createStore(
  Reducers,
  {},
  composeSetup(applyMiddleware(...middleware)),
);

// Begin our Index Saga
sagaMiddleware.run(Sagas);

export { promiseListener };

export default store;

import combineReducers from 'combine-reducers-global-state';
import types from './types';

const questionsByLessonForMember = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getQuestionsByLessonForMemberSuccess:
      return { ...state, [payload.lessonId]: payload.data };
    default:
      return state;
  }
};

const currentQuestionSubmissionFeedback = (state = {}, { type, payload }) => {
  switch (type) {
    case types.postQuestionAnswerForMemberSuccess:
      return { ...state, ...payload };
    case types.postQuestionAnswerForMember:
    case types.postQuestionAnswerForMemberFailure:
    case types.postQuestionAnswerForMemberReset:
      return {};
    default:
      return state;
  }
};

const questionReducers = combineReducers({
  questionsByLessonForMember,
  currentQuestionSubmissionFeedback,
});

export default { question: questionReducers };

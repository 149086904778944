import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import File from 'components/File';
import Grid from 'components/Grid';

const Wrapper = styled('div')`
  margin: 0.5rem 0;
`;

const CommentAttachments = ({ attachments, ...rest }) => !attachments?.length ? null : (
  <Wrapper>
    <Grid container direction="column" wrap="nowrap" spacing={1} {...rest}>
      {attachments.map(({ id, name, url }) => (
        <Grid item xs={12} key={id}>
          <File url={url} name={name} />
        </Grid>
      ))}
    </Grid>
  </Wrapper>
);

CommentAttachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    url: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
  })),
};

export default CommentAttachments;

export default {
  getCoursesForMember: 'course/GET_COURSES_FOR_MEMBER',
  getCoursesForMemberSuccess: 'course/GET_COURSES_FOR_MEMBER_SUCCESS',
  getCoursesForMemberFailure: 'course/GET_COURSES_FOR_MEMBER_FAILURE',

  getCourseForMember: 'course/GET_COURSE_FOR_MEMBER',
  getCourseForMemberSuccess: 'course/GET_COURSE_FOR_MEMBER_SUCCESS',
  getCourseForMemberFailure: 'course/GET_COURSE_FOR_MEMBER_FAILURE',

  getCoursesForAdmin: 'course/GET_COURSES_FOR_ADMIN',
  getCoursesForAdminSuccess: 'course/GET_COURSES_FOR_ADMIN_SUCCESS',
  getCoursesForAdminFailure: 'course/GET_COURSES_FOR_ADMIN_FAILURE',

  getCourseForAdmin: 'course/GET_COURSE_FOR_ADMIN',
  getCourseForAdminSuccess: 'course/GET_COURSE_FOR_ADMIN_SUCCESS',
  getCourseForAdminFailure: 'course/GET_COURSE_FOR_ADMIN_FAILURE',

  postCourseForAdmin: 'course/POST_COURSE_FOR_ADMIN',
  postCourseForAdminSuccess: 'course/POST_COURSE_FOR_ADMIN_SUCCESS',
  postCourseForAdminFailure: 'course/POST_COURSE_FOR_ADMIN_FAILURE',

  putCourseForAdmin: 'course/PUT_COURSE_FOR_ADMIN',
  putCourseForAdminSuccess: 'course/PUT_COURSE_FOR_ADMIN_SUCCESS',
  putCourseForAdminFailure: 'course/PUT_COURSE_FOR_ADMIN_FAILURE',

  deleteCourseVariantForAdmin: 'course/DELETE_COURSE_VARIANT_FOR_ADMIN',
  deleteCourseVariantForAdminSuccess: 'course/DELETE_COURSE_VARIANT_FOR_ADMIN_SUCCESS',
  deleteCourseVariantForAdminFailure: 'course/DELETE_COURSE_VARIANT_FOR_ADMIN_FAILURE',

  duplicateCourseVariantForAdmin: 'course/DUPLICATE_COURSE_VARIANT_FOR_ADMIN',
  duplicateCourseVariantForAdminSuccess: 'course/DUPLICATE_COURSE_VARIANT_FOR_ADMIN_SUCCESS',
  duplicateCourseVariantForAdminFailure: 'course/DUPLICATE_COURSE_VARIANT_FOR_ADMIN_FAILURE',

  archiveCourseVariantForAdmin: 'course/ARCHIVE_COURSE_VARIANT_FOR_ADMIN',
  archiveCourseVariantForAdminSuccess: 'course/ARCHIVE_COURSE_VARIANT_FOR_ADMIN_SUCCESS',
  archiveCourseVariantForAdminFailure: 'course/ARCHIVE_COURSE_VARIANT_FOR_ADMIN_FAILURE',

  courseMembersMassAction: 'course/COURSE_MEMBERS_MASS_ACTION',
  courseMembersMassActionSuccess: 'course/COURSE_MEMBERS_MASS_ACTION_SUCCESS',
  courseMembersMassActionFailure: 'course/COURSE_MEMBERS_MASS_ACTION_FAILURE',

  getTrainingPlanByCourses: 'course/GET_TRAINING_PLAN_BY_COURSES',
  getTrainingPlanByCoursesSuccess: 'course/GET_TRAINING_PLAN_BY_COURSES_SUCCESS',
  getTrainingPlanByCoursesFailure: 'course/GET_TRAINING_PLAN_BY_COURSES_FAILURE',

  getTrainingPlanByLabels: 'course/GET_TRAINING_PLAN_BY_LABELS',
  getTrainingPlanByLabelsSuccess: 'course/GET_TRAINING_PLAN_BY_LABELS_SUCCESS',
  getTrainingPlanByLabelsFailure: 'course/GET_TRAINING_PLAN_BY_LABELS_FAILURE',
};

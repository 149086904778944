import React, { useEffect } from 'react';
import Dialog from 'components/Dialog/Dialog';
import DialogContent from 'components/Dialog/DialogContent';
import DialogActions from 'components/Dialog/DialogActions';
import DialogTitle from 'components/Dialog/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { pollActions, pollSelectors } from 'redux/poll';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import TextLoader from 'components/TextLoader';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PropTypes from 'prop-types';

const VotesDialog = ({ optionId, onClose, open, ...rest }) => {

  const { t } = useTranslation(['component']);

  const dispatch = useDispatch();

  const members = useSelector(pollSelectors.getVotedMembersForOption)(optionId);
  const loading = useSelector(pollSelectors.getVotesByOptionLoading)(optionId);

  useEffect(() => {
    if (optionId && open) {
      dispatch(pollActions.getVotes({ optionId }));
    }
  }, [dispatch, open, optionId]);

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle>{t('poll.votesDialog.title')}</DialogTitle>
      <DialogContent>

        {loading && <TextLoader />}

        <List dense hidden={!members || members.length === 0}>
          {members.map(member => (
            <ListItem key={member.id} disableGutters dense>
              <ListItemAvatar>
                <Avatar size={32} src={member.avatar?.src} srcSet={member.avatar?.srcSet} firstName={member.firstName} lastName={member.lastName} />
              </ListItemAvatar>
              <ListItemText
                style={{ wordWrap: 'break-word' }}
                primary={`${member.firstName} ${member.lastName}`}
                secondary={member.job || null}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>{t('poll.votesDialog.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

VotesDialog.propTypes = {
  optionId: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default VotesDialog;

import React from 'react';
import ChallengeComponent from 'components/Challenge';
import PropTypes from 'prop-types';
import NavLink from 'components/NavLink';
import pick from 'lodash/pick';

const Challenge = ({ challenge, ...rest }) => {

  const challengeProps = pick(challenge, [
    'badge', 'cover', 'title', 'progress', 'startsAt', 'active',
  ]);

  return <ChallengeComponent as={NavLink} to={`/challenges/${challenge.id}`} {...challengeProps} {...rest} />;
};

Challenge.propTypes = {
  challenge: PropTypes.shape({
    id: PropTypes.any,

    badge: PropTypes.string,
    cover: PropTypes.string,

    progress: PropTypes.number,
    progressDetails: PropTypes.array,

    description: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default Challenge;

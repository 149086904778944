import React from 'react';
import NavLink from 'components/NavLink';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const LinkBase = styled('span')`
  color: inherit;
  text-decoration: none;

  overflow: hidden;
  text-overflow: ellipsis;

  min-width: 10px;
  @media(max-width: 400px) {
    min-width: 40px;
  }
  flex-shrink: 1;
`;

const StyledNavLink = styled(LinkBase.withComponent(NavLink))`
  &:hover {
    text-decoration: underline;
  }

  &.active {
    pointer-events: none;
  }
`;

const getChannelLink = channel => {
  switch (channel.originType) {
    case 'WORKSPACE':
      return `/messages/${channel.id}`;
    case 'GROUP':
      return `/groups/${channel.originId}`;
    case 'EVENT':
      return `/event/${channel.originId}`;
    case 'COURSE':
      return `/course/${channel.originId}`;
    case 'RECORD':
      return `/record/${channel.originId}`;
    case 'CHALLENGE':
      return `/challenges/${channel.originId}`;
    default:
      return false;
  }
};

const useChannelName = channel => {
  const { t } = useTranslation(['common']);

  if (channel.originType === 'WORKSPACE') {
    return t(`channel.${channel.name}`);
  }

  if (channel.originType === 'GROUP' && channel.name) {
    return `#${channel.name}`;
  }

  if (channel.name) {
    return channel.name;
  }

  return null;
};

const PostInfoChannel = ({ channel, link = true }) => {
  const name = useChannelName(channel);

  if (!name) {
    return null;
  }

  const to = getChannelLink(channel);

  return to && link !== false
    ? <StyledNavLink to={to}>{name}</StyledNavLink>
    : <LinkBase>{name}</LinkBase>;
};

PostInfoChannel.propTypes = {
  link: PropTypes.bool,
  channel: PropTypes.shape({
    id: PropTypes.any,
    origin: PropTypes.any,
    origin_id: PropTypes.any,
    name: PropTypes.string,
  }),
};

export default PostInfoChannel;

import React, { useMemo } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import { isMobile } from 'helpers';
import { useTheme } from '@emotion/react';

const useWebStyles = () => {
  const theme = useTheme();

  return useMemo(() => ({
    '&&': {
      zIndex: theme.zIndex.tooltip,
    },
  }), [theme]);
};

const useMobileStyles = () => {

  const theme = useTheme();

  return useMemo(() => ({
    '.has-mobile-tabbar &&': {
      top: '-70px',
      '@supports ((top: calc(env(safe-area-inset-bottom) * -1 - 70px)))': {
        top: 'calc(env(safe-area-inset-bottom) * -1 - 70px)',
      },
      zIndex: theme.zIndex.tooltip,
      '@media (max-width: 400px)': {
        maxWidth: '90vw',
        margin: '0 auto',
      },
    },
    '&& > *': {
      opacity: 0.99,
      padding: '0 1rem',
      boxShadow: 'none',
    },
  }), [theme]);
};

const useStyles = isMobile ? useMobileStyles : useWebStyles;
const anchorOrigin = isMobile ? { vertical: 'bottom', horizontal: 'center' } : undefined;

const SnackbarProvider = (args) => {

  const classes = useStyles();

  return (
    <NotistackProvider
      sx={classes}
      autoHideDuration={4000}
      resumeHideDuration={4000}
      hideIconVariant={isMobile}
      dense={isMobile}
      anchorOrigin={anchorOrigin}
      {...args}
    />
  );
};

export default SnackbarProvider;

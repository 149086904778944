import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  send: data => createAction(types.send, data),
  sendSuccess: data => createAction(types.sendSuccess, data),
  sendFailure: data => createAction(types.sendFailure, data),

  validate: data => createAction(types.validate, data),
  validateSuccess: data => createAction(types.validateSuccess, data),
  validateFailure: data => createAction(types.validateFailure, data),
};

import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import { getPeriodDisplay } from 'services/datetimeService';
import NavLink from 'components/NavLink';
import ProgressiveBackgroundImage from './ProgressiveBackgroundImage';

const Wrapper = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  ${({ onClick, href }) => (onClick || href) && 'cursor: pointer;'}

  display: flex;
  overflow: hidden;
`;

const ImageBase = styled('span')`
  vertical-align: middle;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  flex: 0 0 60px;
  width: 60px;
  height: 45px;
  border-radius: 8px;
  margin-right: 1rem;

  background-color: #eee;
  color: #ccc;
`;

const Image = styled(ImageBase)`
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const Info = styled('div')`
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  justify-content: center;
  min-width: 1px;
`;

const Title = styled('span')`
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Time = styled('div')`
  color: #888;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0.25rem 0;
  white-space: nowrap;
`;

const EventBoxSmall = ({ id, cover, name, startAt, endAt, allDay, hasTargets, targetUsersCount, ...rest }) => {
  return (
    <Wrapper to={`/event/${id}`} {...rest}>
      {cover
        ? (
          <ProgressiveBackgroundImage src={cover.src} srcSet={cover.srcSet} sizes="60px">
            {({ src }) => <Image imageSrc={src} />}
          </ProgressiveBackgroundImage>
        )
        : <ImageBase><TodayRoundedIcon color="inherit" /></ImageBase>}
      <Info>
        <Title>{name}</Title>
        <Time title={getPeriodDisplay(startAt, endAt, allDay)}>
          {getPeriodDisplay(startAt, endAt, allDay)}
        </Time>
      </Info>
    </Wrapper>
  );
};

EventBoxSmall.propTypes = {
  id: PropTypes.any.isRequired,
  cover: PropTypes.shape({
    src: PropTypes.string,
    srcSet: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  startAt: PropTypes.any.isRequired,
  endAt: PropTypes.any,
  allDay: PropTypes.bool,

  hasTargets: PropTypes.bool,
  targetUsersCount: PropTypes.number,
};

export default EventBoxSmall;

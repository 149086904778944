import { all, takeEvery, put, select, take } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import { driveActions, driveSelectors } from 'redux/drive';
import { snackbarActions } from 'redux/snackbar';
import { takeOnce } from 'redux/helpers/saga';
import types from './types';
import actions from './actions';

function* onGetRecord({ payload }) {
  const { ok, response, error } = yield apiCall(api.drive.getRecord, payload);

  if (ok) {
    yield put(actions.getRecordSuccess(response));
  } else {
    yield put(actions.getRecordFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* selectOrFetchRecordTypes() {
  const recordTypes = yield select(driveSelectors.getRecordTypes);

  if (recordTypes && recordTypes.length > 0) {
    return yield recordTypes;
  }

  yield put(driveActions.getRecordTypes());
  yield take(types.getRecordTypesSuccess);

  return yield select(driveSelectors.getRecordTypes);
}

function* onGetRecords({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const recordTypes = yield selectOrFetchRecordTypes();

  // When no type specified, use all staff management types
  const type = !payload.type ? recordTypes : payload.type;

  const { ok, response, error } = yield apiCall(api.drive.getRecords, { workspaceId, ...payload, type });

  if (ok) {
    yield put(actions.getRecordsSuccess({ status: payload.status, ...response, type: payload.type }));
  } else {
    yield put(actions.getRecordsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onCreateRecord({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, response, error } = yield apiCall(api.drive.createRecord, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.createRecordSuccess(response));
  } else {
    yield put(actions.createRecordFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateRecord({ payload }) {
  const { ok, response, error } = yield apiCall(api.drive.updateRecord, payload);

  if (ok) {
    yield put(actions.updateRecordSuccess(response));
  } else {
    yield put(actions.updateRecordFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteRecord({ payload }) {
  const { ok, response, error } = yield apiCall(api.drive.deleteRecord, payload);

  if (ok) {
    yield put(actions.deleteRecordSuccess(response));
  } else {
    yield put(actions.deleteRecordFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetRecordTypes() {
  const { ok, response, error } = yield apiCall(api.drive.getRecordTypes);

  if (ok) {
    yield put(actions.getRecordTypesSuccess(response));
  } else {
    yield put(actions.getRecordTypesFailure(error));
  }
}

export default function* driveSagas() {
  yield all([
    takeEvery(types.getRecord, onGetRecord),
    takeOnce(types.getRecordTypes, onGetRecordTypes),
    takeEvery(types.getRecords, onGetRecords),
    takeEvery(types.createRecord, onCreateRecord),
    takeEvery(types.updateRecord, onUpdateRecord),
    takeEvery(types.deleteRecord, onDeleteRecord),
  ]);
}

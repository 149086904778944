import { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import useCapabilities from '../../useCapabilities';

const usePinButtonProps = (args) => {
  const { t } = useTranslation(['component']);

  const { pin } = useCapabilities();

  const { input: { value, onChange } } = useField('pinned');

  useEffect(() => {
    if (!pin && !!value) onChange(undefined);
  }, [onChange, pin, value]);

  return {
    children: t('postForm.addPinButton'),
    ...(!!value && { color: 'primary', selected: true }),
    onClick: () => onChange(!value),
    hidden: !pin,
    ...args,
  };
};

export default usePinButtonProps;

import { all, put, takeLeading } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import actions from './actions';
import types from './types';

function* onBoostUpdate({ payload }) {
  const { ok, error } = yield apiCall(api.boost.update, payload);

  if (ok) {
    yield put(actions.updateSuccess(payload));
  } else {
    yield put(actions.updateFailure(error));
  }
}

function* onGetBoosts({ payload, config }) {
  const { ok, response, error } = yield apiCall(api.boost.get, payload, config);

  if (ok) {
    yield put(actions.getBoostsSuccess(response));
  } else {
    yield put(actions.getBoostsFailure(error));
  }
}

export default function* boostSagas() {
  yield all([
    takeLeading(types.getBoosts, onGetBoosts),
    takeLeading(types.update, onBoostUpdate),
  ]);
}

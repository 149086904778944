import merge from 'lodash/merge';
import get from 'lodash/get';
import { groupTypes } from 'redux/group';

const groupsReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case groupTypes.subscribeSuccess:
      return merge({}, state, {
        [payload.id]: {
          subscribed: payload.subscribe,
          subscribersCount: state[payload.id].subscribersCount + (payload.subscribe ? +1 : -1),
        },
      });

    case groupTypes.getSuccess:
      return { ...state,
        [payload.data]: {
          ...state[payload.data],
          subscribers: get(payload, `entities.groups.${payload.data}.subscribers`, payload.subscribers),
        },
      };

    case groupTypes.updateSuccess:
      return merge({}, state, { [payload.id]: payload });

    default:
      return state;
  }
};

export default groupsReducer;

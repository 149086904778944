import combineReducers from 'combine-reducers-global-state';
import types from './types';

const challengeIds = (state = [], { type, payload }) => {
  switch (type) {
    case types.getChallengesSuccess:
      return payload.data;
    default:
      return state;
  }
};

const activityCategoryIds = (state = [], { type, payload }) => {
  switch (type) {
    case types.getActivityCategoriesSuccess:
      return payload.data;
    default:
      return state;
  }
};


const challengeReducers = combineReducers({
  activityCategoryIds,
  challengeIds,
});

export default { challenge: challengeReducers };

import React, { useCallback, useEffect, useInsertionEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import Sidebar from 'containers/Sidebar/Sidebar';
import MaxWidthContainer from 'components/MaxWidthContainer';
import WebNavbar from 'containers/WebNavbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import SkipToContent from 'components/SkipToContent';
import useResize from 'hooks/useResize';
import { useLocation } from 'react-router';
import usePrevious from 'hooks/usePrevious';
import { useTheme } from '@emotion/react';
import PropTypes from 'propTypes';

const RootContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const SidebarContainer = styled('div')`
  align-self: flex-start;

  display: flex;
  flex: 0 1 260px;
  max-width: 260px;

  height: calc(100vh - var(--navbar-height));

  margin-right: 1rem;

  position: sticky;
  top: var(--navbar-height);
`;

const SidebarScrollContainer = styled('div')`
  padding-right: 1rem; // Room for scrollbar

  overflow-y: auto;
  width: 100%;

  overscroll-behavior-y: contain;
`;

const overscrollHeight = 30;
const StyledSidebar = styled(Sidebar)`
  padding-top: 2rem;

  ${({ hasScroll }) => hasScroll && `margin-bottom: ${overscrollHeight}px;`}
`;

const Content = styled('div')`
  max-width: 100%;
  flex: 0 1 ${({ theme, maxWidth }) => maxWidth ?? theme.maxContentWidth.default};
  min-width: 290px;
  margin: 0 auto;

  margin-top: calc(2rem + var(--navbar-height));
  margin-bottom: 100px; // To allow scrolling over the content
`;

const WebCommonLayout = ({ children, widthVariant }) => {
  const [hasScroll, setHasScroll] = useState(false);

  const sidebarContainer = useRef();
  const sidebarScrollContainer = useRef();
  const sidebar = useRef();

  const sidebarMaxWidth = useRef(-Infinity);
  const location = useLocation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.sidebarBreakpoint})`);

  const isAdmin = location.pathname.indexOf('/manage/') === 0;

  const maxWidth = (isAdmin ? theme.maxContentWidth.wide : theme.maxContentWidth?.[widthVariant]);

  const previousMaxWidth = usePrevious(maxWidth);

  // Detect switch between user & admin, to avoid menu shifting then
  // store the maximum sidebar width and use that until page is resized
  useInsertionEffect(() => {
    if (maxWidth === previousMaxWidth || !sidebar.current) {
      return; // No change or no sidebar
    }

    sidebarMaxWidth.current = Math.max(
      sidebarMaxWidth.current,
      sidebar.current.getBoundingClientRect().width,
    );

    sidebar.current.style.setProperty('min-width', `${sidebarMaxWidth.current}px`);
  }, [maxWidth, previousMaxWidth]);

  // Reset current max on resize
  useResize(() => {
    if (!sidebar.current) {
      return;
    }

    sidebar.current.style.removeProperty('min-width');
    sidebarMaxWidth.current = sidebar.current.getBoundingClientRect().width;
  }, { passive: true }, true);

  // Initial value
  useEffect(() => {
    if (!sidebar.current) {
      return;
    }

    sidebarMaxWidth.current = sidebar.current.getBoundingClientRect().width || -Infinity;
  }, []);

  const calculateScroll = useCallback(() => {
    if (!sidebar.current || !sidebarScrollContainer.current) {
      return;
    }

    const contentHeight = sidebar.current.scrollHeight; // height + padding (excluding margin)
    const containerHeight = sidebarScrollContainer.current.getBoundingClientRect().height - 10; // Add scroll padding little bit sooner

    setHasScroll(contentHeight >= containerHeight);
  }, []);

  useLayoutEffect(calculateScroll, [calculateScroll]);
  useResize(calculateScroll, { passive: true });

  return (
    <>
      <SkipToContent />
      <RootContainer>
        <WebNavbar withSidebar={isSmallScreen} />

        {!isSmallScreen && (
          <SidebarContainer ref={sidebarContainer}>
            <SidebarScrollContainer ref={sidebarScrollContainer} bottomPadding={hasScroll}>
              <StyledSidebar ref={sidebar} hasScroll={hasScroll} />
            </SidebarScrollContainer>
          </SidebarContainer>
        )}

        <Content id="content" maxWidth={maxWidth}>{children}</Content>
      </RootContainer>
    </>
  );
};

WebCommonLayout.propTypes = {
  widthVariant: PropTypes.oneOf(['default', 'medium', 'wide']),
};

export default WebCommonLayout;

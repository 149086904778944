import update from 'immutability-helper';
import { feedbackTypes } from 'redux/feedback';

const feedbackTypesReducer = (state = {}, { type, payload }) => {

  switch (type) {

    case feedbackTypes.getFeedbackTypePublicUrlSuccess:
      return update(state, {
        [payload.feedbackTypeId]: {
          publicUrl: {
            $set: payload.publicUrl,
          },
        },
      });

    case feedbackTypes.updateFeedbackTypeSuccess:
      return update(state, {
        [payload.data]: {
          $set: payload.entities.feedbackTypes[payload.data],
        },
      });

    default:
      return state;
  }
};

export default feedbackTypesReducer;

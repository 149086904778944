import update from 'immutability-helper';
import { calendarTypes } from 'redux/calendar';

const eventsReducer = (state = {}, { type, payload }) => {

  switch (type) {

    case calendarTypes.updateEventSuccess:
      // No merging here. Just replace
      return update(state, {
        [payload.data]: { $set: payload.entities.events[payload.data] },
      });

    default:
      return state;
  }
};

export default eventsReducer;

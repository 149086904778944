import React from 'react';
import PropTypes from 'propTypes';
import TextField from './TextField';

const MenuProps = {
  variant: 'menu',
  PaperProps: {
    style: { maxHeight: 500 },
  },
};

const SelectField = ({ displayEmpty, renderValue, disableUnderline, variant, native, SelectProps, ...args }) => (
  <TextField
    select
    variant={variant}
    SelectProps={{
      ...(renderValue && { renderValue }),
      ...(displayEmpty && { displayEmpty }),
      ...(disableUnderline && { disableUnderline }),
      ...(variant && { variant }),
      ...(native && { native }),
      ...(MenuProps && { MenuProps }),
      ...(SelectProps || {}),
    }}
    {...args}
  />
);

SelectField.propTypes = {
  displayEmpty: PropTypes.bool,
  renderValue: PropTypes.func,
  disableUnderline: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  native: PropTypes.bool,
  SelectProps: PropTypes.object,
};

export default SelectField;

import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {

  getPoll: data => createAction(types.getPoll, data),
  getPollSuccess: data => createAction(types.getPollSuccess, data),
  getPollFailure: data => createAction(types.getPollFailure, data),

  addOption: data => createAction(types.addOption, data),
  addOptionSuccess: data => createAction(types.addOptionSuccess, data),
  addOptionFailure: data => createAction(types.addOptionFailure, data),

  removeOption: data => createAction(types.removeOption, data),
  removeOptionSuccess: data => createAction(types.removeOptionSuccess, data),
  removeOptionFailure: data => createAction(types.removeOptionFailure, data),

  getVotes: data => createAction(types.getVotes, data),
  getVotesSuccess: data => createAction(types.getVotesSuccess, data),
  getVotesFailure: data => createAction(types.getVotesFailure, data),

  storeVote: ({ optionId, pollId, ...data }) => createAction(types.storeVote, { optionId, pollId, ...data }),
  storeVoteSuccess: data => createAction(types.storeVoteSuccess, data),
  storeVoteFailure: ({ optionId, pollId, ...data }) => createAction(types.storeVoteFailure, { optionId, pollId, ...data }),

  deleteVote: ({ optionId, pollId, ...data }) => createAction(types.deleteVote, { optionId, pollId, ...data }),
  deleteVoteSuccess: data => createAction(types.deleteVoteSuccess, data),
  deleteVoteFailure: ({ optionId, pollId, ...data }) => createAction(types.deleteVoteFailure, { optionId, pollId, ...data }),

  downloadVotes: data => createAction(types.downloadVotes, data),
  downloadVotesSuccess: data => createAction(types.downloadVotesSuccess, data),
  downloadVotesFailure: data => createAction(types.downloadVotesFailure, data),

};

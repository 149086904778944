import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import CropOriginalRoundedIcon from '@mui/icons-material/CropOriginalRounded';

const Wrapper = styled('div')`
  height: 115px;
  position: relative;
  background: #252525;
  display: block;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const Image = styled('img')`
  height: 100%;
  max-width: 300px;
  display: block;
`;

const Error = styled('div')`
  width: 145px;
  height: 115px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #f9f9f9;
  font-weight: 600;

  svg {
    margin-right: 0.5rem;
  }
`;

const PreviewImage = args => {

  const [error, setError] = useState(false);

  return error
    ? <Error><CropOriginalRoundedIcon />Error!</Error>
    : <Wrapper><Image onLoad={() => setError(false)} onError={() => setError(true)} sizes="300px" {...args} /></Wrapper>;
};

PreviewImage.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
};

export default PreviewImage;

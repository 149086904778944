import React from 'react';
import PropTypes from 'prop-types';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useTranslation } from 'react-i18next';

const PostTargetUsersCount = ({ count }) => {

  const { t } = useTranslation(['component']);

  if (count <= 0) {
    return null;
  }

  return (
    <>
      <VisibilityOutlinedIcon />
      <p>{t('postPreHeader.postTargetUsersCount.visibleToCount', { count: count - 1 })}</p>
    </>
  );

};

PostTargetUsersCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default PostTargetUsersCount;

import React from 'react';
import styled from '@emotion/styled/macro';
import IconButton from '@mui/material/IconButton';
import TrashIcon from 'components/Icons/Trash';

const RemoveButtonWrapper = styled('div')`
  position: relative;
  min-width: 4rem;
  min-height: 4rem;
  overflow: hidden;
`;

const RemoveButtonClickable = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;

  background: rgba(0, 0, 0, 0.48);
  color: #fff;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const Removable = ({ children, ...args }) => (
  <RemoveButtonWrapper>
    {children}
    <RemoveButtonClickable {...args}>
      <TrashIcon fontSize="small" />
    </RemoveButtonClickable>
  </RemoveButtonWrapper>
);

export default Removable;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Grid from 'components/Grid';
import Collapse from '@mui/material/Collapse';
import { sanitizeQuizExplanation } from 'services/sanitizationService';
import SuccessOutlinedIcon from 'components/Icons/SuccessOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import uniqueId from 'lodash/uniqueId';
import BlankIcon from './Icons/Blank';

const Wrapper = styled('div')``;

const Explanation = styled('div')`
  margin-top: 1rem;
  padding: 0 1rem;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
`;

const Header = styled(Grid)`
  margin-bottom: 0.5rem;
`;

const Question = styled('div')`
  font-weight: 600;
`;

const config = {
  shouldForwardProp: prop => !['isCorrect', 'isRevealed', 'isSelected'].includes(prop),
};

const StyledLabel = styled(FormControlLabel, config)`
  border-radius: 4px;
  background: #f9f9f9;
  border-radius: 4px;
  margin: 0.25rem 0;
  padding: 0.5rem 0;
  align-items: flex-start;

  ${({ isCorrect, isRevealed }) => isRevealed && isCorrect && css`
    background: rgb(237, 247, 237);
    span {
      color: rgb(30, 70, 32) !important;
    }
    svg {
      color: #4caf50;
    }
  `}
`;

const Subtitle = styled('div')`
  color: #888;
  font-size: .9rem;
`;

const BulletRadio = args => (
  <Radio
    style={{ paddingTop: 0, paddingBottom: 0 }}
    icon={<RadioButtonUncheckedIcon />}
    checkedIcon={<RadioButtonCheckedIcon />}
    {...args}
  />
);

const getIconProps = (isCorrect, isRevealed, isSelected) => {
  if (!isRevealed) {
    return {}; // Default
  }

  if (!isSelected && !isCorrect) {
    return {
      icon: <BlankIcon />,
      checkedIcon: <BlankIcon />,
    };
  }

  const Icon = isCorrect ? SuccessOutlinedIcon : HighlightOffOutlinedIcon;

  return {
    icon: <Icon color="inherit" />,
    checkedIcon: <Icon color="inherit" />,
  };

};

const Quiz = ({ question, subtitle, explanation, options, quizActions, value, setValue, disabled, revealed }) => {

  const sanitizedExplanation = useMemo(() => sanitizeQuizExplanation(explanation), [explanation]);

  return (
    <Wrapper>
      <Header>
        <Grid container spacing={2} wrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="column" spacing={0} wrap="nowrap">
              <Grid item><Question>{question}</Question></Grid>
              {subtitle && (
                <Grid item><Subtitle>{subtitle}</Subtitle></Grid>
              )}
            </Grid>
          </Grid>
          <Grid item hidden={!quizActions}>
            {quizActions}
          </Grid>
        </Grid>
      </Header>

      <FormControl fullWidth margin="none" component="fieldset">
        <RadioGroup value={value} onChange={e => setValue(e.target.value)}>
          {options.map(option => (
            <StyledLabel
              isCorrect={option.isCorrect}
              isRevealed={revealed}
              isSelected={option.id === value}

              key={option.id || uniqueId()}
              value={option.id}
              control={<BulletRadio {...getIconProps(option.isCorrect, revealed, option.id === value)} />}
              label={option.value}
              disabled={disabled || revealed}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <Collapse in={revealed && !!explanation}>
        <Explanation dangerouslySetInnerHTML={{ __html: sanitizedExplanation }} />
      </Collapse>

    </Wrapper>
  );
};

Quiz.propTypes = {
  question: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  quizActions: PropTypes.any,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
    }),
  ).isRequired,

  value: PropTypes.any,
  setValue: PropTypes.func,

  disabled: PropTypes.bool,

  revealed: PropTypes.bool,
  explanation: PropTypes.string,
};

export default Quiz;

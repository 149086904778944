import combineReducers from 'combine-reducers-global-state';
import types from './types';

const documentReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getContractSuccess:
      return { ...state, [payload.type]: payload.data };
    default:
      return state;
  }
};

const workspaceReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getWorkspaceContractsSuccess:
      return { ...state, [payload.type]: payload.data };
    default:
      return state;
  }
};

const contractReducer = combineReducers({
  workspace: workspaceReducer,
  document: documentReducer,
});

export default { contract: contractReducer };

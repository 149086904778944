import React from 'react';
import Grid from 'components/Grid';
import Button from 'components/Button';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'helpers';
import PostFormFooter from '../Partials/PostFormFooter';
import PollSettingsButton from './PollSettingsButton';
import useBackClick from './useBackClick';

const PollViewFooter = () => {

  const { t } = useTranslation('component');

  const { submit } = useForm();

  const onBackClick = useBackClick();

  if (isMobile) {
    return (
      <PostFormFooter>
        <Grid container spacing={4} justifyContent="space-between" direction="column" wrap="nowrap">
          <Grid item xs={12}>
            <PollSettingsButton variant="drawer" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="text" onClick={onBackClick}>{t('pollForm.back')}</Button>
              </Grid>
              <Grid item>
                <Button onClick={() => submit()}>{t('pollForm.addToPost')}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PostFormFooter>
    );
  }

  return (
    <PostFormFooter>
      <Grid container spacing={2} justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <PollSettingsButton />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="text" onClick={onBackClick}>{t('pollForm.back')}</Button>
            </Grid>
            <Grid item>
              <Button onClick={() => submit()}>{t('pollForm.addToPost')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PostFormFooter>
  );
};

export default PollViewFooter;

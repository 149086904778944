import { all, takeLeading, put, select } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import { snackbarActions } from 'redux/snackbar';
import types from './types';
import actions from './actions';

function* onGetContract({ payload }) {
  const { ok, error, response } = yield apiCall(api.contract.get, payload);
  if (ok) {
    yield put(actions.getContractSuccess({ type: payload.type, data: response }));
  } else {
    yield put(actions.getContractFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDownloadContract({ payload }) {
  const { ok, error } = yield apiCall(api.contract.download, payload);
  if (ok) {
    yield put(actions.downloadContractSuccess());
  } else {
    yield put(actions.downloadContractFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetWorkspaceContracts({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.contract.workspace.getContracts, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getWorkspaceContractsSuccess({ data: response, type: payload.type }));
  } else {
    yield put(actions.getWorkspaceContractsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDownloadWorkspaceContract({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error } = yield apiCall(api.contract.workspace.downloadContract, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.downloadWorkspaceContractSuccess());
  } else {
    yield put(actions.downloadWorkspaceContractFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onAcceptWorkspaceContract({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.contract.workspace.acceptContract, { workspaceId, ...payload });
  if (ok) {
    yield put(actions.getWorkspaceContracts({ type: payload.type }));
    yield put(actions.acceptWorkspaceContractSuccess(response));
  } else {
    yield put(actions.acceptWorkspaceContractFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

export default function* contractSagas() {
  yield all([

    takeLeading(types.getContract, onGetContract),
    takeLeading(types.downloadContract, onDownloadContract),

    takeLeading(types.getWorkspaceContracts, onGetWorkspaceContracts),
    takeLeading(types.downloadWorkspaceContract, onDownloadWorkspaceContract),
    takeLeading(types.acceptWorkspaceContract, onAcceptWorkspaceContract),

  ]);
}

import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {

  getIntegrations: data => createAction(types.getIntegrations, data),
  getIntegrationsSuccess: data => createAction(types.getIntegrationsSuccess, data),
  getIntegrationsFailure: data => createAction(types.getIntegrationsFailure, data),

  patchIntegration: data => createAction(types.patchIntegration, data),
  patchIntegrationSuccess: data => createAction(types.patchIntegrationSuccess, data),
  patchIntegrationFailure: data => createAction(types.patchIntegrationFailure, data),

  // Rules
  getIntegrationRules: data => createAction(types.getIntegrationRules, data),
  getIntegrationRulesSuccess: data => createAction(types.getIntegrationRulesSuccess, data),
  getIntegrationRulesFailure: data => createAction(types.getIntegrationRulesFailure, data),

  updateIntegrationRule: data => createAction(types.updateIntegrationRule, data),
  updateIntegrationRuleSuccess: data => createAction(types.updateIntegrationRuleSuccess, data),
  updateIntegrationRuleFailure: data => createAction(types.updateIntegrationRuleFailure, data),

  createIntegrationRule: data => createAction(types.createIntegrationRule, data),
  createIntegrationRuleSuccess: data => createAction(types.createIntegrationRuleSuccess, data),
  createIntegrationRuleFailure: data => createAction(types.createIntegrationRuleFailure, data),

  integrationRuleMassAction: data => createAction(types.integrationRuleMassAction, data),
  integrationRuleMassActionSuccess: data => createAction(types.integrationRuleMassActionSuccess, data),
  integrationRuleMassActionFailure: data => createAction(types.integrationRuleMassActionFailure, data),

  // Persona
  getPersonaSettings: data => createAction(types.getPersonaSettings, data),
  getPersonaSettingsSuccess: data => createAction(types.getPersonaSettingsSuccess, data),
  getPersonaSettingsFailure: data => createAction(types.getPersonaSettingsFailure, data),

  updatePersonaSettings: data => createAction(types.updatePersonaSettings, data),
  updatePersonaSettingsSuccess: data => createAction(types.updatePersonaSettingsSuccess, data),
  updatePersonaSettingsFailure: data => createAction(types.updatePersonaSettingsFailure, data),

  // Netvisor
  getNetvisorSettings: data => createAction(types.getNetvisorSettings, data),
  getNetvisorSettingsSuccess: data => createAction(types.getNetvisorSettingsSuccess, data),
  getNetvisorSettingsFailure: data => createAction(types.getNetvisorSettingsFailure, data),

  updateNetvisorSettings: data => createAction(types.updateNetvisorSettings, data),
  updateNetvisorSettingsSuccess: data => createAction(types.updateNetvisorSettingsSuccess, data),
  updateNetvisorSettingsFailure: data => createAction(types.updateNetvisorSettingsFailure, data),

  syncNetvisor: data => createAction(types.syncNetvisor, data),
  syncNetvisorSuccess: data => createAction(types.syncNetvisorSuccess, data),
  syncNetvisorFailure: data => createAction(types.syncNetvisorFailure, data),

  // Coop
  getCoopSettings: data => createAction(types.getCoopSettings, data),
  getCoopSettingsSuccess: data => createAction(types.getCoopSettingsSuccess, data),
  getCoopSettingsFailure: data => createAction(types.getCoopSettingsFailure, data),

  updateCoopSettings: data => createAction(types.updateCoopSettings, data),
  updateCoopSettingsSuccess: data => createAction(types.updateCoopSettingsSuccess, data),
  updateCoopSettingsFailure: data => createAction(types.updateCoopSettingsFailure, data),

  syncCoop: data => createAction(types.syncCoop, data),
  syncCoopSuccess: data => createAction(types.syncCoopSuccess, data),
  syncCoopFailure: data => createAction(types.syncCoopFailure, data),

};

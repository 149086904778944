import React from 'react';

const PollViewContext = React.createContext({
  save: {},

  closeAutomatically: false,
  setCloseAutomatically: () => {},
});

export default PollViewContext;

export default {
  upload: 'files/UPLOAD',
  uploadSuccess: 'files/UPLOAD_SUCCESS',
  uploadFailure: 'files/UPLOAD_FAILURE',

  getFiles: 'files/GET_FILES',
  getFilesSuccess: 'files/GET_FILES_SUCCESS',
  getFilesFailure: 'files/GET_FILES_FAILURE',

  getFile: 'files/GET_FILE',
  getFileSuccess: 'files/GET_FILE_SUCCESS',
  getFileFailure: 'files/GET_FILE_FAILURE',

  saveFiles: 'files/SAVE_FILES',
  saveFilesSuccess: 'files/SAVE_FILES_SUCCESS',
  saveFilesFailure: 'files/SAVE_FILES_FAILURE',

  uploadEvent: 'files/UPLOAD_EVENT',
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import TextLoader from 'components/TextLoader';
import InfiniteScroll from 'react-infinite-scroller';
import { userActions, userSelectors } from 'redux/user';
import Notification from 'components/Notification';
import EmptyState from 'components/EmptyState/EmptyState';
import EndOfSomething from 'components/EndOfSomething';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  overflow: auto;
  width: 100%;
  padding-bottom: 1.5rem;

    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05),
                0px 2px 6px rgba(0, 0, 0, 0.05);
`;

const InfoContainer = styled('div')`
  text-align: center;
  width: 100%;
  padding-top: 1.5rem;
`;

const NotificationsList = React.forwardRef(({ className, useWindow, onClose }, ref) => {

  const dispatch = useDispatch();
  const { t } = useTranslation(['component']);

  const { notificationIds, pagination } = useSelector(userSelectors.getNotifications);

  useEffect(() => {
    dispatch(userActions.readNotifications());
  }, [dispatch, notificationIds]);

  useEffect(() => {
    dispatch(userActions.getNotifications({ page: 0 }));
  }, [dispatch]);

  const loadMore = () => dispatch(userActions.getNotifications({ page: pagination.nextPage }));

  const Loader = <TextLoader key={0} text={notificationIds.length === 0 && pagination.hasMore ? t('notificationsList.loading') : t('notificationsList.loadingMore')} />;

  return (
    <Container className={className} ref={ref}>
      <InfiniteScroll loadMore={loadMore} hasMore={pagination.hasMore} loader={Loader} useWindow={useWindow}>
        {notificationIds.map(id => <Notification key={id} notificationId={id} onClose={onClose} />)}
        <InfoContainer>
          {!pagination.hasMore && notificationIds.length === 0 && <EmptyState title={t('notificationsList.noNotifications')} />}
          {!pagination.hasMore && notificationIds.length > 0 && <EndOfSomething>{t('notificationsList.noMoreNotifications')}</EndOfSomething>}
        </InfoContainer>
      </InfiniteScroll>
    </Container>
  );
});

NotificationsList.propTypes = {
  useWindow: PropTypes.bool,
  onClose: PropTypes.func,
};

export default NotificationsList;

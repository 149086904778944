import React from 'react';
import PostFormWrapper from '../Partials/PostFormWrapper';
import BaseViewHeader from './BaseViewHeader';
import BaseViewContent from './BaseViewContent';
import BaseViewFooter from './BaseViewFooter';

const BaseView = () => (
  <PostFormWrapper>
    <BaseViewHeader />
    <BaseViewContent />
    <BaseViewFooter />
  </PostFormWrapper>
);

export default BaseView;

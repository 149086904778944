import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getQuestionsByLessonForMember: data => createAction(types.getQuestionsByLessonForMember, data),
  getQuestionsByLessonForMemberSuccess: data => createAction(types.getQuestionsByLessonForMemberSuccess, data),
  getQuestionsByLessonForMemberFailure: error => createAction(types.getQuestionsByLessonForMemberFailure, error),

  getQuestionForMember: data => createAction(types.getQuestionForMember, data),
  getQuestionForMemberSuccess: data => createAction(types.getQuestionForMemberSuccess, data),
  getQuestionForMemberFailure: error => createAction(types.getQuestionForMemberFailure, error),

  postQuestionAnswerForMember: data => createAction(types.postQuestionAnswerForMember, data),
  postQuestionAnswerForMemberSuccess: data => createAction(types.postQuestionAnswerForMemberSuccess, data),
  postQuestionAnswerForMemberFailure: error => createAction(types.postQuestionAnswerForMemberFailure, error),
  postQuestionAnswerForMemberReset: data => createAction(types.postQuestionAnswerForMemberReset, data),
};

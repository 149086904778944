import { all, takeLeading, put, call, select, debounce, takeEvery } from 'redux-saga/effects';
import { api, apiCall, commonApiSaga } from 'redux/helpers/api';
import { snackbarActions } from 'redux/snackbar';
import history from 'providers/history';
import { workspaceActions } from 'redux/workspace';
import { challengeActions } from 'redux/challenge';
import { isMobile } from 'helpers';
import { takeEveryAndAggregate, takeLeadingPerKey } from 'redux/helpers/saga';
import { channelSelectors } from 'redux/channel';
import uniq from 'lodash/uniq';
import { authSelectors } from 'redux/auth';
import types from './types';
import selectors from './selectors';
import actions from './actions';

function* onAddLike({ payload }) {
  const { ok, error } = yield apiCall(api.post.addLike, payload);

  if (ok) {
    yield put(actions.addLikeSuccess(payload));
    yield put(actions.get({ postId: payload.postId }));
  } else {
    yield put(actions.addLikeFailure(error));
  }
}

function* onRemoveLike({ payload }) {
  const { ok, error } = yield apiCall(api.post.removeLike, payload);

  if (ok) {
    yield put(actions.removeLikeSuccess(payload));
    yield put(actions.get({ postId: payload.postId }));
  } else {
    yield put(actions.removeLikeFailure(error));
  }
}

function* onGet({ payload, config }) {
  const { ok, response, error } = yield apiCall(api.post.get, payload, config);

  if (ok) {
    yield put(actions.getSuccess(response));
  } else {
    yield put(actions.getFailure({ postId: payload.postId, ...error }));
  }
}

function* onStore({ payload, config }) {
  const { ok, response, error } = yield apiCall(api.post.store, payload, config);

  if (ok) {
    yield put(actions.storeSuccess(response));

    const post = yield select(state => selectors.getPost(state, response.data));

    const channelSelector = yield select(channelSelectors.getChannelSelector);
    const channel = yield channelSelector(post ? post.channel : null);
    const originType = channel ? channel.originType : null;

    const challengeId = (/^#?\/challenges\/(\d+)\/?$/.exec(isMobile ? window.location.hash : window.location.pathname) || [])[1];

    if (challengeId) {
      yield put(workspaceActions.getChallenges({ filter: 'active' }));
      yield put(challengeActions.getChallenge({ challengeId }));
    }

    if (originType === 'WORKSPACE') {
      yield call(history.push, `/messages/${channel.id}`);
    } else if (originType === 'GROUP') {
      yield call(history.push, `/groups/${channel.originId}`);
    } else if (originType === 'CHALLENGE') {
      yield call(history.push, `/challenges/${channel.originId}`);
    } else if (originType === 'USER' && challengeId) {
      yield call(history.push, `/challenges/${challengeId}`);
    } else {
      yield call(history.push, `/post/${post.id}`);
    }
  } else {
    yield put(actions.storeFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdate({ payload, config }) {

  // Formdata can't send null/undefined values and these get removed in our toFormData function
  // At the moment I'm unsure, if this logic can be added globally to the toFormData function
  const scheduledAt = payload.scheduledAt === null ? '' : payload.scheduledAt;

  const { ok, response, error } = yield apiCall(api.post.update, { ...payload, scheduledAt }, config);

  if (ok) {
    response.postId = payload.postId;
    yield put(actions.updateSuccess(response));
    yield put(snackbarActions.createSuccess('post.updated'));
    yield call(history.replace, `/post/${payload.postId}`);
  } else {
    yield put(actions.updateFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDelete({ payload, config }) {
  const { ok, error } = yield apiCall(api.post.delete, payload, config);

  if (ok) {
    yield put(actions.deleteSuccess({ postId: payload.postId }));
    yield put(snackbarActions.createSuccess('post.deleted'));
  } else {
    yield put(actions.deleteFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onAddComment({ payload, config }) {
  const data = yield apiCall(api.post.addComment, payload, config);

  if (data.ok) {
    data.response.postId = payload.postId;
    yield put(actions.addCommentSuccess(data.response));
    yield put(actions.get({ postId: payload.postId }));
  } else {
    yield put(actions.addCommentFailure(data.error));
    yield put(snackbarActions.createFailure(data.error.message));
  }
}

function* onGetComments({ payload }) {
  const { ok, response, error } = yield apiCall(api.post.getComments, payload);

  if (ok) {
    response.postId = payload.postId;
    yield put(actions.getCommentsSuccess(response));
  } else {
    yield put(actions.getCommentsFailure(error));
  }
}

function* onGetLikes({ payload }) {
  const { ok, response, error } = yield apiCall(api.post.getLikes, payload);

  if (ok) {
    response.postId = payload.postId;
    yield put(actions.getLikesSuccess(response));
  } else {
    yield put(actions.getLikesFailure(error));
  }
}

function* onGetSmsExpenseEstimations({ payload }) {
  const { ok, response, error } = yield apiCall(api.post.getSmsExpenseEstimation, payload);
  if (ok) {
    yield put(actions.getSmsExpenseEstimationSuccess(response));
  } else {
    yield put(actions.getSmsExpenseEstimationFailure(error));
  }
}

function* onGetTranslations({ payload }) {
  const { ok, response, error } = yield apiCall(api.post.getTranslations, payload);

  if (ok) {
    response.postId = payload.postId;
    yield put(actions.getTranslationsSuccess(response));
  } else {
    yield put(actions.getTranslationsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onPatch({ payload }) {
  const { ok, response, error } = yield apiCall(api.post.patch, payload);

  if (ok) {
    yield put(actions.patchSuccess({ pinned: payload.pinned, ...response }));
  } else {
    yield put(actions.patchFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onFollow({ payload }) {
  const { ok, response, error } = yield apiCall(api.post.follow, payload);

  if (ok) {
    yield put(actions.followSuccess({ pinned: payload.pinned, ...response }));
  } else {
    yield put(actions.followFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUnfollow({ payload }) {
  const { ok, response, error } = yield apiCall(api.post.unfollow, payload);

  if (ok) {
    yield put(actions.unfollowSuccess({ pinned: payload.pinned, ...response }));
  } else {
    yield put(actions.unfollowFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetFeed({ payload: { feedName, feedType, feedId, ...payload } }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  let apiResponse = null;

  switch (feedType) {
    case 'home':
      apiResponse = yield apiCall(api.workspace.homeFeed, { ...payload, workspaceId }); break;
    case 'challenges':
      apiResponse = yield apiCall(api.challenge.getChallengesFeed, { ...payload, workspaceId }); break;
    case 'groups':
      apiResponse = yield apiCall(api.workspace.groupsFeed, { ...payload, workspaceId }); break;
    case 'group':
      apiResponse = yield apiCall(api.group.getPosts, { ...payload, groupId: feedId }); break;
    case 'record':
      apiResponse = yield apiCall(api.drive.getRecordFeed, { ...payload, recordId: feedId }); break;
    case 'member':
      apiResponse = yield apiCall(api.member.getMemberFeed, { ...payload, memberId: feedId }); break;
    case 'challenge':
      apiResponse = yield apiCall(api.challenge.getChallengeFeed, { ...payload, challengeId: feedId }); break;
    case 'event':
      apiResponse = yield apiCall(api.calendar.getEventFeed, { ...payload, eventId: feedId }); break;
    case 'channel':
      apiResponse = yield apiCall(api.channel.getFeed, { ...payload, channelId: feedId }); break;
    case 'course':
      apiResponse = yield apiCall(api.training.getCourseFeed, { ...payload, courseId: feedId }); break;
    default:
      apiResponse = { error: true };
      break;
  }

  const { ok, response, error } = apiResponse;

  if (ok) {
    yield put(actions.getFeedSuccess({ feedName, ...response }));
  } else {
    yield put(actions.getFeedFailure(error));
  }
}

const getReachPercentageActionAggregator = (actionsArray) => ({
  postIds: uniq(actionsArray.map(({ payload }) => payload?.postId).filter(v => !!v)),
});

export default function* postSagas() {
  yield all([
    takeLeading(types.get, onGet),
    takeLeading(types.store, onStore),
    takeLeading(types.update, onUpdate),
    takeLeading(types.delete, onDelete),
    takeLeading(types.follow, onFollow),
    takeLeading(types.unfollow, onUnfollow),
    takeLeading(types.addComment, onAddComment),
    takeLeading(types.addLike, onAddLike),
    takeLeading(types.removeLike, onRemoveLike),
    takeLeadingPerKey(types.getComments, onGetComments, ({ payload }) => payload.postId),
    takeLeadingPerKey(types.getLikes, onGetLikes, ({ payload }) => payload.postId),
    takeLeadingPerKey(types.patch, onPatch, ({ payload }) => payload.postId),
    debounce(500, types.getSmsExpenseEstimation, onGetSmsExpenseEstimations),
    takeLeading(types.getTranslations, onGetTranslations),

    takeEvery(types.getReachPercentages, commonApiSaga, { apiFn: api.post.getReachPercentages }),

    takeLeadingPerKey(types.getFeed, onGetFeed, ({ payload }) => payload.feedName),

    takeEveryAndAggregate(types.getReachPercentage, getReachPercentageActionAggregator, actions.getReachPercentages),
  ]);
}

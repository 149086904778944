import { combineReducers } from 'redux';
import { addToPagination, createPagination } from 'redux/helpers/pagination';
import types from './types';

const allPages = (state = createPagination(null, 'pageIds'), { type, payload }) => {
  switch (type) {
    case types.getPagesSuccess:
      return addToPagination(state, null, payload, 'pageIds');
    default:
      return state;
  }
};

const pages = combineReducers({
  allPages,
});

export default { pages };

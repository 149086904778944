import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useForm, useFormState } from 'react-final-form';
import Button from 'components/Button';
import EditIcon from 'components/Icons/Edit';
import IconButton from '@mui/material/IconButton';
import TrashIcon from 'components/Icons/Trash';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useDispatch, useSelector } from 'react-redux';
import { isFuture } from 'services/date-fns';
import PostFormContext from 'containers/Post/PostForm/PostFormContext';
import useCapabilities from 'containers/Post/PostForm/useCapabilities';
import { postActions, postSelectors } from 'redux/post';

const Wrapper = styled('div')`
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 1.5rem;
`;

const Title = styled('div')`
  font-weight: 600;
`;

const Subtitle = styled('div')`
  font-size: 0.875rem;
  color: #888;
`;

const EstimatedCost = styled('div')`
  font-weight: 600;
`;

const SmsExhibit = () => {

  const { t } = useTranslation('component');

  const dispatch = useDispatch();

  const { change } = useForm();

  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });

  const { setView } = useContext(PostFormContext);

  const { sms } = useCapabilities();

  const editSms = () => {
    setView('sms');
  };

  const deleteSms = () => {
    change('sms', undefined);
  };

  const smsExpenseEstimation = useSelector(postSelectors.getPostSmsExpenseEstimation);

  useEffect(() => {
    dispatch(postActions.getSmsExpenseEstimation({
      smsContent: values.sms?.content,
      channelId: values.channelId ?? '',
      target: values.target ?? '',
    }));
  }, [dispatch, values.channelId, values.sms?.content, values.target]);

  return (
    <Wrapper>
      <Grid container spacing={3} direction="column">

        <Grid item>
          <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" spacing={1}>

            <Grid item>
              <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item>
                  <SmartphoneIcon />
                </Grid>
                <Grid item xs>
                  <Title>{t('smsForm.exhibitTitle')}</Title>
                  <Subtitle>{t('smsForm.targetMembersDescription')}</Subtitle>
                </Grid>
              </Grid>
            </Grid>

            <Grid item hidden={!sms && !(values.scheduledAt && isFuture(new Date(values.scheduledAt)))}>
              <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center" spacing={1}>
                <Grid item>
                  <Button color="default" startIcon={<EditIcon />} onClick={editSms}>{t('smsForm.editButton')}</Button>
                </Grid>
                <Grid item>
                  <IconButton color="inherit" edge="end" onClick={deleteSms}>
                    <TrashIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>

        <Grid item>
          {values.sms?.content}
        </Grid>

        <Grid item hidden={!sms && !(values.scheduledAt && isFuture(new Date(values.scheduledAt)))}>
          <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Subtitle>
                {t('smsForm.targetMembersTitle')} : {smsExpenseEstimation.totalRecipient}
              </Subtitle>
            </Grid>
            <Grid item>
              <EstimatedCost>
                {t('smsForm.estimatedCost')} : {smsExpenseEstimation.totalExpense}€
              </EstimatedCost>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Wrapper>
  );

};

export default SmsExhibit;

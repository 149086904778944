import { useEffect, useInsertionEffect, useRef } from 'react';

const useResize = (callback, config, insertionHook = false) => {

  // Hook type shouldn't change!
  const insertionRef = useRef(insertionHook);
  const useHookFn = insertionRef.current ? useInsertionEffect : useEffect;

  // Config shouldn't change!
  const configRef = useRef(config);

  useHookFn(() => {
    window.addEventListener('resize', callback, configRef.current);
    return () => window.removeEventListener('resize', callback, configRef.current);
  }, [callback]);
};

export default useResize;

import styled from '@emotion/styled/macro';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { workspaceSelectors } from 'redux/workspace';
import Grid from 'components/Grid';
import Avatar from 'components/Avatar';
import PropTypes from 'prop-types';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import ListItemButton from '@mui/material/ListItemButton';
import ViewContext from '../Contexts/ViewContext';


const config = {
  shouldForwardProp: prop => !['withSubtitle', 'bold'].includes(prop),
};

const StyledListItem = styled(ListItemButton, config)`
  max-width: 100%;

  ${({ withSubtitle }) => withSubtitle && 'min-height: 53px;'}
`;

const Name = styled('div', config)`
  ${({ bold }) => bold && 'font-weight: 500;'}

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Subtitle = styled('div')`
  color: #888;
  font-size: 0.8rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ContactDirectoryListItem = ({ itemType, itemId, withSubtitle, bold, ...rest }) => {

  const getItemSelector = useSelector(workspaceSelectors.getContactDirectoryItemSelector);
  const item = getItemSelector(itemType, itemId);

  const { onItemClick } = useContext(ViewContext);

  if (!item) {
    return null;
  }

  return (
    <StyledListItem withSubtitle={withSubtitle} onClick={() => onItemClick(itemType, itemId)} {...rest}>
      <Grid container alignItems="center" wrap="nowrap" spacing={1}>
        <Grid item>
          {itemType === 'contact'
            ? <Avatar size={32} src={item?.avatar?.src} srcSet={item?.avatar?.srcSet} fallbackIcon={<ApartmentRoundedIcon fontSize="small" />} />
            : <Avatar size={32} src={item?.avatar?.src} srcSet={item?.avatar?.srcSet} name={item?.name} />}

        </Grid>
        <Grid item zeroMinWidth xs container direction="column" spacing={0.25}>
          <Grid item>
            <Name translate="no" title={item?.name} bold={bold}>{item?.name}</Name>
          </Grid>
          {withSubtitle && itemType === 'member' && item?.job && (<Grid item><Subtitle title={item?.job}>{item?.job}</Subtitle></Grid>)}
          {withSubtitle && itemType === 'contact' && item?.structuralUnit && (<Grid item><Subtitle title={item?.structuralUnit}>{item?.structuralUnit}</Subtitle></Grid>)}
        </Grid>
      </Grid>
    </StyledListItem>
  );
};

ContactDirectoryListItem.propTypes = {
  itemType: PropTypes.string,
  itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withSubtitle: PropTypes.bool,
  bold: PropTypes.bool,
};

export default ContactDirectoryListItem;

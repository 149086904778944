import React, { useState } from 'react';
import Button from 'components/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Grid from 'components/Grid';
import LayoutLogo from 'containers/LayoutLogo';
import PropTypes from 'prop-types';
import Sidebar from './Sidebar';

const drawerStyles = {
  '& .MuiPaper-root': {
    padding: 1,
    minWidth: '200px',
    maxWidth: '80%',
  },
};

const DrawerLogo = styled(LayoutLogo)`
  max-height: 100px;
  max-width: 200px;
  margin: 0 auto;
`;

const SidebarDrawer = ({ compact, ...rest }) => {

  const { t } = useTranslation(['common']);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const onClose = () => setDrawerOpen(false);
  const onOpen = () => setDrawerOpen(true);
  const onToggle = () => setDrawerOpen(c => !c);

  return (
    <>
      {compact
        ? <IconButton onClick={onToggle} aria-label={t('generic.menu')} {...rest}><MenuIcon /></IconButton>
        : <Button onClick={onToggle} variant="outlined" startIcon={<MenuIcon />} {...rest}>{t('generic.menu')}</Button>}

      <SwipeableDrawer anchor="left" sx={drawerStyles} open={drawerOpen} onClose={onClose} onOpen={onOpen}>
        <Grid container direction="column" wrap="nowrap" spacing={2}>
          <Grid item><DrawerLogo /></Grid>
          <Grid item><Sidebar /></Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  );
};

SidebarDrawer.propTypes = {
  compact: PropTypes.bool,
};

export default SidebarDrawer;

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';

const config = {
  shouldForwardProp: prop => !['direction', 'disablePadding'].includes(prop),
};

const StyledLoader = styled(Box, config)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;

  color: ${({ theme }) => theme.palette.primary.main};

  ${({ direction }) => direction === 'column' && css`
    flex-direction: column;
    text-align: center;
    * {
      padding-bottom: 0.5rem;
    }
  `}

  ${({ disablePadding }) => disablePadding && css`
    padding: 0;
  `}
`;

const Text = styled('span')`
  margin-left: 0.5rem;
  color: #666;
`;

const TextLoader = ({ text, noSpinner, disablePadding, delay, direction, className, ...rest }) => {
  const { t } = useTranslation(['common']);

  const content = text || t('generic.loading');

  const [isShown, setIsShown] = useState(!(delay > 0));
  useEffect(() => {
    if (delay && isShown === false) {
      // TODO: Maybe change to css animation instead
      const timeout = setTimeout(() => setIsShown(true), delay);
      return () => clearTimeout(timeout);
    }
  }, [delay, isShown]);

  if (!isShown) {
    return null;
  }

  return (
    <StyledLoader className={className} direction={direction} disablePadding={disablePadding} {...rest}>
      <CircularProgress size="1rem" hidden={noSpinner} />
      <Text>{content}</Text>
    </StyledLoader>
  );
};

TextLoader.propTypes = {
  text: PropTypes.string,
  noSpinner: PropTypes.bool,
  delay: PropTypes.number,
  disablePadding: PropTypes.bool,
  direction: PropTypes.oneOf(['row', 'column']),
};

export default TextLoader;

import { selectPagination } from 'redux/helpers/pagination';
import { getGuidanceCategoriesIndex } from './helpers';

const getArticle = ({ entities: { articles } }, articleId) => articles[articleId];

const getArticlesFeed = ({ guidance: { articles } }, categories) => selectPagination(articles, getGuidanceCategoriesIndex(categories), 'articleIds');

export default {
  getArticle,
  getArticlesFeed,
};

import { schema } from 'normalizr';
import memberSchema from './member';
import contactSchema from './contact';

const unknownSchema = new schema.Entity('unknown');

export default {
  data: entity => ({
    contact: contactSchema,
    member: memberSchema,
  })[entity.type] || unknownSchema,
};

import { all, takeLeading, put, call, takeEvery, select } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { snackbarActions } from 'redux/snackbar';
import NotificationService from 'services/notificationService';
import { authSelectors, authActions } from 'redux/auth';
import types from './types';
import actions from './actions';

function* onUpdateAvatar({ payload }) {
  const { ok, error, response } = yield apiCall(api.user.updateAvatar, payload);
  if (ok) {
    yield put(actions.updateAvatarSuccess(response));
    yield put(authActions.getProfile());
    yield put(snackbarActions.createSuccess('user.avatarUpdated'));
  } else {
    yield put(actions.updateAvatarFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteAvatar() {
  const { ok, error, response } = yield apiCall(api.user.deleteAvatar, {});
  if (ok) {
    yield put(actions.deleteAvatarSuccess(response));
    yield put(snackbarActions.createSuccess('user.avatarDeleted'));
  } else {
    yield put(actions.deleteAvatarFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetNotifications({ payload = {} }) {
  const limit = 10;
  const page = payload.page ? payload.page : 1;
  const { ok, error, response } = yield apiCall(api.user.getNotifications, { page, limit });
  if (ok) {
    yield put(actions.getNotificationsSuccess(response));
  } else {
    yield put(actions.getNotificationsFailure(error));
  }
}

function* onGetNotificationSettings() {
  const { ok, error, response } = yield apiCall(api.user.getNotificationSettings);
  if (ok) {
    yield put(actions.getNotificationSettingsSuccess(response));
  } else {
    yield put(actions.getNotificationSettingsFailure(error));
  }
}

function* onGetSettings() {
  const { ok, error, response } = yield apiCall(api.user.getSettings);
  if (ok) {
    yield put(actions.getSettingsSuccess(response));
  } else {
    yield put(actions.getSettingsFailure(error));
  }
}

function* onReadNotifications({ payload }) {
  const { ok, error, response } = yield apiCall(api.user.readNotifications, payload);
  if (ok) {
    yield put(actions.readNotificationsSuccess(response));
  } else {
    yield put(actions.readNotificationsFailure(error));
  }

  yield call(NotificationService.firebase.setBadgeNumber, 0);
  yield call(NotificationService.firebase.clearAllNotifications);
}

function* onNewNotification() {
  yield put(actions.getNotifications());

  const count = yield select(authSelectors.getNotificationCount);
  yield call(NotificationService.firebase.setBadgeNumber, count);
}

function* onUpdateProfile({ payload }) {
  const { ok, error } = yield apiCall(api.user.updateProfile, payload);
  if (ok) {
    yield put(actions.updateProfileSuccess(payload));
    yield put(authActions.getProfile());
    yield put(snackbarActions.createSuccess('user.profileUpdated'));
  } else {
    yield put(actions.updateProfileFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateSettings({ payload, config }) {
  const { ok, error } = yield apiCall(api.user.updateSettings, payload, config);
  if (ok) {
    yield put(actions.updateSettingsSuccess(payload));
    yield put(actions.getSettings());
    yield put(snackbarActions.createSuccess('user.settingsUpdated'));
  } else {
    yield put(actions.updateSettingsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateNotificationSettings({ payload }) {
  const { ok, error } = yield apiCall(api.user.updateNotificationSettings, payload);
  if (ok) {
    yield put(actions.updateNotificationSettingsSuccess(payload));
    yield put(snackbarActions.createSuccess('user.notificationSettingsUpdated'));
  } else {
    yield put(actions.updateNotificationSettingsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onSendEmailChangeVerification({ payload }) {
  const { ok, error } = yield apiCall(api.user.sendEmailChangeVerification, payload);
  if (ok) {
    yield put(actions.sendEmailChangeVerificationSuccess(payload));
  } else {
    yield put(actions.sendEmailChangeVerificationFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}


export default function* userSagas() {
  yield all([
    takeLeading(types.updateAvatar, onUpdateAvatar),
    takeLeading(types.deleteAvatar, onDeleteAvatar),
    takeLeading(types.getNotifications, onGetNotifications),
    takeLeading(types.readNotifications, onReadNotifications),
    takeLeading(types.updateProfile, onUpdateProfile),
    takeLeading(types.updateSettings, onUpdateSettings),
    takeLeading(types.updateNotificationSettings, onUpdateNotificationSettings),
    takeLeading(types.getSettings, onGetSettings),
    takeLeading(types.getNotificationSettings, onGetNotificationSettings),
    takeEvery(types.newNotification, onNewNotification),

    takeLeading(types.sendEmailChangeVerification, onSendEmailChangeVerification),
  ]);
}

import React, { useContext } from 'react';
import { useForm, useFormState } from 'react-final-form';
import Form from 'components/FinalForm/Form';
import validator, { isRequired } from 'validators';
import PostFormWrapper from '../Partials/PostFormWrapper';
import PostFormContext from '../PostFormContext';
import SmsViewContext from './SmsViewContext';
import SmsViewHeader from './SmsViewHeader';
import SmsViewContent from './SmsViewContent';
import SmsViewFooter from './SmsViewFooter';

const validate = validator({
  content: isRequired,
});

const SmsView = () => {

  const { save } = useContext(SmsViewContext);
  const { setView } = useContext(PostFormContext);
  const { values } = useFormState();
  const { change } = useForm();

  const onSubmit = (smsValues) => {
    change('sms', smsValues);
    save.current = null;
    setView('base');
  };

  return (
    <Form initialValues={save.current || values.sms} validate={validate} onSubmit={onSubmit}>
      {() => (
        <PostFormWrapper>
          <SmsViewHeader />
          <SmsViewContent />
          <SmsViewFooter channelId={values.channelId} target={values.target} />
        </PostFormWrapper>
      )}
    </Form>
  );
};

export default SmsView;

import merge from 'lodash/merge';
import { feedbackTypes } from 'redux/feedback';
import update from 'immutability-helper';

const feedbackCommentsReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case feedbackTypes.getCommentSuccess:
    case feedbackTypes.updateCommentSuccess:
      return update(state, {
        [payload.data]: {
          $set: payload.entities.feedbackComments[payload.data],
        },
      });

    // TODO: Preferably add { deleted: true } instead
    case feedbackTypes.deleteCommentSuccess:
      return merge({}, state, { [payload.feedbackCommentId]: null });

    default:
      return state;
  }
};

export default feedbackCommentsReducer;

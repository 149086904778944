import { all, takeLeading } from 'redux-saga/effects';
import { api, commonApiSaga } from 'redux/helpers/api';
import types from './types';

export default function* contactSagas() {
  yield all([
    takeLeading(types.getContact, commonApiSaga, { apiFn: api.workspace.contact.show, workspaceId: true }),
    takeLeading(types.storeContact, commonApiSaga, { apiFn: api.workspace.contact.store, workspaceId: true, successMessage: 'contact.stored' }),
    takeLeading(types.updateContact, commonApiSaga, { apiFn: api.workspace.contact.update, workspaceId: true, successMessage: 'contact.updated' }),
    takeLeading(types.deleteContact, commonApiSaga, { apiFn: api.workspace.contact.delete, workspaceId: true, successMessage: 'contact.deleted' }),
  ]);
}

import React from 'react';
import { Form as BaseForm } from 'react-final-form';
import PropTypes from 'prop-types';
import usePromiseSubmit from './usePromiseSubmit';

const Form = React.forwardRef((args, ref) => {

  const {
    // Basic construction:
    formHeader,
    formWrap: FormWrap,
    formFooter,

    // Submit logic #1:
    start,
    resolve,
    reject,
    onSuccess,
    onFailure,
    beforeSubmit,

    // Submit logic #2:
    onSubmit,

    // Rest:
    children,
    ...rest
  } = args;

  const submitFn = usePromiseSubmit({ start, resolve, reject, beforeSubmit, onSuccess, onFailure, onSubmit });

  return (
    <BaseForm ref={ref} onSubmit={submitFn} {...rest}>
      {props => (
        <>
          {formHeader && formHeader(props)}
          {FormWrap ? <FormWrap>{children(props)}</FormWrap> : children(props)}
          {formFooter && formFooter(props)}
        </>
      )}
    </BaseForm>
  );
});

Form.propTypes = {
  formHeader: PropTypes.func,
  formWrap: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  formFooter: PropTypes.func,

  onSuccess: PropTypes.func,
};

export default Form;

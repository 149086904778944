import React, { useEffect } from 'react';

import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import useFileInput from 'hooks/useFileInput';
import PaperclipIcon from 'components/Icons/Paperclip';
import useCapabilities from '../../useCapabilities';

const useAttachmentsButtonProps = ({ name, ...rest }) => {

  const { t } = useTranslation(['component']);

  const { attachments } = useCapabilities();

  const { input: { value, onChange } } = useField(name);

  const { open } = useFileInput({
    onChange: added => onChange([...value, ...added]),
    multiple: true,
  });

  useEffect(() => {
    if (!attachments) onChange(undefined);
  }, [attachments, onChange]);

  return {
    onClick: open,
    startIcon: <PaperclipIcon />,
    children: t('postForm.fileAddButton'),

    ...rest,
  };
};

export default useAttachmentsButtonProps;

import styled from '@emotion/styled/macro';

const DrawerBody = styled('div')`
  min-height: 0px; /* To allow shrinking */
  height: 100%; /* Take as much space as possible */

  width: 440px;
  max-width: 90vw;
  @media (max-width: 440px) {
    max-width: 100vw;
  }

  overflow-y: auto;
`;

export default DrawerBody;

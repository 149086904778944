import Resumable from 'resumablejs';
import { getCommonHeaders, getFullUrl } from './utils';

export const getResumableUploader = (token, opts = {}) => new Resumable({
  testChunks: false,
  testTarget: getFullUrl('/files/upload'),
  testMethod: 'GET',

  target: getFullUrl('/files/upload'),
  uploadMethod: 'POST',

  headers: getCommonHeaders(token),
  chunkSize: 2 * 1024 * 1024, // 2MB
  maxChunkRetries: 100,
  chunkRetryInterval: 100,

  ...opts,
});

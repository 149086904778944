import { selectPagination } from '../helpers/pagination';

const getJobAnniversaryEventsOverviewToday = ({ jobAnniversary: { jobAnniversaryEventsOverview } }) => jobAnniversaryEventsOverview?.today;
const getJobAnniversaryEventsOverviewUpcoming = ({ jobAnniversary: { jobAnniversaryEventsOverview } }) => jobAnniversaryEventsOverview?.upcoming;
const getJobAnniversaryEvents = ({ jobAnniversary: { jobAnniversaryEvents } }) => selectPagination(jobAnniversaryEvents, null, 'members');
const getJobAnniversaryEvent = ({ jobAnniversary: { jobAnniversaryEventOpened } }) => jobAnniversaryEventOpened;
const getComments = ({ jobAnniversary: { commentsByJobAnniversaryEventId } }, eventId) => selectPagination(commentsByJobAnniversaryEventId, eventId, 'commentIds');

export default {
  getJobAnniversaryEvents,
  getJobAnniversaryEventsOverviewToday,
  getJobAnniversaryEventsOverviewUpcoming,
  getJobAnniversaryEvent,
  getComments,
};

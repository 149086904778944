import styled from '@emotion/styled/macro';

const DrawerFooter = styled('div')`
  padding: 1rem 1.5rem;
  border-top: 1px solid #f5f5f5;

  padding-bottom: 1rem;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 1rem);
  padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);
`;

export default DrawerFooter;

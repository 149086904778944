import history from 'providers/history';
import config from 'config';

const isInternalHref = (href) => config.appUrlRegex && !!href?.match(config.appUrlRegex);

const isBlacklisted = (href) => {
  const { pathname } = new URL(href);

  return [
    '/manage',
  ].find(exclude => pathname?.indexOf(exclude) === 0);
};

const userContentClickHandler = (e) => {

  const anchor = e.target?.closest('[data-user-content]');

  if (!anchor) {
    return;
  }

  const { href } = anchor;

  if (isInternalHref(href) && !isBlacklisted(href)) {
    const url = new URL(href);

    const relative = url.pathname + url.search;
    history.push(relative);
    e.preventDefault();
  }
};

const init = () => {
  document.addEventListener('click', userContentClickHandler, false);
};

export default init;

import combineReducers from 'combine-reducers-global-state';
import { addToPagination, resetPagination } from 'redux/helpers/pagination';
import types from './types';

const commentsByPostId = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getCommentsSuccess:
      return addToPagination(state, payload.postId, payload, 'commentIds');

    case types.addCommentSuccess:
      return resetPagination(state, payload.postId, 'commentIds');

    default:
      return state;
  }
};

const likesByPostId = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLikesSuccess:
      return { ...state, [payload.postId]: payload.data };
    default:
      return state;
  }
};

const smsExpense = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getSmsExpenseEstimationSuccess:
      return { ...state, totalRecipient: payload.totalRecipient, totalExpense: payload.totalExpense };
    case types.smsExpenseEstimationReset:
    case types.getSmsExpenseEstimationFailure:
      return { ...state, totalRecipient: 0, totalExpense: 0 };
    default:
      return state;
  }
};

const feeds = (state = {}, { type, payload }) => {
  switch (type) {
    case types.patchSuccess:
      return payload.pinned === undefined ? state : resetPagination(state, '*', 'postIds');

    case types.storeSuccess:
      return resetPagination(state, '*', 'postIds');

    case types.getFeedSuccess:
      return addToPagination(state, payload.feedName, payload, 'postIds');

    default:
      return state;
  }
};

const postReducers = combineReducers({
  commentsByPostId,
  likesByPostId,
  smsExpense,

  feeds,
});

export default { post: postReducers };

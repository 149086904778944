import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

const fallbackArray = [];

export const getMeUser = ({ auth: { user } }) => user;
export const getWorkspaceColor = ({ auth: { workspace } }) => workspace?.color;

export const getIntercom = ({ auth: { intercom } }) => intercom;

export const getWorkspace = ({ auth: { workspace } }) => workspace;
export const getWorkspaceId = ({ auth: { workspace } }) => workspace?.id;

export const getMemberId = ({ auth: { workspace } }) => workspace?.memberId;

export const isActiveWorkspaceAdmin = createSelector(getWorkspace, workspace => ['OWNER', 'ADMIN'].includes(workspace?.role));
export const isActiveWorkspaceContentCreator = createSelector(getWorkspace, workspace => ['CONTENT_CREATOR'].includes(workspace?.role));

const getWorkspacePermissions = ({ auth }) => auth?.workspace?.permissions || fallbackArray;

export const getUserIsLoggedIn = ({ auth: { isLoggedIn } }) => isLoggedIn;
export const getIsLoading = ({ auth: { isLoading } }) => isLoading;

const getNotificationCount = ({ auth: { user: { notificationCount } } }) => notificationCount;
const getLocale = ({ auth: { user: { locale } } }) => locale;

const getFeatures = ({ auth: { workspace } }) => workspace?.features || fallbackArray;

const getFeatureSelector = createSelector(
  [getFeatures],
  (features) => memoize(feature => features?.[feature]),
);

const hasPermissionSelector = createSelector(
  [getWorkspacePermissions],
  (permissions) => memoize(permission => (permissions || []).includes(permission)),
);

const hasAnyPermissionSelector = createSelector(
  [hasPermissionSelector],
  (hasPermission) => memoize((...requiredPermissions) => !!requiredPermissions.some(required => hasPermission(required))),
);

const hasFeatureSelector = createSelector(
  [getFeatureSelector],
  (getFeature) => memoize(requiredFeature => !!getFeature(requiredFeature)?.enabled),
);

const hasAnyFeatureSelector = createSelector(
  [hasFeatureSelector],
  (hasFeature) => memoize((...requiredFeatures) => !!requiredFeatures.some(required => hasFeature(required))),
);

export default {
  getMeUser,
  getIntercom,
  getWorkspaceColor,
  getWorkspace,
  getWorkspaceId,
  getWorkspacePermissions,

  getFeatureSelector,
  hasPermissionSelector,
  hasAnyPermissionSelector,
  hasFeatureSelector,
  hasAnyFeatureSelector,

  getMemberId,
  isActiveWorkspaceAdmin,
  isActiveWorkspaceContentCreator,
  getUserIsLoggedIn,
  getLocale,
  getIsLoading,

  getNotificationCount,
};

import React from 'react';
import Grid from 'components/Grid';
import Button from 'components/Button';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PostFormFooter from '../Partials/PostFormFooter';
import useBackClick from './useBackClick';

const QuizViewFooter = () => {

  const { t } = useTranslation('component');

  const { submit } = useForm();

  const onBackClick = useBackClick();

  return (
    <PostFormFooter>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button variant="text" onClick={onBackClick}>{t('quizForm.back')}</Button>
        </Grid>
        <Grid item>
          <Button onClick={() => submit()}>{t('quizForm.addToPost')}</Button>
        </Grid>
      </Grid>
    </PostFormFooter>
  );
};

export default QuizViewFooter;

import config from 'config';
import { authTypes } from 'redux/auth';
import { addDays } from 'services/date-fns';
import { isProd } from 'helpers';

function setCookie(name, value, $options = {}) {

  const options = {
    path: '/',
    ...$options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
}

function work() {
  if (!isProd || !config.landingDomain) {
    return;
  }

  try {
    setCookie('guava-existing', 1, {
      secure: 1,
      domain: config.landingDomain,
      expires: addDays(new Date(), 30),
    });
  } catch (e) {
    // Unimportant
  }
}

export default () => next => action => {

  const { type } = action;

  switch (type) {
    case authTypes.loginSuccess:
    case authTypes.refreshTokenSuccess:
      work();
      break;
    case authTypes.logoutSuccess:
      //
      break;
    default:
      break;
  }

  next(action);
};

import React, { useContext, useState } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import NavLink from 'components/NavLink';
import clip from 'text-clipper';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'helpers';
import HtmlContent from 'components/HtmlContent';
import PropTypes from 'prop-types';
import PostContext from './PostContext';

const Wrapper = styled('div')`

  white-space: pre-wrap;
  margin: 1rem;

  ${({ onClick }) => onClick && 'cursor: pointer;'}

  /* Make navlink clickable area bigger, while maintaining margin collapse possibility */
  ${({ as }) => as === NavLink && css`
    &:not(:empty)::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -1rem;
      bottom: -1rem;
    }
  `}
`;

const SeeMore = styled('a')`
  color: #15c;
  cursor: pointer;

  white-space: nowrap;
  display: inline-block;
  padding: 0.5rem;
  margin: -0.5rem;
`;

const shouldTruncate = html => {
  return html !== clip(html, (isMobile ? 400 : 500), { html: true, maxLines: 8 });
};

const getTruncated = html => {
  const clipped = clip(html, (isMobile ? 320 : 380), { html: true, maxLines: 4, indicator: '' });

  const trimmed = clipped.replace(/\s+$/, '');

  // Space after ellipsis intentional
  return `${trimmed}… `;
};

const PostBody = ({ html, ...args }) => {
  const { isCompact } = useContext(PostContext);
  const { t } = useTranslation(['component']);

  const [isTruncated, setTruncated] = useState(isCompact && shouldTruncate(html));

  const onSeeMoreClick = e => {
    setTruncated(false);
    e.stopPropagation();
    return false;
  };

  return (
    <Wrapper {...args}>
      <HtmlContent dangerouslySetInnerHTML={{ __html: isTruncated ? getTruncated(html) : html }} />
      {isTruncated && <SeeMore onClick={onSeeMoreClick}>{t('post.seeMore')}</SeeMore>}
    </Wrapper>
  );
};

PostBody.propTypes = {
  html: PropTypes.string,
};

export default PostBody;

import { postTypes } from 'redux/post';
import update from 'immutability-helper';

const postsReducer = (state = {}, { type, payload }) => {

  switch (type) {
    case postTypes.deleteSuccess:
    case postTypes.getFailure:
      if (type === postTypes.deleteSuccess || payload.type === 'ModelNotFoundException') {
        return update(state, { [payload.postId]: { $set: { deleted: true } } });
      }

      return state;

    case postTypes.getReachPercentagesSuccess:

      return update(state, {
        ...payload.data.reach?.reduce((acc, { id, ...reachInfo }) => ({
          ...acc,
          [id]: {
            reach: { $set: reachInfo },
          },
        }), {}),
      });

    case postTypes.getSuccess:
    case postTypes.updateSuccess:
    case postTypes.storeSuccess:
    case postTypes.patchSuccess:
      // No merging here. Just replace
      return update(state, {
        [payload.data]: {
          $set: payload.entities.posts[payload.data],
        },
      });

    case postTypes.addLikeSuccess:
      return update(state, { [payload.postId]: { userReaction: { $set: payload.type || 'like' } } });

    case postTypes.removeLikeSuccess:
      return update(state, { [payload.postId]: { userReaction: { $set: null } } });

    default:
      return state;
  }
};

export default postsReducer;

const apiUrl = process.env.REACT_APP_API_URL;

const config = {
  env: process.env.NODE_ENV,
  apiUrl,
  appUrl: process.env.REACT_APP_URL,
  landingDomain: process.env.REACT_APP_LANDING_DOMAIN,
  appUrlRegex: process.env.REACT_APP_APP_URL_REGEX ? new RegExp(process.env.REACT_APP_APP_URL_REGEX) : null,
  version: process.env.REACT_APP_VERSION,
  buildDateTime: process.env.REACT_APP_BUILD_DATE_TIME,

  bugsnag: {
    key: process.env.REACT_APP_BUGSNAG_KEY,
    logger: process.env.REACT_APP_BUGSNAG_LOGGER === 'true',
  },
  ga: {
    enabled: process.env.REACT_APP_GA_ENABLED === 'true',
    debug: process.env.REACT_APP_GA_DEBUG === 'true',
    testMode: process.env.REACT_APP_GA_TEST_MODE === 'true',
    trackingId: process.env.REACT_APP_GA_TRACKING_ID,
  },
  zendesk: {
    enabled: process.env.REACT_APP_ZENDESK_ENABLED === 'true' && process.env.REACT_APP_ZENDESK_KEY,
    key: process.env.REACT_APP_ZENDESK_KEY,
  },
  intercom: {
    enabled: process.env.REACT_APP_INTERCOM_ENABLED === 'true' && !!process.env.REACT_APP_INTERCOM_ID,
    appId: process.env.REACT_APP_INTERCOM_ID,
  },
  pusher: {
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    tls: process.env.REACT_APP_PUSHER_TLS,
    authEndpoint: `${apiUrl}/broadcasting/auth`,
  },
  tinymce: {
    key: process.env.REACT_APP_TINYMCE_KEY,
  },
  uploadcare: {
    key: process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY,
  },
};

export default config;

import React from 'react';
import styled from '@emotion/styled/macro';
import Grid from 'components/Grid';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import PropTypes from 'propTypes';
import { Description, Thumbnail, Title } from './PreviewComponents';

const wrapperConfig = {
  shouldForwardProp: prop => !['guavaInternal', 'guavaModelId', 'guavaModelType'].includes(prop),
};

const Wrapper = styled(Box, wrapperConfig)`
  padding: 0.5rem 0.75rem;
  min-height: 60px;
  display: flex;
  align-items: center;
  background: #fff;

  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  display: block;
  text-decoration: none;
  color: inherit;

  ${({ href, onClick }) => (href || onClick) && css`
    &:hover {
      background: #f7f7f7;
    }
  `}
`;

const TinyOpengraphPreview = ({ image, title, description, ...rest }) => (
  <Wrapper {...rest}>
    <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
      {image && (
        <Grid item>
          <Thumbnail src={image} />
        </Grid>
      )}
      <Grid item xs>
        <Grid container direction="column" wrap="nowrap" spacing={0.25}>
          {title && (
            <Grid item xs={12}>
              <Title>{title}</Title>
            </Grid>
          )}
          {description && (
            <Grid item xs={12}>
              <Description>{description}</Description>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  </Wrapper>
);

TinyOpengraphPreview.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ src: PropTypes.string, srcSet: PropTypes.string }),
  ]),
};

export default TinyOpengraphPreview;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { authSelectors } from 'redux/auth';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/Avatar';
import Greeting from 'components/Greeting';
import Grid from 'components/Grid';
import { isMobile } from 'helpers';

const AuthenticatedGreeting = ({ avatarProps, ...rest }) => {

  const { t } = useTranslation(['component']);
  const { firstName, lastName, avatar } = useSelector(authSelectors.getMeUser);

  const customGreeting = useSelector(authSelectors.getWorkspace)?.customGreeting;

  const subtitle = customGreeting || t('authenticatedGreeting.greetingSubtitle');

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="space-between" wrap="nowrap" {...rest}>
      <Grid item>
        <Greeting
          greeting={t('authenticatedGreeting.greeting', { name: firstName })}
          subtitle={subtitle}
        />
      </Grid>
      <Grid item>
        <Avatar size={isMobile ? 38 : 56} src={avatar?.src} srcSet={avatar?.srcSet} firstName={firstName} lastName={lastName} {...avatarProps} />
      </Grid>
    </Grid>
  );
};

AuthenticatedGreeting.propTypes = {
  avatarProps: PropTypes.object,
};

export default AuthenticatedGreeting;

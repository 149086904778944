import { combineReducers } from 'redux';
import { addToPagination, createPagination, resetPagination } from 'redux/helpers/pagination';
import types from './types';

const coursesInitialState = {
  upcoming: createPagination(null, 'courseIds'),
  past: createPagination(null, 'courseIds'),
};
const courses = (state = coursesInitialState, { type, payload }) => {
  switch (type) {
    case types.createCourseSuccess:
      return resetPagination(state, '*', 'courseIds');

    case types.getCoursesSuccess:
      return addToPagination(state, payload.status || 'upcoming', payload, 'courseIds');
    default:
      return state;
  }
};

const trainingReducers = combineReducers({
  courses,
});

export default { training: trainingReducers };

import React from 'react';

const PostFormContext = React.createContext({
  asDialog: false,
  editing: false,

  disableScheduling: true,
  disableChannelSelect: false,
  videoUploader: {},

  onClose: () => {},

  view: 'base',
  setView: () => {},
});

export default PostFormContext;

import combineReducers from 'combine-reducers-global-state';
import merge from 'lodash/merge';
import { userTypes } from 'redux/user';
import NotificationService from 'services/notificationService';
import { workspaceTypes } from 'redux/workspace';
import authTypes from './types';

const setNotificationsCount = (count) => {
  NotificationService.firebase.setBadgeNumber(count || 0);
};

const isLoading = (state = true, { type }) => {
  switch (type) {
    case authTypes.loginSuccess:
    case authTypes.loginFailure:
    case authTypes.logoutFailure:
    case authTypes.logoutSuccess:
    case authTypes.refreshTokenSuccess:
    case authTypes.refreshTokenFailure:
      return false;
    default:
      return state;
  }
};

const isLoggedIn = (state = false, { type }) => {
  switch (type) {
    case authTypes.loginSuccess:
    case authTypes.refreshTokenSuccess:
      return true;
    case authTypes.logoutSuccess:
    case authTypes.loginFailure:
    case authTypes.refreshTokenFailure:
      return false;
    default:
      return state;
  }
};

const intercom = (state = {}, { type, payload }) => {
  switch (type) {
    case authTypes.loginSuccess:
    case authTypes.refreshTokenSuccess:
      return { ...payload.intercom };
    default:
      return state;
  }
};

const user = (state = {}, { type, payload }) => {
  switch (type) {
    case authTypes.loginFailure:
    case authTypes.refreshTokenFailure:
    case authTypes.logoutSuccess:
      return {};
    case authTypes.loginSuccess:
    case authTypes.refreshTokenSuccess:
      setNotificationsCount(payload.user.notificationCount);
      return merge({}, state, payload.user);

    case authTypes.getProfileSuccess:
    case userTypes.updateProfileSuccess:
      return merge({}, state, payload);

    // TODO: Notification handling to a separate reducer
    case userTypes.newNotification:
      setNotificationsCount(state.notificationCount + 1);
      return merge({}, state, { notificationCount: state.notificationCount + 1 });

    case userTypes.readNotificationsSuccess:
      setNotificationsCount(0);
      return merge({}, state, { notificationCount: 0 });

    case userTypes.changeLanguageSuccess:
      return { ...state, locale: payload };

    default:
      return state;
  }
};

const workspace = (state = {
  features: [],
}, { type, payload }) => {
  switch (type) {
    // name, color, locale, logo, custom greeting
    case workspaceTypes.getWorkspaceSettingsSuccess:
    case workspaceTypes.updateWorkspaceSettingsSuccess:
      return merge({}, state, payload);
    case authTypes.loginSuccess:
    case authTypes.refreshTokenSuccess:
      return payload.workspace || { features: [] };
    case workspaceTypes.sendPulseFeedback:
      return {
        ...state,
        pulse: false,
      };
    default:
      return state;
  }
};

const authReducer = combineReducers({
  isLoading,
  isLoggedIn,
  user,
  intercom,
  workspace,
});

export default { auth: authReducer };

import memoize from 'lodash/memoize';
import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';

const getFeedbacks = ({ feedback: { admin } }) => selectPagination(admin, null, 'feedbackIds');

const getFeedbackEntities = ({ entities: { feedbacks } }) => feedbacks;
const getFeedback = ({ entities: { feedbacks } }, feedbackId) => feedbacks[feedbackId];

const getFeedbackSelector = createSelector(
  [getFeedbackEntities],
  feedbacks => memoize(
    feedbackId => feedbacks?.[feedbackId],
  ),
);

const getFeedbackTypeEntities = ({ entities: { feedbackTypes } }) => feedbackTypes;

const getFeedbackTypeIds = ({ feedback: { feedbackTypes } }) => feedbackTypes;
const getFeedbackTypesLoading = ({ feedback: { feedbackTypesLoading } }) => feedbackTypesLoading;

const getFeedbackType = ({ entities: { feedbackTypes } }, id) => feedbackTypes[id];

const getFeedbackTypeSelector = createSelector(
  [getFeedbackTypeEntities],
  feedbackTypes => memoize(
    feedbackTypeId => feedbackTypes?.[feedbackTypeId],
  ),
);

const getFeedbackTypes = createSelector(
  [getFeedbackTypeIds, getFeedbackTypeSelector],
  (typeIds, getSelector) => typeIds.map(getSelector).filter(v => !!v),
);

const getFeedbackSettings = ({ feedback: { feedbackSettings } }) => feedbackSettings;

const getFeedbackStatuses = ({ feedback: { feedbackStatuses } }) => feedbackStatuses;

const getFeedbackCommentFeed = ({ feedback: { feedbackComments } }, feedbackId, type) => selectPagination(feedbackComments[type?.toLowerCase()], feedbackId, 'feedbackCommentIds');

const getFeedbackComment = ({ entities: { feedbackComments } }, id) => feedbackComments[id];

const getFeedbackForm = ({ entities: { feedbackForms } }, id) => feedbackForms?.[id];

export default {
  getFeedbacks,
  getFeedback,

  getFeedbackTypeIds,
  getFeedbackTypesLoading,
  getFeedbackTypes,
  getFeedbackType,
  getFeedbackTypeSelector,
  getFeedbackSelector,
  getFeedbackForm,

  getFeedbackSettings,
  getFeedbackStatuses,

  getFeedbackComment,
  getFeedbackCommentFeed,
};

import update from 'immutability-helper';
import { feedbackTypes } from 'redux/feedback';

const feedbackReducer = (state = {}, { type, payload }) => {

  switch (type) {

    case feedbackTypes.patchSuccess:
      return update(state, {
        [payload.data]: {
          $set: payload.entities.feedbacks[payload.data],
        },
      });

    case feedbackTypes.deleteSuccess:
      return update(state, { $unset: [payload.feedbackId] });

    case feedbackTypes.massActionSuccess: {
      switch (payload.action) {
        case 'change_status':
          return update(state,
            payload.updated.reduce((acc, feedbackId) => ({
              ...acc,
              [feedbackId]: { status: { $set: payload.data.status } },
            }), {}));

        case 'delete':
          return update(state, { $unset: payload.updated });

        default:
          return state;
      }
    }

    default:
      return state;
  }
};

export default feedbackReducer;

const { useEffect } = require('react');
const { useDispatch, useSelector } = require('react-redux');
const { workspaceActions, workspaceSelectors } = require('redux/workspace');

const useWorkspaceCover = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workspaceActions.getWorkspaceProfile());
  }, [dispatch]);

  const workspaceProfile = useSelector(workspaceSelectors.getProfile);

  return workspaceProfile?.cover;
};

export default useWorkspaceCover;

import React, { useLayoutEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const StyledTabbar = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 56px;
  background: #fff;

  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05),
              0 2px 6px 0 rgba(0,0,0,0.05);

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: ${({ theme }) => theme.zIndex.tabbar};

  box-sizing: content-box;

  @supports (bottom: env(safe-area-inset-bottom, 0)){
    bottom: env(safe-area-inset-bottom, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
    margin-bottom: calc(-1 * env(safe-area-inset-bottom, 0));
  }
`;

const Tabbar = ({ className, children, ...rest }) => {

  const tabbar = useRef();

  useLayoutEffect(() => {
    const preventDefault = e => {
      e.preventDefault();
    };

    const tabbarCurrent = tabbar.current;

    document.documentElement.classList.add('has-mobile-tabbar');
    tabbarCurrent.addEventListener('touchmove', preventDefault, { passive: false });

    return () => {
      document.documentElement.classList.remove('has-mobile-tabbar');
      tabbarCurrent.removeEventListener('touchmove', preventDefault, { passive: false });
    };

  }, [tabbar]);

  return <StyledTabbar className={`mui-fixed ${className}`} ref={tabbar} {...rest}>{children}</StyledTabbar>;
};

Tabbar.propTypes = {
  children: PropTypes.node,
};

export default Tabbar;

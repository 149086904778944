import React from 'react';
import styled from '@emotion/styled/macro';
import { isToday, isThisYear } from 'services/date-fns';
import DateFormat from 'components/DateFormat';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

const Wrapper = styled('div')`
  color: inherit;
  text-decoration: none;

  a&:hover:not(.active) time {
    text-decoration: underline;
  }

  a&.active {
    pointer-events: none;
  }
`;

const getMobileFormat = (date) => {
  if (isToday(date)) {
    return 'p';
  }

  if (isThisYear(date)) {
    return { month: 'short', day: 'numeric' };
  }

  return 'PPP';
};

const getWebFormat = (date) => {
  if (isThisYear(date)) {
    return { month: 'long', day: 'numeric' };
  }

  return 'PPP';
};

const PostInfoTimestamp = ({ date, ...rest }) => {

  const smallScreen = useMediaQuery('(max-width: 400px)');

  return (
    <Wrapper {...rest}>
      {smallScreen
        ? <DateFormat date={date} format={getMobileFormat(date)} />
        : <DateFormat date={date} format={getWebFormat(date)} fromNowDuring={7 * 24 * 60 * 60} />}
    </Wrapper>
  );
};

PostInfoTimestamp.propTypes = {
  date: PropTypes.object,
};

export default PostInfoTimestamp;

import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled/macro';
import { useTheme } from '@emotion/react';
import ProgressiveImage from 'components/ProgressiveImage';
import PropTypes from 'propTypes';

const BaseImg = styled('img')`
  background-color: #fff;
  object-fit: cover;
  object-position: center center;
`;

const OpengraphImage = ({ src, ...rest }) => {

  const theme = useTheme();

  const [imageError, setImageError] = useState(false);

  // Reset errors when src should change
  useEffect(() => {
    setImageError(false);
  }, [src]);

  if (imageError) {
    return null;
  }


  return src?.src
    ? (
      <ProgressiveImage src={src?.src} srcSet={src?.srcSet} sizes={theme.feedImageSrcSetSizes}>
        {({ loading, ...image }) => <BaseImg draggable="false" {...image} onError={setImageError} {...rest} />}
      </ProgressiveImage>
    )
    : <BaseImg draggable="false" src={src} onError={setImageError} {...rest} />;
};

OpengraphImage.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),
  ]),
};

export default OpengraphImage;

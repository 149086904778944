import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ColorfulButton from 'components/ColorfulButton';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled/macro';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import { isMobile } from 'helpers';
// 8 months later... Not sure what it is though... but ignore
// eslint-disable-next-line no-restricted-imports
import ownerDocument from '@mui/material/utils/ownerDocument';
import reactionService from 'services/reactionService';
import Tooltip from '@mui/material/Tooltip';
import { css } from '@emotion/react';
import LikeIcon from './Icons/Like';

const Wrapper = styled('div')``;

const StyledMenuList = styled(MenuList)`
  background: #fff;
  display: flex;
  padding: 0;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 0.75em;

  svg {
    transition: transform 300ms;
    font-size: 2em;
  }

  &:hover {
    background: none;

    svg {
      transform: scale(1.2);
    }
  }

  ${({ color }) => css`
    &:hover, &:focus, &:focus-within {
      background: ${color[1]};
    }
  `}
`;

// Following functions and logic is copied from
// https://github.com/mui-org/material-ui/blob/5f2a237ff689ef067b048459e82dd05379069658/packages/material-ui/src/MenuList/MenuList.js#L49
function nextItem(list, item, disableListWrap) {
  if (list === item) {
    return list.firstChild;
  }
  if (item && item.nextElementSibling) {
    return item.nextElementSibling;
  }

  return disableListWrap ? null : list.firstChild;
}

function previousItem(list, item, disableListWrap) {
  if (list === item) {
    return disableListWrap ? list.firstChild : list.lastChild;
  }
  if (item && item.previousElementSibling) {
    return item.previousElementSibling;
  }

  return disableListWrap ? null : list.lastChild;
}

function textCriteriaMatches(nextFocus, textCriteria) {
  if (textCriteria === undefined) {
    return true;
  }
  let text = nextFocus.innerText;
  if (text === undefined) {
    // jsdom doesn't support innerText
    text = nextFocus.textContent;
  }
  text = text.trim().toLowerCase();
  if (text.length === 0) {
    return false;
  }
  if (textCriteria.repeating) {
    return text[0] === textCriteria.keys[0];
  }

  return text.indexOf(textCriteria.keys.join('')) === 0;
}

function moveFocus(
  list,
  currentFocus,
  disableListWrap,
  disabledItemsFocusable,
  traversalFunction,
  textCriteria,
) {
  let wrappedOnce = false;
  let nextFocus = traversalFunction(list, currentFocus, currentFocus ? disableListWrap : false);

  while (nextFocus) {
    // Prevent infinite loop.
    if (nextFocus === list.firstChild) {
      if (wrappedOnce) {
        return;
      }
      wrappedOnce = true;
    }

    // Same logic as useAutocomplete.js
    const nextFocusDisabled = disabledItemsFocusable
      ? false
      : nextFocus.disabled || nextFocus.getAttribute('aria-disabled') === 'true';

    if (
      !nextFocus.hasAttribute('tabindex')
      || !textCriteriaMatches(nextFocus, textCriteria)
      || nextFocusDisabled
    ) {
      // Move to the next element.
      nextFocus = traversalFunction(list, nextFocus, disableListWrap);
    } else {
      nextFocus.focus();
      return;
    }
  }
}

const ReactionMenu = ({ reactions, onSelect, selected, open, onClose, anchorEl, autoFocusItem, ...rest }) => {

  const { t } = useTranslation(['component']);

  const menuListRef = useRef();

  const onKeyDown = event => {
    const list = menuListRef.current;
    const { key } = event;

    const currentFocus = ownerDocument(list).activeElement;

    if (key === 'ArrowLeft') {
      event.preventDefault();
      moveFocus(list, currentFocus, false, false, previousItem);
    } else if (key === 'ArrowRight') {
      event.preventDefault();
      moveFocus(list, currentFocus, false, false, nextItem);
    } else if (key === 'Tab') {
      event.preventDefault();

      if (onClose) {
        onClose(event, 'tabKeyDown');
      }
    }
  };

  return (
    <Popper open={open} anchorEl={anchorEl} transition disablePortal placement="top-start" {...rest}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <StyledMenuList ref={menuListRef} onKeyDown={onKeyDown} autoFocusItem={open && autoFocusItem}>
                {reactions.map(reaction => (
                  <Tooltip key={reaction} title={reactionService.getReaction(reaction)?.getTranslation(t)} placement="top">
                    <StyledMenuItem
                      onClick={() => onSelect(reaction, true)}
                      className={reaction}
                      component="button"
                      color={reactionService.getReaction(reaction)?.color}
                      aria-label={reactionService.getReaction(reaction)?.getTranslation(t)}
                    >
                      {reactionService.getReaction(reaction)?.icon}
                    </StyledMenuItem>
                  </Tooltip>
                ))}
              </StyledMenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

ReactionMenu.propTypes = {
  reactions: PropTypes.arrayOf(PropTypes.oneOf(reactionService.reactionsArray)),
  selected: PropTypes.oneOf(reactionService.reactionsArray),
  onSelect: PropTypes.func,
  autoFocusItem: PropTypes.bool,

  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
};

const defaultButton = {
  ...reactionService.getReaction('like'),
  Icon: LikeIcon,
  icon: <LikeIcon />,
};

const ReactionButton = ({ reactions, selected, onReactionAdd, onReactionRemove, disabled, ...rest }) => {
  const { t } = useTranslation(['component']);

  const menuButton = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);

  const hasMenu = reactions && reactions.length > 1;

  const timeout = useRef();

  const replaceTimeout = (...args) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(...args);
  };

  const onSelect = (reaction, menu = false) => {
    replaceTimeout(() => setMenuOpen(false), 100);
    // Remove:
    if (!menu && selected === reaction) return onReactionRemove();
    // Switch:
    if (selected && reaction) return onReactionAdd(reaction);
    // Remove:
    if (selected) return onReactionRemove();
    // Add:
    return onReactionAdd(reaction);
  };

  const buttonProps = hasMenu && {
    ref: menuButton,
    onClick: e => {
      setAutoFocus(true);
      if (isMobile || e.detail === 0) {
        if (selected) {
          // Remove
          return onSelect(selected || 'like', false);
        }

        return setMenuOpen(true);
      }
      setAutoFocus(false);

      clearTimeout(timeout.current);
      onSelect(selected || 'like', false);
      setMenuOpen(false);
    },
  };

  const wrapperProps = {
    onMouseEnter: () => replaceTimeout(() => setMenuOpen(true), 300),
    onMouseLeave: () => replaceTimeout(() => setMenuOpen(false), 500),
  };

  const { Icon, getTranslation, color } = selected ? reactionService.getReaction(selected) : defaultButton;

  return (
    <Wrapper {...wrapperProps}>
      <ColorfulButton disabled={disabled} color={selected ? color : undefined} active={!!selected} startIcon={<Icon />} {...buttonProps} {...rest}>
        {getTranslation(t)}
      </ColorfulButton>
      {hasMenu && (
        <ReactionMenu
          reactions={reactions}
          onSelect={onSelect}
          selected={selected}
          autoFocusItem={autoFocus}

          open={menuOpen}
          anchorEl={menuButton.current}
          onClose={() => setMenuOpen(false)}
        />
      )}
    </Wrapper>
  );
};

ReactionButton.propTypes = {
  ...ColorfulButton.propTypes,

  onReactionAdd: PropTypes.func.isRequired,
  onReactionRemove: PropTypes.func.isRequired,

  reactions: PropTypes.arrayOf(PropTypes.oneOf(reactionService.reactionsArray)),
  selected: PropTypes.oneOf(reactionService.reactionsArray),
};

export default ReactionButton;

import combineReducers from 'combine-reducers-global-state';
import types from './types';

const likesByCommentId = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLikesSuccess:
      return { ...state, [payload.commentId]: payload.data };
    default:
      return state;
  }
};

const commentReducers = combineReducers({
  likesByCommentId,
});

export default { comment: commentReducers };

import React, { useState } from 'react';

// TODO: Migrate common and fullscreen layout props
// TODO: Migrate all mobile views to use context as well
// TODO: Initiate MobileDefaultLayout already in App.jsx
export const layoutContextDefaults = {
  fullScreen: false,
  width: 'default',

  // FullScreenLayout props
  backUrl: '',
  title: '',
  rightComponent: '',
};

export const LayoutReadContext = React.createContext(layoutContextDefaults);
export const LayoutWriteContext = React.createContext(() => {});

const LayoutProvider = (args) => {

  const [layoutProps, setLayoutProps] = useState({});

  return (
    <LayoutWriteContext.Provider value={setLayoutProps}>
      <LayoutReadContext.Provider value={layoutProps} {...args} />
    </LayoutWriteContext.Provider>
  );
};

export default LayoutProvider;

import React from 'react';
import { NavLink as MuiNavLink, useRouteMatch } from 'react-router-dom';

const NavLink = React.forwardRef(({ to, ...rest }, ref) => {

  const match = useRouteMatch(to);

  const replace = to && match?.isExact;

  return <MuiNavLink replace={replace} to={to || '#'} ref={ref} {...rest} />;
});

NavLink.displayName = MuiNavLink.displayName;
NavLink.propTypes = MuiNavLink.propTypes;

export default NavLink;

import { selectPagination } from '../helpers/pagination';

const getBirthdayEventsOverviewToday = ({ birthday: { birthdayEventsOverview } }) => birthdayEventsOverview?.today;
const getBirthdayEventsOverviewUpcoming = ({ birthday: { birthdayEventsOverview } }) => birthdayEventsOverview?.upcoming;
const getBirthdayEvents = ({ birthday: { birthdayEvents } }) => selectPagination(birthdayEvents, null, 'members');
const getBirthdayEvent = ({ birthday: { birthdayEventOpened } }) => birthdayEventOpened;
const getComments = ({ birthday: { commentsByBirthdayEventId } }, eventId) => selectPagination(commentsByBirthdayEventId, eventId, 'commentIds');

export default {
  getBirthdayEvents,
  getBirthdayEventsOverviewToday,
  getBirthdayEventsOverviewUpcoming,
  getBirthdayEvent,
  getComments,
};

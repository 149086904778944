import React, { useContext } from 'react';
import { useForm, useFormState } from 'react-final-form';
import Form from 'components/FinalForm/Form';
import arrayMutators from 'final-form-arrays';
import validator, { isRequired, isNotEmpty } from 'validators';
import { FORM_ERROR } from 'final-form';
import PostFormWrapper from '../Partials/PostFormWrapper';
import PostFormContext from '../PostFormContext';
import PollViewContext from './PollViewContext';
import PollViewHeader from './PollViewHeader';
import PollViewContent from './PollViewContent';
import PollViewFooter from './PollViewFooter';

// https://github.com/final-form/react-final-form-arrays/issues/138
// arrayMutators insert seems to break state
const insertAt = ([name, index, value], state, { changeValue }) => {
  changeValue(state, name, (array) => {
    const copy = [...(array || [])];
    copy.splice(index, 0, value);
    return copy;
  });
};

const validate = (values) => {

  const result = validator({
    question: isRequired,
    options: [isRequired, isNotEmpty],
  })(values);

  // Remove options error and present as global error. Otherwise it's not working...
  // Perhaps some arrayMutator is badly written, and ignores all validation results completely?
  // It has something to do with unregistered fields ('options') are being ignored
  // Anyway let's remove the options key and add it as global form error.
  return result.then(({ options, ...errors }) => ({
    ...errors,
    ...(options && { [FORM_ERROR]: options }),
  }));
};

const mutators = { ...arrayMutators, insertAt };
const PollView = () => {

  const { save } = useContext(PollViewContext);
  const { setView } = useContext(PostFormContext);
  const { values } = useFormState();
  const { change } = useForm();

  const onSubmit = (pollValues) => {
    change('poll', {
      ...pollValues,
      options: pollValues.options.filter(({ option }) => !!option),
    });
    save.current = null;
    setView('base');
  };

  return (
    <Form initialValues={save.current || values.poll} validate={validate} onSubmit={onSubmit} mutators={mutators}>
      {() => (
        <PostFormWrapper>
          <PollViewHeader />
          <PollViewContent />
          <PollViewFooter />
        </PostFormWrapper>
      )}
    </Form>
  );
};

export default PollView;

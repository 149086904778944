import { api } from 'redux/helpers/api';

const aiRequest = (request, respondWith) => respondWith.string((signal) => {

  // Adds each previous query and response as individual messages
  const conversation = request.thread.flatMap((event) => {
    if (event.response) {
      return [
        { role: 'user', content: event.request.query },
        { role: 'assistant', content: event.response.data },
      ];
    }

    return [];
  });

  // Forms the new query sent to the API
  const content = request.context.length === 0 || conversation.length > 0
    ? request.query
    : `Question: ${request.query} Context: """${request.context}"""`;

  return api.openai.chatCompletions({
    messages: [
      ...conversation,
      { role: 'user', content },
    ],
  }, {
    openWhenHidden: true, // continue processing while the browser window is hidden
    signal, // AbortController's AbortSignal
  })
    .then(({ ok, error, response }) => {
      if (ok && !response.error) {
        return response?.choices[0]?.message?.content?.trim();
      }

      // Guava error
      if (error) {
        throw new Error(error.message);
      }

      // OpenAI error? Untested response handler
      if (response.error && response.error.type && response.error.message) {
        throw new Error(`Failed to communicate with ChatGPT API because of ${response.error.type} error: ${response.error.message}`);
      }

      throw new Error('Something went wrong!');
    });
});

export default aiRequest;

import MuiDrawer from '@mui/material/Drawer';
import styled from '@emotion/styled/macro';

const StyledDrawer = styled(MuiDrawer)`
  display: flex;
  flex-direction: column;
`;

StyledDrawer.propTypes = MuiDrawer.propTypes;

export default StyledDrawer;

import { createAction } from 'redux/helpers/actions';
import types from './types';

const actions = {

  updateAvatar: data => createAction(types.updateAvatar, data),
  updateAvatarSuccess: data => createAction(types.updateAvatarSuccess, data),
  updateAvatarFailure: error => createAction(types.updateAvatarFailure, error),

  deleteAvatar: data => createAction(types.deleteAvatar, data),
  deleteAvatarSuccess: data => createAction(types.deleteAvatarSuccess, data),
  deleteAvatarFailure: error => createAction(types.deleteAvatarFailure, error),

  getNotifications: data => createAction(types.getNotifications, data),
  getNotificationsSuccess: data => createAction(types.getNotificationsSuccess, data),
  getNotificationsFailure: error => createAction(types.getNotificationsFailure, error),

  getSettings: data => createAction(types.getSettings, data),
  getSettingsSuccess: data => createAction(types.getSettingsSuccess, data),
  getSettingsFailure: error => createAction(types.getSettingsFailure, error),

  getNotificationSettings: data => createAction(types.getNotificationSettings, data),
  getNotificationSettingsSuccess: data => createAction(types.getNotificationSettingsSuccess, data),
  getNotificationSettingsFailure: error => createAction(types.getNotificationSettingsFailure, error),

  readNotifications: data => createAction(types.readNotifications, data),
  readNotificationsSuccess: data => createAction(types.readNotificationsSuccess, data),
  readNotificationsFailure: error => createAction(types.readNotificationsFailure, error),

  updateProfile: data => createAction(types.updateProfile, data),
  updateProfileSuccess: data => createAction(types.updateProfileSuccess, data),
  updateProfileFailure: data => createAction(types.updateProfileFailure, data),

  updateSettings: (data, config) => createAction(types.updateSettings, data, config),
  updateSettingsSuccess: data => createAction(types.updateSettingsSuccess, data),
  updateSettingsFailure: data => createAction(types.updateSettingsFailure, data),

  updateNotificationSettings: (data, config) => createAction(types.updateNotificationSettings, data, config),
  updateNotificationSettingsSuccess: data => createAction(types.updateNotificationSettingsSuccess, data),
  updateNotificationSettingsFailure: data => createAction(types.updateNotificationSettingsFailure, data),

  sendEmailChangeVerification: () => createAction(types.sendEmailChangeVerification),
  sendEmailChangeVerificationSuccess: data => createAction(types.sendEmailChangeVerificationSuccess, data),
  sendEmailChangeVerificationFailure: data => createAction(types.sendEmailChangeVerificationFailure, data),

  /* Middleware actions. */
  changeLanguageSuccess: language => createAction(types.changeLanguageSuccess, language),
  subscribeToNotifications: () => createAction(types.subscribeToNotifications),
  newNotification: () => createAction(types.newNotification),
};

export default actions;

import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getOverview: data => createAction(types.getOverview, data),
  getOverviewSuccess: data => createAction(types.getOverviewSuccess, data),
  getOverviewFailure: error => createAction(types.getOverviewFailure, error),

  getBirthdays: data => createAction(types.getBirthdays, data),
  getBirthdaysSuccess: data => createAction(types.getBirthdaysSuccess, data),
  getBirthdaysFailure: error => createAction(types.getBirthdaysFailure, error),

  getBirthdayEvent: data => createAction(types.getBirthdayEvent, data),
  getBirthdayEventSuccess: data => createAction(types.getBirthdayEventSuccess, data),
  getBirthdayEventFailure: error => createAction(types.getBirthdayEventFailure, error),

  addComment: data => createAction(types.addComment, data),
  addCommentSuccess: data => createAction(types.addCommentSuccess, data),
  addCommentFailure: error => createAction(types.addCommentFailure, error),

  getComments: data => createAction(types.getComments, data),
  getCommentsSuccess: data => createAction(types.getCommentsSuccess, data),
  getCommentsFailure: error => createAction(types.getCommentsFailure, error),
};

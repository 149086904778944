import { all, takeLeading, select, put, call } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import { postActions } from 'redux/post';
import history from 'providers/history';
import types from './types';
import actions from './actions';

function* onGetActivityCategories() {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.challenge.getActivityCategories, { workspaceId });

  if (ok) {
    yield put(actions.getActivityCategoriesSuccess(response));
  } else {
    yield put(actions.getActivityCategoriesFailure(error));
  }
}

function* onGetChallenges({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);
  const { ok, response, error } = yield apiCall(api.challenge.getChallenges, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getChallengesSuccess(response));
  } else {
    yield put(actions.getChallengesFailure(error));
  }
}

function* onGetChallenge({ payload: { challengeId } }) {
  const { ok, response, error } = yield apiCall(api.challenge.getChallenge, { challengeId });

  if (ok) {
    yield put(actions.getChallengeSuccess(response));
  } else {
    yield put(actions.getChallengeFailure(error));
  }
}

function* onUpdateActivity({ payload }) {
  const { postId } = payload;
  const { ok, response, error } = yield apiCall(api.challenge.updateActivity, payload);

  if (ok) {
    yield put(actions.updateActivitySuccess(response));
    yield put(postActions.get({ postId }));
    yield call(history.push, `/post/${postId}`);
  } else {
    yield put(actions.updateActivityFailure(error));
  }
}

function* onStoreActivity({ payload }) {
  const { ok, response, error } = yield apiCall(api.challenge.storeActivity, payload);

  if (ok) {
    yield put(actions.storeActivitySuccess(response));
    yield call(history.push, `/post/${response.id}`);
  } else {
    yield put(actions.storeActivityFailure(error));
  }
}


export default function* challengeSagas() {
  yield all([
    takeLeading(types.getActivityCategories, onGetActivityCategories),
    takeLeading(types.getChallenge, onGetChallenge),
    takeLeading(types.getChallenges, onGetChallenges),
    takeLeading(types.updateActivity, onUpdateActivity),
    takeLeading(types.storeActivity, onStoreActivity),
  ]);
}

import React from 'react';

const PostContext = React.createContext({
  isCompact: false,
  isImportant: false,

  isPinnable: false,
  isPinned: false,

  isPublished: true,
  isDraft: false,

  hasMenu: false,

  translated: false,
  setTranslated: () => {},
});

export default PostContext;

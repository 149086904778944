import { all, takeLeading, takeEvery, put, select } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { authSelectors } from 'redux/auth';
import { snackbarActions } from 'redux/snackbar';
import types from './types';
import actions from './actions';

function* onGetCourse({ payload }) {
  const { ok, response, error } = yield apiCall(api.training.getCourse, payload);

  if (ok) {
    yield put(actions.getCourseSuccess(response));
  } else {
    yield put(actions.getCourseFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetCourses({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, response, error } = yield apiCall(api.training.getCourses, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.getCoursesSuccess({ status: payload.status, ...response }));
  } else {
    yield put(actions.getCoursesFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onCreateCourse({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, response, error } = yield apiCall(api.training.createCourse, { workspaceId, ...payload });

  if (ok) {
    yield put(actions.createCourseSuccess(response));
  } else {
    yield put(actions.createCourseFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onUpdateCourse({ payload }) {
  const { ok, response, error } = yield apiCall(api.training.updateCourse, payload);

  if (ok) {
    yield put(actions.updateCourseSuccess(response));
  } else {
    yield put(actions.updateCourseFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteCourse({ payload }) {
  const { ok, response, error } = yield apiCall(api.training.deleteCourse, payload);

  if (ok) {
    yield put(actions.deleteCourseSuccess(response));
  } else {
    yield put(actions.deleteCourseFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

export default function* trainingSagas() {
  yield all([
    takeEvery(types.getCourse, onGetCourse),
    takeLeading(types.getCourses, onGetCourses),
    takeEvery(types.createCourse, onCreateCourse),
    takeEvery(types.updateCourse, onUpdateCourse),
    takeEvery(types.deleteCourse, onDeleteCourse),
  ]);
}

import update from 'immutability-helper';
import { denormalize } from 'normalizr';
import { fileTypes } from 'redux/file';
import fileGroupSchema from 'schemas/fileGroup';

const fileGroupsReducer = (state = {}, { type, payload }) => {
  switch (type) {

    case fileTypes.saveFilesSuccess:
    case fileTypes.getFilesSuccess:
      return update(state, {
        ...payload.data.reduce((acc, id) => ({
          ...acc,
          [id]: {
            // Ignore merge here and just replace the files
            files: { $set: denormalize(id, fileGroupSchema, payload.entities).files },
            // Override targets as well
            targets: { $set: payload.entities?.fileGroups?.[id]?.targets || [] },
          },
        }), {}),
      });

    default:
      return state;
  }
};

export default fileGroupsReducer;

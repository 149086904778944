import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getChannel: data => createAction(types.getChannel, data),
  getChannelSuccess: data => createAction(types.getChannelSuccess, data),
  getChannelFailure: data => createAction(types.getChannelFailure, data),

  subscribeToChannel: data => createAction(types.subscribeToChannel, data),
};

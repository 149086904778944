import combineReducers from 'combine-reducers-global-state';
import { addToPagination } from 'redux/helpers/pagination';
import types from './types';

const lessonsByCourseVariantForMember = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLessonsByCourseVariantForMember:
      if (!payload.page || payload.page === 1) {
        return { ...state, [payload.courseVariantId]: {} };
      }

      return state;
    case types.getLessonsByCourseVariantForMemberSuccess:
      return addToPagination(state, payload.courseVariantId, payload, 'lessonIds');
    default:
      return state;
  }
};

const lessonsByCourseVariantForAdmin = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getLessonsByCourseVariantForAdminSuccess:
      return { ...state, [payload.courseVariantId]: payload.data };
    case types.deleteLessonForAdminSuccess:
      // eslint-disable-next-line eqeqeq
      return { ...state, [payload.courseVariantId]: state[payload.courseVariantId].filter((value) => value != payload.lessonId) };
    default:
      return state;
  }
};

const lessonReducers = combineReducers({
  lessonsByCourseVariantForMember,
  lessonsByCourseVariantForAdmin,
});

export default { lesson: lessonReducers };

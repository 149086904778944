import { all, put, takeLeading } from 'redux-saga/effects';
import { api, apiCall } from 'redux/helpers/api';
import { takeLeadingPerKey } from 'redux/helpers/saga';
import types from './types';
import actions from './actions';
import { snackbarActions } from '../snackbar';

function* onGetSectionsByFolderVariantForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.getSections, payload);

  if (ok) {
    yield put(actions.getSectionsByFolderVariantForMemberSuccess({ folderVariantId: payload.folderVariantId, ...response }));
  } else {
    yield put(actions.getSectionsByFolderVariantForMemberFailure(error));
  }
}

function* onPostSectionClickForMember({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.postSectionClick, payload);

  if (ok) {
    yield put(actions.postSectionClickForMemberSuccess(response));
  } else {
    yield put(actions.postSectionClickForMemberFailure(error));
  }
}

function* onGetSectionsByFolderVariantForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.getSectionsForAdmin, payload);

  if (ok) {
    yield put(actions.getSectionsByFolderVariantForAdminSuccess({ folderVariantId: payload.folderVariantId, ...response }));
  } else {
    yield put(actions.getSectionsByFolderVariantForAdminFailure(error));
  }
}

function* onGetSectionForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.getSectionForAdmin, payload);

  if (ok) {
    yield put(actions.getSectionForAdminSuccess(response));
  } else {
    yield put(actions.getSectionForAdminFailure(error));
  }
}

function* onPostSectionForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.postSectionForAdmin, payload);

  if (ok) {
    yield put(actions.postSectionForAdminSuccess(response));
    yield put(snackbarActions.createSuccess('sections.successfulPostSection', { translate: true }));
  } else {
    yield put(actions.postSectionForAdminFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onPutSectionForAdmin({ payload }) {
  const { ok, response, error } = yield apiCall(api.documents.putSectionForAdmin, payload);

  if (ok) {
    yield put(actions.putSectionForAdminSuccess(response));
    yield put(snackbarActions.createSuccess('sections.successfulPutSection', { translate: true }));
  } else {
    yield put(actions.putSectionForAdminFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDeleteSectionForAdmin({ payload }) {
  const { ok, error } = yield apiCall(api.documents.deleteSectionForAdmin, payload);

  if (ok) {
    yield put(actions.deleteSectionForAdminSuccess({ folderVariantId: payload.folderVariantId, sectionId: payload.sectionId }));
    yield put(snackbarActions.createSuccess('sections.successfulDeleteSection', { translate: true }));
  } else {
    yield put(actions.deleteSectionForAdminFailure(error));
    yield put(snackbarActions.createFailure('sections.unsuccessfulDeleteSection', { translate: true }));
  }
}

function* onReorderSectionsForAdmin({ payload }) {
  const { ok, error } = yield apiCall(api.documents.reorderSectionsForAdmin, payload);

  if (ok) {
    yield put(actions.reorderSectionsForAdminSuccess({ folderVariantId: payload.folderVariantId, sectionId: payload.sectionId }));
    yield put(snackbarActions.createSuccess('sections.sectionReorderSuccess', { translate: true }));
  } else {
    yield put(actions.reorderSectionsForAdminFailure(error));
    yield put(snackbarActions.createFailure('sections.sectionReorderFailure', { translate: true }));
  }
}

export default function* sectionSagas() {
  yield all([
    takeLeadingPerKey(types.getSectionsByFolderVariantForMember, onGetSectionsByFolderVariantForMember, ({ payload }) => payload.folderVariantId),
    takeLeading(types.postSectionClickForMember, onPostSectionClickForMember),
    takeLeadingPerKey(types.getSectionsByFolderVariantForAdmin, onGetSectionsByFolderVariantForAdmin, ({ payload }) => payload.folderVariantId),
    takeLeadingPerKey(types.getSectionForAdmin, onGetSectionForAdmin, ({ payload }) => payload.sectionId),
    takeLeading(types.postSectionForAdmin, onPostSectionForAdmin),
    takeLeadingPerKey(types.putSectionForAdmin, onPutSectionForAdmin, ({ payload }) => payload.sectionId),
    takeLeadingPerKey(types.deleteSectionForAdmin, onDeleteSectionForAdmin, ({ payload }) => payload.sectionId),

    takeLeadingPerKey(types.reorderSectionsForAdmin, onReorderSectionsForAdmin, ({ payload }) => payload.folderVariantId),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/FinalForm/Form';
import { useTranslation } from 'react-i18next';
import SingleButtonAttachmentField from 'components/FinalForm/Fields/SingleButtonAttachmentField';
import { birthdayTypes } from 'redux/birthday';
import { jobAnniversaryTypes } from 'redux/jobAnniversary';
import styled from '@emotion/styled/macro';
import FormField from 'components/FinalForm/Fields/FormField';
import Grid from 'components/Grid';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import EndOfSomething from 'components/EndOfSomething';

const SendMessageFormField = styled(FormField)`
  .MuiInputBase-root {
    padding: 0.5rem 1rem;
    background: #f5f5f5;
    border-radius: 30px;
  }
`;

const AddComment = ({ type, eventId, disabled, ...rest }) => {
  const { t } = useTranslation(['component']);

  const onSuccess = (values, form) => setTimeout(form.reset, 0);

  return (
    <Form
      start={(type === 'Birthday') ? birthdayTypes.addComment : jobAnniversaryTypes.addComment}
      resolve={(type === 'Birthday') ? birthdayTypes.addCommentSuccess : jobAnniversaryTypes.addCommentSuccess}
      reject={(type === 'Birthday') ? birthdayTypes.addCommentFailure : jobAnniversaryTypes.addCommentFailure}
      onSuccess={onSuccess}
      initialValues={{ eventId }}
      {...rest}
    >
      {({ handleSubmit, values, invalid, submitting, style }) => (
        <form onSubmit={handleSubmit} style={style}>
          {!disabled && (
            <Grid container direction="row" wrap="nowrap" alignItems="center" spacing={2}>
              <Grid item xs zeroMinWidth>
                <SendMessageFormField
                  required
                  name="body"
                  variant="outlined"
                  margin="none"
                  multiline
                  maxRows={5}
                  placeholder={(type === 'Birthday') ? t('birthdayEvents.birthdayWishPlaceHolder') : t('jobAnniversaryEvents.jobAnniversaryWishPlaceHolder')}
                  InputProps={{
                    endAdornment: <SingleButtonAttachmentField
                      values={values}
                    />,
                  }}
                />
              </Grid>
              <Grid item>
                <IconButton type="submit" disabled={invalid || submitting} color="primary">
                  <SendIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {disabled && (
            <Grid container direction="row" wrap="nowrap" alignItems="center">
              <Grid item xs><EndOfSomething>{t('component:comment.commentsDisabled')}</EndOfSomething></Grid>
            </Grid>
          )}
        </form>
      )}
    </Form>
  );
};

AddComment.propTypes = {
  type: PropTypes.oneOf(['Birthday', 'JobAnniversary']).isRequired,
  disabled: PropTypes.bool,
  eventId: PropTypes.number,
};

export default AddComment;

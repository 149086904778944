import React from 'react';
import { useTransition, a } from '@react-spring/web';

const useAlternatingContent = (map, view) => {

  // This can be improved, but no time.
  const transition = useTransition(view, {
    keys: key => key,
    from: { opacity: 0 },
    enter: { position: 'relative', opacity: 1, x: '0%' },
    leave: { position: 'absolute', opacity: 0, x: '-100%' },
  });

  return transition((style, active) => {
    const Page = map[active];

    return <a.div style={style}><Page key={active} /></a.div>;
  });
};

export default useAlternatingContent;

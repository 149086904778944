import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import getErrorProperties from 'components/FinalForm/getErrorProperties';

const SubmitBlocker = ({ name, children }) => (
  <Field name={name} validate={() => children}>
    {({ meta }) => {
      const { error, helperText } = getErrorProperties(meta);

      return error
        ? <FormHelperText error={error}>{helperText}</FormHelperText>
        : null;
    }}
  </Field>
);

SubmitBlocker.propTypes = {
  name: PropTypes.string,
  children: PropTypes.string.isRequired,
};

export default SubmitBlocker;

import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getSectionsByFolderVariantForMember: data => createAction(types.getSectionsByFolderVariantForMember, data),
  getSectionsByFolderVariantForMemberSuccess: data => createAction(types.getSectionsByFolderVariantForMemberSuccess, data),
  getSectionsByFolderVariantForMemberFailure: error => createAction(types.getSectionsByFolderVariantForMemberFailure, error),

  postSectionClickForMember: data => createAction(types.postSectionClickForMember, data),
  postSectionClickForMemberSuccess: data => createAction(types.postSectionClickForMemberSuccess, data),
  postSectionClickForMemberFailure: error => createAction(types.postSectionClickForMemberFailure, error),

  getSectionsByFolderVariantForAdmin: data => createAction(types.getSectionsByFolderVariantForAdmin, data),
  getSectionsByFolderVariantForAdminSuccess: data => createAction(types.getSectionsByFolderVariantForAdminSuccess, data),
  getSectionsByFolderVariantForAdminFailure: error => createAction(types.getSectionsByFolderVariantForAdminFailure, error),

  getSectionForAdmin: data => createAction(types.getSectionForAdmin, data),
  getSectionForAdminSuccess: data => createAction(types.getSectionForAdminSuccess, data),
  getSectionForAdminFailure: error => createAction(types.getSectionForAdminFailure, error),

  postSectionForAdmin: data => createAction(types.postSectionForAdmin, data),
  postSectionForAdminSuccess: data => createAction(types.postSectionForAdminSuccess, data),
  postSectionForAdminFailure: error => createAction(types.postSectionForAdminFailure, error),

  putSectionForAdmin: data => createAction(types.putSectionForAdmin, data),
  putSectionForAdminSuccess: data => createAction(types.putSectionForAdminSuccess, data),
  putSectionForAdminFailure: error => createAction(types.putSectionForAdminFailure, error),

  deleteSectionForAdmin: data => createAction(types.deleteSectionForAdmin, data),
  deleteSectionForAdminSuccess: data => createAction(types.deleteSectionForAdminSuccess, data),
  deleteSectionForAdminFailure: error => createAction(types.deleteSectionForAdminFailure, error),

  reorderSectionsForAdmin: data => createAction(types.reorderSectionsForAdmin, data),
  reorderSectionsForAdminSuccess: data => createAction(types.reorderSectionsForAdminSuccess, data),
  reorderSectionsForAdminFailure: error => createAction(types.reorderSectionsForAdminFailure, error),
};

import combineReducers from 'combine-reducers-global-state';
import union from 'lodash/union';
import types from './types';

const all = (state = [], { type, payload }) => {
  switch (type) {
    case types.getFilesSuccess:
      return payload.data;
    default:
      return state;
  }
};

// null > "analyzing" > "uploading" > "saving" > "success" || "failure"
const saveStatus = (state = null, { type, payload }) => {
  switch (type) {
    case types.saveFiles:
      return 'analyzing';
    case types.uploadEvent: {

      switch (payload.event) {
        case 'progress':
          return 'uploading';
        case 'complete':
          return 'saving';
        default:
          return state;
      }

    }
    case types.saveFilesSuccess:
      return 'success';
    case types.saveFilesFailure:
      return 'failure';
    default:
      return state;
  }
};

const saveProgress = (state = 0, { type, payload }) => {
  switch (type) {
    case types.saveFiles:
      return 0;
    case types.uploadEvent: {

      switch (payload.event) {
        case 'progress':
          return payload.resumable.progress();
        default:
          return state;
      }

    }
    default:
      return state;
  }
};

const saveFiles = (state = [], { type, payload }) => {
  switch (type) {
    case types.saveFiles:
      return [];
    case types.uploadEvent: {
      switch (payload.event) {
        case 'fileAdded':
          return union([], state, [payload.args[0].fileName]);
        case 'filesAdded':
          return union([], state, payload.args[0].map(file => file.fileName));
        default:
          return state;
      }

    }
    default:
      return state;
  }
};

const saveUploading = (state = [], { type, payload }) => {
  switch (type) {
    case types.saveFiles:
      return [];
    case types.uploadEvent: {

      switch (payload.event) {
        case 'fileProgress':
          return union([], state, [payload.args[0].fileName]);
        case 'fileSuccess':
          return state.slice().filter(fileName => fileName !== payload.args[0].fileName);
        default:
          return state;
      }

    }
    default:
      return state;
  }
};

const saveSuccess = (state = [], { type, payload }) => {
  switch (type) {
    case types.saveFiles:
      return [];
    case types.uploadEvent: {

      switch (payload.event) {
        case 'fileError':
          return state.slice().filter(fileName => fileName !== payload.args[0].fileName);
        case 'fileSuccess':
          return union([], state, [payload.args[0].fileName]);
        default:
          return state;
      }

    }
    default:
      return state;
  }
};

const saveFailure = (state = [], { type, payload }) => {
  switch (type) {
    case types.saveFiles:
      return [];
    case types.uploadEvent: {

      switch (payload.event) {
        case 'fileError':
          return union([], state, [payload.args[0].fileName]);
        case 'fileSuccess':
          return state.slice().filter(fileName => fileName !== payload.args[0].fileName);
        default:
          return state;
      }

    }
    default:
      return state;
  }
};

const getFilesLoading = (state = false, { type }) => {
  switch (type) {
    case types.getFiles:
      return true;
    case types.getFilesFailure:
    case types.getFilesSuccess:
      return false;
    default:
      return state;
  }
};

const fileReducers = combineReducers({
  all,
  getFilesLoading,
  save: combineReducers({
    status: saveStatus,
    progress: saveProgress,

    files: saveFiles,
    uploading: saveUploading,
    success: saveSuccess,
    failure: saveFailure,
  }),
});

export default { files: fileReducers };

import React, { useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pollActions, pollSelectors, pollTypes } from 'redux/poll';
import PollComponent from 'components/Poll';
import usePromiseSubmit from 'components/FinalForm/usePromiseSubmit';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';
import { translationSelectors } from 'redux/translation';
import { useTranslation } from 'react-i18next';
import VotesDialog from './VotesDialog';
import useConfirm from '../../components/Confirmation/useConfirm';

const findIndexOrUndefined = (...args) => {
  const value = findIndex(...args);
  return value === -1 ? undefined : value;
};

// Try to pretain order as much as possible
const usePollOptions = pollId => {
  const stored = useSelector(pollSelectors.getOptionsForPoll)(pollId);

  const previous = useRef([]);

  return useMemo(() => {
    const expanded = stored.map(args => ({
      ...args,
      serverOrder: findIndexOrUndefined(stored, ({ id }) => id === args.id),
      previousOrder: findIndexOrUndefined(previous.current, ({ id }) => id === args.id),
    }));

    previous.current = orderBy(expanded, ['votesCount', 'previousOrder', 'serverOrder'], ['desc', 'asc', 'asc']);

    return previous.current;
  }, [stored]);
};

const Poll = ({ pollId, translated, ...rest }) => {

  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { t } = useTranslation(['component']);

  const poll = useSelector(state => pollSelectors.getPoll(state, pollId));

  const getTranslation = useSelector(translationSelectors.getTranslationSelector);

  const question = useMemo(() => translated
    ? getTranslation('poll', pollId, 'question', poll.question)
    : poll.question,
  [getTranslation, poll.question, pollId, translated]);

  let options = usePollOptions(pollId);
  options = useMemo(() => {
    return translated
      ? options.map(option => ({
        ...option,
        option: getTranslation('poll_option', option.id, 'option', option.option),
      }))
      : options;
  }, [getTranslation, options, translated]);

  const onOptionClick = option => {
    // Hack: to get better visuals.
    // Remove the previous vote, before adding a new vote
    if (poll.maxVotes === 1 && !option.voted) {
      const votedOption = options.find(({ voted }) => voted);
      if (votedOption) {
        dispatch(pollActions.deleteVote({ optionId: votedOption.id, pollId }));
      }
    }

    if (option.voted) {
      dispatch(pollActions.deleteVote({ optionId: option.id, pollId }));
    } else {
      dispatch(pollActions.storeVote({ optionId: option.id, pollId }));
    }
  };

  const [optionId, setOptionId] = useState();
  const [open, setOpen] = useState(false);

  const onVotesClick = option => {
    dispatch(pollActions.getVotes({ optionId: option.id }));
    setOptionId(option.id);
    setOpen(true);
  };

  const addNewOption = usePromiseSubmit({
    start: pollTypes.addOption,
    resolve: pollTypes.addOptionSuccess,
    reject: pollTypes.addOptionFailure,
  });

  const onOptionAdd = option => addNewOption({ pollId, option });

  const onOptionRemove = (option) => {
    confirm({
      title: t('poll.removeOption.title'),
      description: t('poll.removeOption.description'),
    })
      .then(() => {
        dispatch(pollActions.removeOption({ pollId, optionId: option.id }));
      })
      .catch(() => { });
  };

  return (
    <>
      <PollComponent
        {...poll}
        addOptions={poll.permissions?.addOptions ? poll.addOptions : false}
        disabled={!poll.permissions?.vote}
        question={question}
        options={options}
        onOptionClick={onOptionClick}
        onOptionAdd={onOptionAdd}
        onOptionRemove={onOptionRemove}
        onVotesClick={onVotesClick}
        {...rest}
      />
      <VotesDialog optionId={optionId} open={open} onClose={() => setOpen(false)} />
    </>
  );
};

Poll.propTypes = {
  pollId: PropTypes.any,

  translated: PropTypes.bool,
  translation: PropTypes.object,
};

export default Poll;

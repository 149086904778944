import React, { useState, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getCategoryIcon, getCategoryTranslation, getValueWithUnitTranslation } from 'services/challengeService';
import isArray from 'lodash/isArray';
import Grid from 'components/Grid';

export const CategoryIconWrapper = styled('div')`
  display: inline-flex;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #eee;
  color: #333;
  align-items: center;
  justify-content: center;

  ${({ onClick }) => onClick && 'cursor: pointer;'}
  ${({ active }) => active === false && 'opacity: .4;'}
`;

const ChallengeCategoryIcons = (props) => {

  let { categories, total } = props;
  const { unit } = props;

  const { t } = useTranslation(['component']);
  const [activeCategory, setActiveCategory] = useState(null);
  const wrapperRef = useRef();

  // If object provided, make clickable.
  const clickable = !Array.isArray(categories) && Object.keys(categories).length > 1;

  // Make sure categories are an object
  if (categories && isArray(categories)) {
    categories = categories.reduce((acc, o) => ({ ...acc, [o]: null }), {});
  }

  total = total || Object.values(categories).reduce((acc, o) => acc + o, 0);

  const onCategoryClick = category => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.style.minWidth = `${wrapperRef.current.offsetWidth}px`;
    }
    setActiveCategory(activeCategory === category ? null : category);
  };

  const getDisplayValue = () => {
    const value = activeCategory === null ? total : categories[activeCategory];

    const categoryKeys = Object.keys(categories || {});

    const category = categoryKeys.length === 1 ? categoryKeys[0] : null;

    return getValueWithUnitTranslation(t, +value, unit, category);
  };

  return (
    <Grid container spacing={1} alignItems="center" ref={wrapperRef}>
      {Object.keys(categories).map(category => {

        const Icon = getCategoryIcon(category);

        return (
          <Grid item key={category}>
            <CategoryIconWrapper
              title={getCategoryTranslation(t, category)}
              onClick={clickable ? () => onCategoryClick(category) : null}
              active={activeCategory === null ? null : activeCategory === category}
            >
              <Icon />
            </CategoryIconWrapper>
          </Grid>
        );
      })}
      <Grid item>
        {getDisplayValue()}
      </Grid>
    </Grid>
  );
};

ChallengeCategoryIcons.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
};

export default ChallengeCategoryIcons;

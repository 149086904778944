import React from 'react';
import useContactDirectoryQueryParams from 'containers/Side/ContactDirectory/useContactDirectoryQueryParams';
import ContactDirectory from './ContactDirectory';

const contactsFilter = { type: 'contact' };

const ManageContacts = (args) => {

  const { onAddContactClick } = useContactDirectoryQueryParams();

  return (
    <ContactDirectory onAddContactClick={onAddContactClick} filters={contactsFilter} {...args} />
  );
};

export default ManageContacts;

import { decamelizeKeys as humpsDecamelizeKeys, decamelize as humpsDecamelize } from 'humps';
import config from 'config';
import { isMobile } from 'helpers';
import { formatISO9075 } from 'services/date-fns';

export const getFullUrl = endpoint => {
  return /^(http|https):\/\//.test(endpoint)
    ? endpoint
    : `${config.apiUrl}${endpoint}`;
};

export const getCommonHeaders = token => ({
  Accept: 'application/json',
  ...(config.version && { 'x-guava-version': config.version }),
  'x-guava-platform': isMobile ? 'mobile' : 'browser',
  ...(token && { Authorization: `Bearer ${token}` }),
});

export const createArrayParams = (key, values) => values.map(value => `${key}[]=${encodeURIComponent(value || '')}`).join('&');

export const createQueryParams = params => Object.keys(params)
  .map(key => {
    return params[key] instanceof Array
      ? createArrayParams(key, params[key])
      : `${key}=${encodeURIComponent(params[key] === null ? '' : params[key])}`;
  })
  .filter(v => !!v)
  .join('&');

// https://stackoverflow.com/a/67994693/1016777
export const getFileName = (disposition) => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName = null;
  if (utf8FilenameRegex.test(disposition)) {
    fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    // slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }

  return fileName;
};

export const getGenericFileName = (prefix = 'Download') => {
  const date = formatISO9075(new Date())
    .replace(/:/g, '-')
    .replace(' ', '_');

  return [prefix, date].join(' ');
};

export const decamelizeKeys = (obj, opts) => {

  const processorFn = (key, convert, options) => /^[A-Z0-9_]+$/.test(key)
    ? key
    : convert(key, { ...options, ...opts });

  return humpsDecamelizeKeys(obj, processorFn);
};

export const composeError = (data) => {

  // In case of a validation exception, get the first fields first exception message.
  if ('ValidationException'.localeCompare(data.type) === 0) {
    const errorKeys = Object.keys(data.errors || {});
    if (errorKeys.length === 0) {
      return data;
    }

    return {
      ...data,
      message: data.errors[errorKeys[0]][0],
    };
  }

  return data;
};


export const toFormData = (obj, form, namespace) => {
  const fd = form || new FormData();

  Object.keys(obj).forEach(key => {

    let value = obj[key];

    if (value === undefined || value === null) {
      return;
    }

    if (value === true || value === false) {
      value = value ? 1 : 0;
    }

    const formKey = namespace ? `${namespace}[${key}]` : key;

    if (value instanceof Array && value.length === 0) {
      fd.append(humpsDecamelize(formKey), value);
    }

    if (value instanceof Array || (typeof value === 'object' && !(value instanceof File))) {
      toFormData(value, fd, formKey);
    } else {
      fd.append(humpsDecamelize(formKey), value);
    }

  });

  return fd;
};

export const isFailedToFetchError = error => (error.name === 'TypeError' && error.message === 'Failed to fetch');

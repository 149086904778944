import * as dateFns from 'date-fns'; // eslint-disable-line no-restricted-imports
import i18n from 'providers/i18n';
import { et, ru, fi, lv, lt, sv, uk, pl, hi, de } from 'date-fns/locale'; // eslint-disable-line no-restricted-imports

const locales = { et, ru, fi, lv, lt, sv, uk, pl, hi, de };
export const getLocale = () => locales[i18n.language];

// http://chartsbin.com/view/41671
const weekStartsOn = { et: 1, ru: 1, fi: 1, lv: 1, lt: 1, sv: 1, uk: 1, pl: 1, hi: 0, de: 1 };
export const getWeekStartsOn = () => weekStartsOn[i18n.language] || 1;

// Export all default and then start overriding with locale specific functions
export * from 'date-fns'; // eslint-disable-line no-restricted-imports

export const format = (date, f, options) => dateFns.format(date, f, { locale: getLocale(), ...options });
export const formatDistanceToNowStrict = (date, options) => dateFns.formatDistanceToNowStrict(date, { locale: getLocale(), ...options });
export const formatRelative = (date, baseDate, options) => dateFns.formatRelative(date, baseDate, { locale: getLocale(), weekStartsOn: getWeekStartsOn(), ...options });

export const formatString = (str, f, options) => {
  if (!str) {
    return null;
  }

  const date = str instanceof Date
    ? str
    : new Date(str);

  if (!dateFns.isValid(date)) {
    return null;
  }

  return format(date, f, options);
};

export const startOfWeek = (date, options) => dateFns.startOfWeek(date, { weekStartsOn: getWeekStartsOn(), ...options });
export const endOfWeek = (date, options) => dateFns.endOfWeek(date, { weekStartsOn: getWeekStartsOn(), ...options });

export const isBirthday = (date) => {
  const now = new Date();

  return dateFns.getMonth(date) === dateFns.getMonth(now)
    && dateFns.getDate(date) === dateFns.getDate(now);
};

export const getDateString = (date, obj, fallback = 'PPPp') => {
  try {
    return date.toLocaleString(getLocale()?.code, obj);
  } catch (e) {
    console.error(e);
    return format(date, fallback);
  }
};

import React from 'react';
import Grid from 'components/Grid';
import { useTranslation } from 'react-i18next';
import FormField from 'components/FinalForm/Fields/FormField';
import Form from 'components/FinalForm/Form';
import Button from 'components/Button';
import CommentContent from 'components/Comment/CommentContent';
import PropTypes from 'prop-types';

const CommentEditForm = ({ onReset, beforeSubmit, ...args }) => {
  const { t } = useTranslation(['component']);

  const onBeforeSubmit = (values) => {
    const combined = {
      ...values,
    };

    return beforeSubmit
      ? beforeSubmit(combined)
      : combined;
  };

  return (
    <Form beforeSubmit={onBeforeSubmit} {...args}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <CommentContent>
            <Grid container direction="column" wrap="nowrap" spacing={1}>
              <Grid item>
                <FormField name="body" multiline maxRows={10} margin="none" />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item><Button onClick={onReset} type="reset" size="small" variant="text" disabled={submitting}>{t('comment.editDialogCancel')}</Button></Grid>
                  <Grid item><Button type="submit" size="small" loading={submitting}>{t('comment.editDialogSubmit')}</Button></Grid>
                </Grid>
              </Grid>
            </Grid>
          </CommentContent>
        </form>
      )}
    </Form>
  );
};

CommentEditForm.propTypes = {
  onReset: PropTypes.func,
  ...Form.propTypes,
};

export default CommentEditForm;

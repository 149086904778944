import { all, put, debounce } from 'redux-saga/effects';
import { api, apiCall, commonApiSaga } from 'redux/helpers/api';
import types from './types';
import actions from './actions';
import { snackbarActions } from '../snackbar';
import { takeLeadingPerKey } from '../helpers/saga';

function* onGetCoursesAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getCoursesAnalytics, payload);

  if (ok) {
    yield put(actions.getCoursesAnalyticsSuccess(response));
  } else {
    yield put(actions.getCoursesAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetCourseMembersAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getCourseMembersAnalytics, payload);

  if (ok) {
    yield put(actions.getCourseMembersAnalyticsSuccess({
      courseId: payload.courseId,
      refreshOnly: payload.refreshOnly,
      ...response,
    }));
  } else {
    yield put(actions.getCourseMembersAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetCourseMembersAnalyticsDownload({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getCourseMembersAnalyticsDownload, payload);

  if (ok) {
    yield put(actions.getCourseMembersAnalyticsDownloadSuccess(response));
  } else {
    yield put(actions.getCourseMembersAnalyticsDownloadFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetCourseLessonsAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getCourseLessonsAnalytics, payload);

  if (ok) {
    yield put(actions.getCourseLessonsAnalyticsSuccess({ courseId: payload.courseId, data: response }));
  } else {
    yield put(actions.getCourseLessonsAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetCourseLessonsAnalyticsDownload({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getCourseLessonsAnalyticsDownload, payload);

  if (ok) {
    yield put(actions.getCourseLessonsAnalyticsDownloadSuccess(response));
  } else {
    yield put(actions.getCourseLessonsAnalyticsDownloadFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetCourseVariantQuestionsAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getCourseVariantQuestionsAnalytics, payload);

  if (ok) {
    yield put(actions.getCourseVariantQuestionsAnalyticsSuccess({ courseVariantId: payload.courseVariantId, data: response }));
  } else {
    yield put(actions.getCourseVariantQuestionsAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetLessonQuestionsAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getLessonQuestionsAnalytics, payload);

  if (ok) {
    yield put(actions.getLessonQuestionsAnalyticsSuccess({ lessonId: payload.lessonId, data: response }));
  } else {
    yield put(actions.getLessonQuestionsAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetMembersAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getMembersAnalytics, payload);

  if (ok) {
    yield put(actions.getMembersAnalyticsSuccess(response));
  } else {
    yield put(actions.getMembersAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetMemberCoursesAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getMemberCoursesAnalytics, payload);

  if (ok) {
    yield put(actions.getMemberCoursesAnalyticsSuccess({ memberId: payload.memberId, refreshOnly: payload.refreshOnly, ...response }));
  } else {
    yield put(actions.getMemberCoursesAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetMemberCoursesAnalyticsDownload({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getMemberCoursesAnalyticsDownload, payload);

  if (ok) {
    yield put(actions.getMemberCoursesAnalyticsDownloadSuccess({ memberId: payload.memberId, ...response }));
  } else {
    yield put(actions.getMemberCoursesAnalyticsDownloadFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetMemberCourseAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getMemberCourseAnalytics, payload);

  if (ok) {
    yield put(actions.getMemberCourseAnalyticsSuccess({ memberId: payload.memberId, courseId: payload.courseId, ...response }));
  } else {
    yield put(actions.getMemberCourseAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetMemberCourseAnalyticsDownloadCsv({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getMemberCourseAnalyticsCsv, payload);

  if (ok) {
    yield put(actions.getMemberCourseAnalyticsDownloadCsvSuccess(response));
  } else {
    yield put(actions.getMemberCourseAnalyticsDownloadCsvFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetMemberCourseAnalyticsDownloadPdf({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getMemberCourseAnalyticsPdf, payload);

  if (ok) {
    yield put(actions.getMemberCourseAnalyticsDownloadPdfSuccess(response));
  } else {
    yield put(actions.getMemberCourseAnalyticsDownloadPdfFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetLabelsAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getLabelsAnalytics, payload);

  if (ok) {
    yield put(actions.getLabelsAnalyticsSuccess(response));
  } else {
    yield put(actions.getLabelsAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onGetLabelCoursesAnalytics({ payload }) {
  const { ok, error, response } = yield apiCall(api.learning.getLabelCoursesAnalytics, payload);

  if (ok) {
    yield put(actions.getLabelCoursesAnalyticsSuccess({ labelId: payload.labelId, ...response }));
  } else {
    yield put(actions.getLabelCoursesAnalyticsFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

export default function* learningAnalyticsSagas() {
  yield all([
    debounce(500, types.getCoursesAnalytics, onGetCoursesAnalytics),
    takeLeadingPerKey(types.getCourseMembersAnalytics, onGetCourseMembersAnalytics, ({ payload }) => payload.courseId),
    takeLeadingPerKey(types.getCourseMembersAnalyticsDownload, onGetCourseMembersAnalyticsDownload, ({ payload }) => payload.courseId),
    takeLeadingPerKey(types.getCourseLessonsAnalytics, onGetCourseLessonsAnalytics, ({ payload }) => payload.courseId),
    takeLeadingPerKey(types.getCourseLessonsAnalyticsDownload, onGetCourseLessonsAnalyticsDownload, ({ payload }) => payload.courseId),
    takeLeadingPerKey(types.getCourseVariantQuestionsAnalytics, onGetCourseVariantQuestionsAnalytics, ({ payload }) => payload.courseVariantId),
    takeLeadingPerKey(types.getLessonQuestionsAnalytics, onGetLessonQuestionsAnalytics, ({ payload }) => payload.lessonId),
    debounce(500, types.getMembersAnalytics, onGetMembersAnalytics),
    takeLeadingPerKey(types.getMemberCoursesAnalytics, onGetMemberCoursesAnalytics, ({ payload }) => payload.memberId),
    takeLeadingPerKey(types.getMemberCoursesAnalyticsDownload, onGetMemberCoursesAnalyticsDownload, ({ payload }) => payload.memberId),
    takeLeadingPerKey(types.getMemberCourseAnalytics, onGetMemberCourseAnalytics, ({ payload }) => `${payload.memberId}-${payload.courseId}`),
    takeLeadingPerKey(types.getMemberCourseAnalyticsDownloadCsv, onGetMemberCourseAnalyticsDownloadCsv, ({ payload }) => `${payload.memberId}-${payload.courseId}`),
    takeLeadingPerKey(types.getMemberCourseAnalyticsDownloadPdf, onGetMemberCourseAnalyticsDownloadPdf, ({ payload }) => `${payload.memberId}-${payload.courseId}`),
    debounce(500, types.getLabelsAnalytics, onGetLabelsAnalytics),
    takeLeadingPerKey(types.getLabelCoursesAnalytics, onGetLabelCoursesAnalytics, ({ payload }) => payload.labelId),

    takeLeadingPerKey(
      types.getMemberCourseVariantsAnalytics, commonApiSaga,
      ({ payload }) => `${payload.memberId}-${payload.courseId}`,
      { apiFn: api.learning.getMemberCourseVariantsAnalytics },
    ),

    takeLeadingPerKey(
      types.getMemberCourseVariantAnalytics, commonApiSaga,
      ({ payload }) => `${payload.memberId}-${payload.variantId}`,
      { apiFn: api.learning.getMemberCourseVariantAnalytics },
    ),
  ]);
}

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { postActions, postSelectors, postTypes } from 'redux/post';
import PostForm from 'containers/Post/PostForm/PostForm';
import { pollSelectors } from 'redux/poll';
import { channelSelectors } from 'redux/channel';
import { quizSelectors } from 'redux/quiz';

const shouldDisableChannelSelect = channel => {
  return channel && (channel.originType === 'CHALLENGE' || channel.originType === 'USER');
};

const getActivityInitialValues = ({ activity }) => {
  if (!activity) {
    return;
  }

  const categoryId = activity.category.id;

  const values = activity.types && activity.types.map(type => ({
    typeId: type.id,
    value: type.typeValue,
  }));

  return {
    activity: {
      categoryId,
      ...(values && { values }),
    },
  };
};

const EditPostForm = ({ postId, ...rest }) => {

  const dispatch = useDispatch();

  const post = useSelector(state => postSelectors.getPost(state, postId));

  const poll = useSelector(state => pollSelectors.getPoll(state, post ? post.poll : 0));
  const pollOptions = useSelector(state => pollSelectors.getOptionsForPoll(state))(post ? post.poll : 0);

  const quiz = useSelector(state => quizSelectors.getQuiz(state, post ? post.quiz : 0));
  const quizOptions = useSelector(state => quizSelectors.getOptionsForQuiz(state))(post ? post.quiz : 0);
  const channel = useSelector(channelSelectors.getChannelSelector)(post ? post.channel : null);

  useEffect(() => {
    dispatch(postActions.get({ postId }));
  }, [dispatch, postId]);

  const initialValues = useMemo(() => {
    if (!post) {
      return {};
    }

    return {
      postId: post.id,
      body: post.body,
      bodyHtml: post.bodyHtml,
      channelId: post.channel,
      important: post.important ? 1 : 0,
      target: post.targets.map(({ id }) => id),

      media: post.media,
      attachments: post.attachments,
      pinned: !!post.pinned,

      isDraft: post.isDraft,
      scheduledAt: post.scheduledAt,

      $schedule: !post.postedAt && !!post.scheduledAt, // Show scheduling field?
      postedAt: post.postedAt, // Required to decide if scheduling is possible

      ...(post.poll && {
        poll: {
          ...poll,
          options: pollOptions,
        },
      }),

      ...(post.quiz && {
        quiz: {
          ...quiz,
          options: quizOptions,
        },
      }),

      ...(post.sms && {
        sms: {
          content: post.sms.content,
        },
      }),

      ...(post.opengraph && {
        link: post.opengraph,
      }),

      ...getActivityInitialValues(post),
    };
  }, [poll, pollOptions, post, quiz, quizOptions]);

  if (!post) {
    return null;
  }

  return (
    <PostForm
      start={postTypes.update}
      reject={postTypes.updateFailure}
      resolve={postTypes.updateSuccess}

      initialValues={initialValues}
      disableChannelSelect={shouldDisableChannelSelect(channel)}
      disableScheduling={!!post.postedAt}
      editing
      {...rest}
    />
  );
};

EditPostForm.propTypes = {
  postId: PropTypes.any.isRequired,
};

export default EditPostForm;

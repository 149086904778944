export default {
  getCourse: 'training/GET_COURSE',
  getCourseSuccess: 'training/GET_COURSE_SUCCESS',
  getCourseFailure: 'training/GET_COURSE_FAILURE',

  createCourse: 'training/CREATE_COURSE',
  createCourseSuccess: 'training/CREATE_COURSE_SUCCESS',
  createCourseFailure: 'training/CREATE_COURSE_FAILURE',

  updateCourse: 'training/UPDATE_COURSE',
  updateCourseSuccess: 'training/UPDATE_COURSE_SUCCESS',
  updateCourseFailure: 'training/UPDATE_COURSE_FAILURE',

  deleteCourse: 'training/DELETE_COURSE',
  deleteCourseSuccess: 'training/DELETE_COURSE_SUCCESS',
  deleteCourseFailure: 'training/DELETE_COURSE_FAILURE',

  getCourses: 'training/GET_COURSES',
  getCoursesSuccess: 'training/GET_COURSES_SUCCESS',
  getCoursesFailure: 'training/GET_COURSES_FAILURE',
};

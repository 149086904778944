import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import List from '@mui/material/List';
import { useSelector } from 'react-redux';
import { workspaceSelectors } from 'redux/workspace';
import styled from '@emotion/styled';
import ListSubheader from '@mui/material/ListSubheader';
import PropTypes from 'propTypes';
import ContactDirectoryListItem from './ContactDirectoryListItem';

const StyledListSubheader = styled(ListSubheader)`
  color: #666;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 2;
  padding-top: 1rem;
`;

const ContactDirectoryByName = ({ items, ...rest }) => {

  const getContactDirectoryItemSelector = useSelector(workspaceSelectors.getContactDirectoryItemSelector);

  const render = useMemo(() => {
    return items.map(({ type: itemType, data: itemId }, idx, all) => {

      const currentItem = getContactDirectoryItemSelector(itemType, itemId);
      const currentInitial = currentItem?.name?.[0];

      const previousItem = getContactDirectoryItemSelector(all?.[idx - 1]?.type, all?.[idx - 1]?.data);
      const previousInitial = previousItem?.name?.[0];

      const showSubheader = currentInitial !== previousInitial;

      return (
        <React.Fragment key={itemType + itemId}>
          {showSubheader && (
            <StyledListSubheader>{currentInitial}</StyledListSubheader>
          )}
          <ContactDirectoryListItem itemType={itemType} itemId={itemId} divider bold withSubtitle />
        </React.Fragment>
      );
    });
  }, [items, getContactDirectoryItemSelector]);


  return (
    <InfiniteScroll {...rest}>
      <List>
        {render}
      </List>
    </InfiniteScroll>
  );
};

ContactDirectoryByName.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ContactDirectoryByName;

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import styled from '@emotion/styled/macro';
import { ReactComponent as EmojiMehColoredSvg } from './assets/emoji-meh-colored.svg';

const StyledSvgIcon = styled(SvgIcon)`
  color: #FFE17D;
`;

const EmojiMehColoredIcon = args => <StyledSvgIcon component={EmojiMehColoredSvg} {...args} />;

export default EmojiMehColoredIcon;

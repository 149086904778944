import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Dialog from 'components/Dialog/Dialog';
import DialogActions from 'components/Dialog/DialogActions';
import DialogContent from 'components/Dialog/DialogContent';
import DialogContentText from 'components/Dialog/DialogContentText';
import DialogTitle from 'components/Dialog/DialogTitle';
import Grid from 'components/Grid';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';

const StyledDialogTitle = styled(DialogTitle)`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
`;

const ExtraContent = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const ConfirmationDialog = ({ open, title, description, cancel, onClose, submit, onSubmit, children, disableBackdropClick, submitProps, cancelProps, ...rest }) => {

  const { t } = useTranslation(['common']);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={disableBackdropClick ? undefined : onClose} {...rest}>
      {title && <StyledDialogTitle>{title}</StyledDialogTitle>}

      {(description || children) && (
        <DialogContent>
          {description && <DialogContentText>{description}</DialogContentText>}
          {children && <ExtraContent>{children}</ExtraContent>}
        </DialogContent>
      )}

      <DialogActions>
        <Grid container spacing={4} justifyContent="flex-end" alignItems="center">
          <Grid item><Button onClick={onClose} variant="text" {...cancelProps}>{cancel || t('common:generic.cancel')}</Button></Grid>
          <Grid item><Button onClick={onSubmit} {...submitProps}>{submit || t('common:generic.yes')}</Button></Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,

  title: PropTypes.string,
  description: PropTypes.string,

  cancel: PropTypes.string,
  submit: PropTypes.string,

  disableEscapeKeyDown: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,

  submitProps: PropTypes.object,
  cancelProps: PropTypes.object,
};

export default ConfirmationDialog;
